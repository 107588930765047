@if (activeMethodology || statusBasedViewMode == 'create') {
  <form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
    <p-divider class="w-full" align="left">
      <b>General Information</b>
    </p-divider>
    <div class="formgrid grid p-fluid">
      <div class="col-12 md:col-6">
        <app-basic-input
          label="Name"
          [placeholder]="'Enter Name'"
          [control]="formGroup?.controls?.name"
          [viewMode]="fieldViewMode"
        ></app-basic-input>
      </div>
      <div class="col-12 md:col-6">
        <app-user-and-group-selector
          [label]="'Owner'"
          [placeholder]="'Search...'"
          [principleMode]="true"
          [control]="formGroup?.controls?.owner"
          [optionValue]="undefined"
          [multi]="false"
          [viewMode]="statusBasedViewMode"
        >
        </app-user-and-group-selector>
      </div>
      <div class="col-12 md:col-6">
        <app-user-and-group-selector
          [label]="'Approver'"
          [placeholder]="'Search...'"
          [principleMode]="true"
          [control]="formGroup?.controls?.approver"
          [optionValue]="undefined"
          [multi]="false"
          [viewMode]="statusBasedViewMode"
        >
        </app-user-and-group-selector>
      </div>
      <div class="col-12 md:col-12">
        <app-text-editor
          [height]="'100px'"
          [advanced]="true"
          [name]="'textarea'"
          label="Description"
          [placeholder]="'Enter Text...'"
          [control]="formGroup?.controls?.description"
          [viewMode]="fieldViewMode"
        ></app-text-editor>
      </div>
    </div>
    <p-divider class="w-full" align="left">
      <b>Risk Matrix</b>
    </p-divider>
    <div class="w-full">
      <app-risk-methodology-summary
        [methodology]="activeMethodology"
        [maxThreshold]="maxThreshold"
      ></app-risk-methodology-summary>
    </div>
    @if (activeMethodology || statusBasedViewMode == 'create') {
      <div class="w-full">
        <div class="mt-5 tabs methodology-tabs">
          <p-tabView>
            <p-tabPanel header="Likelihood Values" leftIcon="pi pi-bell">
              <ng-template pTemplate="content">
                <div class="col-12">
                  <app-form-repeater
                    [viewMode]="statusBasedViewMode"
                    [title]="'Likelihood Values'"
                    [cols]="likelihoodValuesFields"
                    [control]="formGroup?.controls.likelihoodValues"
                  ></app-form-repeater>
                </div>
              </ng-template>
            </p-tabPanel>
            <p-tabPanel header="Impact Values" leftIcon="pi pi-bell">
              <ng-template pTemplate="content">
                <div class="col-12">
                  <app-form-repeater
                    [viewMode]="statusBasedViewMode"
                    [title]="'Impact Values'"
                    [cols]="impactValuesFields"
                    [control]="formGroup?.controls.impactValues"
                  ></app-form-repeater>
                </div>
              </ng-template>
            </p-tabPanel>
            <p-tabPanel header="Impact Factors" leftIcon="pi pi-bell">
              <ng-template pTemplate="content">
                <div class="col-12">
                  <app-form-repeater
                    [title]="'Impact Factors'"
                    [viewMode]="statusBasedViewMode"
                    [cols]="impactFactorsFields"
                    [control]="formGroup?.controls.impactFactors"
                    [showOptionsAction]="true"
                    [addOrderFieldToOptions]="false"
                    [optionsFormPopupComponent]="impactFactorOptionsFormPopupComponent"
                    [popupFormExtraData]="{
                      impactValueOptions: formGroup?.controls.impactValues.value,
                    }"
                  ></app-form-repeater>
                </div>
              </ng-template>
            </p-tabPanel>
            <p-tabPanel header="Threshold Info" leftIcon="pi pi-bell">
              <div class="">
                <div class="col-12">
                  @for (Threshold of thresholdList; track Threshold; let i2 = $index) {
                    <div>
                      <app-threshold-item
                        (onRemoveThresholdItem)="onRemoveThresholdItem(thresholdList[i2])"
                        [threshold]="thresholdList[i2]"
                        [floor]="i2 == 0 ? minThreshold : thresholdList[i2 - 1].value + 1"
                        [ceil]="maxThreshold"
                        [disabled]="i2 == thresholdList.length - 1"
                        [viewMode]="statusBasedViewMode"
                      >
                      </app-threshold-item>
                    </div>
                  }
                </div>
                @if (statusBasedViewMode != 'view') {
                  <div class="w-full text-right">
                    <app-button [action]="addItemButtonActionPopup"></app-button>
                  </div>
                }
                <p-divider align="left">
                  <b>Acceptable Risk</b>
                </p-divider>
                <div class="formgrid grid p-fluid mb-2">
                  <!-- <div class="col-1 ml-3 mt-3 flex align-items-center">
                  <label>Acceptable Risk</label>
                </div> -->
                  <div class="col-12">
                    <span>
                      <span class="flex justify-content-between w-100 mt-1">
                        <small>{{ options?.floor }}</small>
                        <p-inplace
                          [closable]="true"
                          styleClass="inplace-algin-super"
                          [style]="{ verticalAlign: 'super' }"
                          [disabled]="statusBasedViewMode == 'view'"
                          [preventClick]="statusBasedViewMode == 'view'"
                        >
                          <ng-template pTemplate="display">
                            <small>{{ acceptableRisk }}</small>
                          </ng-template>
                          <ng-template pTemplate="content">
                            <input
                              type="number"
                              [ngModelOptions]="{ standalone: true }"
                              [(ngModel)]="acceptableRisk"
                              [disabled]="statusBasedViewMode == 'view'"
                              pInputText
                            />
                          </ng-template>
                        </p-inplace>
                        <small>{{ options?.ceil }}</small>
                      </span>
                      <p-slider
                        [min]="options?.floor"
                        [max]="options?.ceil"
                        [step]="10"
                        [ngModelOptions]="{ standalone: true }"
                        [(ngModel)]="acceptableRisk"
                        [disabled]="statusBasedViewMode == 'view'"
                      ></p-slider>
                    </span>
                  </div>
                </div>
              </div>
            </p-tabPanel>
          </p-tabView>
        </div>
      </div>
    }
    <div class="flex flex-row-reverse justify-content-between align-items-center">
      <div class="flex flex-row-reverse gap-2">
        @if (showSaveButton) {
          <app-button [action]="submitButtonAction"></app-button>
        }
        @if (showSaveButton) {
          <app-button [action]="submitSaveButtonAction"></app-button>
        }
        @if (showInPlaceButton) {
          <app-button [action]="submitInPlaceButtonAction"></app-button>
        }
        @if (fieldViewMode == 'create') {
          <app-button [action]="cancelButtonAction"></app-button>
        }
      </div>
      <!-- @if (fieldViewMode == 'create') {
        <app-switch-input
          [label]="'Create another record'"
          [name]="'inPlaceCreate'"
          [labelWidthFit]="true"
          [control]="inPlaceCreateControl"
          [viewMode]="'create'"
          [styleClass]="'mb-0'"
        >
        </app-switch-input>
      } -->
    </div>
    <!-- <div class="flex flex-row-reverse gap-2">
      @if (fieldViewMode != 'view') {
        <app-button [action]="submitButtonActionPopup"></app-button>
      }
    </div> -->
  </form>
}

@if (!activeMethodology && fieldViewMode == 'edit') {
  <div class="custom-skeleton">
    <div class="flex mb-3">
      <div class="w-full mb-2">
        <p-skeleton styleClass="mb-2"></p-skeleton>
        <p-skeleton width="50%" styleClass="mb-2"></p-skeleton>
        <p-skeleton width="20%" styleClass="mb-2"></p-skeleton>
        <p-skeleton width="80%" height="2rem" styleClass="mb-2"></p-skeleton>
        <p-skeleton width="65%" height="4rem"></p-skeleton>
      </div>
    </div>
    <p-skeleton width="100%" height="150px"></p-skeleton>
    <div class="flex justify-content-end gap-2 mt-3">
      <p-skeleton width="8rem" height="2rem"></p-skeleton>
      <p-skeleton width="8rem" height="2rem"></p-skeleton>
    </div>
  </div>
}

import { Injectable } from '@angular/core';
import { DataTypeEnum, IFieldDefinition, TransparencyCaseCategory } from '@shared/classes';
import { BaseFieldDefinitionsService } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class TransparencyCaseCategoryMappingService extends BaseFieldDefinitionsService {
  recordFields: IFieldDefinition[] = [
    {
      name: 'Name',
      key: 'name',
      dataType: DataTypeEnum.Text,
      required: false,
      showInTable: true,
      description: 'Name of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Approver',
      key: 'approver',
      dataType: DataTypeEnum.ResponsibilityListView,
      required: true,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Approver of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Status',
      key: 'status',
      dataType: DataTypeEnum.Badge,
      required: false,
      showInTable: true,
      description: 'Status of the record',
      tableDefinition: {
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: TransparencyCaseCategory.StatusEnum,
        },
      },
    },
    {
      name: 'Reviewers',
      key: 'reviewers',
      dataType: DataTypeEnum.ResponsibilityListView,
      required: true,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Reviewers of the policy',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
          extraKey: '.name',
        },
      },
    },
  ];
  constructor() {
    super();
    this.setAllFields();
  }
}

import { Injectable } from '@angular/core';
import { AppPermissions, DataTypeEnum, IFieldDefinition, IssueDto, TargetTypeEnum } from '@shared/classes';
import { BaseFieldDefinitionsService } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class IssuesMappingService extends BaseFieldDefinitionsService {
  recordFields: IFieldDefinition[] = [
    {
      name: 'Name',
      key: 'name',
      dataType: DataTypeEnum.Text,
      required: true,
      showInTable: true,
      description: 'Record Name',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Parent',
      key: 'parent',
      dataType: DataTypeEnum.CodeLink,
      required: false,
      showInTable: true,
      description: 'Parent of the record',
      tableDefinition: {
        filter: {
          type: 'recordCode',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          recordCodeType: TargetTypeEnum.Issue,
        },
      },
    },
    {
      name: 'Criticality',
      key: 'criticality',
      dataType: DataTypeEnum.Badge,
      required: true,
      showInTable: true,
      description: 'Record Type',
      tableDefinition: {
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: IssueDto.CriticalityEnum,
        },
      },
    },
    {
      name: 'Relation Type',
      key: 'sourceType',
      dataType: DataTypeEnum.Badge,
      required: true,
      showInTable: true,
      description: 'Record relation source type',
      tableDefinition: {
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: TargetTypeEnum,
        },
      },
    },
    {
      name: 'Source Code',
      key: 'sourceCode',
      dataType: DataTypeEnum.CodeLink,
      required: true,
      showInTable: true,
      description: 'Record linked code',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Type',
      key: 'issueTypeCode',
      dataType: DataTypeEnum.CodeLink,
      required: false,
      showInTable: true,
      description: 'Linked issue type of the record',
      tableDefinition: {
        filter: {
          type: 'recordCode',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          recordCodeType: TargetTypeEnum.IssueType,
        },
      },
    },
    {
      name: 'Owner',
      key: 'issueOwner',
      dataType: DataTypeEnum.ResponsibilityListView,
      required: false,
      showInTable: true,
      description: 'Owner of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Status',
      key: 'issueStatus',
      dataType: DataTypeEnum.Badge,
      required: true,
      showInTable: true,
      description: 'Status of the record',
      tableDefinition: {
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: IssueDto.IssueStatusEnum,
        },
      },
    },
    {
      name: 'Assignee',
      key: 'assignee',
      dataType: DataTypeEnum.ResponsibilityListView,
      permissions: AppPermissions.ReadResponsibility,
      required: false,
      showInTable: true,
      description: 'Owner of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Description',
      key: 'description',
      dataType: DataTypeEnum.LongWithHoverText,
      required: true,
      showInTable: true,
      description: 'Record Description',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
  ];
  constructor() {
    super();
    this.setAllFields();
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppPermissions } from '@shared/classes';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { IssueTypesItemComponent } from './issue-types-item/issue-types-item.component';
import { IssueTypesListComponent } from './issue-types-list/issue-types-list.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'list' },
  {
    path: 'list',
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: [AppPermissions.ReadIssueType],
        redirectTo: '/access',
      },
    },
    component: IssueTypesListComponent,
  },
  {
    path: 'create',
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: [AppPermissions.CreateIssueType],
        redirectTo: '/access',
      },
    },
    component: IssueTypesItemComponent,
  },
  {
    path: 'edit/:id',
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: [AppPermissions.UpdateIssueType],
        redirectTo: '/access',
      },
    },
    component: IssueTypesItemComponent,
  },
  {
    path: 'view/:id',
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: [AppPermissions.ReadIssueType],
        redirectTo: '/access',
      },
    },
    component: IssueTypesItemComponent,
  },
  {
    path: 'settings',
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: [AppPermissions.DeleteIssueType],
        redirectTo: '/access',
      },
    },
    loadChildren: () => import('./settings/settings.module').then((m) => m.SettingsModule),
  },
  {
    path: 'bulk-operation',
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: [AppPermissions.UpdateIssueType, AppPermissions.DeleteIssueType],
        redirectTo: '/access',
      },
    },
    loadChildren: () => import('./bulk-operations/bulk-operations.module').then((m) => m.BulkOperationsModule),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class IssueTypesRoutingModule {}

// Returns {results,height: num,noControls: boolean}
// Takes {controlsHidden:bool,controlObjectivesHidden:bool}
export function parseSankey(data, options) {
  if (options?.controlObjectivesHidden || data?.complianceReportType == 'CONTROL_OBJECTIVE') {
    return parseSankeyChartDataWithoutCob(data, options?.controlsHidden);
  } else {
    return parseSankeyChartDataWithCob(data, options?.controlsHidden);
  }
}

// Returns {results,height}
function parseSankeyChartDataWithCob(data, controlsHidden = false) {
  const heightAddtion = 60;
  const defaultComplianceObj = {
    COMPLIANT: 0,
    NON_COMPLIANT: 0,
    NO_RESPONSE: 0,
  };

  let sankeyMergeOptions: any = {
    title: {
      text: 'Sankey Diagram',
    },
    tooltip: {
      trigger: 'item',
      triggerOn: 'mousemove',
      appendToBody: true,
    },
    legend: {
      data: ['Category A', 'Category B', 'Category C'],
      orient: 'vertical',
      left: 10,
      top: 10,
    },

    series: [
      {
        type: 'sankey',
        animationDuration: 1500,
        animationDurationUpdate: 1500,
        universalTransition: true,
        nodeGap: 40, // Add margin between nodes
        data: [],
        links: [],
        emphasis: {
          focus: 'adjacency',
        },
        lineStyle: {
          curveness: 0.5,
        },
        label: {
          position: 'top',
          formatter: labelFormatter,
          offset: [0, 6],
        },
        tooltip: {
          formatter: toolTipFormatter,
        },
        draggable: false,
      },
    ],
  };

  let heightNum = 0;
  let itemExist = {};
  let controlObjectives = {};
  // setTimeout(() => {
  //2 loops to get set the name and code for control objectives in an map object
  for (let i = 0; i < data?.items?.length; i++) {
    const reportItem = data?.items[i];
    for (let j = 0; j < reportItem?.cobResult?.length; j++) {
      const cobItem = reportItem?.cobResult[j];
      if (!itemExist[cobItem.code]) {
        itemExist[cobItem.code] = true;
        controlObjectives[cobItem.code] = { ...cobItem, counts: { ...defaultComplianceObj } };
      }
    }
  }
  let noControls = true;
  //here we count the controls and objectives and add thier links
  for (let i = 0; i < data?.controlGroups?.length; i++) {
    const groupItem = data?.controlGroups[i];
    const groupName = groupItem.code + ' : ' + groupItem.name;
    let groupControlCount = { ...defaultComplianceObj, controls: {} };

    for (let j = 0; j < groupItem?.controls?.length; j++) {
      noControls = false;
      const control = groupItem?.controls[j];
      const controlName = control.code + ' : ' + control.name;
      groupControlCount.controls = {
        ...groupControlCount.controls,
        [controlName]:
          control?.controlComplianceStatus == 'COMPLIANT'
            ? 'COMPLIANT'
            : control?.controlComplianceStatus == 'NON_COMPLIANT'
              ? 'NON_COMPLIANT'
              : 'NO_RESPONSE',
      };
      if (control?.controlComplianceStatus == 'NON_COMPLIANT') {
        if (!controlsHidden) {
          if (!itemExist[controlName]) {
            sankeyMergeOptions?.series[0]?.data.push(
              addSankeyItem(
                controlName,
                3,
                { ...defaultComplianceObj, NON_COMPLIANT: 1 },
                {
                  code: control.code,
                  type: 'CONTROL',
                }
              )
            );
            itemExist[controlName] = true;
            heightNum += heightAddtion;
          }
          sankeyMergeOptions?.series[0]?.links.push(
            addSankeyLink(groupName, controlName, { ...defaultComplianceObj, NON_COMPLIANT: 1 })
          );
        }
        groupControlCount = { ...groupControlCount, NON_COMPLIANT: groupControlCount.NON_COMPLIANT + 1 };
      } else if (control?.controlComplianceStatus == 'COMPLIANT') {
        if (!controlsHidden) {
          if (!itemExist[controlName]) {
            sankeyMergeOptions?.series[0]?.data.push(
              addSankeyItem(
                controlName,
                3,
                { ...defaultComplianceObj, COMPLIANT: 1 },
                {
                  code: control.code,
                  type: 'CONTROL',
                }
              )
            );
            itemExist[controlName] = true;
            heightNum += heightAddtion;
          }
          sankeyMergeOptions?.series[0]?.links.push(
            addSankeyLink(groupName, controlName, { ...defaultComplianceObj, COMPLIANT: 1 })
          );
        }
        groupControlCount = { ...groupControlCount, COMPLIANT: groupControlCount.COMPLIANT + 1 };
      } else {
        if (!controlsHidden) {
          if (!itemExist[controlName]) {
            sankeyMergeOptions?.series[0]?.data.push(
              addSankeyItem(
                controlName,
                3,
                { ...defaultComplianceObj, NO_RESPONSE: 1 },
                {
                  code: control.code,
                  type: 'CONTROL',
                }
              )
            );
            itemExist[controlName] = true;
            heightNum += heightAddtion;
          }
          sankeyMergeOptions?.series[0]?.links.push(
            addSankeyLink(groupName, controlName, { ...defaultComplianceObj, NO_RESPONSE: 1 })
          );
        }
        groupControlCount = { ...groupControlCount, NO_RESPONSE: groupControlCount.NO_RESPONSE + 1 };
      }
    }
    for (let j = 0; j < groupItem?.controlObjectives?.length; j++) {
      const cobItem = groupItem?.controlObjectives[j];
      controlObjectives[cobItem] = {
        ...controlObjectives[cobItem],
        controls: { ...controlObjectives[cobItem]?.controls, ...groupControlCount?.controls },
        group: groupName,
        counts: {
          COMPLIANT: controlObjectives[cobItem]?.counts.COMPLIANT + groupControlCount.COMPLIANT,
          NON_COMPLIANT: controlObjectives[cobItem]?.counts.NON_COMPLIANT + groupControlCount.NON_COMPLIANT,
          NO_RESPONSE: controlObjectives[cobItem]?.counts.NO_RESPONSE + groupControlCount.NO_RESPONSE,
        },
      };
      const cobName = controlObjectives[cobItem].code + ' : ' + controlObjectives[cobItem].name;
      if (groupControlCount.NON_COMPLIANT)
        sankeyMergeOptions?.series[0]?.links.push(
          addSankeyLink(
            groupName,
            cobName,
            { ...defaultComplianceObj, NON_COMPLIANT: 1 },
            groupControlCount.NON_COMPLIANT
          )
        );
      if (groupControlCount.COMPLIANT)
        sankeyMergeOptions?.series[0]?.links.push(
          addSankeyLink(groupName, cobName, { ...defaultComplianceObj, COMPLIANT: 1 }, groupControlCount.COMPLIANT)
        );
      if (groupControlCount.NO_RESPONSE)
        sankeyMergeOptions?.series[0]?.links.push(
          addSankeyLink(groupName, cobName, { ...defaultComplianceObj, NO_RESPONSE: 1 }, groupControlCount.NO_RESPONSE)
        );
    }
    if (!itemExist[groupName]) {
      sankeyMergeOptions?.series[0]?.data.push(
        addSankeyItem(groupName, 2, groupControlCount, {
          code: groupItem.code,
          counts: groupControlCount,
          type: 'CONTROL_GROUP',
        })
      );
      itemExist[groupName] = true;
    }
  }

  //here we count the scope and add thier links
  for (let i = 0; i < data?.items?.length; i++) {
    const reportItem = data?.items[i];
    const reportName = reportItem.code + ' : ' + reportItem.name;
    let scopeControlCount = { ...defaultComplianceObj };
    let controls = {};
    for (let j = 0; j < reportItem?.cobResult?.length; j++) {
      const cobItem = reportItem?.cobResult[j];
      const cobName = cobItem.code + ' : ' + cobItem.name;
      if (!itemExist[cobName]) {
        itemExist[cobName] = true;
        sankeyMergeOptions?.series[0]?.data.push(
          addSankeyItem(cobName, 1, controlObjectives[cobItem.code]?.counts, {
            code: cobItem.code,
            counts: controlObjectives[cobItem.code]?.counts,
            type: 'CONTROL_OBJECTIVE',
          })
        );
      }
      controls = { ...controls, ...controlObjectives[cobItem.code]?.controls };

      // if (controlObjectives[cobItem.code]?.counts?.COMPLIANT || controlObjectives[cobItem.code]?.counts?.NON_COMPLIANT || controlObjectives[cobItem.code]?.counts?.NO_RESPONSE) {
      if (controlObjectives[cobItem.code]?.counts?.NON_COMPLIANT)
        sankeyMergeOptions?.series[0]?.links.push(
          addSankeyLink(
            cobName,
            reportName,
            { ...defaultComplianceObj, NON_COMPLIANT: 1 },
            controlObjectives[cobItem.code]?.counts?.NON_COMPLIANT
          )
        );
      if (controlObjectives[cobItem.code]?.counts?.COMPLIANT)
        sankeyMergeOptions?.series[0]?.links.push(
          addSankeyLink(
            cobName,
            reportName,
            { ...defaultComplianceObj, COMPLIANT: 1 },
            controlObjectives[cobItem.code]?.counts?.COMPLIANT
          )
        );
      if (controlObjectives[cobItem.code]?.counts?.NO_RESPONSE)
        sankeyMergeOptions?.series[0]?.links.push(
          addSankeyLink(
            cobName,
            reportName,
            { ...defaultComplianceObj, NO_RESPONSE: 1 },
            controlObjectives[cobItem.code]?.counts?.NO_RESPONSE
          )
        );
      // }
    }
    Object.entries(controls).forEach(([key, value]) => {
      scopeControlCount = {
        COMPLIANT: scopeControlCount.COMPLIANT + (value == 'COMPLIANT' ? 1 : 0),
        NON_COMPLIANT: scopeControlCount.NON_COMPLIANT + (value == 'NON_COMPLIANT' ? 1 : 0),
        NO_RESPONSE: scopeControlCount.NO_RESPONSE + (value == 'NO_RESPONSE' ? 1 : 0),
      };
    });
    if (!itemExist[reportName]) {
      itemExist[reportName] = true;
      sankeyMergeOptions?.series[0]?.data.push(
        addSankeyItem(reportName, 0, scopeControlCount, {
          code: reportItem.code,
          counts: scopeControlCount,
          type: 'SCOPE',
        })
      );
    }
  }
  const objectivesHeight = (Object.keys(controlObjectives)?.length ?? 0) * heightAddtion;
  const scopeHeight = data?.items?.length * heightAddtion;

  // }, 0);
  const height = Math.max(heightNum, objectivesHeight, scopeHeight, 400) + 'px';
  return { result: sankeyMergeOptions, height: height, noControls: noControls };
}
// Returns {results,height}
function parseSankeyChartDataWithoutCob(data, controlsHidden = false) {
  const heightAddtion = 60;
  const defaultComplianceObj = {
    COMPLIANT: 0,
    NON_COMPLIANT: 0,
    NO_RESPONSE: 0,
  };
  let sankeyMergeOptions: any = {
    title: {
      text: 'Sankey Diagram',
    },
    tooltip: {
      trigger: 'item',
      triggerOn: 'mousemove',
      appendToBody: true,
    },
    legend: {
      data: ['Category A', 'Category B', 'Category C'],
      orient: 'vertical',
      left: 10,
      top: 10,
    },

    series: [
      {
        type: 'sankey',
        animationDuration: 1500,
        animationDurationUpdate: 1500,
        universalTransition: true,
        nodeGap: 40, // Add margin between nodes
        data: [],
        links: [],
        emphasis: {
          focus: 'adjacency',
        },
        lineStyle: {
          curveness: 0.5,
        },
        label: {
          position: 'top',
          formatter: labelFormatter,
          offset: [0, 6],
        },
        tooltip: {
          formatter: toolTipFormatter,
        },
        draggable: false,
      },
    ],
  };

  let heightNum = 0;
  let itemExist = {};
  let groups = {};
  let controlObjectives = {};
  // setTimeout(() => {
  //2 loops to get set the name and code for control objectives in an map object
  for (let i = 0; i < data?.items?.length; i++) {
    const reportItem = data?.items[i];
    for (let j = 0; j < reportItem?.cobResult?.length; j++) {
      const cobItem = reportItem?.cobResult[j];
      if (!itemExist[cobItem.code]) {
        itemExist[cobItem.code] = true;
        controlObjectives[cobItem.code] = { ...cobItem, counts: { ...defaultComplianceObj } };
      }
    }
  }
  let noControls = true;
  //here we count the controls and objectives and add thier links
  for (let i = 0; i < data?.controlGroups?.length; i++) {
    const groupItem = data?.controlGroups[i];
    const groupName = groupItem.code + ' : ' + groupItem.name;
    let groupControlCount = { ...defaultComplianceObj, controls: {} };

    for (let j = 0; j < groupItem?.controls?.length; j++) {
      noControls = false;
      const control = groupItem?.controls[j];
      const controlName = control.code + ' : ' + control.name;
      groupControlCount.controls = {
        ...groupControlCount.controls,
        [controlName]:
          control?.controlComplianceStatus == 'COMPLIANT'
            ? 'COMPLIANT'
            : control?.controlComplianceStatus == 'NON_COMPLIANT'
              ? 'NON_COMPLIANT'
              : 'NO_RESPONSE',
      };
      if (control?.controlComplianceStatus == 'NON_COMPLIANT') {
        if (!controlsHidden) {
          if (!itemExist[controlName]) {
            sankeyMergeOptions?.series[0]?.data.push(
              addSankeyItem(
                controlName,
                2,
                { ...defaultComplianceObj, NON_COMPLIANT: 1 },
                {
                  code: control.code,
                  type: 'CONTROL',
                }
              )
            );
            itemExist[controlName] = true;
            heightNum += heightAddtion;
          }
          sankeyMergeOptions?.series[0]?.links.push(
            addSankeyLink(groupName, controlName, { ...defaultComplianceObj, NON_COMPLIANT: 1 })
          );
        }
        groupControlCount = { ...groupControlCount, NON_COMPLIANT: groupControlCount.NON_COMPLIANT + 1 };
      } else if (control?.controlComplianceStatus == 'COMPLIANT') {
        if (!controlsHidden) {
          if (!itemExist[controlName]) {
            sankeyMergeOptions?.series[0]?.data.push(
              addSankeyItem(
                controlName,
                2,
                { ...defaultComplianceObj, COMPLIANT: 1 },
                {
                  code: control.code,
                  type: 'CONTROL',
                }
              )
            );
            itemExist[controlName] = true;
            heightNum += heightAddtion;
          }
          sankeyMergeOptions?.series[0]?.links.push(
            addSankeyLink(groupName, controlName, { ...defaultComplianceObj, COMPLIANT: 1 })
          );
        }
        groupControlCount = { ...groupControlCount, COMPLIANT: groupControlCount.COMPLIANT + 1 };
      } else {
        if (!controlsHidden) {
          if (!itemExist[controlName]) {
            sankeyMergeOptions?.series[0]?.data.push(
              addSankeyItem(
                controlName,
                2,
                { ...defaultComplianceObj, NO_RESPONSE: 1 },
                {
                  code: control.code,
                  type: 'CONTROL',
                }
              )
            );
            itemExist[controlName] = true;
            heightNum += heightAddtion;
          }
          sankeyMergeOptions?.series[0]?.links.push(
            addSankeyLink(groupName, controlName, { ...defaultComplianceObj, NO_RESPONSE: 1 })
          );
        }
        groupControlCount = { ...groupControlCount, NO_RESPONSE: groupControlCount.NO_RESPONSE + 1 };
      }
    }
    for (let j = 0; j < groupItem?.controlObjectives?.length; j++) {
      const cobItem = groupItem?.controlObjectives[j];
      controlObjectives[cobItem] = {
        ...controlObjectives[cobItem],
        controls: { ...controlObjectives[cobItem]?.controls, ...groupControlCount?.controls },
        group: groupName,
        counts: {
          COMPLIANT: controlObjectives[cobItem]?.counts.COMPLIANT + groupControlCount.COMPLIANT,
          NON_COMPLIANT: controlObjectives[cobItem]?.counts.NON_COMPLIANT + groupControlCount.NON_COMPLIANT,
          NO_RESPONSE: controlObjectives[cobItem]?.counts.NO_RESPONSE + groupControlCount.NO_RESPONSE,
        },
      };
      controlObjectives[cobItem].groups = { ...controlObjectives[cobItem].groups, [groupName]: true };
    }
    groups[groupName] = {
      ...groups[groupName],
      controls: { ...groups[groupName]?.controls, ...groupControlCount.controls },
    };
    if (!itemExist[groupName]) {
      sankeyMergeOptions?.series[0]?.data.push(
        addSankeyItem(groupName, 1, groupControlCount, {
          code: groupItem.code,
          counts: groupControlCount,
          type: 'CONTROL_GROUP',
        })
      );
      itemExist[groupName] = true;
    }
  }

  //here we count the scope and add thier links
  for (let i = 0; i < data?.items?.length; i++) {
    const reportItem = data?.items[i];
    const reportName = reportItem.code + ' : ' + reportItem.name;
    let scopeControlCount = { ...defaultComplianceObj };
    let controls = {};
    for (let j = 0; j < reportItem?.cobResult?.length; j++) {
      const cobItem = reportItem?.cobResult[j];
      const cobName = cobItem.code + ' : ' + cobItem.name;
      controls = { ...controls, ...controlObjectives[cobItem.code]?.controls };

      if (controlObjectives[cobItem.code].groups) {
        Object.keys(controlObjectives[cobItem.code].groups).forEach((x) => {
          groups[x].scopes = { ...groups[x].scopes, [reportName]: true };
        });
      }
    }
    Object.entries(controls).forEach(([key, value]) => {
      scopeControlCount = {
        COMPLIANT: scopeControlCount.COMPLIANT + (value == 'COMPLIANT' ? 1 : 0),
        NON_COMPLIANT: scopeControlCount.NON_COMPLIANT + (value == 'NON_COMPLIANT' ? 1 : 0),
        NO_RESPONSE: scopeControlCount.NO_RESPONSE + (value == 'NO_RESPONSE' ? 1 : 0),
      };
    });
    if (!itemExist[reportName]) {
      itemExist[reportName] = true;
      sankeyMergeOptions?.series[0]?.data.push(
        addSankeyItem(reportName, 0, scopeControlCount, {
          code: reportItem.code,
          counts: scopeControlCount,
          type: 'SCOPE',
        })
      );
    }
  }

  Object.entries(groups).forEach(([groupKey, groupVal]: [any, any]) => {
    let complianceCount = { ...defaultComplianceObj };
    if (groupVal?.controls) {
      Object.values(groupVal?.controls).forEach((x: any) => {
        complianceCount[x]++;
      });
    }
    if (groupVal?.scopes) {
      Object.keys(groupVal.scopes).forEach((x) => {
        if (complianceCount?.NON_COMPLIANT)
          sankeyMergeOptions?.series[0]?.links.push(
            addSankeyLink(groupKey, x, { ...defaultComplianceObj, NON_COMPLIANT: 1 }, complianceCount?.NON_COMPLIANT)
          );
        if (complianceCount?.COMPLIANT)
          sankeyMergeOptions?.series[0]?.links.push(
            addSankeyLink(groupKey, x, { ...defaultComplianceObj, COMPLIANT: 1 }, complianceCount?.COMPLIANT)
          );
        if (complianceCount?.NO_RESPONSE)
          sankeyMergeOptions?.series[0]?.links.push(
            addSankeyLink(groupKey, x, { ...defaultComplianceObj, NO_RESPONSE: 1 }, complianceCount?.NO_RESPONSE)
          );
      });
    }
  });
  const groupsHeight = (Object.keys(groups)?.length ?? 0) * heightAddtion;
  const scopeHeight = data?.items?.length * heightAddtion;
  const height = Math.max(controlsHidden ? 0 : heightNum, scopeHeight, groupsHeight, 400) + 'px';
  return { result: sankeyMergeOptions, height: height, noControls: noControls };
}
function getGradientColor(nonCompliantCount, compliantCount, noResponseCount) {
  const totalCount = nonCompliantCount + compliantCount + noResponseCount;
  const badgeColors = MyBadgeColors;
  // Check if any count is zero to avoid division by zero
  if (totalCount === 0) {
    // Return a default gradient (e.g., all gray)
    return {
      type: 'linear',
      x: 1,
      y: 0,
      x2: 1,
      y2: 1,
      colorStops: [
        { offset: 0, color: badgeColors['NO_RESPONSE'] },
        { offset: 1, color: badgeColors['NO_RESPONSE'] },
      ],
      global: false,
    };
  }

  const redOffset = nonCompliantCount / totalCount;
  const greenOffset = (nonCompliantCount + compliantCount) / totalCount;

  return {
    type: 'linear',
    x: 1,
    y: 0,
    x2: 1,
    y2: 1,
    colorStops: [
      { offset: 0, color: badgeColors['NON_COMPLIANT'] }, // Start with red
      { offset: redOffset, color: badgeColors['NON_COMPLIANT'] }, // Red to Green transition
      { offset: redOffset, color: badgeColors['COMPLIANT'] }, // Green starts
      { offset: greenOffset, color: badgeColors['COMPLIANT'] }, // Green to Gray transition
      { offset: greenOffset, color: badgeColors['NO_RESPONSE'] }, // Gray starts
      { offset: 1, color: badgeColors['NO_RESPONSE'] }, // End with gray
    ],
    global: false, // False by default, can be omitted
  };
}
function addSankeyItem(item, depth, complianceObj, payload = null) {
  const color = getGradientColor(complianceObj.NON_COMPLIANT, complianceObj.COMPLIANT, complianceObj.NO_RESPONSE);
  const badgeColors = MyBadgeColors;

  return {
    name: item,
    depth: depth,
    payload: payload,
    type: 'node',
    tooltip: {
      borderColor:
        badgeColors[
          payload?.type == 'CONTROL'
            ? complianceObj.NON_COMPLIANT
              ? 'NON_COMPLIANT'
              : complianceObj.NO_RESPONSE
                ? 'NO_RESPONSE'
                : complianceObj.COMPLIANT
                  ? 'COMPLIANT'
                  : 'NO_RESPONSE'
            : complianceObj.NON_COMPLIANT
              ? 'NON_COMPLIANT'
              : complianceObj.NO_RESPONSE
                ? 'NON_COMPLIANT'
                : complianceObj.COMPLIANT
                  ? 'COMPLIANT'
                  : 'NO_RESPONSE'
        ],
      position: function (point, params, dom, rect, size) {
        const xOffsetRight = 20; // Offset to the right of the mouse pointer
        const xOffsetLeft = 20; // Offset to the left of the mouse pointer
        const yOffset = 10; // Offset for the vertical position

        // Calculate tooltip position for the right side
        let xPosRight = point[0] + xOffsetRight;
        if (xPosRight + size.contentSize[0] > size.viewSize[0]) {
          xPosRight = size.viewSize[0] - size.contentSize[0] - xOffsetRight;
        }

        // Calculate tooltip position for the left side
        let xPosLeft = point[0] - size.contentSize[0] - xOffsetLeft;
        if (xPosLeft < 0) {
          xPosLeft = xOffsetLeft;
        }

        // Default vertical position
        let yPos = point[1] + yOffset;
        if (yPos + size.contentSize[1] > size.viewSize[1]) {
          yPos = size.viewSize[1] - size.contentSize[1] - yOffset;
        }

        if (payload?.type == 'SCOPE' || payload?.type == 'CONTROL_OBJECTIVE') {
          // Position the tooltip to the right of the mouse
          return [xPosRight, yPos];
        } else if (payload?.type == 'CONTROL' || payload?.type == 'CONTROL_GROUP') {
          // Position the tooltip to the left of the mouse
          return [xPosLeft, yPos];
        } else {
          return null; // Default behavior for other types
        }
      },
    },
    label: {
      position: payload?.type == 'CONTROL' ? 'right' : 'top',
      offset: payload?.type == 'CONTROL' ? [0, 0] : [0, 6],
    },
    itemStyle: {
      color: color,
    },
  };
}

function addSankeyLink(source, target, complianceObj, value = 1) {
  const color = getGradientColor(complianceObj.NON_COMPLIANT, complianceObj.COMPLIANT, complianceObj.NO_RESPONSE);
  const badgeColors = MyBadgeColors;

  return {
    source: source,
    target: target,
    tooltip: {
      borderColor:
        badgeColors[
          complianceObj.NON_COMPLIANT ? 'NON_COMPLIANT' : complianceObj.NO_RESPONSE ? 'NO_RESPONSE' : 'COMPLIANT'
        ],
      position: function (point, params, dom, rect, size) {
        const xOffset = 20; // Horizontal offset from the mouse pointer
        const yOffset = 20; // Vertical offset from the mouse pointer

        // Calculate default tooltip position (right and below the pointer)
        let xPos = point[0] + xOffset;
        let yPos = point[1] + yOffset;

        // Adjust position to keep tooltip within the viewable area horizontally
        if (xPos + size.contentSize[0] > size.viewSize[0]) {
          xPos = point[0] - size.contentSize[0] - xOffset;
          if (xPos < 0) {
            xPos = size.viewSize[0] - size.contentSize[0] - xOffset;
          }
        }

        // Adjust position to keep tooltip within the viewable area vertically
        if (yPos + size.contentSize[1] > size.viewSize[1]) {
          yPos = point[1] - size.contentSize[1] - yOffset;
          if (yPos < 0) {
            yPos = size.viewSize[1] - size.contentSize[1] - yOffset;
          }
        }

        return [xPos, yPos];
      },
    },
    type: 'link',
    value: value,
    lineStyle: { color: color },
  };
}

function toolTipFormatter(params) {
  // Customize the tooltip content
  const controlsColor = params?.data?.tooltip?.borderColor;

  if (params?.data?.type == 'link') {
    const name = params?.name;
    const value = params?.value;
    const badgeColors = MyBadgeColors;

    const iconHTML = '<span class="pi pi-arrow-right" style="font-weight: bold;"></span>';
    if (name.length > 100) {
      // Find the first occurrence of '>'
      const index = name.indexOf('>');
      if (index !== -1) {
        // Split the name at the first '>'
        const firstPart = name.substring(0, index + 1); // Including '>'
        const secondPart = name.substring(index + 1);

        // Replace '>' with icon
        const formattedFirstPart = firstPart.replace(/>/g, iconHTML);
        const formattedSecondPart = secondPart.replace(/>/g, iconHTML);

        // Format the parts
        const formattedName = formattedFirstPart + '<br/>' + formattedSecondPart;
        return (
          formattedName +
          '<br/>' +
          '<span style="display: inline-block; margin-right: 4px; border-radius: 50%; width: 10px; height: 10px; background-color: ' +
          controlsColor +
          ';"></span>' +
          '<span style="font-weight: bold;">' +
          'Controls : ' +
          value +
          '</span>'
        );
      }
    }

    // If name length is not greater than 100 or no '>' found, replace all '>' with icon
    const formattedName = name.replace(/>/g, iconHTML);
    return (
      formattedName +
      '<br/>' +
      '<span style="display: inline-block; margin-right: 4px; border-radius: 50%; width: 10px; height: 10px; background-color: ' +
      controlsColor +
      ';"></span>' +
      '<span style="font-weight: bold;">' +
      'Controls : ' +
      value +
      '</span>'
    );
  }
  let content = '<div>';

  const total =
    params?.data?.payload?.counts?.COMPLIANT +
    params?.data?.payload?.counts?.NON_COMPLIANT +
    params?.data?.payload?.counts?.NO_RESPONSE;

  if (!total)
    return (
      '<span style="display: inline-block; margin-right: 4px; border-radius: 50%; width: 10px; height: 10px; background-color: ' +
      controlsColor +
      ';"></span>' +
      params?.name
    );
  const compliantCount = params?.data?.payload?.counts?.COMPLIANT;
  const incompliantCount = params?.data?.payload?.counts?.NON_COMPLIANT;
  const noResponseCount = params?.data?.payload?.counts?.NO_RESPONSE;

  const compliantPercentage = ((compliantCount / total) * 100).toFixed(2);
  const incompliantPercentage = ((incompliantCount / total) * 100).toFixed(2);
  const noResponsePercentage = ((noResponseCount / total) * 100).toFixed(2);

  content += '<strong>' + params?.name + '</strong><br/>';

  content +=
    '<span style="display: inline-block; margin-right: 4px; border-radius: 50%; width: 10px; height: 10px; background-color: #EF5350;"></span>';
  content += 'Non Compliant: ' + incompliantCount + '/' + total + ' (' + incompliantPercentage + '%)<br/>';

  content +=
    '<span style="display: inline-block; margin-right: 4px; border-radius: 50%; width: 10px; height: 10px; background-color: #9CCC65;"></span>';
  content += 'Compliant: ' + compliantCount + '/' + total + ' (' + compliantPercentage + '%)<br/>';

  content +=
    '<span style="display: inline-block; margin-right: 4px; border-radius: 50%; width: 10px; height: 10px; background-color: #BDBDBD;"></span>';
  content += 'No Response: ' + noResponseCount + '/' + total + ' (' + noResponsePercentage + '%)<br/>';

  content += '</div>';
  return content;
}

function labelFormatter(params) {
  const len = 40;
  if (params?.name?.length > len + 3) {
    return params?.name?.substring(0, len) + '...';
  } else {
    return params?.name;
  }
}

enum MyBadgeColors {
  'COMPLIANT' = '#9CCC65',
  'NON_COMPLIANT' = '#EF5350',
  'NO_RESPONSE' = '#BDBDBD',
}

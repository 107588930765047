import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  BaseForm,
  DataTypeEnum,
  IAction,
  IDynamicComponent,
  IViewMode,
  LikelihoodValue,
  RiskMethodology,
  RiskMethodologyDto,
  ThresholdValue,
  getEnumOptions,
} from '@shared/classes';
import { BasicInputComponent } from '@shared/components/ui/input-fields/basic-input/basic-input.component';
import { DropDownInputComponent } from '@shared/components/ui/input-fields/drop-down-input/drop-down-input.component';
import { NumberInputComponent } from '@shared/components/ui/input-fields/number-input/number-input.component';
import { TextEditorComponent } from '@shared/components/ui/input-fields/text-editor/text-editor.component';
import { AppDialogService, IConfirmationConfig, IConfirmationPosition } from '@shared/services/app-dialog.service';
import { ViewModeService } from '@shared/services/view-mode.service';
import { findIndex, maxBy, minBy } from 'lodash-es';
import { RiskMethodologyDataService } from '../../../services/data/risk-methodology-data.service';
import { ImpactFactorOptionsFormPopupComponent } from './impact-factor-options-form-popup/impact-factor-options-form-popup.component';

@Component({
  selector: 'app-risk-methodology-form',
  templateUrl: './risk-methodology-form.component.html',
  styleUrls: ['./risk-methodology-form.component.scss'],
})
export class RiskMethodologyFormComponent extends BaseForm<RiskMethodologyDto> implements OnInit {
  impactFactorOptionsFormPopupComponent = ImpactFactorOptionsFormPopupComponent;
  acceptableRisk: number = 100;

  options = {
    floor: 0,
    ceil: 100,
  };

  @Input() submitButtonActionPopup: IAction = {
    id: 1,
    label: 'Save',
    buttonType: 'button',
    command: this.onSubmitConfirm.bind(this),
    icon: 'pi pi-save',
    passEvent: true,
  };

  addItemButtonActionPopup: IAction = {
    id: 1,
    label: 'Add Item',
    buttonType: 'button',
    command: this.onAddThreshold.bind(this),
    buttonClass: '',
    icon: 'pi pi-plus',
    passEvent: true,
  };

  activeMethodology: RiskMethodology;

  likelihoodValuesFields: IDynamicComponent[] = [];
  impactValuesFields: IDynamicComponent[] = [];
  impactFactorsFields: IDynamicComponent[] = [];
  public thresholdList: ThresholdValue[];

  public minThreshold;
  public maxThreshold;

  constructor(
    public viewModeService: ViewModeService,
    public appDialogService: AppDialogService,
    private riskMethodologyDataService: RiskMethodologyDataService
  ) {
    super(viewModeService);
    this.initFormRepeaterFields();
  }

  ngOnInit(): void {
    // this.riskMethodologyDataService.fetchActiveRiskMethodology().subscribe({
    //   next: (res) => {
    //     this.setData(this.activeMethodology);
    //   },
    //   error: (error) => {

    //   },
    //   complete: () => {},
    // });

    this.formGroup.valueChanges.subscribe((active) => {
      this.reloadValues({ ...active });
    });
  }

  private reloadValues(active: RiskMethodology) {
    this.activeMethodology = active;
    this.thresholdList = this.activeMethodology?.thresholdValues;
    this.calculateMinMax();
  }

  calculateMinMax() {
    this.maxThreshold =
      maxBy(this.activeMethodology?.impactValues, 'value')?.value *
        maxBy(this.activeMethodology?.likelihoodValues, 'value')?.value || 0;

    this.minThreshold =
      minBy(this.activeMethodology?.impactValues, 'value')?.value *
        minBy(this.activeMethodology?.likelihoodValues, 'value')?.value || 0;

    this.options = {
      floor: this.minThreshold,
      ceil: this.maxThreshold,
    };
  }

  getData() {
    const retData = {
      ...this.formGroup.getRawValue(),
      acceptableRisk: this.acceptableRisk,
      thresholdValues: this.thresholdList ? [...this.thresholdList] : [],
    };
    let currentTime = new Date().getTime();
    let riskMeth: RiskMethodology = retData;
    riskMeth.impactFactors = riskMeth?.impactFactors?.map((item, index) => {
      return {
        ...item,
        code: item.code ? item.code : `IFA-${currentTime + index}`,
      };
    });
    riskMeth.likelihoodValues = riskMeth?.likelihoodValues?.map((item, index) => {
      return {
        ...item,
        code: item.code ? item.code : `LHV-${currentTime + index}`,
      };
    });
    riskMeth.impactValues = riskMeth?.impactValues?.map((item, index) => {
      return {
        ...item,
        code: item.code ? item.code : `IMV-${currentTime + index}`, // (currentTime+index),//`IMV-${currentTime+index}`,
      };
    });
    return this.viewModeService.viewMode == 'create'
      ? this.getDataKeyValueFormat(riskMeth)
      : this.getChangedFormValues(riskMeth).updateItems;
  }

  setData(data: any) {
    let currentTimeStamp = new Date().getTime();
    data?.impactValues?.forEach((element, index) => {
      element.code = element.code ?? `IMV-${currentTimeStamp + index}`;
    });
    this.formGroup.patchValue({ ...data });
    this.data = data;
    this.reloadValues(data);
    this.acceptableRisk = this.activeMethodology?.acceptableRisk;
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(null, Validators.required),
      owner: new FormControl(null),
      approver: new FormControl(null),
      description: new FormControl(null),
      likelihoodValues: new FormControl(null),
      impactValues: new FormControl(null),
      impactFactors: new FormControl([]),
      thresholdValues: new FormControl(null),
      acceptableRisk: new FormControl(null),
    });
  }

  initFormRepeaterFields() {
    this.likelihoodValuesFields = [
      {
        componentType: BasicInputComponent,
        options: {
          label: 'Code',
          name: 'code',
          control: new FormControl(null),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
          hideInFormRepeater: true,
          formatGetValue: (control) => {
            let currentTimeStamp = new Date().getTime();
            return control?.value ?? `LHV-${currentTimeStamp}`;
          },
        },
      },
      {
        componentType: BasicInputComponent,
        options: {
          label: 'Name',
          name: 'name',
          control: new FormControl(null, Validators.required),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
      },
      {
        componentType: NumberInputComponent,
        options: {
          label: 'Value',
          name: 'value',
          control: new FormControl(null, Validators.required),
          dataType: DataTypeEnum.Number,
          showLabelInViewMode: false,
          inputOptions: {
            numberInput: {
              showButtons: true,
              min: 0,
            },
          },
        },
      },
      {
        componentType: NumberInputComponent,
        options: {
          label: 'From',
          name: 'occuresFrom',
          control: new FormControl(null),
          dataType: DataTypeEnum.Number,
          inputOptions: {
            numberInput: {
              showButtons: true,
              min: 0,
            },
          },
          showLabelInViewMode: false,
        },
      },
      {
        componentType: NumberInputComponent,
        options: {
          label: 'To',
          name: 'occuresTo',
          control: new FormControl(null),
          dataType: DataTypeEnum.Number,
          inputOptions: {
            numberInput: {
              showButtons: true,
              min: 0,
            },
          },
          showLabelInViewMode: false,
        },
      },
      {
        componentType: DropDownInputComponent,
        options: {
          label: 'Duration',
          name: 'occurrenceDuration',
          control: new FormControl(null),
          inputOptions: {
            dropDownInput: {
              multi: false,
              showClear: true,
              optionLabel: 'label',
              optionValue: 'value',
              items: getEnumOptions(LikelihoodValue.OccurrenceDurationEnum),
              badgeView: true,
              appendTo: 'body',
            },
          },
          dataType: DataTypeEnum.Badge,
          showLabelInViewMode: false,
        },
      },
    ];

    this.impactValuesFields = [
      {
        componentType: BasicInputComponent,
        options: {
          label: 'Code',
          name: 'code',
          control: new FormControl(null),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
          hideInFormRepeater: true,
          formatGetValue: (control) => {
            let currentTimeStamp = new Date().getTime();
            return control?.value ?? `IMV-${currentTimeStamp}`;
          },
        },
      },
      {
        componentType: BasicInputComponent,
        options: {
          label: 'Name',
          name: 'name',
          control: new FormControl(null, Validators.required),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
      },
      {
        componentType: NumberInputComponent,
        options: {
          label: 'Value',
          name: 'value',
          control: new FormControl(null, Validators.required),
          dataType: DataTypeEnum.Number,
          inputOptions: {
            numberInput: {
              showButtons: true,
              min: 0,
            },
          },
          showLabelInViewMode: false,
        },
      },
    ];
    this.impactFactorsFields = [
      {
        componentType: BasicInputComponent,
        options: {
          label: 'Code',
          name: 'code',
          control: new FormControl(null),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
          hideInFormRepeater: true,
          formatGetValue: (control) => {
            let currentTimeStamp = new Date().getTime();
            return control?.value ?? `IFA-${currentTimeStamp}`;
          },
        },
      },
      {
        componentType: BasicInputComponent,
        options: {
          label: 'Name',
          name: 'name',
          control: new FormControl(null, Validators.required),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
      },
      {
        componentType: BasicInputComponent,
        options: {
          label: 'Field Options',
          name: 'options',
          control: new FormControl([]),
          dataType: DataTypeEnum.Text,
          inputOptions: {
            dynamicTypeOptionsInput: {
              fieldType: null,
              fieldTypeControl: null,
            },
            // linkedControlName:'type',
          },
          showLabelInViewMode: false,
          hideInFormRepeater: true,
        },
      },
      {
        componentType: TextEditorComponent,
        options: {
          label: 'Description',
          name: 'description',
          control: new FormControl(null),
          dataType: DataTypeEnum.LongWithHoverText,
          showLabelInViewMode: false,
          hideInFormRepeater: true,
        },
      },
    ];
  }

  onSubmitConfirm(event: any) {
    let config: IConfirmationConfig = new IConfirmationConfig();

    config.position = IConfirmationPosition.top;

    config.header = `Are you sure about editing the current Risk Methodology`;
    config.message = 'This Action Will Reset The Current Registered Risks.';

    this.appDialogService.confirm(
      {
        accept: () => {
          this.onSubmitForm();
        },
      },
      config
    );
  }

  onAddThreshold() {
    let threshold = <ThresholdValue>{};
    threshold.color = '#999999';
    threshold.value = 1;

    if (this.thresholdList == undefined || this.thresholdList.length == 0) {
      threshold.value = this.maxThreshold;
      this.thresholdList = [threshold];
    } else {
      this.thresholdList.push(threshold);
    }

    this.activeMethodology.thresholdValues = this.thresholdList;
  }

  onRemoveThresholdItem(item) {
    let index = findIndex(this.thresholdList, item);
    this.thresholdList.splice(index, 1);
    this.calculateMinMax();
  }
  get statusBasedViewMode(): IViewMode {
    return !this.data || this.data?.status == 'DRAFT' ? this.fieldViewMode : 'view';
  }
}

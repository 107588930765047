import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseForm, DataTypeEnum, EntityTypeFieldDto, FilterItem } from '@shared/classes';
import { Remediation } from '@shared/classes/model/backend/issue';
import { ViewModeService } from '@shared/services/view-mode.service';
import { RemediationPlanDataService } from '../../services/data/remediation-plan-data.service';
import { IssueDto } from './../../../../shared/classes/model/backend/issue/model/issueDto';

@Component({
  selector: 'app-remediation-plan-item-form',
  templateUrl: './remediation-plan-item-form.component.html',
  styleUrls: ['./remediation-plan-item-form.component.scss'],
})
export class RemediationPlanItemFormComponent extends BaseForm<Remediation> implements OnInit {
  categoryList = [EntityTypeFieldDto.CategoryEnum.Responsibility];
  dataTypeEnum = DataTypeEnum;
  options = {
    floor: 0,
    ceil: 100,
  };
  actualDatesFormGroup: FormGroup;
  @Input()
  itemId: string;

  @Input()
  searchT: string = null;

  @Input()
  editMode: Boolean = false;

  extraFilters: FilterItem[] = [
    {
      property: 'issueStatus',
      operation: 'IN',
      value: [IssueDto.IssueStatusEnum.Analysis],
    },
  ];

  constructor(
    private requestService: RemediationPlanDataService,

    public viewModeService: ViewModeService
  ) {
    super(viewModeService);
    this.initFormStructure();
  }

  getData() {
    return this.viewModeService.viewMode == 'create'
      ? this.getDataKeyValueFormat()
      : this.getChangedFormValues().updateItems;
  }

  setData(data: any) {
    this.formGroup.patchValue({ ...data });
    this.data = data;
    this.actualDatesFormGroup.patchValue({ ...data });
  }
  ngOnInit(): void {}

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(null, Validators.required),
      description: new FormControl(null),
      owner: new FormControl(null, Validators.required),
      approvers: new FormControl(null, Validators.required),
      issues: new FormControl(null, Validators.required),
      // status: new FormControl(null, Validators.required),
      startDate: new FormControl(null, Validators.required),
      actualStartDate: new FormControl(null),
      dueDate: new FormControl(null, Validators.required),
      actualCloseDate: new FormControl(null),
      progress: new FormControl(0),
    });
    this.actualDatesFormGroup = new FormGroup({
      actualStartDate: new FormControl(null),
      actualCloseDate: new FormControl(null),
    });
  }
}

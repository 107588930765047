/**
 * Sightflare Service
 * Rest Api for Sightflare Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { GlobalPackageCount } from './globalPackageCount';
import { RelationExportDto } from './relationExportDto';


export interface GeneratedPackage { 
    creatorName?: string;
    creationDate?: string;
    documentUrl?: string;
    counts?: Array<GlobalPackageCount>;
    relations?: Array<RelationExportDto>;
}


/**
 * Sightflare Service
 * Rest Api for Sightflare Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { QuestionnaireAnswerDto } from './questionnaireAnswerDto';
import { RiskItemsDto } from './riskItemsDto';


export interface AssignRisksDto { 
    riskItemsDto?: RiskItemsDto;
    questionnaireValue?: QuestionnaireAnswerDto;
}


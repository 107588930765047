import { Injectable } from '@angular/core';
import { AppPermissions, AttestationDto, DataTypeEnum, IFieldDefinition } from '@shared/classes';
import { BaseFieldDefinitionsService } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class AttestationsMappingService extends BaseFieldDefinitionsService {
  recordFields: IFieldDefinition[] = [
    {
      name: 'Questionnaire Answer',
      key: 'questionnaireValueId',
      dataType: DataTypeEnum.CodeLink,
      required: false,
      showInTable: true,
      permissions: AppPermissions.ReadQuestionnaireValue,
      description: 'Questionnaire Answer',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Compliance Status',
      key: 'controlComplianceStatus',
      dataType: DataTypeEnum.Badge,
      required: true,
      showInTable: true,
      description: 'Control Compliance Status',
      tableDefinition: {
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: AttestationDto.ControlComplianceStatusEnum,
        },
      },
    },
    {
      name: 'Controls',
      key: 'controls',
      dataType: DataTypeEnum.Text,
      required: false,
      showInTable: true,
      description: 'Record Linked Controls',
      tableDefinition: {
        filter: {
          type: 'text',
          matchMode: 'startsWith',
        },
      },
    },
  ];
  constructor() {
    super();
    this.setAllFields();
  }
}

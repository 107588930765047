<div class="formgrid grid p-fluid">
  <div class="col-6">
    <app-basic-input
      label=""
      [placeholder]="'Search Query'"
      [control]="formGroup?.controls?.query"
      [viewMode]="'create'"
    ></app-basic-input>
  </div>
  <div class="col-6">
    <app-tag-search-input
      [viewMode]="'create'"
      [placeholder]="'Search Tags...'"
      [controls]="{ tagStringsControl: tagsStringControl, tagCodesControl: formGroup?.controls?.tags }"
    ></app-tag-search-input>
  </div>
</div>
<div class="mt-2 overflow-y-auto" style="max-height: 60dvh">
  @if (searchable) {
    @if (loading) {
      <div class="mb-4 w-full text-center">
        <i class="pi pi-spin pi-spinner text-400"></i>
      </div>
    } @else {
      @if (searchItems.length) {
        <div class="flex flex-column gap-2">
          <app-tab-menu
            class="w-full"
            [scrollable]="true"
            [model]="tabItems"
            [activeItem]="currentItem"
            (activeItemChange)="onActiveItemChange($event)"
          />

          @if (filterLoading) {
            <div class="mb-4 w-full text-center">
              <i class="pi pi-spin pi-spinner text-400"></i>
            </div>
          } @else if (currentItem?.id == 'all') {
            @for (item of allItems; track item) {
              <app-code-navigation [staticData]="item" [data]="item"></app-code-navigation>
            }
          } @else {
            @for (item of filteredItems; track item) {
              <app-code-navigation [staticData]="item" [data]="item"></app-code-navigation>
            }
          }
        </div>
      } @else {
        <div class="mb-4 w-full text-center">
          <app-text-view [data]="'No Results Found'"></app-text-view>
        </div>
      }
    }
  } @else {
    <div class="mb-4 w-full text-center">
      <app-text-view [data]="'No Tags Selected'"></app-text-view>
    </div>
  }
</div>
@if (filteredItems?.length || allItems?.length) {
  <div class="mt-2">
    <app-button [action]="goToMorePageAction"></app-button>
  </div>
}

import { Injectable } from '@angular/core';
import { AuditCase } from '@shared/classes/model/backend/audit';
import { IFieldDefinition } from '@shared/classes/view/AppFieldDefinition';
import { DataTypeEnum } from '@shared/classes/view/view-enums';
import { BaseFieldDefinitionsService } from '@shared/services/base-field-definitions.service';

@Injectable({
  providedIn: 'root',
})
export class AuditCaseMappingService extends BaseFieldDefinitionsService {
  recordFields: IFieldDefinition[] = [
    {
      name: 'Name',
      key: 'name',
      dataType: DataTypeEnum.Text,
      required: false,
      showInTable: true,
      description: 'Name of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Audit Case Status',
      key: 'auditCaseStatus',
      dataType: DataTypeEnum.Badge,
      required: false,
      showInTable: true,
      description: 'Audit Case Status of the record',
      tableDefinition: {
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: AuditCase.AuditCaseStatusEnum,
        },
      },
    },
    {
      name: 'Audit Program',
      key: 'auditProgram',
      dataType: DataTypeEnum.CodeLink,
      required: false,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Audit Program to this Record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Audit Project',
      key: 'auditProject',
      dataType: DataTypeEnum.CodeLink,
      required: false,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Audit Project to this Record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Owner',
      key: 'owner',
      dataType: DataTypeEnum.ResponsibilityListView,
      required: true,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Owner of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Respondent',
      key: 'respondent',
      dataType: DataTypeEnum.ResponsibilityListView,
      required: true,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Respondent of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Auditor',
      key: 'auditor',
      dataType: DataTypeEnum.ResponsibilityListView,
      required: true,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Auditor of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Auditee',
      key: 'auditee',
      dataType: DataTypeEnum.ResponsibilityListView,
      required: true,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Auditee of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Audit Questionnaire',
      key: 'auditQuestionnaire',
      dataType: DataTypeEnum.CodeLink,
      required: false,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Audit Questionnaire to this Record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Evidence Questionnaire',
      key: 'evidenceQuestionnaire',
      dataType: DataTypeEnum.Text,
      required: false,
      showInTable: true,
      description: 'Evidence Questionnaire of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Planned Start Date',
      key: 'plannedStartDate',
      required: false,
      showInTable: true,
      description: 'Planned Start Date of the record',
      dataType: DataTypeEnum.DateLong,
      tableDefinition: {
        filter: {
          type: 'date',
          matchMode: 'dateBefore',
        },
      },
    },
    {
      name: 'Planned End Date',
      key: 'plannedEndDate',
      required: false,
      showInTable: true,
      description: 'Planned End Date of the record',
      dataType: DataTypeEnum.DateLong,
      tableDefinition: {
        filter: {
          type: 'date',
          matchMode: 'dateBefore',
        },
      },
    },
    {
      name: 'Planned Duration',
      key: 'plannedDuration',
      dataType: DataTypeEnum.Text,
      required: false,
      showInTable: true,
      description: 'Planned Duration of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Actual Start Date',
      key: 'actualStartDate',
      required: false,
      showInTable: true,
      description: 'Actual Start Date of the record',
      dataType: DataTypeEnum.DateLong,
      tableDefinition: {
        filter: {
          type: 'date',
          matchMode: 'dateBefore',
        },
      },
    },
    {
      name: 'Actual End Date',
      key: 'actualEndDate',
      required: false,
      showInTable: true,
      description: 'Actual End Date of the record',
      dataType: DataTypeEnum.DateLong,
      tableDefinition: {
        filter: {
          type: 'date',
          matchMode: 'dateBefore',
        },
      },
    },
    {
      name: 'Actual Duration',
      key: 'actualDuration',
      dataType: DataTypeEnum.Text,
      required: false,
      showInTable: true,
      description: 'Actual Duration of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Actual Time Resources',
      key: 'actualTimeResources',
      dataType: DataTypeEnum.Text,
      required: false,
      showInTable: true,
      description: 'Actual Time Resources of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
  ];
  constructor() {
    super();
    this.setAllFields();
  }
}

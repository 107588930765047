/**
 * Sightflare Service
 * Rest Api for Sightflare Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FilterItem } from './filterItem';
import { RunAs } from './runAs';
import { RelationFilterItem } from './relationFilterItem';
import { SearchFilter } from './searchFilter';


export interface SearchBody { 
    filters?: Array<FilterItem>;
    relationFilters?: Array<RelationFilterItem>;
    textSearch?: SearchFilter;
    runAs?: RunAs;
    projectionFields?: Array<string>;
}


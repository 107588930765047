import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  BaseForm,
  DataTypeEnum,
  PermissionActions,
  TypesWithName,
  getEnumOptions,
  isNullObj
} from '@shared/classes';
import { BulkOperation } from '@shared/classes/view/BulkOperation';
import { BulkOperationFieldsMapper } from '@shared/classes/view/BulkOperationFieldsMapper';
import { RelationsService, ViewModeService } from '@shared/services';
import { BulkOperationService } from '@shared/services/bulk-operation-service/bulk-operation.service';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'app-bulk-operation-confirmation-step-form',
  templateUrl: './bulk-operation-confirmation-step-form.component.html',
  styleUrls: ['./bulk-operation-confirmation-step-form.component.scss'],
})
export class BulkOperationConfirmationStepFormComponent extends BaseForm<any> implements OnInit {
  @Input() stepperService: BulkOperationService;
  bulkOperations = BulkOperation;
  items = [];
  dataTypes = DataTypeEnum;

  //Add Relations
  tagType;
  excludedCodes: string[] = [];
  listOfTypes = [];
  selectedType;

  constructor(
    public viewModeService: ViewModeService,
    private permissionService: NgxPermissionsService,
    private relationServiec: RelationsService
  ) {
    super(viewModeService);
  }

  ngOnInit(): void {
    if (this.stepperService.operation == this.bulkOperations.Delete || this.stepperService.operation == this.bulkOperations.PermenantDelete) {
      this.initializeDeleteOperation();
      return;
    }
    if (this.stepperService.operation == this.bulkOperations.AddRelations) {
      this.initializeAddRelationsOperation();
      return;
    }
    this.initializeUpdateOperation();
  }

  initializeAddRelationsOperation() {
    this.formGroup = new FormGroup({
      targetType: new FormControl(null, Validators.required),
      notes: new FormControl(null),
      targetCode: new FormControl(null, Validators.required),
      relationType: new FormControl(null, Validators.required),
    });
    this.excludedCodes = this.stepperService.items.map((x) => x.code + '');

    this.formGroup?.controls?.targetType.valueChanges.subscribe((res) => {
      this.selectedType = res ?? null;
      this.formGroup?.patchValue({
        targetCode: null,
      });
    });
    const perms = this.permissionService.getPermissions();

    const allowedTypeList = [];
    getEnumOptions(TypesWithName).forEach((element) => {
      if (!isNullObj(perms?.[`${PermissionActions.Read}${element.value}`])) {
        allowedTypeList.push(element);
      }
    });
    this.relationServiec
      .searchRelationTypes(
        { all: true },
        {},
        { showLoading: true, showMsg: false, extras: { cacheKey: 'allRelationTypes' } }
      )
      .subscribe((res) => {
        this.tagType = res;
      });
    this.listOfTypes = [...allowedTypeList];
    this.formGroup.patchValue({ ...this.stepperService.stepperItem });
  }

  initializeDeleteOperation() {
    this.formGroup = new FormGroup([]);
  }

  initializeUpdateOperation() {
    this.formGroup = new FormGroup(
      BulkOperationFieldsMapper[this.stepperService.moduleKeyword]
        .filter((x, idx) => this.stepperService.checkControls[idx].value)
        .reduce((acc, x) => {
          acc[x.options.name] = x.options.control;
          return acc;
        }, {})
    );
    this.items = this.stepperService.items.map((x) => {
      return {
        code: x.code,
        oldFields: BulkOperationFieldsMapper[this.stepperService.moduleKeyword]
          .filter(
            (f, idx) =>
              this.stepperService.checkControls[idx].value &&
              this.stepperService.stepperItem[f.options.name] !== undefined
          )
          .map((y) => {
            return {
              ...y,
              options: { ...y.options, showLabelInViewMode: true, control: new FormControl(x[y.options.name]) },
            };
          }),
        newFields: BulkOperationFieldsMapper[this.stepperService.moduleKeyword]
          .filter(
            (f, idx) =>
              this.stepperService.checkControls[idx].value &&
              this.stepperService.stepperItem[f.options.name] !== undefined
          )
          .map((y) => {
            return {
              ...y,
              options: { ...y.options, control: new FormControl(this.stepperService.stepperItem[y.options.name]) },
            };
          }),
      };
    });
  }
  getData() {
    return this.getChangedFormValues(this.data);
  }

  setData(data: any) {
    this.data = data;
  }

  initFormStructure(): void {}
}

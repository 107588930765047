/**
 * Sightflare Service
 * Rest Api for Sightflare Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Composites } from './composites';


export interface RoleRepresentation { 
    id?: string;
    name?: string;
    description?: string;
    /** @deprecated */
    scopeParamRequired?: boolean;
    composite?: boolean;
    composites?: Composites;
    clientRole?: boolean;
    containerId?: string;
    attributes?: { [key: string]: Array<string>; };
}


/**
 * Sightflare Service
 * Rest Api for Sightflare Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Column } from './column';
import { SortField } from './sortField';


export interface TableViewConfig { 
    columns?: Array<Column>;
    showActions?: boolean;
    showOperationActions?: boolean;
    showGlobalFilter?: boolean;
    showSettingsLink?: boolean;
    showFileExportAction?: boolean;
    showPackageExportAction?: boolean;
    showBulkOperationAction?: boolean;
    showDeleteAction?: boolean;
    showEditAction?: boolean;
    showLockAction?: boolean;
    showActivateAction?: boolean;
    showDeactivateAction?: boolean;
    showStatusActions?: boolean;
    showAddAction?: boolean;
    showUnLockAction?: boolean;
    sortFields?: Array<SortField>;
    showReviseRelationsAction?: boolean;
}


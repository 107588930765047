/**
 * Sightflare Service
 * Rest Api for Sightflare Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RoleRepresentation } from './roleRepresentation';
import { Client } from './client';


export interface ClientRole { 
    id?: string;
    name?: string;
    composite?: boolean;
    clientRole?: boolean;
    description?: string;
    roleComposites?: Set<RoleRepresentation>;
    clients?: Array<Client>;
}


import { Injectable } from '@angular/core';
import { DataTypeEnum, Guideline, IFieldDefinition } from '@shared/classes';
import { BaseFieldDefinitionsService } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class GuidelineMappingService extends BaseFieldDefinitionsService {
  recordFields: IFieldDefinition[] = [
    {
      name: 'Name',
      key: 'name',
      dataType: DataTypeEnum.Text,
      required: true,
      showInTable: true,
      description: 'Record Name',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      importerDefinition: {
        type: 'text',
        field: {
          validators: null,
        },
      },
    },
    {
      name: 'Status',
      key: 'status',
      dataType: DataTypeEnum.Badge,
      required: false,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Guidline Status',
      tableDefinition: {
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: Guideline.StatusEnum,
        },
      },
    },
    {
      name: 'Details',
      key: 'details',
      dataType: DataTypeEnum.LongWithHoverText,
      required: false,
      showInTable: false,
      description: 'Record Details',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      importerDefinition: {
        type: 'text',
        field: {
          validators: null,
        },
      },
    },
    {
      name: 'Description',
      key: 'description',
      dataType: DataTypeEnum.LongWithHoverText,
      required: false,
      showInTable: false,
      description: 'Record Description',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      importerDefinition: {
        type: 'text',
        field: {
          validators: null,
        },
      },
    },
  ];
  constructor() {
    super();
    this.setAllFields();
  }
}

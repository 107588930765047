import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'splitString',
})
export class SplitStringPipe implements PipeTransform {
  transform(value: any, separator: string | RegExp, args?: any): string[] {
    if (typeof value === 'string') return value.split(separator);
    else return value;
  }
}

/**
 * Sightflare Service
 * Rest Api for Sightflare Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Account { 
    id?: string;
    firstName?: string;
    lastName?: string;
    userName?: string;
    email?: string;
    emailVerified?: boolean;
    enable?: boolean;
}


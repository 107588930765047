<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid">
    <div class="col-12 md:col-6">
      <app-basic-input
        label="Name"
        [placeholder]="'Name'"
        [control]="formGroup?.controls?.name"
        [viewMode]="fieldViewMode"
      ></app-basic-input>
    </div>

    <div class="col-12 md:col-6">
      <!-- <app-entity-category-tree-selector
      [label]="'Owner'"
      [categoryList]="categoryList"
      [placeholder]="'Search Entities'"
      [control]="formGroup?.controls?.owner" [multi]="false"
      [selectedEntities]="[data?.owner]"
      [viewMode]="fieldViewMode"
      [dropDown]="true">
    </app-entity-category-tree-selector> -->
      <app-user-and-group-selector
        [label]="'Owner'"
        [placeholder]="'Search...'"
        [principleMode]="true"
        [control]="formGroup?.controls?.owner"
        [optionValue]="undefined"
        [multi]="false"
        [viewMode]="fieldViewMode"
      >
      </app-user-and-group-selector>
    </div>

    <div class="col-12 md:col-6">
      <!-- <app-entity-category-tree-selector
    [label]="'Approvers'"
    [categoryList]="categoryList"
    [placeholder]="'Search Entities'"
    [control]="formGroup?.controls?.approvers"
    [selectedEntities]="data?.approvers"
    [multi]="true"
    [viewMode]="fieldViewMode"
    [dropDown]="true"
    [useResponsibilityMode]="true"
    [dataType]="dataTypeEnum.ResponsibilityWithAllOneMode"
    >
  </app-entity-category-tree-selector> -->
      <app-user-and-group-tree-selector
        [label]="'Approvers'"
        [placeholder]="'Search...'"
        [control]="formGroup?.controls?.approvers"
        [multi]="true"
        [selectedEntities]="data?.approvers"
        [viewMode]="fieldViewMode"
        [useResponsibilityMode]="true"
        [principleModeSelection]="'SELECTION'"
      >
      </app-user-and-group-tree-selector>
    </div>

    <div class="col-12 md:col-6">
      <app-target-code-selector
        [label]="'Issues'"
        [placeholder]="'Search Issues'"
        [control]="formGroup?.controls?.issues"
        [viewMode]="fieldViewMode"
        [multi]="true"
        [optionLabel]="'name'"
        [optionValue]="'code'"
        [targetTypes]="['ISSUE']"
        [extraFilters]="extraFilters"
      >
      </app-target-code-selector>
    </div>
    <div class="col-12 md:col-6">
      <app-date-input
        [label]="'Start Date'"
        [placeholder]="'11/22/2022'"
        [name]="'startDate'"
        [showIcon]="true"
        [control]="formGroup?.controls?.startDate"
        [viewMode]="fieldViewMode"
        [maxDate]="formGroup?.controls?.dueDate?.value || null"
      ></app-date-input>
    </div>

    <div class="col-12 md:col-6">
      <app-date-input
        [label]="'Due Date '"
        [placeholder]="'11/22/2022'"
        [name]="'dueDate'"
        [showIcon]="true"
        [control]="formGroup?.controls?.dueDate"
        [viewMode]="fieldViewMode"
        [minDate]="formGroup?.controls?.startDate?.value || null"
      ></app-date-input>
    </div>
    @if (editMode && actualDatesFormGroup?.controls?.actualStartDate) {
      <div class="col-12 md:col-6">
        <app-date-input
          [label]="'Actual Start Date '"
          [placeholder]="'11/22/2022'"
          [name]="'actualStartDate'"
          [showIcon]="false"
          [control]="actualDatesFormGroup?.controls?.actualStartDate"
          [viewMode]="'view'"
        ></app-date-input>
      </div>
    }
    @if (editMode && actualDatesFormGroup?.controls?.actualCloseDate) {
      <div class="col-12 md:col-6">
        <app-date-input
          [label]="'Actual Close Date '"
          [placeholder]="'11/22/2022'"
          [name]="'actualCloseDate'"
          [showIcon]="false"
          [control]="actualDatesFormGroup?.controls?.actualCloseDate"
          [viewMode]="'view'"
        ></app-date-input>
      </div>
    }

    <!-- <div class="col-12 md:col-6">
<label>Progress </label>

<ngx-slider
  [options]="options"
  [(value)]="progress"
></ngx-slider>

</div> -->
    @if (editMode) {
      <div class="col-12 md:col-6">
        <app-p-slider-input
          [name]="'progress'"
          label="Progress "
          [placeholder]="'Enter Progress Number'"
          [control]="formGroup?.controls?.progress"
          [viewMode]="fieldViewMode"
          [valueSuffix]="'%'"
        ></app-p-slider-input>
      </div>
    }
    <div class="col-12 md:col-12">
      <app-text-editor
        [name]="'description'"
        label="Description"
        [placeholder]="'Enter Text...'"
        [control]="formGroup?.controls?.description"
        [viewMode]="fieldViewMode"
        [advanced]="true"
      ></app-text-editor>
    </div>
  </div>
  <div class="flex flex-row-reverse justify-content-between align-items-center">
    <div class="flex flex-row-reverse gap-2">
      @if (showSaveButton) {
        <app-button [action]="submitButtonAction"></app-button>
      }
      @if (showSaveButton) {
        <app-button [action]="submitSaveButtonAction"></app-button>
      }
      @if (showInPlaceButton) {
        <app-button [action]="submitInPlaceButtonAction"></app-button>
      }
      @if (fieldViewMode == 'create') {
        <app-button [action]="cancelButtonAction"></app-button>
      }
    </div>
    @if (fieldViewMode == 'create') {
      <app-switch-input
        [label]="'Create another record'"
        [name]="'inPlaceCreate'"
        [labelWidthFit]="true"
        [control]="inPlaceCreateControl"
        [viewMode]="'create'"
        [styleClass]="'mb-0'"
      >
      </app-switch-input>
    }
  </div>
</form>

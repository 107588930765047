import { ComponentRef, Directive, Input, OnInit, Type, ViewContainerRef } from '@angular/core';
import { UnsubscribeOnDestroyAdapter } from '@shared/classes';

@Directive({
  selector: '[appDynamicViewComponent]',
})
export class DynamicViewComponentDirective extends UnsubscribeOnDestroyAdapter implements OnInit {
  @Input() dynamicComponent: Type<any>;
  component: ComponentRef<any>;

  constructor(public viewContainerRef: ViewContainerRef) {
    super();
  }
  ngOnInit(): void {
    this.component = this.viewContainerRef?.createComponent(this.dynamicComponent);
  }
}

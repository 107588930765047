<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid">
    <div class="col-6">
      <div class="w-12">
        <app-target-code-selector
          [label]="'Relation Type'"
          [placeholder]="'Enter Relation Type'"
          [control]="formGroup?.controls?.relationType"
          [viewMode]="'create'"
          [multi]="false"
          [optionLabel]="'name'"
          [optionValue]="'name'"
          [showStatusBadge]="false"
          [customProjectionFields]="['code', 'recordStatus', 'label', 'name', 'color', 'icon']"
          [badgeView]="true"
          [useCustomBadgeMode]="true"
          [targetTypes]="[tagType]"
          [inlineMode]="true"
          [listBoxMode]="false"
        >
        </app-target-code-selector>
      </div>
      <div class="w-12">
        <app-drop-down-input
          label="Target Type"
          [placeholder]="'Select Item'"
          [name]="'dropdown'"
          [optionLabel]="'label'"
          [items]="listOfTypes"
          [optionValue]="'value'"
          [control]="formGroup?.controls?.targetType"
          [badgeView]="true"
        ></app-drop-down-input>
      </div>
    </div>
    <div class="col-6">
      <app-text-editor
        [height]="'100px'"
        [advanced]="true"
        [name]="'textarea'"
        [label]="''"
        [placeholder]="'Enter Text...'"
        [control]="formGroup?.controls?.notes"
      ></app-text-editor>
    </div>
    @if (selectedType) {
      <div class="col-12 md:col-12">
        <app-target-code-selector
          [excludeCodes]="sourceCode ? [sourceCode] : []"
          [label]="'Target Element'"
          [placeholder]="'Search Items'"
          [control]="formGroup?.controls?.targetCode"
          [viewMode]="'create'"
          [multi]="true"
          [targetTypes]="[selectedType]"
          [listBoxMode]="true"
          [inlineMode]="true"
          [optionValue]="undefined"
          [dataKey]="'code'"
        >
        </app-target-code-selector>
      </div>
    }
  </div>

  <div class="flex flex-row-reverse gap-2">
    @if (fieldViewMode != 'view' && showSaveAndClose) {
      <app-button [action]="submitButtonAction"></app-button>
    }
    @if (showSaveButton) {
      <app-button [action]="submitSaveButtonAction"></app-button>
    }
  </div>
</form>

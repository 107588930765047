import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  AcknowledgmentRequest,
  BaseForm,
  PermissionActions,
  TypesWithName,
  getEnumOptions,
  isNullObj,
} from '@shared/classes';
import { BulkOperation } from '@shared/classes/view/BulkOperation';
import { BulkOperationFieldsMapper } from '@shared/classes/view/BulkOperationFieldsMapper';
import { ViewModeService } from '@shared/services';
import { BulkOperationService } from '@shared/services/bulk-operation-service/bulk-operation.service';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'app-bulk-operation-details-step-form',
  templateUrl: './bulk-operation-details-step-form.component.html',
  styleUrls: ['./bulk-operation-details-step-form.component.scss'],
})
export class BulkOperationDetailsStepFormComponent extends BaseForm<any> implements OnInit {
  @Input() stepperService: BulkOperationService;

  bulkOperations = BulkOperation;

  //Update
  fields;

  //Add Relations
  tagType = AcknowledgmentRequest.TargetTypeEnum.RelationType;
  excludedCodes: string[] = [];
  listOfTypes = [];
  selectedType;

  constructor(
    public viewModeService: ViewModeService,
    private permissionService: NgxPermissionsService
  ) {
    super(viewModeService);
  }

  ngOnInit(): void {
    if (this.stepperService.operation == this.bulkOperations.Delete || this.stepperService.operation == this.bulkOperations.PermenantDelete) {
      this.initializeDeleteOperation();
      return;
    }
    if (this.stepperService.operation == this.bulkOperations.AddRelations) {
      this.initializeAddRelationsOperation();
      return;
    }
    this.initializeUpdateOperation();
  }

  initializeAddRelationsOperation() {
    this.formGroup = new FormGroup({
      targetType: new FormControl(null, Validators.required),
      notes: new FormControl(null),
      targetCode: new FormControl(null, Validators.required),
      relationType: new FormControl(null, Validators.required),
    });
    this.excludedCodes = this.stepperService.items.map((x) => x.code + '');

    this.formGroup?.controls?.targetType?.valueChanges.subscribe((res) => {
      this.selectedType = res ?? null;
      this.formGroup?.patchValue({
        targetCode: null,
      });
    });
    const perms = this.permissionService.getPermissions();

    const allowedTypeList = [];
    getEnumOptions(TypesWithName).forEach((element) => {
      if (!isNullObj(perms?.[`${PermissionActions.Read}${element.value}`])) {
        allowedTypeList.push(element);
      }
    });
    this.listOfTypes = [...allowedTypeList];
  }

  initializeDeleteOperation() {
    this.formGroup = new FormGroup([]);
  }

  initializeUpdateOperation() {
    this.fields = [...BulkOperationFieldsMapper[this.stepperService.moduleKeyword]];
    this.stepperService.checkControls = this.stepperService.checkControls.map((x) => new FormControl(x.value));
    this.formGroup = new FormGroup(
      BulkOperationFieldsMapper[this.stepperService.moduleKeyword]
        .filter((x, idx) => this.stepperService.checkControls[idx].value)
        .reduce((acc, x) => {
          acc[x.options.name] = x.options.control;
          return acc;
        }, {})
    );
  }

  getData() {
    return this.getChangedFormValues(this.data);
  }

  setData(data: any) {
    // if (this.formGroup)
    //   this.formGroup.patchValue({ ...data });
    this.data = data;
  }

  initFormStructure(): void {}

  formChecked(controlName: string, control: FormControl, event) {
    if (controlName) {
      if (this.formGroup.contains(controlName)) {
        this.formGroup.removeControl(controlName);
      } else {
        this.formGroup.addControl(controlName, control);
      }
    }
  }
}

import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { BaseViewItem, getModuleKeywordByCode, humanizeCasedString } from '@shared/classes';
import { CodeLabelCacheService, PathResolverService } from '@shared/services';
import { isArray } from 'lodash-es';
import { MenuItem } from 'primeng/api';
import { DropdownFilterOptions } from 'primeng/dropdown';

@Component({
  selector: 'app-target-code-list-box-view',
  templateUrl: './target-code-list-box-view.component.html',
  styleUrl: './target-code-list-box-view.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class TargetCodeListBoxViewComponent extends BaseViewItem implements OnInit {
  filterValue: string | undefined = '';

  _value;
  @Input() set value(value) {
    this._value = value;
    this.fetchCodeLabels();
  }
  get value() {
    return this._value;
  }
  _currentItem: MenuItem;
  set currentItem(value: MenuItem) {
    this._currentItem = value ?? [];
    this.listOfData = [...value?.list];
    this.filteredData = [...value?.list];
    this.customFilterFunction(null);
  };
  get currentItem() {
    return this._currentItem;
  }
  @Input() filterFields: string[] = ['code', 'label'];
  @Input() preventSelection = true;
  @Input() heigth = '30vh';
  @Input() dirty: boolean = false;
  @Input() invalid: boolean = false;
  @Input() disabled: boolean = false;
  @Input() buttonEnabled: boolean = false;
  @Output() onButtonClick: EventEmitter<any> = new EventEmitter();

  menuItems: MenuItem[] = [];
  listOfData = [];
  currentList = [];
  filteredData = [];
  loading = true;

  constructor(
    private pathResolverService: PathResolverService,
    private codeLabelCacheService: CodeLabelCacheService
  ) {
    super();
  }
  ngOnInit(): void { }

  fetchCodeLabels() {
    this.listOfData = [];

    if (this.value && isArray(this.value)) {
      this.loading = true;
      let listOfObj = {};
      this.value.forEach(x => {
        this.codeLabelCacheService.addCode(x).subscribe(y => {
          const code = x?.code ? x.code : x;
          listOfObj[code] = { code: code, loading: y.loading };
          this.listOfData = [...Object.values(listOfObj).map(z => z)];
          this.filteredData = [...this.listOfData];
          if (this.listOfData?.length >= this.value?.length) {
            this.initMenuItems();
          }
        });
      });
    } else {
      this.loading = false;
    }
  }

  initMenuItems() {
    this.menuItems = [];
    let moduleMap = {};
    this.listOfData.forEach(x => {
      try {
        const module = getModuleKeywordByCode(x?.code);
        moduleMap[module] = [...(moduleMap[module] ?? []), x];
      } catch (e) {

      }
    });
    this.menuItems.push({
      label: humanizeCasedString('All'),
      list: this.listOfData,
      command: () => { },
    });
    this.currentItem = this.currentItem ?? {
      label: humanizeCasedString('All'),
      list: this.listOfData,
      command: () => { },
    };
    Object.entries(moduleMap).forEach(x => {
      this.menuItems.push({
        label: humanizeCasedString(x[0].toString()),
        list: x[1],
        command: () => { },
      });
    });
  }

  preventSelectionFunc(event): void {
    if (this.preventSelection) {
      event.preventDefault(); // Prevent default selection behavior
    }
  }

  customFilterFunction(event: KeyboardEvent) {
    // options?.filter(event);
    if (!this.filterValue || !this.filterFields.length) {
      this.filteredData = [...this.listOfData]; // Return the original list if no query or fields are provided
    }

    const query = this.filterValue.toLowerCase(); // Make the query case-insensitive

    this.filteredData = [...this.listOfData.filter(object =>
      this.filterFields.some(field =>
        String(object[field]).toLowerCase().includes(query)
      )
    )];
  }
  resetFunction(options: DropdownFilterOptions) {
    options?.reset();
    this.filterValue = '';
  }
  buttonClick() {
    this.onButtonClick?.emit(this);
  }


}

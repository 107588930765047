import { Injectable } from '@angular/core';
import {
  AcknowledgmentRequestDto,
  AppPermissions,
  DataTypeEnum,
  IFieldDefinition
} from '@shared/classes';
import { BaseFieldDefinitionsService } from '@shared/services';
import { AcknowledgementRequestProgressBarComponent } from '../../../../modules/acknowledgement-module/acknowledgement-request/acknowledgement-request-progress-bar/acknowledgement-request-progress-bar.component';
import { AcknowledgementRequestProgressFinishedComponent } from '../../../../modules/acknowledgement-module/acknowledgement-request/acknowledgement-request-progress-finished/acknowledgement-request-progress-bar.component';

@Injectable({
  providedIn: 'root',
})
export class AcknowledgmentRequestMappingService extends BaseFieldDefinitionsService {
  recordFields: IFieldDefinition[] = [
    {
      name: 'Name',
      key: 'name',
      dataType: DataTypeEnum.Text,
      required: true,
      showInTable: true,
      description: 'Record Name',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Owner',
      key: 'owner',
      dataType: DataTypeEnum.ResponsibilityListView,
      required: true,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Owner group of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Approver',
      key: 'approver',
      dataType: DataTypeEnum.ResponsibilityListView,
      required: true,
      showInTable: true,
      permissions: AppPermissions.ReadResponsibility,
      description: 'Users who should approve',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Acknowledgers',
      key: 'acknowledgers',
      dataType: DataTypeEnum.ResponsibilityListView,
      required: true,
      showInTable: true,
      permissions: AppPermissions.ReadResponsibility,
      description: 'Users who will are required to acknowledge',
      tableDefinition: {
        sortDisabled: true,
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
          extraKey: '.name',
        },
      },
    },
    {
      name: 'Is Satisfied',
      key: 'isSatisfied',
      dataType: DataTypeEnum.Badge,
      required: false,
      showInTable: true,
      description: 'Is the acknowledgment request satisfied?',
      tableDefinition: {
        filter: {
          type: 'boolean',
          display: 'menu',
          matchMode: 'equals',
        },
      },
    },
    {
      name: 'Progress Status',
      key: 'progress',
      dataType: DataTypeEnum.DynamicComponent,
      required: false,
      showInTable: true,
      description: 'Status of the request progress',
      tableDefinition: {
        sortDisabled: true,
        options: {
          dynamicComponent: AcknowledgementRequestProgressBarComponent,
        },
        passRowAsData: true,
      },
    },
    {
      name: 'AKR progress',
      key: 'ackReqProgress',
      dataType: DataTypeEnum.DynamicComponent,
      required: false,
      showInTable: true,
      description: 'Progress of the acknowledgement request',
      tableDefinition: {
        sortDisabled: true,
        options: {
          dynamicComponent: AcknowledgementRequestProgressFinishedComponent,
        },
        passRowAsData: true,
      },
    },
    {
      name: 'Status',
      key: 'status',
      dataType: DataTypeEnum.Badge,
      required: false,
      showInTable: true,
      description: 'Status of the acknowledgement request',
      tableDefinition: {
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: AcknowledgmentRequestDto.StatusEnum,
        },
      },
    },
    {
      name: 'Require Signed Document',
      key: 'requireSignedDocument',
      dataType: DataTypeEnum.Badge,
      required: false,
      showInTable: true,
      description: 'Record Requires Signed Document?',
      tableDefinition: {
        filter: {
          type: 'boolean',
          display: 'menu',
          matchMode: 'equals',
        },
      },
    },
    {
      name: 'Document',
      key: 'documentUrl',
      required: true,
      showInTable: true,
      description: 'Document link',
      dataType: DataTypeEnum.Link,
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },

    // {
    //     name: "Description",
    //     key: "description",
    //     dataType: DataTypeEnum.LongText,
    //     required: false,
    //     showInTable: true,
    //     description: "Record Description",
    //     tableDefinition: {
    //         filter: {
    //             type: "text",
    //             display: "menu",
    //             matchMode: "contains",
    //             showMatchModes: true,
    //             showAddButton: true,
    //             showOperator: true,
    //         },
    //     },
    // },
  ];
  constructor() {
    super();
    this.setAllFields();
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  BaseTablePage,
  ButtonColor,
  ModuleKeywords,
  PermissionActions,
  RiskMethodologyDto,
  toKebabCase,
} from '@shared/classes';
import { AppDialogService, ExportDataService } from '@shared/services';
import { RiskMethodologyMappingService } from '@shared/services/mappings/risk/risk-methodology-mapping.service';
import { AppInjector } from 'app/app-injector';
import { RiskMethodologyDataService } from '../../services/data/risk-methodology-data.service';

@Component({
  selector: 'app-risk-methodology-list',
  templateUrl: './risk-methodology-list.component.html',
  styleUrl: './risk-methodology-list.component.scss',
})
export class RiskMethodologyListComponent extends BaseTablePage<RiskMethodologyDto> implements OnInit {
  constructor(
    requestService: RiskMethodologyDataService,
    exportService: ExportDataService,
    appDialogService: AppDialogService,
    router: Router
  ) {
    super(requestService, exportService, appDialogService, router, {
      moduleKeyword: ModuleKeywords.RiskMethodology,
      routePrefix: 'risk',
    });
    
    this.SetPageValues({
      breadCrumb: {
        items: [
          {
            label: 'Risk',
            routerLink: [`${this.routePrefix ? this.routePrefix : toKebabCase(this.moduleKeyword)}`],
          },
          {
            label: 'Risk Methodologies',
            routerLink: [`/${this.routePrefix}${toKebabCase(this.moduleKeyword)}/list`],
          },
        ],
      },
      pageActions: [
        {
          label: 'Add Risk Methodology',
          id: 1,
          color: ButtonColor.Primary,
          icon: 'pi pi-plus',
          iconPos: 'left',
          command: this.addNewItem.bind(this),
          permission: `${PermissionActions.Create}${this.moduleKeyword}`,
        },
      ],
    });
  }

  ngOnInit(): void {}

  setCols() {
    const mappingService = AppInjector.get(RiskMethodologyMappingService);
    this.cols = [...mappingService.tableFields];
  }
}

import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NotificationDto } from '@shared/classes/model/backend/task/model/notificationDto';
import { IFieldDefinition } from '@shared/classes/view/AppFieldDefinition';
import { DataTypeEnum, TargetTypeEnum } from '@shared/classes/view/view-enums';
import { UserSelectorComponent } from '@shared/components/selectors/user-selector/user-selector.component';
import { BaseFieldDefinitionsService } from '@shared/services/base-field-definitions.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationMappingService extends BaseFieldDefinitionsService {
  recordFields: IFieldDefinition[] = [
    {
      name: 'Type',
      key: 'notificationType',
      required: false,
      showInTable: true,
      dataType: DataTypeEnum.Badge,
      tableDefinition: {
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: NotificationDto.NotificationTypeEnum,
        },
      },
    },
    {
      name: 'Subscription',
      key: 'subscriptionType',
      required: false,
      showInTable: true,
      dataType: DataTypeEnum.Badge,
      tableDefinition: {
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: NotificationDto.SubscriptionTypeEnum,
        },
      },
    },
    {
      name: 'Related to Code',
      key: 'relatedToCode',
      required: false,
      showInTable: true,
      dataType: DataTypeEnum.CodeLink,
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Related to Type',
      key: 'relatedToType',
      required: false,
      showInTable: true,
      dataType: DataTypeEnum.Badge,
      tableDefinition: {
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: TargetTypeEnum,
        },
      },
    },
    {
      name: 'Sender',
      key: 'sender',
      required: false,
      showInTable: true,
      dataType: DataTypeEnum.UserListView,
      tableDefinition: {
        filter: {
          type: 'multiDropdown',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          dynamicInput: {
            componentType: UserSelectorComponent,
            options: {
              label: '',
              name: '',
              control: new FormControl(null),
            },
          },
        },
      },
    },
    {
      name: 'Auto',
      key: 'autoSubscription',
      required: false,
      showInTable: true,
      dataType: DataTypeEnum.Badge,
      tableDefinition: {
        filter: {
          type: 'boolean',
          display: 'menu',
          matchMode: 'equals',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Seen By',
      key: 'seenBy',
      required: false,
      showInTable: true,
      dataType: DataTypeEnum.UserListView,
      tableDefinition: {
        filter: {
          type: 'multiDropdown',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          dynamicInput: {
            componentType: UserSelectorComponent,
            options: {
              label: '',
              name: '',
              control: new FormControl(null),
            },
          },
        },
      },
    },
  ];

  constructor() {
    super();
    this.setAllFields();
  }
}

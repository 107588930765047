import { Injectable } from '@angular/core';
import { DataTypeEnum, IFieldDefinition, Policy } from '@shared/classes';
import { BaseFieldDefinitionsService } from '@shared/services/base-field-definitions.service';

@Injectable({
  providedIn: 'root',
})
export class PolicyMappingService extends BaseFieldDefinitionsService {
  recordFields: IFieldDefinition[] = [
    {
      name: 'Name',
      key: 'name',
      dataType: DataTypeEnum.Text,
      required: true,
      showInTable: true,
      description: 'Record Name',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Owner',
      key: 'owner',
      dataType: DataTypeEnum.ResponsibilityListView,
      required: true,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Owner group of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Status',
      key: 'policyStatus',
      dataType: DataTypeEnum.Badge,
      required: false,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Policy Status',
      tableDefinition: {
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: Policy.PolicyStatusEnum,
        },
      },
    },
    {
      name: 'Reviewers',
      key: 'reviewers',
      dataType: DataTypeEnum.ResponsibilityListView,
      required: true,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Reviewers of the policy',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
          extraKey: '.name',
        },
      },
    },
    {
      name: 'Approvers',
      key: 'approvers',
      dataType: DataTypeEnum.ResponsibilityListView,
      required: true,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Approvers of the policy',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
          extraKey: '.name',
        },
      },
    },
    {
      name: 'Published by',
      key: 'approvedActionDetails.user',
      dataType: DataTypeEnum.UserListView,
      required: false,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Approvers Details of the policy',
      tableDefinition: {
        filter: {
          type: 'userSelector',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
        },
      },
    },
    {
      name: 'Published at',
      key: 'approvedActionDetails.date',
      dataType: DataTypeEnum.DateLong,
      required: false,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Approvers Details of the policy',
      tableDefinition: {
        filter: {
          type: 'date',
          matchMode: 'dateBefore',
        },
      },
    },
    // {
    //     name: 'Compliance Status',
    //     key: 'progress',
    //     dataType: DataTypeEnum.DynamicComponent,
    //     required: false,
    //     showInTable: true,
    //     // permissions: AppPermissions.ReadGroup,
    //     description: 'Compliance Status of the policy',
    //     tableDefinition: {
    //         options: {
    //             dynamicComponent: ControlComplianceProgressBarComponent,
    //         },
    //         passRowAsData: true,
    //     },
    // },
    {
      name: 'Approved By',
      key: 'policyBody.approvedBy',
      dataType: DataTypeEnum.AvatarInfoList,
      required: false,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Approvers of the policy',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
          extraKey: '.name',
        },
      },
    },
    {
      name: 'Reviewed By',
      key: 'policyBody.reviewedBy',
      dataType: DataTypeEnum.AvatarInfoList,
      required: false,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Reviewers of the policy',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
          extraKey: '.name',
        },
      },
    },
    {
      name: 'Valid From',
      key: 'validFrom',
      dataType: DataTypeEnum.DateLong,
      required: false,
      showInTable: true,
      description: 'Policy Valid From',
      tableDefinition: {
        filter: {
          type: 'date',
          matchMode: 'dateBefore',
        },
      },
    },
    {
      name: 'Valid To',
      key: 'validTo',
      dataType: DataTypeEnum.DateLong,
      required: false,
      showInTable: true,
      description: 'Policy Valid To',
      tableDefinition: {
        filter: {
          type: 'date',
          matchMode: 'dateBefore',
        },
      },
    },

    {
      name: 'Allow Exceptions',
      key: 'allowExceptions',
      required: false,
      showInTable: true,
      dataType: DataTypeEnum.Badge,
      description: 'Allow Exceptions in Policy',
      tableDefinition: {
        filter: {
          type: 'boolean',
          display: 'menu',
          matchMode: 'equals',
        },
      },
    },
    {
      name: 'Exception Duration From',
      key: 'exceptionDurationFrom',
      dataType: DataTypeEnum.DateLong,
      required: false,
      showInTable: true,
      description: 'Policy Exception Duration From if Allow Exception is enabled',
      tableDefinition: {
        filter: {
          type: 'date',
          matchMode: 'dateBefore',
        },
      },
    },
    {
      name: 'Exception Duration To',
      key: 'exceptionDurationTo',
      dataType: DataTypeEnum.DateLong,
      required: false,
      showInTable: true,
      description: 'Policy Exception Duration To if Allow Exception is enabled',
      tableDefinition: {
        filter: {
          type: 'date',
          matchMode: 'dateBefore',
        },
      },
    },
    {
      name: 'External References',
      key: 'externalReferences',
      dataType: DataTypeEnum.Text,
      required: false,
      showInTable: true,
      description: 'External References Id of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Description',
      key: 'description',
      dataType: DataTypeEnum.Text,
      required: true,
      showInTable: true,
      description: 'Record Description',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
  ];
  constructor() {
    super();
    this.setAllFields();
  }
}

import { Injectable } from '@angular/core';
import {
  AcknowledgmentDto,
  AcknowledgmentRequestDto,
  AppPermissions,
  DataTypeEnum,
  IFieldDefinition,
} from '@shared/classes';
import { BaseFieldDefinitionsService } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class MyAcknowledgmentMappingService extends BaseFieldDefinitionsService {
  recordFields: IFieldDefinition[] = [
    {
      name: 'Name',
      key: 'name',
      dataType: DataTypeEnum.Text,
      required: true,
      showInTable: true,
      description: 'Record Name',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Owner',
      key: 'owner',
      dataType: DataTypeEnum.ResponsibilityListView,
      required: true,
      showInTable: true,
      description: 'Owner of the acknowledgement request',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Approver',
      key: 'approver',
      dataType: DataTypeEnum.ResponsibilityListView,
      required: true,
      showInTable: true,
      permissions: AppPermissions.ReadResponsibility,
      description: 'Users who should approve',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Approved by',
      key: 'myAcknowledgment.approvalUser',
      dataType: DataTypeEnum.UserListView,
      showInTable: true,
      required: false,
      tableDefinition: {
        filter: {
          type: 'userSelector',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
        },
      },
    },
    {
      name: 'Approval Date',
      key: 'myAcknowledgment.approvalDate',
      dataType: DataTypeEnum.DateLong,
      showInTable: true,
      required: false,
      tableDefinition: {
        filter: {
          type: 'date',
          matchMode: 'dateBefore',
        },
      },
    },
    {
      name: 'Acknowledgers',
      key: 'acknowledgers',
      dataType: DataTypeEnum.ResponsibilityListView,
      required: true,
      showInTable: true,
      permissions: AppPermissions.ReadResponsibility,
      description: 'Users who will are required to acknowledge',
      tableDefinition: {
        sortDisabled: true,
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
          extraKey: '.name',
        },
      },
    },
    {
      name: 'Is Satisfied',
      key: 'isSatisfied',
      dataType: DataTypeEnum.Badge,
      required: false,
      showInTable: true,
      description: 'Is the acknowledgment request satisfied?',
      tableDefinition: {
        filter: {
          type: 'boolean',
          display: 'menu',
          matchMode: 'equals',
        },
      },
    },
    // {
    //     name: "Progress Status",
    //     key: "progress",
    //     dataType: DataTypeEnum.DynamicComponent,
    //     required: false,
    //     showInTable: true,
    //     description: "Status of the request progress",
    //     tableDefinition: {
    //         sortDisabled: true,
    //         options: {
    //             dynamicComponent:
    //                 AcknowledgementRequestProgressBarComponent,
    //         },
    //         passRowAsData: true,
    //     },
    // },
    // {
    //     name: "AKR progress",
    //     key: "ackReqProgress",
    //     dataType: DataTypeEnum.DynamicComponent,
    //     required: false,
    //     showInTable: true,
    //     description: "Progress of the acknowledgement request",
    //     tableDefinition: {
    //         sortDisabled: true,
    //         options: {
    //             dynamicComponent:
    //                 AcknowledgementRequestProgressFinishedComponent,
    //         },
    //         passRowAsData: true,
    //     },
    // },
    {
      name: 'Status',
      key: 'status',
      dataType: DataTypeEnum.Badge,
      required: false,
      showInTable: true,
      description: 'Status of the acknowledgement request',
      tableDefinition: {
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: AcknowledgmentRequestDto.StatusEnum,
        },
      },
    },
    {
      name: 'Acknowledgment Status',
      key: 'myAcknowledgment.acknowledgmentStatus',
      dataType: DataTypeEnum.Badge,
      showInTable: true,
      required: false,
      tableDefinition: {
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: AcknowledgmentDto.AcknowledgmentStatusEnum,
        },
      },
    },
    {
      name: 'Require Signed Document',
      key: 'requireSignedDocument',
      dataType: DataTypeEnum.Badge,
      required: false,
      showInTable: true,
      description: 'Record Requires Signed Document?',
      tableDefinition: {
        filter: {
          type: 'boolean',
          display: 'menu',
          matchMode: 'equals',
        },
      },
    },
    {
      name: 'Document',
      key: 'documentUrl',
      required: true,
      showInTable: true,
      description: 'Document link',
      dataType: DataTypeEnum.Link,
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },

    // {
    //     name: "Description",
    //     key: "description",
    //     dataType: DataTypeEnum.LongText,
    //     required: false,
    //     showInTable: true,
    //     description: "Record Description",
    //     tableDefinition: {
    //         filter: {
    //             type: "text",
    //             display: "menu",
    //             matchMode: "contains",
    //             showMatchModes: true,
    //             showAddButton: true,
    //             showOperator: true,
    //         },
    //     },
    // },
  ];
  constructor() {
    super();
    this.setAllFields();
  }
}

import { Injectable } from '@angular/core';
import { AppPermissions, DataTypeEnum, IFieldDefinition, TargetTypeEnum } from '@shared/classes';
import { BaseFieldDefinitionsService } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class CitationMappingService extends BaseFieldDefinitionsService {
  recordFields: IFieldDefinition[] = [
    {
        name: "Authority Document",
        key: "authorityDocument",
        dataType: DataTypeEnum.CodeLink,
        required: true,
        showInTable: true,
        permissions: AppPermissions.ReadAuthorityDocument,
        description: "Authority document of the record",
        tableDefinition: {
            filter: {
                type: "recordCode",
                matchMode: "in",
                showMatchModes: false,
                showAddButton: false,
                showOperator: false,
                recordCodeType: TargetTypeEnum.AuthorityDocument,
            },
        },
    },
    {
      name: 'Framework Ref',
      key: 'frameworkRef',
      dataType: DataTypeEnum.Text,
      required: true,
      showInTable: true,
      description: 'Framework reference of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Title',
      key: 'title',
      dataType: DataTypeEnum.Text,
      required: true,
      showInTable: true,
      description: 'Title of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Name',
      key: 'name',
      dataType: DataTypeEnum.Text,
      required: true,
      showInTable: true,
      description: 'Name of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Citation Type',
      key: 'citationType',
      dataType: DataTypeEnum.CodeLink,
      required: true,
      showInTable: true,
      permissions: AppPermissions.ReadCitationType,
      description: 'Citation type of the record',
      tableDefinition: {
        filter: {
          type: 'recordCode',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          recordCodeType: TargetTypeEnum.CitationType,
        },
      },
    },
    {
      name: 'Mandatory',
      key: 'mandatory',
      dataType: DataTypeEnum.Badge,
      required: false,
      showInTable: true,
      description: 'Is the record mandatory?',
      tableDefinition: {
        filter: {
          type: 'boolean',
          display: 'menu',
          matchMode: 'equals',
        },
      },
    },
    {
      name: 'Exempted',
      key: 'isExempted',
      dataType: DataTypeEnum.Badge,
      required: false,
      showInTable: true,
      description: 'Is the record exempted?',
      tableDefinition: {
        filter: {
          type: 'boolean',
          display: 'menu',
          matchMode: 'equals',
        },
      },
    },
    {
      name: 'Parent',
      key: 'parent',
      dataType: DataTypeEnum.CodeLink,
      required: false,
      showInTable: true,
      permissions: AppPermissions.ReadCitation,
      description: 'Parent of the record',
      tableDefinition: {
        filter: {
          type: 'recordCode',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          recordCodeType: TargetTypeEnum.Citation,
        },
      },
    },
    // {
    //     name: "Description",
    //     key: "description",
    //     dataType: DataTypeEnum.LongText,
    //     required: false,
    //     showInTable: true,
    //     description: "Record Description",
    //     tableDefinition: {
    //         filter: {
    //             type: "text",
    //             display: "menu",
    //             matchMode: "contains",
    //             showMatchModes: true,
    //             showAddButton: true,
    //             showOperator: true,
    //         },
    //     },
    // },
  ];
  constructor() {
    super();
    this.setAllFields();
  }
}

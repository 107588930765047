import { Component, EventEmitter, Input, OnInit, Output, QueryList, Type, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {
  DataTypeEnum,
  DynamicComponentBase,
  DynamicFieldConfig,
  FieldDto,
  IAction,
  IDynamicComponent,
  getDynamicFieldObjectOptions,
  getFieldValidators,
} from '@shared/classes';
import { GvlSelectorComponent } from '@shared/components/selectors/gvl-selector/gvl-selector.component';
import { TargetCodeSelectorComponent } from '@shared/components/selectors/target-code-selector/target-code-selector.component';
import { DynamicComponentDirective } from '@shared/directives/dynamic-component.directive';
import sortBy from 'lodash-es/sortBy';
import { Observable, Subject, takeUntil } from 'rxjs';
import { AttachmentInputComponent } from '../attachment-input/attachment-input.component';
import { BasicInputComponent } from '../basic-input/basic-input.component';
import { ColorInputComponent } from '../color-input/color-input.component';
import { IpInputComponent } from '../customised-fields/ip-input/ip-input.component';
import { IP6InputComponent } from '../customised-fields/ip6-input/ip6-input.component';
import { Ipv4MaskInputComponent } from '../customised-fields/ipv4-mask-input/ipv4-mask-input.component';
import { MacInputComponent } from '../customised-fields/mac-input/mac-input.component';
import { DateInputComponent } from '../date-input/date-input.component';
import { DropDownInputComponent } from '../drop-down-input/drop-down-input.component';
import { ImageInputComponent } from '../image-input/image-input.component';
import { MCQComponent } from '../mcq/mcq.component';
import { NumberInputComponent } from '../number-input/number-input.component';
import { RatingInputComponent } from '../rating-input/rating-input.component';
import { SwitchInputComponent } from '../switch-input/switch-input.component';
import { TextEditorComponent } from '../text-editor/text-editor.component';
import { TimeInputComponent } from '../time-input/time-input.component';

@Component({
  selector: 'app-dynamic-field-list-input',
  templateUrl: './dynamic-field-list-input.component.html',
  styleUrls: ['./dynamic-field-list-input.component.scss'],
  providers: [
    { provide: DynamicComponentBase, useExisting: DynamicFieldListInputComponent },
    { provide: NumberInputComponent, useExisting: DynamicFieldListInputComponent },
  ],
})
export class DynamicFieldListInputComponent extends DynamicComponentBase implements OnInit {
  dataType: DataTypeEnum = DataTypeEnum.Text;
  dynamicFields: IDynamicComponent[] = [];
  private formValid = new Subject<boolean>();
  private _config;
  displayDialog: boolean = false;
  @ViewChildren(DynamicComponentDirective) inputFields!: QueryList<DynamicComponentDirective>;

  @Input() set config(conf: { dynamicFields: FieldDto[]; dynamicFieldValues: { [key: string]: any } }) {
    this._config = conf;
    this.initInputFields();
  }
  get config() {
    return this._config;
  }
  @Input() loadingFields: boolean = false;
  @Input() showGetValueButton: boolean = false;
  @Input() showPopupEditButton: boolean = false;
  @Input() set showGetValueButtonText(showGetValueButtonText: string) {
    this.getFieldValueAction.label = showGetValueButtonText;
    this.getFieldValueAction = { ...this.getFieldValueAction };
  }
  @Input() set getValueButtonLoader(getValueButtonLoader: Observable<boolean>) {
    this.getFieldValueAction.loading$ = getValueButtonLoader;
    this.getFieldValueAction = { ...this.getFieldValueAction };
  }
  getFieldValueAction: IAction = {
    id: 2,
    label: 'Save',
    buttonType: 'button',
    command: this.getFieldValue.bind(this),
    icon: 'pi pi-save',
  };
  showEditPopupAction: IAction = {
    id: 3,
    label: 'Edit',
    buttonType: 'button',
    command: this.showDialog.bind(this),
    icon: 'pi pi-pencil',
  };
  @Output() onGetFieldValue: EventEmitter<any> = new EventEmitter();

  constructor(private fb: FormBuilder) {
    super();
  }

  ngOnInit(): void {
    // this.initInputFields();
  }
  getFieldValue(field: IDynamicComponent) {
    this.onGetFieldValue.emit({ value: field?.options?.control?.value, key: field.options?.name });
  }
  setInputOptions() {}
  initInputFields() {
    let fields: IDynamicComponent[] = [];

    this.fControl.statusChanges.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      this.formValid.next(res == 'VALID');
    });
    (this.fControl as any as FormGroup)?.reset();
    Object.keys((this.fControl as any as FormGroup).controls).forEach((key) => {
      (this.fControl as any as FormGroup).removeControl(key);
    });
    if (!(this.config?.dynamicFields == null || this.config?.dynamicFields == undefined))
      fields = Object.keys(this.config.dynamicFields)?.map((key, i) => {
        const field: FieldDto = this.config.dynamicFields[key];
        const defValue = field?.options?.[DynamicFieldConfig.MULTI_SELECT_DEFAULT_OPTION]
          ? field?.options?.[DynamicFieldConfig.MULTI_SELECT_DEFAULT_OPTION]
          : field?.options?.[DynamicFieldConfig.DEFAULT_VALUE]
            ? field?.options?.[DynamicFieldConfig.DEFAULT_VALUE]
            : field?.type == 'BOOLEAN'
              ? false
              : null;
        if (
          (this.fControl as any as FormGroup).get(field.key) === null ||
          (this.fControl as any as FormGroup).get(field.key) === undefined
        ) {
          (this.fControl as any as FormGroup).addControl(
            field.key,
            new FormGroup({
              field: new FormControl(field.code),
              value: new FormControl(
                this.config?.dynamicFieldValues?.[field.key]?.value ?? defValue,
                getFieldValidators(field?.options)
              ),
            })
          );
        } else {
          (this.fControl as any as FormGroup).patchValue({
            [field.key]: { field: field.code, value: this.config?.dynamicFieldValues?.[field.key]?.value ?? defValue },
          });
        }
        return getDynamicFieldObjectOptions(
          field,
          DynamicFieldMap,
          this.config?.dynamicFieldValues,
          ((this.fControl as any as FormGroup).controls?.[field.key] as FormGroup)?.controls?.value,
          i,
          this.viewMode
        );
      });
    // }
    if (fields) {
      fields = sortBy(fields, 'options.id');
      this.dynamicFields = [...fields];
    } else this.dynamicFields = [];
    setTimeout(() => {
      this.formValid.next((this.fControl as any as FormGroup).valid);
    }, 1);
  }
  popupActiveDynamicFieldIndex: number = 0;
  showDialog(fieldIndex: number) {
    this.popupActiveDynamicFieldIndex = fieldIndex;
    this.displayDialog = true;
  }
}
export const DynamicFieldMap: { [key in FieldDto.TypeEnum]: Type<DynamicComponentBase> } = {
  ['INTEGER']: NumberInputComponent,
  ['BOOLEAN']: SwitchInputComponent,
  ['STRING']: BasicInputComponent,
  ['NUMBER']: NumberInputComponent,
  ['SINGLE_SELECTION']: MCQComponent,
  ['ATTACHMENT']: AttachmentInputComponent,
  ['IP']: IpInputComponent,
  ['IP6']: IP6InputComponent,
  ['MAC']: MacInputComponent,
  ['IPV4_MASK']: Ipv4MaskInputComponent,
  ['DATE']: DateInputComponent,
  ['DATETIME']: DateInputComponent,
  ['MULTISELECT']: DropDownInputComponent,
  ['RATING']: RatingInputComponent,
  ['LONG_TEXT']: TextEditorComponent,
  ['RELATION']: TargetCodeSelectorComponent,
  ['IMAGE']: ImageInputComponent,
  ['GROUP_VALUE_LIST']: GvlSelectorComponent,
  ['COLOR']: ColorInputComponent,
  ['TIME']: TimeInputComponent,
};

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { PermissionParserService } from '@core/services/permission-parser/permission-parser.service';
import { HubDataService } from '@shared/services';
import { UserDataService } from 'app/modules/users/services/data/user-data.service';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { forkJoin } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard extends KeycloakAuthGuard {
  constructor(
    protected readonly router: Router,
    protected readonly keycloak: KeycloakService,
    private userRequestService: UserDataService,
    private hubRequestService: HubDataService,
    private permissionsParser: PermissionParserService
  ) {
    super(router, keycloak);
  }

  public async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    // Force the user to log in if currently unauthenticated.
    if (!this.authenticated) {
      await this.keycloak.login({
        redirectUri: window.location.origin + state.url,
      });
    }
    await forkJoin({
      userInfo: this.userRequestService.getUserInfo({
        extras: { cacheKey: 'userInfo' },
        showLoading: true,
        showMsg: true,
      }),
      licensedModules: this.hubRequestService.getLicensedModules({
        extras: { cacheKey: 'licensedModules' },
        showLoading: true,
        showMsg: true,
      }),
    })
      .toPromise()
      .then((res) => {
        this.permissionsParser.initializeUserPermissions(res.userInfo, res.licensedModules.data);
      });
    // await this.userRequestService.getUserInfo({ extras: { cacheKey: 'userInfo' }, showLoading: true, showMsg: true }).toPromise().then(res => {

    //     this.permissionsParser.initializeUserPermissions(res);
    // });
    // await this.hubRequestService.getLicensedModules().toPromise().then(res=>{

    // })
    // Get the roles required from the route.
    const requiredRoles = route.data.roles;

    // Allow the user to to proceed if no additional roles are required to access the route.
    if (!(requiredRoles instanceof Array) || requiredRoles.length === 0) {
      return true;
    }

    // Allow the user to proceed if all the required roles are present.
    return requiredRoles.every((role) => this.roles.includes(role));
  }
}

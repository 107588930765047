import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppPermissions } from '@shared/classes';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { IssuesItemComponent } from './issues-item/issues-item.component';
import { IssuesListComponent } from './issues-list/issues-list.component';
import { IssuesTreeListComponent } from './issues-tree-list/issues-tree-list.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'list' },
  {
    path: 'list',
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: [AppPermissions.ReadIssue],
        redirectTo: '/access',
      },
    },
    component: IssuesListComponent,
  },
  {
    path: 'tree',
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: [AppPermissions.ReadIssue],
        redirectTo: '/access',
      },
    },
    component: IssuesTreeListComponent,
  },
  {
    path: 'create',
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: [AppPermissions.CreateIssue],
        redirectTo: '/access',
      },
    },
    component: IssuesItemComponent,
  },
  {
    path: 'edit/:id',
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: [AppPermissions.UpdateIssue],
        redirectTo: '/access',
      },
    },
    component: IssuesItemComponent,
  },
  {
    path: 'view/:id',
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: [AppPermissions.ReadIssue],
        redirectTo: '/access',
      },
    },
    component: IssuesItemComponent,
  },
  {
    path: 'settings',
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: [AppPermissions.DeleteIssue],
        redirectTo: '/access',
      },
    },
    loadChildren: () => import('./settings/settings.module').then((m) => m.SettingsModule),
  },
  {
    path: 'bulk-operation',
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: [AppPermissions.UpdateIssue, AppPermissions.DeleteIssue],
        redirectTo: '/access',
      },
    },
    loadChildren: () => import('./bulk-operations/bulk-operations.module').then((m) => m.BulkOperationsModule),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class IssuesRoutingModule {}

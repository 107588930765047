<div
  class="layout-wrapper"
  (click)="onLayoutClick()"
  [ngClass]="{
    'layout-static': app.menuMode === 'static',
    'layout-overlay': app.menuMode === 'overlay',
    'layout-slim': app.menuMode === 'slim',
    'layout-horizontal': app.menuMode === 'horizontal',
    'layout-sidebar': app.menuMode === 'sidebar',
    'layout-sidebar-static': app.menuMode === 'sidebar' && sidebarStatic,
    'layout-overlay-active': overlayMenuActive,
    'layout-mobile-active': staticMenuMobileActive,
    'layout-static-inactive': staticMenuDesktopInactive && app.menuMode === 'static',
    'p-ripple-disabled': !app.ripple,
    'dark-color-scheme': app.colorScheme == 'dark',
    'light-color-scheme': app.colorScheme == 'light',
  }"
>
  <div class="layout-main">
    @if (licenseHandlerService?.messages?.length > 0) {
      <div class="license-message w-8 md:w-6" style="margin: auto; min-height: 64px">
        @for (message of licenseHandlerService.messages; track message) {
          <p-messages [severity]="message.severity" [enableService]="false" [closable]="false">
            <ng-template pTemplate>
              <!-- <span class="p-message-icon">
              <i [class]="message.icon"></i>
            </span> -->
              <div class="ml-2">
                {{ message.detail }} Contact Support at:
                <a href="mailto:support@sightflare.com">support&#64;sightflare.com</a>
              </div>
              <span class="p-message-close p-link" (click)="licenseHandlerService.toggleLicenseDialog(message)">
                <i class="pi pi-exclamation-circle"></i>
              </span>
            </ng-template>
          </p-messages>
        }
      </div>
    }
    @if (isMobile() || isOverlay() || isHorizontal()) {
      <app-topbar></app-topbar>
    }

    @if (!isMobile() && (isStatic() || isSlim() || isSidebar())) {
      <app-menu></app-menu>
    }

    <app-breadcrumb></app-breadcrumb>

    <div class="layout-main-content pt-3">
      <router-outlet></router-outlet>
    </div>

    <app-footer></app-footer>
  </div>

  <app-rightmenu></app-rightmenu>

  <app-config></app-config>
</div>
<p-dialog
  header="License Information"
  [modal]="true"
  [style]="{ width: '40vw' }"
  [draggable]="false"
  [resizable]="true"
  [(visible)]="licenseHandlerService.licenseDialog"
  [class]="'dialog-' + licenseHandlerService?.currentLicenseDialogMessage?.severity"
>
  <div class="flex flex-column w-full">
    <p class="">
      <i class="pi pi-id-card mr-2"></i> Licensed for:
      <span class="font-semibold">{{ licenseHandlerService?.currentLicenseDialogMessage?.data?.licenseName }}</span>
    </p>
    <p>
      <i class="pi pi-clock mr-2"></i> Expiry:
      @if (licenseHandlerService?.currentLicenseDialogMessage?.data?.licenseExpiryDate) {
        <span
          class="font-semibold"
          [pTooltip]="
            licenseHandlerService?.currentLicenseDialogMessage?.data?.licenseExpiryDate | date: 'yyyy-MM-dd HH:mm'
          "
        >
          <span
            timeago
            [date]="
              licenseHandlerService?.currentLicenseDialogMessage?.data?.licenseExpiryDate | date: 'yyyy-MM-dd HH:mm:ss'
            "
            style="font-variant-numeric: lining-nums"
          >
          </span>
        </span>
      }
    </p>
    <p class="">
      <i class="pi pi-comment mr-2"></i> Contact Support at:
      <a href="mailto:support@sightflare.com">support&#64;sightflare.com</a>
    </p>
  </div>
</p-dialog>

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { BaseRequestControllerService } from '@shared/services/api/base-request-controller.service';
import { DataService } from '@shared/services/api/data.service';

@Injectable({
  providedIn: 'root',
})
export class GlobalValueListDataService extends BaseRequestControllerService<any> {
  constructor(
    dataService: DataService,
    private router: Router
  ) {
    super(dataService, environment.dataStructure, '/global-value-list', [
      'id',
      'code',
      'tags',
      'tagsCodes',
      'lockedBy',
      'lockedForEdit',
      'lockedUntil',
      'userAccessLevel',
      'recordStatus',
      'creatorName',
      'lastModifierName',
      'creationDate',
      'lastModificationDate',
      'name',
      'label',
      'values',
      'value',
      'icon',
      'helpText',
      'description',
      'numericValue',
      'color',
      'icon',
    ]);
  }
  navigateToListPage() {
    this.router.navigateByUrl(`data-templates/global-value-list/list`);
  }
}

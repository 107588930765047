import { UnsubscribeOnDestroyAdapter } from '../controller/UnsubscribeOnDestroyAdapter';
import { BreadCrumb } from '../model';
import { IAction } from './ButtonAction';
import { PageConfigParams, PageStaticConfigParams } from './PageConfigParams';

export class BasePage extends UnsubscribeOnDestroyAdapter {
  /**
   * BreadCrumb Items to show in the navigation menu
   *
   * @type {BreadCrumb}
   * @memberof BasePage
   */
  public breadCrumb: BreadCrumb = { items: [{ label: 'Blank', routerLink: '#' }] };

  /**
   * Shared Keyword for router url and permissions
   *
   * @example Please Use PascalCase ex: EntityLevels
   * @type {string}
   * @memberof BasePage
   */
  public moduleKeyword: string = '';

  /**
   * additional prefix to the router for navigation
   *
   * @example Please Use KebabCase without ending / for singularity ex: entity-levels
   * @type {string}
   * @memberof BasePage
   */
  public routePrefix: string = '';

  /**
   * additional middle route to the router for navigation
   *
   * @example Please Use KebabCase without ending / for singularity ex: entity-levels
   * @type {string}
   * @memberof BasePage
   */
  public routeMiddlefix: string = '';

  /**
   * Header Actions Related To The Page
   *
   * @type {IAction[]}
   * @memberof BasePage
   */
  public pageActions: IAction[] = [];

  /**
   * Header Actions Passby Data (not required)
   *
   * @type {any}
   * @memberof BasePage
   */
  public pageActionsCommandData: any = null;
  public SetPageValues(config: PageConfigParams = new PageConfigParams()) {
    this.breadCrumb = config.breadCrumb;
    this.pageActions = config.pageActions ?? [];
  }
  constructor(config: PageStaticConfigParams = new PageStaticConfigParams()) {
    super();
    this.moduleKeyword = config.moduleKeyword ?? '';
    this.routePrefix = config.routePrefix ? config.routePrefix + '/' : (config.routePrefix ?? '');
  }
}

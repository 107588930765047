import { Injectable } from '@angular/core';
import { IFieldDefinition, DataTypeEnum, ControlDto, FrameworkExceptionDto } from '@shared/classes';
import { BaseFieldDefinitionsService } from '@shared/services/base-field-definitions.service';

@Injectable({
  providedIn: 'root',
})
export class FrameworkExceptionsMappingService extends BaseFieldDefinitionsService {
  recordFields: IFieldDefinition[] = [
    {
      name: 'Title',
      key: 'title',
      dataType: DataTypeEnum.Text,
      required: true,
      showInTable: true,
      description: 'Record Title',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Status',
      key: 'status',
      dataType: DataTypeEnum.Badge,
      required: false,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Status of the exception',
      tableDefinition: {
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: FrameworkExceptionDto.StatusEnum,
        },
      },
    },
    {
      name: 'Target',
      key: 'targetCode',
      dataType: DataTypeEnum.CodeLink,
      required: false,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Target element of the exception',
      tableDefinition: {
        filter: {
          type: 'text',
          matchMode: 'startsWith',
        },
      },
    },
    {
      name: 'Type',
      key: 'type',
      dataType: DataTypeEnum.Badge,
      required: true,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Type of the exception',
      tableDefinition: {
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: FrameworkExceptionDto.TypeEnum,
        },
      },
    },
    {
      name: 'Owner',
      key: 'owner',
      dataType: DataTypeEnum.ResponsibilityListView,
      required: true,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Owner of the exception',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'duration From',
      key: 'durationFrom',
      dataType: DataTypeEnum.DateLong,
      required: true,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Start from this date for the exception',
      tableDefinition: {
        filter: {
          type: 'date',
          matchMode: 'lte',
        },
      },
    },
    {
      name: 'duration To',
      key: 'durationTo',
      dataType: DataTypeEnum.DateLong,
      required: true,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'End with this date for the exception',
      tableDefinition: {
        filter: {
          type: 'date',
          matchMode: 'lte',
        },
      },
    },
  ];
  constructor() {
    super();
    this.setAllFields();
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  AggregationBody,
  BadgeColor,
  ButtonColor,
  IAction,
  ModuleIcons,
  ModuleKeywords,
  PermissionActions,
  getModuleKeywordBaseRoute,
  humanizeCasedString,
} from '@shared/classes';
import { PathResolverService } from '@shared/services/path-resolver.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { PrimeIcons } from 'primeng/api';

@Component({
  selector: 'app-module-card-view',
  templateUrl: './module-card-view.component.html',
  styleUrls: ['./module-card-view.component.scss'],
})
export class ModuleCardViewComponent implements OnInit {
  @Input() icon: string = 'pi-image';
  @Input() title: string = '';
  @Input() description: string = '';
  @Input() color: string = 'purple';
  @Input() settingsUrl: string = 'settings';
  @Input() reportsUrl: string = 'list';
  @Input() actionData: any;
  @Input() value: number = 0;
  @Input() showSettingsLink: boolean = true;
  @Input() showReportsLink: boolean = true;
  @Input() showAmount: boolean = true;
  @Input() requiredPermission: string | string[];
  @Input() requiredAdminPermission: string | string[];
  private _moduleKeyword: ModuleKeywords;
  @Input() set moduleKeyword(moduleKeyword: ModuleKeywords) {
    this._moduleKeyword = moduleKeyword;
    this.onSetModuleKeyword();
  }
  get moduleKeyword() {
    return this._moduleKeyword;
  }
  isLoading: boolean = true;
  constructor(
    private pathResolverService: PathResolverService,
    private router: Router,
    private permissionService: NgxPermissionsService
  ) {}

  ngOnInit(): void {}
  onSetModuleKeyword() {
    if (this.moduleKeyword) {
      this.requiredPermission = PermissionActions.Read + this.moduleKeyword;
      this.requiredAdminPermission = PermissionActions.Delete + this.moduleKeyword;
      this.title = humanizeCasedString(this.parseModuleKeyword(this.moduleKeyword));
      this.color = BadgeColor[this.moduleKeyword];
      this.icon = ModuleIcons[this.moduleKeyword];
      if (this.showAmount) {
        this.permissionService.hasPermission(this.requiredPermission).then((allowed) => {
          if (allowed) {
            this.isLoading = true;
            this.pathResolverService
              .getDataServiceByModuleKeyword(this.moduleKeyword)
              .aggregate(this.getAggregationBody(), { showLoading: false, showMsg: false })
              .subscribe({
                next: (res) => {
                  this.value = res?.aggregation_value?.[0]?.recordStatus_count ?? 0;
                  this.isLoading = false;
                },
                error: (err) => {
                  this.isLoading = false;
                },
              });
          }
        });
      }
    }
  }
  parseModuleKeyword(keyword: ModuleKeywords): string {
    if (keyword == 'QUESTIONNAIRE_VALUE') return 'QuestionnaireAnswers';
    if (keyword == 'MY_QUESTIONNAIRE_VALUE') return 'MyQuestionnaireAnswers';
    return keyword;
  }
  goToSeeMore() {
    this.pathResolverService?.getDataServiceByModuleKeyword(this.moduleKeyword)?.navigateToListPage();
  }
  goToSettingsPage() {
    this.router.navigateByUrl(this.settingsRoute);
  }
  get settingsRoute() {
    return `${getModuleKeywordBaseRoute(this.moduleKeyword)}/${this.settingsUrl}`;
  }
  get listRoute() {
    return `${getModuleKeywordBaseRoute(this.moduleKeyword)}/${this.reportsUrl}`;
  }
  getAggregationBody(): AggregationBody {
    return {
      filters: [
        {
          property: 'recordStatus',
          operation: 'EQUAL',
          value: 'ACTIVE',
        },
      ],
      aggregationFields: [
        {
          operation: 'COUNT',
          fieldName: 'recordStatus',
        },
      ],
    };
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppPermissions } from '@shared/classes';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { RemediationPlanItemComponent } from './remediation-plan-item/remediation-plan-item.component';
import { RemediationPlanListComponent } from './remediation-plan-list/remediation-plan-list.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'list' },
  {
    path: 'list',
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: [AppPermissions.ReadRemediation],
        redirectTo: '/access',
      },
    },
    component: RemediationPlanListComponent,
  },
  {
    path: 'create',
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: [AppPermissions.CreateRemediation],
        redirectTo: '/access',
      },
    },
    component: RemediationPlanItemComponent,
  },
  {
    path: 'edit/:id',
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: [AppPermissions.UpdateRemediation],
        redirectTo: '/access',
      },
    },
    component: RemediationPlanItemComponent,
  },
  {
    path: 'view/:id',
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: [AppPermissions.ReadRemediation],
        redirectTo: '/access',
      },
    },
    component: RemediationPlanItemComponent,
  },
  {
    path: 'settings',
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: [AppPermissions.DeleteRemediation],
        redirectTo: '/access',
      },
    },
    loadChildren: () => import('./settings/settings.module').then((m) => m.SettingsModule),
  },
  {
    path: 'bulk-operation',
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: [AppPermissions.UpdateRemediation, AppPermissions.DeleteRemediation],
        redirectTo: '/access',
      },
    },
    loadChildren: () => import('./bulk-operations/bulk-operations.module').then((m) => m.BulkOperationsModule),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RemediationPlanRoutingModule {}

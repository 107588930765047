<form class="overflow-hidden w-full">
  <div>
    <ng-container>
      <div class="flex flex-row md:flex-nowrap flex-wrap gap-1 w-full algin-items-start">
        @if (true) {
          @if (label) {
            <label [for]="label" class="md:w-12rem md:max-w-12rem md:min-w-12rem w-full input-field-label"
              >{{ label }}
              @if (isControlRequired) {
                <span class="text-red-500"> *</span>
              }
            </label>
          }
        }
        <ng-container>
          <div class="field mt-2 w-full">
            @for (task of list; track trackByFn(i, task); let i = $index) {
              <div class="w-full flex items-center py-1 rounded-md my-1">
                <input
                  label="Text Field"
                  pInputText
                  [disabled]="viewMode == 'view'"
                  [placeholder]="placeholder || 'Add URL'"
                  [value]="task"
                  (input)="updateTaskOnNote(myinput.value, i)"
                  #myinput
                  class="mb-1"
                />
                @if (viewMode != 'view') {
                  <i
                    class="custom-plus -ml-0.5 icon-size-5 text-hint layout-inline-menu-icon pi pi-times"
                    (click)="removeTaskFromNote(i)"
                  ></i>
                }
              </div>
            }

            @if (viewMode != 'view') {
              <div class="flex items-center">
                <input
                  class="w-full"
                  pInputText
                  [placeholder]="placeholder || 'Add URL'"
                  (keydown.enter)="addTaskToNote(newTaskInput.value); newTaskInput.value = ''"
                  #newTaskInput
                />
                <i
                  class="custom-plus -ml-0.5 icon-size-5 text-hint layout-inline-menu-icon pi pi-plus"
                  (click)="addTaskToNote(newTaskInput.value); newTaskInput.value = ''"
                ></i>
              </div>
            }
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
</form>

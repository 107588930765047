import { Injectable } from '@angular/core';
import { DataTypeEnum, IFieldDefinition } from '@shared/classes';
import { BaseFieldDefinitionsService } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class ComplianceReportResultMappingService extends BaseFieldDefinitionsService {
  recordFields: IFieldDefinition[] = [
    {
      name: 'Date',
      key: 'date',
      dataType: DataTypeEnum.DateLong,
      required: true,
      showInTable: true,
      description: 'Record Name',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Report',
      key: 'reportCode',
      dataType: DataTypeEnum.CodeLink,
      required: false,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Report Linked to the results',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    // {
    //   name: 'Description',
    //   key: 'description',
    //   dataType: DataTypeEnum.Text,
    //   required: true,
    //   showInTable: true,
    //   description: 'Record Description',
    //   tableDefinition: {
    //     filter: {
    //       type: 'text',
    //       display: 'menu',
    //       matchMode: 'startsWith',
    //       showMatchModes: true,
    //       showAddButton: true,
    //       showOperator: true,
    //     },
    //   },
    // },
    // {
    //     name: "Items",
    //     key: "items",
    //     dataType: DataTypeEnum.Text,
    //     required: false,
    //     showInTable: true,
    //     // permissions: AppPermissions.ReadGroup,
    //     description: "Items Linked to the results",
    //     tableDefinition:{
    //         filter: {
    //             type: "text",
    //             display: "menu",
    //             matchMode: "startsWith",
    //             showMatchModes: true,
    //             showAddButton: true,
    //             showOperator: true,
    //         },
    //     }
    // },
  ];
  constructor() {
    super();
    this.setAllFields();
  }
}

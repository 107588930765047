<app-input-view-switch
  [showLabelInViewMode]="showLabelInViewMode"
  [mode]="viewMode"
  [data]="getViewData()"
  [dataType]="dataType"
  [label]="label"
  [multiViewFields]="multi"
  [keepValueCallback]="setFieldResetable.bind(this)"
  [resetFieldCheckVisable]="resetFieldCheckVisable"
  [resetableChecked]="resetable"
>
  <ng-container *ngIf="dropdown; else autoCompleteTemplate">
    <app-drop-down-input
      [label]="label ?? 'Principles'"
      [placeholder]="'Search Principles'"
      [name]="'dropdown'"
      [optionLabel]="optionLabel"
      [items]="unformattedOptions"
      [optionValue]="optionValue"
      [control]="fControl"
      [multi]="true"
      [floatLabel]="floatLabel"
      [appendTo]="appendTo"
      [loading]="isLoading"
      [lazy]="true"
      [onFilterCommand]="onFilter.bind(this)"
      [customFilter]="true"
      [group]="true"
      [listBoxMode]="true"
      [dataKey]="'name'"
    >
      <ng-template #resetCheckTemplate>
        @if (resetFieldCheckVisable) {
          <app-reset-switch-input
            [checked]="!resetable"
            (onChange)="setFieldResetable($event)"
          ></app-reset-switch-input>
        }
      </ng-template>
      <ng-template let-item #itemTemplate>
        <div class="w-full flex gap-4 justify-content-between align-items-center">
          <span class="h-fit flex align-items-center">
            <p>{{ item?.[optionLabel] }}</p>
          </span>
          <div *ngIf="principleMode && principleModeSelection == 'SELECTION'">
            <p-selectButton
              [options]="responsibilityModeOptions"
              [(ngModel)]="item.mode"
              optionLabel="label"
              optionValue="value"
              (onOptionClick)="onOptionClick($event)"
              (onChange)="onChangeRespMode($event, item)"
              styleClass="resp-mode-buttons"
            >
              <ng-template let-item>
                <div
                  class="flex flex-row gap-2 align-items-center w-full align-self-center justify-content-center"
                  [pTooltip]="item.tooltip"
                  tooltipPosition="top"
                  style="padding: 0.629rem 0"
                >
                  <i [class]="item.icon"></i>
                  <div *ngIf="item.label">
                    {{ item.label }}
                  </div>
                </div>
              </ng-template>
            </p-selectButton>
          </div>
        </div>
      </ng-template>
    </app-drop-down-input>
  </ng-container>
  <ng-template #autoCompleteTemplate>
    <app-auto-complete-input
      [label]="label ?? 'Principles'"
      [placeholder]="fControl?.value ? '' : 'Search Principles'"
      [name]="name || label || 'dropdown'"
      [optionLabel]="optionLabel"
      [items]="users"
      [optionValue]="optionValue"
      [control]="fControl"
      [multi]="multi"
      [floatLabel]="floatLabel"
      [appendTo]="appendTo"
      [loading]="isLoading"
      [lazy]="true"
      [dropdown]="false"
      [onFilterCommand]="onFilter.bind(this)"
      [customFilter]="true"
      [group]="true"
    >
      <ng-template #resetCheckTemplate>
        @if (resetFieldCheckVisable) {
          <app-reset-switch-input
            [checked]="!resetable"
            (onChange)="setFieldResetable($event)"
          ></app-reset-switch-input>
        }
      </ng-template>
      <ng-template let-item #itemTemplate>
        <div class="w-full flex gap-4 justify-content-between align-items-center">
          <span class="h-fit flex align-items-center">
            <app-badge-item
              *ngIf="allUsersMap[item?.[optionLabel]]?.responsibilityCode"
              class="mr-2"
              [styleClass]="'badge-shining'"
              [pTooltip]="
                !(allUsersMap[item?.[optionLabel]]?.members?.length > 0)
                  ? 'No Members'
                  : allUsersMap[item?.[optionLabel]]?.isMember
                    ? 'You are a Member in this group'
                    : ''
              "
              [styleClass]="'text-xs'"
              [status]="
                !(allUsersMap[item?.[optionLabel]]?.members?.length > 0)
                  ? 'red'
                  : allUsersMap[item?.[optionLabel]]?.isMember
                    ? 'gold'
                    : 'green'
              "
              [customIcon]="
                !(allUsersMap[item?.[optionLabel]]?.members?.length > 0)
                  ? 'fas fa-user-slash'
                  : allUsersMap[item?.[optionLabel]]?.isMember
                    ? 'fas fa-user-check'
                    : 'pi pi-users'
              "
              [text]="allUsersMap[item?.[optionLabel]]?.responsibilityCode"
              [pulse]="!(allUsersMap[item?.[optionLabel]]?.members?.length > 0)"
            ></app-badge-item>
            <app-avatar-list
              *ngIf="!allUsersMap[item?.[optionLabel]]?.responsibilityCode"
              class="mr-2"
              [users]="item?.[optionLabel]"
            ></app-avatar-list>
            <!-- <app-badge-item *ngIf="item?.responsibilityCode && !(item?.members?.length > 0)" class="mr-2" [styleClass]="'text-xs'" [status]="'ERROR'" [text]="'No Members'"></app-badge-item> -->
            @if (allUsersMap[item?.[optionLabel]]?.responsibilityCode) {
              <p>
                {{
                  allUsersMap[item?.[optionLabel]]?.responsibilityCode
                    ? allUsersMap[item?.[optionLabel]]?.responsibilityName
                    : item?.[optionLabel]
                }}
              </p>
            }
          </span>
          <div *ngIf="principleMode && principleModeSelection == 'SELECTION'">
            <p-selectButton
              [options]="responsibilityModeOptions"
              [(ngModel)]="item.mode"
              optionLabel="label"
              optionValue="value"
              (onOptionClick)="onOptionClick($event)"
              (onChange)="onChangeRespMode($event, item)"
              styleClass="resp-mode-buttons"
            >
              <ng-template let-item>
                <div
                  class="flex flex-row gap-2 align-items-center w-full align-self-center justify-content-center"
                  [pTooltip]="item.tooltip"
                  tooltipPosition="top"
                  style="padding: 0.629rem 0"
                >
                  <i [class]="item.icon"></i>
                  <div *ngIf="item.label">
                    {{ item.label }}
                  </div>
                </div>
              </ng-template>
            </p-selectButton>
          </div>
        </div>
      </ng-template>
    </app-auto-complete-input>
  </ng-template>
</app-input-view-switch>

import { AfterViewInit, Directive, Input, OnInit } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { IDynamicComponentBase } from './IDynamicComponentBase';
import { IViewMode } from './view-enums';

@Directive()
export abstract class DynamicComponentBase extends IDynamicComponentBase implements OnInit, AfterViewInit {
  /**
   * Component data member initializations through object instances should be placed here
   *
   * @abstract
   * @memberof DynamicComponentBase
   */
  @Input() set manualValue(value) {
    this.manualPatchData(value, { emitEvent: false });
  }
  resetable = true;
  resetFieldCheckVisable = false;
  liveDynamicData = null;
  prevViewMode: IViewMode;
  prevShowLabelInView: boolean;

  abstract setInputOptions(): void;
  ngOnInit(): void {}
  ngAfterViewInit(): void {
    if (this.control) {
      this.subs.sink = this.fControl.valueChanges.subscribe((changes) => {
        this.onChanges.emit(changes);
      });
    }
    this.onAfterViewInit();
  }
  setLiveData(liveData: any) {
    this.liveDynamicData = liveData;
    this.onSetLiveData();
  }
  onSetLiveData() {}

  setFieldResetCheckerVisablity(visable) {
    this.resetFieldCheckVisable = visable;
    if (visable) {
      this.prevViewMode = cloneDeep(this.viewMode);
      this.prevShowLabelInView = cloneDeep(this.showLabelInViewMode);
    } else {
      this.viewMode = cloneDeep(this.prevViewMode);
      this.showLabelInViewMode = cloneDeep(this.prevShowLabelInView);
    }
  }

  setFieldResetable(r) {
    this.resetable = !(r.checked == undefined ? r : r.checked);
    if (!this.resetable) {
      if (this.data || this.control.value) this.viewMode = 'view';
      this.showLabelInViewMode = true;
    } else {
      this.viewMode = cloneDeep(this.prevViewMode);
      this.showLabelInViewMode = cloneDeep(this.prevShowLabelInView);
    }
  }
  manualPatchData(
    value,
    options?: {
      onlySelf?: boolean;
      emitEvent?: boolean;
      emitModelToViewChange?: boolean;
      emitViewToModelChange?: boolean;
    }
  ) {
    if (this.fControl) {
      this.fControl.patchValue(value, options);
    }
  }
  onAfterViewInit(){

  }
}

import { Injectable } from '@angular/core';
import { IFieldDefinition, DataTypeEnum, AppPermissions, TargetTypeEnum, EntityTypeFieldDto } from '@shared/classes';
import { BaseFieldDefinitionsService } from '@shared/services/base-field-definitions.service';

@Injectable({
  providedIn: 'root',
})
export class GeographiesMappingService extends BaseFieldDefinitionsService {
  recordFields: IFieldDefinition[] = [
    {
      name: 'Name',
      key: 'name',
      dataType: DataTypeEnum.Text,
      required: true,
      showInTable: true,
      description: 'Record Name',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Owner',
      key: 'owner',
      dataType: DataTypeEnum.ResponsibilityListView,
      required: true,
      showInTable: true,
      description: 'Owner group of the Geography',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    // {
    //     name: "Responsibility",
    //     key: "responsibility",
    //     dataType: DataTypeEnum.CodeLink,
    //     required: true,
    //     showInTable: true,
    //     permissions: AppPermissions.ReadResponsibility,
    //     description: "Responsibility of the geography",
    //     tableDefinition: {
    //         filter: {
    //             type: "recordCode",
    //             matchMode: "in",
    //             showMatchModes: false,
    //             showAddButton: false,
    //             showOperator: false,
    //             recordCodeType: TargetTypeEnum.Responsibility,
    //         },
    //     },
    // },
    {
      name: 'Parent',
      key: 'parent',
      dataType: DataTypeEnum.CodeLink,
      required: false,
      showInTable: true,
      permissions: AppPermissions.ReadGeography,
      description: 'Parent of the geography',
      tableDefinition: {
        filter: {
          type: 'recordCode',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          recordCodeType: TargetTypeEnum.Geography,
        },
      },
    },
    {
      name: 'Type',
      key: 'type',
      dataType: DataTypeEnum.CodeLink,
      required: true,
      showInTable: true,
      permissions: AppPermissions.ReadEntityType,
      description: 'Entity Type of the geography',
      tableDefinition: {
        filter: {
          type: 'recordCode',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          recordCodeType: TargetTypeEnum.EntityType,
          options: {
            extraFilters: [
              {
                property: 'category',
                operation: 'IN',
                value: [EntityTypeFieldDto.CategoryEnum.Geography],
              },
            ],
          },
        },
      },
    },
    {
      name: 'organization',
      key: 'organization',
      dataType: DataTypeEnum.CodeLink,
      required: true,
      showInTable: true,
      permissions: AppPermissions.ReadOrganization,
      description: 'Organization that the geography belongs to',
      tableDefinition: {
        filter: {
          type: 'recordCode',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          recordCodeType: TargetTypeEnum.Organization,
        },
      },
    },
    // {
    //     name: "Description",
    //     key: "description",
    //     dataType: DataTypeEnum.LongText,
    //     required: false,
    //     showInTable: true,
    //     description: "Record Description",
    //     tableDefinition: {
    //         filter: {
    //             type: "text",
    //             display: "menu",
    //             matchMode: "contains",
    //             showMatchModes: true,
    //             showAddButton: true,
    //             showOperator: true,
    //         },
    //     },
    // },
    {
      name: 'Dynamics',
      key: 'dynamics',
      dataType: DataTypeEnum.AuditTrailDynamics,
      required: false,
      showInTable: false,
      description: 'Extra fields of record',
      tableDefinition: {},
    },
  ];
  constructor() {
    super();
    this.setAllFields();
  }
}

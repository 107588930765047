import { Component, OnInit } from '@angular/core';
import { IssueType } from '@shared/classes/model/backend/issue';

import { Router } from '@angular/router';
import { AppDialogService } from '@shared/services/app-dialog.service';
import { ExportDataService } from '@shared/services/export-data.service';
import { IssueTypeMappingService } from '@shared/services/mappings/issue/issue-type-mapping.service';
import { AppInjector } from 'app/app-injector';
import { ActionTypesEnum, ButtonColor, ModuleKeywords, PermissionActions, toKebabCase } from 'app/shared/classes';
import { BaseTablePage } from 'app/shared/classes/view/BaseTablePage';
import { IssueTypeDataService } from '../../services/data/issue-type-data.service';

@Component({
  selector: 'app-issue-types-list',
  templateUrl: './issue-types-list.component.html',
  styleUrls: ['./issue-types-list.component.scss'],
})
export class IssueTypesListComponent extends BaseTablePage<IssueType> implements OnInit {
  constructor(
    private requestService: IssueTypeDataService,
    exportService: ExportDataService,
    appDialogService: AppDialogService,
    router: Router
  ) {
    super(requestService, exportService, appDialogService, router, {
      moduleKeyword: ModuleKeywords.IssueType,
      routePrefix: 'issue',
    });
    this.SetPageValues({
      breadCrumb: {
        items: [
          {
            label: 'Issue Management',
            routerLink: [`${this.routePrefix ? this.routePrefix : toKebabCase(this.moduleKeyword)}`],
          },
          {
            label: 'Issue Types',
            routerLink: [`/${this.routePrefix}${toKebabCase(this.moduleKeyword)}/list`],
          },
        ],
      },
      pageActions: [
        {
          label: 'Add Issue Type',
          id: 1,
          type: ActionTypesEnum.Add,
          color: ButtonColor.Primary,
          icon: 'pi pi-plus',
          iconPos: 'left',
          command: this.addNewItem.bind(this),
          permission: `${PermissionActions.Create}${this.moduleKeyword}`,
        },
      ],
    });
  }

  ngOnInit(): void {}

  setCols() {
    const mappingService = AppInjector.get(IssueTypeMappingService);
    this.cols = [...mappingService.tableFields];
  }
}

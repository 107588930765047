import { Component, OnInit } from '@angular/core';
import { isAllowedInEnvironment, isNullObj, UnsubscribeOnDestroyAdapter } from '@shared/classes';
import { PathResolverService } from '@shared/services';
import { NgxPermissionsObject, NgxPermissionsService } from 'ngx-permissions';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import { AppComponent } from './app.component';
import { ConfigService } from './app.config.service';
import { AppMainComponent } from './app.main.component';
import { MenuItem, MenuItemList } from './app.menu.items';
import { DashboardDataService } from './modules/data-structure-module/services/data/dashboard-data.service';

@Component({
  selector: 'app-menu',
  templateUrl: './app.menu.component.html',
})
export class AppMenuComponent extends UnsubscribeOnDestroyAdapter implements OnInit {
  public originalModel: MenuItem[];
  public model: MenuItem[];

  constructor(
    public app: AppComponent,
    public appMain: AppMainComponent,
    private permissionService: NgxPermissionsService,
    public configService: ConfigService,
    private pathResolverService: PathResolverService,
    private dashboardService: DashboardDataService
  ) {
    super();
  }

  ngOnInit() {
    this.subs.sink = this.permissionService.permissions$.subscribe((res) => {
      this.originalModel = this.getRecursiveMenuItemList(res, MenuItemList);
      this.model = [...this.originalModel];
      this.pathResolverService.renderedMenuItems = this.model;
      this.searchSubscriber();
      // this.model = [...MenuItemList]
      // if (environment.production == 'dev') {
      //   this.subs.sink = this.dashboardService
      //     .search({ page: 0, all: true }, { projectionFields: ['code', 'label'] })
      //     .subscribe((dashboards: any) => {
      //       if (dashboards?.length > 0) {
      //         this.model.splice(1, 0, {
      //           label: 'Custom Dashboards',
      //           icon: 'sf sf-main',
      //           routerLink: ['/data-templates/dashboard/view-dashboard'],
      //           items: (dashboards as any[])?.map((x) => {
      //             return {
      //               label: x?.label,
      //               icon: 'pi pi-minus',
      //               routerLink: ['/data-templates/dashboard/view-dashboard/' + x?.code],
      //             };
      //           }),
      //         });
      //       }
      //     });
      // }
    });
  }
  getMenuItemList(perms: NgxPermissionsObject) {
    let menuList = [];
    MenuItemList.filter((x) => isAllowedInEnvironment(x.production)).forEach((item) => {
      if (this.checkIfAnyPermissionIncluded(perms, item?.permission)) {
        let itemCopy = item?.items ? { ...item, items: [] } : { ...item };
        item?.items
          ?.filter((x) => isAllowedInEnvironment(x.production))
          ?.forEach((childItem: any) => {
            if (this.checkIfAnyPermissionIncluded(perms, childItem?.permission)) {
              let childItemCopy = childItem?.items ? { ...childItem, items: [] } : { ...childItem };
              childItem?.items
                ?.filter((x) => isAllowedInEnvironment(x.production))
                ?.forEach((childItem2: any) => {
                  if (this.checkIfAnyPermissionIncluded(perms, childItem2?.permission)) {
                    childItemCopy.items.push({ ...childItem2 });
                  }
                });
              itemCopy.items.push({ ...childItemCopy });
            }
          });
        menuList.push({ ...itemCopy });
      }
    });
    return [...menuList];
  }
  getRecursiveMenuItemList(perms: NgxPermissionsObject, items: MenuItem[]): MenuItem[] {
    const filteredItems = items?.filter((item) => {
      return isAllowedInEnvironment(item?.production) && this.checkIfAnyPermissionIncluded(perms, item?.permission);
    });

    return filteredItems.map((item) => {
      const itemCopy = item?.items ? { ...item, items: [] } : { ...item };
      if (item?.items) {
        itemCopy.items = this.getRecursiveMenuItemList(perms, item?.items);
      }
      return itemCopy;
    });
  }
  checkIfAnyPermissionIncluded(perms: NgxPermissionsObject, permissions: string[]) {
    let hasPerm: boolean = false;
    if (permissions?.length > 0) {
      for (let i1 = 0; i1 < permissions.length; i1++) {
        const element = permissions[i1];
        if (perms[element]) {
          hasPerm = true;
          break;
        }
      }
    } else {
      //Object has no permission required so it meets condition
      hasPerm = true;
    }
    return hasPerm;
  }
  private searchText$ = new Subject<string>();
  search(keywords: string) {
    this.searchText$.next(keywords);
  }
  searchSubscriber() {
    this.searchText$.pipe(debounceTime(150), distinctUntilChanged()).subscribe((keywords) => {
      //@TODO: improve search algorithm using fuzzy-search-ts
      this.model = isNullObj(keywords) ? [...this.originalModel] : this.deepSearchByLabel(this.originalModel, keywords);
    });
  }
  deepSearchByLabel(menuItems: MenuItem[], searchLabel: string): MenuItem[] {
    const normalizedSearch = searchLabel.trim().toLowerCase();
    const matchingItems: MenuItem[] = [];

    for (const menuItem of menuItems) {
      if (menuItem.label) {
        const normalizedLabel = menuItem.label.trim().toLowerCase();
        if (normalizedLabel.includes(normalizedSearch)) {
          // Found a match!
          matchingItems.push(menuItem);
        }
      }

      if (menuItem.items) {
        // Recurse into nested items
        const childMatches = this.deepSearchByLabel(menuItem.items, searchLabel);
        matchingItems.push(...childMatches);
      }
    }

    return matchingItems;
  }
}

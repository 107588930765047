import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, FormControl, Validator, Validators } from '@angular/forms';
import { UnsubscribeOnDestroyAdapter } from '../controller';
import { Field, FilterItem } from '../model';
import { IAction } from './ButtonAction';
import { DataTypeEnum, IViewMode } from './view-enums';

@Directive()
export class BaseInput extends UnsubscribeOnDestroyAdapter implements IBaseInput {
  @Input() label: string;
  @Input() name: string;
  @Input() labelList?: string[];
  @Input() readOnly?: boolean = false;
  @Input() placeholder?: string = '';
  @Input() control: AbstractControl<any>;
  @Input() linkedControl: AbstractControl<any>;
  @Input() rightIcon?: string;
  @Input() leftIcon?: string;
  @Input() type?: string = 'text';
  @Input() inputMask?: string = '';
  @Input() patternError?: string = 'Must Be Valid';
  @Input() customError?: boolean = false;
  @Input() customErrorLabel?: string = 'Must Be Valid';
  @Input() helpText?: string = null;
  @Input() inputOptions?: BaseInputOptions = new BaseInputOptions();
  @Input() floatLabel?: boolean = false;
  @Input() data?: any;
  @Input() dataType?: DataTypeEnum = DataTypeEnum.Text;
  private _viewMode: IViewMode = IViewMode.create;
  get viewMode() {
    return this._viewMode;
  }
  @Input() set viewMode(viewMode: IViewMode) {
    this._viewMode = viewMode;
    this.onSetViewMode();
  }
  @Input() set disabled(disabled: boolean) {
    if (this.control) {
      disabled ? this?.control?.disable() : this?.control?.enable();
      this?.control?.updateValueAndValidity();
    }
  }
  @Input() showLabelInViewMode?: boolean = true;
  @Input() hideInFormRepeater?: boolean = false;
  @Input() instanceInFormRepeater?: boolean = false;

  @Output() onChanges: EventEmitter<any> = new EventEmitter();

  get fControl(): FormControl {
    return this.control as FormControl;
  }
  onSetViewMode() {}
  setBaseOptions(options: IBaseInput) {
    this.labelList = options?.labelList ?? this.labelList;
    this.label = options?.label ?? this.label;
    this.name = options?.name ?? this.name;
    this.placeholder = options?.placeholder ?? this.placeholder;
    this.control = options?.control ?? this.control;
    this.rightIcon = options?.rightIcon ?? this.rightIcon;
    this.leftIcon = options?.leftIcon ?? this.leftIcon;
    this.type = options?.type ?? this.type;
    this.inputMask = options?.inputMask ?? this.inputMask;
    this.patternError = options?.patternError ?? this.patternError;
    this.customError = options?.customError ?? this.customError;
    this.customErrorLabel = options?.customErrorLabel ?? this.customErrorLabel;
    this.inputOptions = options?.inputOptions ?? this.inputOptions;
    this.floatLabel = options?.floatLabel ?? this.floatLabel;
    this.data = options?.data ?? this.data;
    this.dataType = options?.dataType ?? this.dataType;
    this.viewMode = options?.viewMode ?? this.viewMode;
    this.showLabelInViewMode = options?.showLabelInViewMode ?? this.showLabelInViewMode;
    this.hideInFormRepeater = options?.hideInFormRepeater ?? this.hideInFormRepeater;
    this.instanceInFormRepeater = options?.instanceInFormRepeater ?? this.instanceInFormRepeater;
  }
  get isControlRequired() {
    return this.control.hasValidator(Validators.required);
  }
  get isControlRequiredTrue() {
    return this.control.hasValidator(Validators.requiredTrue);
  }
  get isLinkedControlsValid() {
    // let ret = true;
    // for (let index = 0; index < this.linkedControls?.length; index++) {
    //     const element = this.linkedControls[index];
    //     if(element.invalid) {
    //         ret = false;
    //         break;
    //     }
    // }
    return this.linkedControl?.valid;
  }
  get isLinkedControlsInvalid() {
    // let ret = false;
    // for (let index = 0; index < this.linkedControls?.length; index++) {
    //     const element = this.linkedControls[index];
    //     if(element.invalid) {
    //         ret = true;
    //         break;
    //     }
    // }
    return this.linkedControl?.invalid;
  }
}
export class BaseInputOptions {
  checkBox?: ICheckBox = new ICheckBox();
  dateInput?: IDateInput = new IDateInput();
  dropDownInput?: IDropDownInput = new IDropDownInput();
  aclInput?: IAclInput = new IAclInput();
  numberInput?: INumberInput = new INumberInput();
  sliderInput?: ISliderInput = new ISliderInput();
  radioInput?: IRadioInput = new IRadioInput();
  textEditorInput?: ITextEditorInput = new ITextEditorInput();
  textInput?: ITextInput = new ITextInput();
  attachmentInput?: IAttachmentInput = new IAttachmentInput();
  ratingInput?: IRatingInput = new IRatingInput();
  colorInput?: IColorInput = new IColorInput();
  codeSelectorInput?: ICodeSelector = new ICodeSelector();
  dynamicTypeOptionsInput?: IDynamicTypeOptionsInput = new IDynamicTypeOptionsInput();
  formRepeater?: IFormRepeater = new IFormRepeater();
  inheritedRiskInput?: IInheritedRiskField = new IInheritedRiskField();
  imageInput?: IImageInput = new IImageInput();
  extra?: any;
  linkedControlName?: string;
  linkedControl?: FormControl = null;
  linkedControlValue?: any = null;
  networkInput?: INetworkOptions = new INetworkOptions();
  gvlInput?: IGVLOptions = new IGVLOptions();
}

export class ICheckBox {
  binary: boolean = true;
  messageLabel: string = null;
  messageWrapper: boolean = false;
  falseValue: any = false;
  trueValue: any = true;
}
export class IDateInput {
  showIcon?: boolean = true;
  showClear?: boolean = false;
  showTime: boolean = false;
  minDate?: Date = null;
  maxDate?: Date = null;
  selectionMode?: 'single' | 'multiple' | 'range' = 'single';
  mode?: 'date' | 'month' | 'year' = 'date';
}
export class IAclInput {
  principleFieldList?: any[] = [];
  inheritedFieldList?: any[] = [];
}
export class IDropDownInput {
  multi: boolean = true;
  items: any[] = [];
  optionLabel: string;
  optionValue: string;
  showClear?: boolean = true;
  badgeView? = false;
  virtualScroll?: boolean = false;
  virtualScrollItemSize?: number = 10;
  lazy?: boolean = false;
  loading?: boolean = false;
  customFilter?: boolean = false;
  showFilter?: boolean = true;
  showStatusBadge?: boolean = true;
  showStatusBadgeStatusField?: string = 'recordStatus';
  showStatusBadgeTextField?: string = 'code';
  onLazyLoadCommand?: Function;
  onFilterCommand?: Function;
  appendTo?: string;
  treeDropDown?: boolean;
  dataKey?: string = null;
  defaultOption?: any = null;
  formatGetItems?: (items: any[], linkedControlValue: any) => any[];
  useCustomBadgeMode?: boolean = false;
  customBadgeColorField?: string = 'color';
  customBadgeIconField?: string = 'icon';
  customProjectionFields?: string[] = null;
}
export class INumberInput {
  mode?: 'decimal' | 'currency' = 'decimal';
  showButtons? = false;
  maxFractionDigits? = 5;
  buttonLayout?: 'stacked' | 'horizontal' | 'vertical' = 'stacked';
  incrementButtonClass? = 'p-button-primary';
  decrementButtonClass? = 'p-button-primary';
  incrementButtonIcon? = 'pi pi-angle-up';
  decrementButtonIcon? = 'pi pi-angle-down';
  min? = undefined;
  max? = undefined;
}

export class ISliderInput {
  min? = 0;
  max? = 100;
  disabled? = false;
  animate? = false;
  orientation?: 'horizontal' | 'vertical' = 'horizontal';
  step? = 1;
  range? = false;
  styleClass? = '';
  style? = '';
}

export class IRadioInput {
  groupItems: { label: string; value: any }[] = [];
  selectionMode: 'radio' | 'dropdown' = 'radio';
}
export class ITextEditorInput {
  advanced?: boolean = false;
  height?: any = '240px';
  marginTop?: string = '0px';
  showOnFocus?: boolean = false;
  showLabelInFocusMode?: boolean = true;
  hideToolbar?: boolean = false;
  sunEditorMode?: boolean = true;
  customDropdownData?: any[] = [];
}
export class ICodeSelector {
  targetTypes: any[] = null;
  extraFilters?: FilterItem[] = [];
  inlineMode?: boolean = false;
}
export class IInheritedRiskField {
  riskMeth = null;
}
export class IDynamicTypeOptionsInput {
  fieldType?: Field.TypeEnum = null;
  fieldTypeControl?: FormControl = null;
}
export class IRatingInput {
  maxNumber: number = 5;
  cancel?: boolean = true;
  iconOnClass?: string = 'pi pi-star-fill';
  iconOffClass?: string = 'pi pi-star';
  iconCancelClass?: string = 'pi pi-ban';
}
export class IColorInput {
  format: 'HEX' | 'RGB' | 'HSB';
  disabled: boolean;
}
export class IAttachmentInput {
  bucketId: string = 'root';
  mode: 'basic' | 'advanced' = 'basic';
  multiple: boolean = false;
  accept: string = null;
  maxFileSize: number = null;
  fileLimit: number = null;
  externalCode?: string = null;
  uploadLocation?: 'CODE' | 'CUSTOM_FOLDER' = 'CODE';
  acceptCustomLinks?: boolean = false;
}
export class IImageInput {
  maintainAspectRatio: boolean = true;
  aspectRatio: number = 16 / 9;
  format: 'png' | 'jpeg' | 'bmp' | 'webp' | 'ico' = 'png';
}
export class ITextInput {
  rows = 5;
  cols = 30;
}
export class IFormRepeater {
  title? = '';
  cols = [];
  extraActions?: IAction[] = [];
  extraEditActions?: IAction[] = [];
}
export class INetworkOptions {
  defaultBlockValues: string[] = ['', '', '', '', '', '', '', ''];
  disabledBlocks: number[] = [];
}
export class IGVLOptions {
  gvlCode = null;
  gvlListMode = false;
  gvlSelectionMode = false;
  gvlInlineMode = false;
  gvlMinSelection = 0;
  gvlMaxSelection = 0;
  gvlShowDescription = true;
  gvlShowNumeric = true;
}
export interface IBaseInput {
  label: string;
  name: string;
  labelList?: string[];
  placeholder?: string;
  control: AbstractControl<any>;
  rightIcon?: string;
  leftIcon?: string;
  type?: string;
  inputMask?: string;
  patternError?: string;
  customError?: boolean;
  customErrorLabel?: string;
  inputOptions?: BaseInputOptions;
  floatLabel?: boolean;
  data?: any;
  dataType?: DataTypeEnum;
  viewMode?: IViewMode;
  showLabelInViewMode?: boolean;
  validators?: Validator[];
  hideInFormRepeater?: boolean;
  instanceInFormRepeater?: boolean;
  passFormGroupAsData?: boolean;
  useCodeIfAvailable?: boolean;
  localUuidField?: boolean;
  formatGetValue?: (control: any) => any;
}

/**
 * Sightflare Service
 * Rest Api for Sightflare Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ChangeStatusBody } from './changeStatusBody';


export interface BulkChangeStatusBody { 
    codes?: Array<string>;
    toStatus?: BulkChangeStatusBody.ToStatusEnum;
    changeStatusBody?: ChangeStatusBody;
}
export namespace BulkChangeStatusBody {
    export type ToStatusEnum = 'TO_ATTEST' | 'TO_MONITOR' | 'TO_ATTEST_FROM_MONITOR' | 'TO_RETIRED' | 'TO_RETIRED_FROM_DRAFT' | 'TO_RETIRED_FROM_ATTEST';
    export const ToStatusEnum = {
        Attest: 'TO_ATTEST' as ToStatusEnum,
        Monitor: 'TO_MONITOR' as ToStatusEnum,
        AttestFromMonitor: 'TO_ATTEST_FROM_MONITOR' as ToStatusEnum,
        Retired: 'TO_RETIRED' as ToStatusEnum,
        RetiredFromDraft: 'TO_RETIRED_FROM_DRAFT' as ToStatusEnum,
        RetiredFromAttest: 'TO_RETIRED_FROM_ATTEST' as ToStatusEnum
    };
}



import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ImportBasePage, ImporterColumnDefinitions, ModuleKeywords, toKebabCase } from '@shared/classes';
import { EntityTypesDataService } from '../../services/data/entity-types-data.service';
import { ObjectivesDataService } from '../../services/data/objectives-data.service';
import { ResponsibilitiesDataService } from '../../services/data/responsibilities-data.service';

@Component({
  selector: 'app-objectives-import',
  templateUrl: './objectives-import.component.html',
  styleUrls: ['./objectives-import.component.scss'],
})
export class ObjectivesImportComponent extends ImportBasePage implements OnInit {
  typeId: any;
  constructor(
    public requestService: ObjectivesDataService,
    private responsibilityService: ResponsibilitiesDataService,
    private typeRequestService: EntityTypesDataService,
    private route: ActivatedRoute
  ) {
    super({ moduleKeyword: ModuleKeywords.Objective, routePrefix: 'entity' }, requestService);
    this.subs.sink = this.route.params.subscribe({
      next: (params) => {
        this.typeId = params['typeId'];
        this.SetPageValues({
          breadCrumb: {
            items: [
              {
                label: 'Entity',
                routerLink: [`${this.routePrefix ? this.routePrefix : toKebabCase(this.moduleKeyword)}`],
              },
              {
                label: 'Objectives',
                routerLink: [`/${this.routePrefix}${toKebabCase(this.moduleKeyword)}/list`],
              },
              { label: 'Import Data', routerLink: [] },
            ],
          },
          colDefinitions: ImporterColumnDefinitions[this.moduleKeyword],
          extraBodyObjects: { type: this.typeId },
        });
        this.initAsyncDropDownFields();
      },
    });
  }

  ngOnInit(): void {}
  initAsyncDropDownFields() {
    // this.subs.sink = this.typeRequestService.search<EntityTypeFieldDto>(
    //     { all: true },
    //     { projectionFields: ['code'], filters: [{ "property": "category", "operation": "EQUAL", "value": EntityTypeFieldDto.CategoryEnum.Objective }] },
    //     { showLoading: false, showMsg: false }
    // ).subscribe({
    //     next: (res:any) => {
    //         let list = res.map(x => x.code).join(",");
    //         this.colDefinitions[1].field.validators = [{
    //             key: 'dropdown',
    //             value: {
    //                 "type": "dropdown",
    //                 "type2": null,
    //                 "value1": list,
    //                 "value2": "",
    //                 "checked": false,
    //                 "remote": false,
    //                 "prohibitInput": false,
    //                 "hintShow": false,
    //                 "hintText": ""
    //             }
    //         }, ,];
    //         this.colDefinitions = [...this.colDefinitions];
    //     }
    // });
    // this.subs.sink = this.responsibilityService.getResponsibilityList(['code']).subscribe((res:any)=>{
    //     let list = res.map(x => x.code).join(",");
    //     this.colDefinitions[1].field.validators = [{
    //         key: 'dropdown',
    //         value: {
    //             "type": "dropdown",
    //             "type2": null,
    //             "value1": list,
    //             "value2": "",
    //             "checked": false,
    //             "remote": false,
    //             "prohibitInput": false,
    //             "hintShow": false,
    //             "hintText": ""
    //         }
    //     }, ,];
    //     this.colDefinitions = [...this.colDefinitions];
    // })
    // this.subs.sink = this.requestService.search<ObjectiveDto>(
    //     { all: true },
    //     { projectionFields: ['code'], filters: [] },
    //     { showLoading: false, showMsg: false }
    // ).subscribe((res:any)=>{
    //     let list = res.map(x => x.code).join(",");
    //     this.colDefinitions[2].field.validators = [{
    //         key: 'dropdown',
    //         value: {
    //             "type": "dropdown",
    //             "type2": null,
    //             "value1": list,
    //             "value2": "",
    //             "checked": false,
    //             "remote": false,
    //             "prohibitInput": false,
    //             "hintShow": false,
    //             "hintText": ""
    //         }
    //     }, ,];
    //     this.colDefinitions = [...this.colDefinitions];
    // })
    this.subs.sink = this.typeRequestService.getByIdOrCode(this.typeId).subscribe((res) => {
      res?.data?.fields.forEach((item) => {
        this.colDefinitions.push({
          id: this.colDefinitions.length + 4,
          type: 'text',
          field: {
            name: item.name, // 'Name',
            key: item.key, //'name',
            required:
              (item?.options?.GENERAL_REQUIRED as any) === true || (item?.options?.GENERAL_REQUIRED as any) === 'true'
                ? true
                : false,
            validators: null,
          },
          fieldGroupKey: 'dynamics',
          customValueObject: {
            valueKey: 'value',
            customObject: {
              field: item.code,
              value: null,
            },
          },
        });
      });
      this.colDefinitions = [...this.colDefinitions];
    });
  }
}

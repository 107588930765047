import { Injectable } from '@angular/core';
import { IFieldDefinition, DataTypeEnum, TargetTypeEnum } from '@shared/classes';
import { BaseFieldDefinitionsService } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class AuthorityMappingService extends BaseFieldDefinitionsService {
  recordFields: IFieldDefinition[] = [
    {
      name: 'Name',
      key: 'name',
      dataType: DataTypeEnum.Text,
      required: false,
      showInTable: true,
      description: 'Name of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Alias Name',
      key: 'aliasName',
      dataType: DataTypeEnum.Text,
      required: false,
      showInTable: true,
      description: 'Alias Name of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Version',
      key: 'versionName',
      dataType: DataTypeEnum.Text,
      required: false,
      showInTable: true,
      description: 'Version of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Unique Id',
      key: 'uniqueId',
      dataType: DataTypeEnum.Text,
      required: false,
      showInTable: true,
      description: 'Unique Id of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Valid From',
      key: 'validFrom',
      dataType: DataTypeEnum.DateLong,
      required: false,
      showInTable: true,
      description: 'Record is valid from',
      tableDefinition: {
        filter: {
          type: 'date',
          matchMode: 'dateBefore',
        },
      },
    },
    {
      name: 'Valid To',
      key: 'validTo',
      dataType: DataTypeEnum.DateLong,
      required: false,
      showInTable: true,
      description: 'Record is valid to',
      tableDefinition: {
        filter: {
          type: 'date',
          matchMode: 'dateBefore',
        },
      },
    },
    // {
    //     name: "Description",
    //     key: "description",
    //     dataType: DataTypeEnum.LongText,
    //     required: false,
    //     showInTable: true,
    //     description: "Record Description",
    //     tableDefinition: {
    //         filter: {
    //             type: "text",
    //             display: "menu",
    //             matchMode: "contains",
    //             showMatchModes: true,
    //             showAddButton: true,
    //             showOperator: true,
    //         },
    //     },
    // },
    {
      name: 'External References',
      key: 'externalReferences',
      dataType: DataTypeEnum.Text,
      required: false,
      showInTable: true,
      description: 'External References Id of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
  ];
  constructor() {
    super();
    this.setAllFields();
  }
}

import { Injectable } from '@angular/core';
import {
  AppPermissions,
  DataTypeEnum,
  IFieldDefinition,
  RiskItemDto,
  TargetTypeEnum
} from '@shared/classes';
import { BaseFieldDefinitionsService } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class RiskItemMappingService extends BaseFieldDefinitionsService {
  recordFields: IFieldDefinition[] = [
    {
      name: 'Name',
      key: 'name',
      dataType: DataTypeEnum.Text,
      required: true,
      showInTable: true,
      description: 'Record Name',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Owner',
      key: 'owner',
      dataType: DataTypeEnum.ResponsibilityListView,
      required: true,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Owner group of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Approver',
      key: 'approver',
      dataType: DataTypeEnum.ResponsibilityListView,
      required: true,
      showInTable: true,
      permissions: AppPermissions.ReadResponsibility,
      description: 'Users who should approve',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Strategy',
      key: 'strategy',
      dataType: DataTypeEnum.Badge,
      required: true,
      showInTable: true,
      description: 'Strategy of the record',
      tableDefinition: {
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: RiskItemDto.StrategyEnum,
        },
      },
    },
    {
      name: 'Status',
      key: 'status',
      dataType: DataTypeEnum.Badge,
      required: true,
      showInTable: true,
      description: 'Status of the record',
      tableDefinition: {
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: RiskItemDto.StatusEnum,
        },
      },
    },
    {
      name: 'Entity',
      key: 'entityId',
      dataType: DataTypeEnum.CodeLink,
      required: true,
      showInTable: true,
      description: 'Entity linked to the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Project',
      key: 'project',
      dataType: DataTypeEnum.CodeLink,
      required: true,
      showInTable: true,
      permissions: AppPermissions.ReadRiskProject,
      description: 'Project that the risk belongs to',
      tableDefinition: {
        filter: {
          type: 'recordCode',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          recordCodeType: TargetTypeEnum.RiskProject,
        },
      },
    },
    {
      name: 'Methodology',
      key: 'methodology',
      dataType: DataTypeEnum.CodeLink,
      required: false,
      showInTable: true,
      description: 'Risk Methodology linked to the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Inherited Likelihood',
      key: 'inheritedLikelihood.name',
      dataType: DataTypeEnum.Text,
      required: false,
      showInTable: true,
      description: 'Record Name',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Inherited Impact',
      key: 'inheritedRiskImpact.name',
      dataType: DataTypeEnum.Text,
      required: false,
      showInTable: true,
      description: 'Record Name',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Inherited',
      key: 'inheritedRisk',
      dataType: DataTypeEnum.RiskItemCell,
      required: false,
      showInTable: true,
      description: 'Record Name',
      tableDefinition: {
        options: {}, //TODO: fix risk item cell component to request the methodology on it's own similar to CodeLink component
        filter: {
          type: 'numeric',
          display: 'menu',
          matchMode: 'gte',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Inherited Classification',
      key: 'inheritedClassification',
      dataType: DataTypeEnum.Text,
      required: false,
      showInTable: true,
      description: 'Record Name',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Residual Likelihood',
      key: 'residualLikelihood.name',
      dataType: DataTypeEnum.Text,
      required: false,
      showInTable: true,
      description: 'Record Name',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Residual Impact',
      key: 'residualImpact.name',
      dataType: DataTypeEnum.Text,
      required: false,
      showInTable: true,
      description: 'Record Name',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Residual',
      key: 'residualRisk',
      dataType: DataTypeEnum.RiskItemCell,
      required: false,
      showInTable: true,
      description: 'Record Name',
      tableDefinition: {
        options: {}, //TODO: fix risk item cell component to request the methodology on it's own similar to CodeLink component
        filter: {
          type: 'numeric',
          display: 'menu',
          matchMode: 'gte',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Residual Classification',
      key: 'residualClassification',
      dataType: DataTypeEnum.Text,
      required: false,
      showInTable: true,
      description: 'Record Name',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
  ];
  constructor() {
    super();
    this.setAllFields();
  }
}

import {
  AccessRecord,
  AcknowledgmentDto,
  AggregationBody,
  ApiResponseBoolean,
  BaseListResponse,
  BasePaginationResponse,
  BaseResponse,
  ChangeStatusBody,
  CreateBody,
  FilterItem,
  OneEntityUpdateBody,
  PaginationModel,
  RequestHandlerOptions,
  SearchBody,
  SettingDto,
  UpdateItem
} from '@shared/classes';
import { PageableOptions } from 'app/shared/classes/model/frontend/pageable-options';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { DataService } from './data.service';

export class BaseRequestControllerService<BaseType> {
  private baseUrl: string = '';
  private subUrl: string = ''; //@NOTE: please be careful with the extra slash that might exist in the baseUrl
  protected defaultProjectionFields: string[] = ['id'];

  protected searchUrl: string = '/search';
  protected searchDeletedUrl: string = '/search-deleted';
  protected hardDeleteUrl: string = '/hard/';
  protected searchPackageExportUrl: string = '/search-packages-export';
  protected searchPackageImportUrl: string = '/search-packages-import';
  protected packageExportUrl: string = '/save-package-export';
  protected packageImportUrl: string = '/save-package-import';
  protected startPackageExportUrl: string = '/start-package-export/';
  protected startPackageImportUrl: string = '/start-package-import/';
  protected startPackageExportInstantUrl: string = '/export-package-instant-run/';
  protected startPackageImportInstantUrl: string = '/import-package-instant-run/';
  protected aggregateUrl: string = '/aggregate';
  protected bulkCreateUrl: string = '/bulk-create';
  protected bulkChangeStatusUrl: string = '/bulk-change-status';
  private pageUrl: string = '/page';
  private getByIdOrCodeUrl: string = '/getByIdOrCode/';
  private getByIdOrCodeForEditUrl: string = '/getByIdOrCodeForEdit/';
  private extendLockUrl: string = '/extend/';
  private releaseLockUrl: string = '/release/';
  private listUrl: string = '/list';
  private fixRelationsUrl: string = '/fix-relations';
  private restoreItemUrl: string = '/restore/';

  constructor(
    private request: DataService,
    baseUrl: string = '',
    subUrl: string = '',
    defaultProjectionFields = ['id', 'code']
  ) {
    this.baseUrl = baseUrl || environment.framework;
    this.subUrl = subUrl || '/entity';
    this.defaultProjectionFields = defaultProjectionFields || ['id'];
  }
  get projectionFields() {
    return this.defaultProjectionFields;
  }
  get url() {
    return this.baseUrl + this.subUrl;
  }
  getById<T = BaseType>(id: string, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.request.getData<BaseResponse<T>>(this.url + '/' + id, options) as Observable<BaseResponse<T>>;
  }
  create<T = BaseType>(body: any, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.request.postData<BaseResponse<T>>(this.url, body, options) as Observable<BaseResponse<T>>;
  }
  createV2<T = BaseType>(body: CreateBody, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.request.postData<BaseResponse<T>>(this.url + '/create/bulk/new', body, options) as Observable<
      BaseResponse<T>
    >;
  }
  bulkCreate<T = BaseType>(body: { bodies: CreateBody[] }, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.request.postData<BaseResponse<T>>(this.url + this.bulkCreateUrl, body, options) as Observable<
      BaseResponse<T>
    >;
  }
  bulkChangeStatus<T = BaseType>(body: { codes: String[], toStatus: String, changeStatusBody?: ChangeStatusBody }, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.request.postData<BaseResponse<T>>(this.url + this.bulkChangeStatusUrl, body, options) as Observable<
      BaseResponse<T>
    >;
  }
  update<T = BaseType>(body: T, id: string, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.request.putData<BaseResponse<T>>(this.url + '/' + id, body, options) as Observable<BaseResponse<T>>;
  }
  patchUpdate<T = BaseType>(
    body: OneEntityUpdateBody & { filters?: any[] },
    options: RequestHandlerOptions = new RequestHandlerOptions()
  ) {
    return this.request.postData<BaseResponse<T>>(this.url + '/update', body, options) as Observable<BaseResponse<T>>;
  }
  patchSingleUpdate<T = BaseType>(
    body: Array<UpdateItem>,
    idOrCode: string,
    options: RequestHandlerOptions = new RequestHandlerOptions()
  ) {
    return this.request.postData<BaseResponse<T>>(
      this.url + '/one-update/' + idOrCode,
      { updateItems: body },
      options
    ) as Observable<BaseResponse<T>>;
  }
  removeAccess<T = BaseType>(
    idOrCode: string,
    principle: string,
    options: RequestHandlerOptions = new RequestHandlerOptions()
  ) {
    return this.request.postData<BaseResponse<T>>(
      `${this.url}/${idOrCode}/${principle}/remove-access`,
      null,
      options
    ) as Observable<BaseResponse<T>>;
  }
  grantAccess<T = BaseType>(
    body: Array<{ principle?: string; accessLevel?: 'READ' | 'UPDATE' | 'ADMIN' | 'OWNER' }>,
    idOrCode: string,
    options: RequestHandlerOptions = new RequestHandlerOptions()
  ) {
    return this.request.postData<BaseResponse<T>>(`${this.url}/${idOrCode}/access`, body, options) as Observable<
      BaseResponse<T>
    >;
  }
  getAccessList<T = BaseType>(idOrCode: string, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.request.getData<BaseResponse<Array<AccessRecord>>>(
      `${this.url}/${idOrCode}/access`,
      options
    ) as Observable<BaseResponse<Array<AccessRecord>>>;
  }
  changeBaseStatus(
    id: string,
    status: AcknowledgmentDto.RecordStatusEnum,
    options: RequestHandlerOptions = new RequestHandlerOptions()
  ) {
    return this.request.postData<ApiResponseBoolean>(
      `${this.url}/changeBaseStatus/${id}/${status}`,
      null,
      options
    ) as Observable<ApiResponseBoolean>;
  }
  delete<T = BaseType>(id: string, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.request.deleteData<BaseResponse<T>>(this.url + '/' + id, options) as Observable<BaseResponse<T>>;
  }
  hardDelete<T = BaseType>(id: string, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.request.deleteData<BaseResponse<T>>(this.url + this.hardDeleteUrl + id, options) as Observable<BaseResponse<T>>;
  }
  getPage<T = BaseType>(pageOptions: PageableOptions, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.request.getData<BasePaginationResponse<T>>(this.url + this.pageUrl, {
      ...options,
      params: { ...pageOptions, ...options.params },
    }) as Observable<BasePaginationResponse<T>>;
  }
  search<T = BaseType>(
    pageOptions: PageableOptions,
    searchBody: SearchBody,
    options: RequestHandlerOptions = new RequestHandlerOptions(),
    searchUrl = this.searchUrl
  ) {
    searchBody.projectionFields = !!searchBody?.projectionFields
      ? searchBody.projectionFields
      : this.defaultProjectionFields;
    return this.request.postData<BasePaginationResponse<T>>(this.url + searchUrl, searchBody, {
      ...options,
      params: { ...pageOptions, ...options.params },
    }) as Observable<PaginationModel<T>>;
  }
  searchDeleted<T = BaseType>(
    pageOptions: PageableOptions,
    searchBody: SearchBody,
    options: RequestHandlerOptions = new RequestHandlerOptions(),
    searchUrl = this.searchDeletedUrl
  ) {
    return this.search<T>(pageOptions, searchBody, options, searchUrl);
  }
  searchPackageExport<T = BaseType>(
    pageOptions: PageableOptions,
    searchBody: SearchBody,
    options: RequestHandlerOptions = new RequestHandlerOptions(),
    searchUrl = this.searchPackageExportUrl
  ) {
    searchBody.projectionFields = !!searchBody?.projectionFields
      ? searchBody.projectionFields
      : [
        'id',
        'searchScore',
        'recordStatus',
        'code',
        'label',
        'version',
        'lockedForEdit',
        'creatorName',
        'lastModifierName',
        'lockedBy',
        'userAccessLevel',
        'parent',
        'parents',
        'creationDate',
        'lastModificationDate',
        'lockedUntil',
        'name',
        'mainType',
        'packageStatus',
        'mode',
        'counts',
        'fileUrl',
        'description',
        'filters',
        'typesToInclude',
      ];
    return this.request.postData<BasePaginationResponse<T>>(this.url + searchUrl, searchBody, {
      ...options,
      params: { ...pageOptions, ...options.params },
    }) as Observable<PaginationModel<T>>;
  }
  searchPackageImport<T = BaseType>(
    pageOptions: PageableOptions,
    searchBody: SearchBody,
    options: RequestHandlerOptions = new RequestHandlerOptions(),
    searchUrl = this.searchPackageImportUrl
  ) {
    searchBody.projectionFields = !!searchBody?.projectionFields
      ? searchBody.projectionFields
      : [
        'id',
        'searchScore',
        'recordStatus',
        'code',
        'label',
        'version',
        'lockedForEdit',
        'creatorName',
        'lastModifierName',
        'lockedBy',
        'userAccessLevel',
        'parent',
        'parents',
        'creationDate',
        'lastModificationDate',
        'lockedUntil',
        'name',
        'mainType',
        'packageStatus',
        'mode',
        'counts',
        'fileUrl',
        'description',
        'filters',
        'typesToInclude',
      ];
    return this.request.postData<BasePaginationResponse<T>>(this.url + searchUrl, searchBody, {
      ...options,
      params: { ...pageOptions, ...options.params },
    }) as Observable<PaginationModel<T>>;
  }
  fixRelations<T = BaseType>(
    searchBody: { filters?: FilterItem[] },
    options: RequestHandlerOptions = new RequestHandlerOptions()
  ) {
    return this.request.postData<BasePaginationResponse<T>>(this.url + this.fixRelationsUrl, searchBody, {
      ...options,
      params: { ...options.params },
    }) as Observable<PaginationModel<T>>;
  }
  exportPackage<T = BaseType>(
    pageOptions: PageableOptions,
    searchBody: {
      projectionFields?: string[];
      query?: any;
      filters?: FilterItem[];
      textSearch?: any;
      typesToInclude?: any[];
      mode?: any;
      name?: string;
      description?: string;
      exportReferenceRelations?: boolean;
      exportManualRelations?: boolean;
    },
    options: RequestHandlerOptions = new RequestHandlerOptions(),
    searchUrl = this.packageExportUrl
  ) {
    searchBody.projectionFields = undefined; //!!searchBody?.projectionFields ? searchBody.projectionFields : this.defaultProjectionFields;
    searchBody.mode = 'EXPORT';
    return this.request.postData<BasePaginationResponse<T>>(this.url + searchUrl, searchBody, {
      ...options,
      params: { ...pageOptions, ...options.params },
    }) as Observable<PaginationModel<T>>;
  }
  exportGlobalPackage<T = BaseType>(body: any, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    const tempUrl = environment.hub + '/global-export-packages';
    return this.request.postData<BasePaginationResponse<T>>(tempUrl, body, {
      ...options,
      params: { ...options.params },
    }) as Observable<PaginationModel<T>>;
  }
  importPackage<T = BaseType>(body: string, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.request.postData<BaseResponse<T>>(this.url + this.packageImportUrl, body, options) as Observable<
      BaseResponse<T>
    >;
  }
  startPackageExport<T = BaseType>(packageCode: string, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.request.postData<BaseResponse<T>>(
      this.url + this.startPackageExportUrl + packageCode,
      null,
      options
    ) as Observable<BaseResponse<T>>;
  }
  startPackageImport<T = BaseType>(packageCode: string, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.request.postData<BaseResponse<T>>(
      this.url + this.startPackageImportUrl + packageCode,
      null,
      options
    ) as Observable<BaseResponse<T>>;
  }
  startPackageExportInstant<T = BaseType>(
    packageCode: string,
    options: RequestHandlerOptions = new RequestHandlerOptions()
  ) {
    return this.request.postData<BaseResponse<T>>(
      this.url + this.startPackageExportInstantUrl + packageCode,
      null,
      options
    ) as Observable<BaseResponse<T>>;
  }
  startPackageImportInstant<T = BaseType>(
    packageCode: string,
    options: RequestHandlerOptions = new RequestHandlerOptions()
  ) {
    return this.request.postData<BaseResponse<T>>(
      this.url + this.startPackageImportInstantUrl + packageCode,
      null,
      options
    ) as Observable<BaseResponse<T>>;
  }
  aggregate<T = any>(
    aggregationBody: AggregationBody,
    options: RequestHandlerOptions = new RequestHandlerOptions(),
    aggregateUrl = this.aggregateUrl
  ) {
    return this.request.postData<any>(this.url + aggregateUrl, aggregationBody, options) as Observable<any>;
  }

  getList<T = BaseType>(options: RequestHandlerOptions = new RequestHandlerOptions(), listUrl = this.listUrl) {
    return this.request.getData<BaseListResponse<T>>(this.url + listUrl, {
      ...options,
      params: { ...options.params },
    }) as Observable<BaseListResponse<T>>;
  }
  getByIdOrCode<T = BaseType>(idOrCode: string, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.request.getData<BaseResponse<T>>(this.url + this.getByIdOrCodeUrl + idOrCode, options) as Observable<
      BaseResponse<T>
    >;
  }
  releaseLock<T = BaseType>(idOrCode: string, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.request.getData<BaseResponse<T>>(this.url + this.releaseLockUrl + idOrCode, options) as Observable<
      BaseResponse<T>
    >;
  }
  extendLock<T = BaseType>(idOrCode: string, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.request.getData<BaseResponse<T>>(this.url + this.extendLockUrl + idOrCode, options) as Observable<
      BaseResponse<T>
    >;
  }
  getByIdOrCodeForEdit<T = BaseType>(idOrCode: string, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.request.getData<BaseResponse<T>>(
      this.url + this.getByIdOrCodeForEditUrl + idOrCode,
      options
    ) as Observable<BaseResponse<T>>;
  }
  downloadPackageWithProgressExport(code: string, params = null) {
    return this.request.downloadWithProgress(`${this.url}/download-package-export/${code}`, params);
  }
  downloadPackageWithProgressImport(code: string, params = null) {
    return this.request.downloadWithProgress(`${this.url}/download-package-import/${code}`, params);
  }
  downloadFileWithProgress(fileId: string, params = null) {
    return this.request.downloadWithProgress(`${this.url}/download/${fileId}`, params);
  }
  patchSettings<T = SettingDto>(body: Array<UpdateItem>, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.request.putData<BaseResponse<T>>(this.url + '/settings', { updateItems: body }, options) as Observable<
      BaseResponse<T>
    >;
  }
  getSettings<T = SettingDto>(options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.request.getData<BaseResponse<T>>(this.url + '/settings', options) as Observable<BaseResponse<T>>;
  }
  getUnlinkedItems(unLinkString: string, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.request.getData<BaseResponse<any[]>>(this.url + '/un-linked-' + unLinkString, options) as Observable<
      BaseResponse<any[]>
    >;
  }
  restoreDeletedItem<T = BaseType>(code: string, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.request.postData<BaseResponse<T>>(this.url + this.restoreItemUrl + code, null, options) as Observable<
      BaseResponse<T>
    >;
  }
  navigateToListPage(params = null) {
    console.error('Define Usage in Children');
  }
}

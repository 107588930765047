import { Component, Input, OnInit } from '@angular/core';
import { DataTypeEnum } from '@shared/classes';
import { DynamicComponentBase } from '@shared/classes/view/DynamicComponentBase';

@Component({
  selector: 'app-radio-group',
  templateUrl: './radio-group.component.html',
  styleUrls: ['./radio-group.component.scss'],
  providers: [{ provide: DynamicComponentBase, useExisting: RadioGroupComponent }],
})
export class RadioGroupComponent extends DynamicComponentBase implements OnInit {
  @Input() groupItems: { label: string; value: any }[] = [];
  dataType: DataTypeEnum = DataTypeEnum.Text;
  constructor() {
    super();
  }

  ngOnInit(): void {}

  setInputOptions() {
    this.groupItems = this.inputOptions?.radioInput?.groupItems ?? this.groupItems;
  }
}

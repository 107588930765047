import { Injectable } from '@angular/core';
import { AppPermissions, DataTypeEnum, IFieldDefinition, TargetTypeEnum } from '@shared/classes';
import { BaseFieldDefinitionsService } from '@shared/services/base-field-definitions.service';

@Injectable({
  providedIn: 'root',
})
export class ControlObjectivesMappingService extends BaseFieldDefinitionsService {
  recordFields: IFieldDefinition[] = [
    {
      name: 'Name',
      key: 'name',
      dataType: DataTypeEnum.Text,
      required: true,
      showInTable: true,
      description: 'Record Name',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      importerDefinition: {
        type: 'text',
        field: {
          validators: null,
        },
      },
    },
    {
      name: 'Category',
      key: 'controlCategory',
      dataType: DataTypeEnum.CodeLink,
      required: false,
      showInTable: true,
      permissions: AppPermissions.ReadControlCategory,
      description: 'Control Category of the record',
      tableDefinition: {
        filter: {
          type: 'recordCode',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          recordCodeType: TargetTypeEnum.ControlCategory,
        },
      },
      importerDefinition: {
        type: 'text_content',
        field: {
          validators: [
            {
              key: 'text_content',
              value: {
                type: 'text_content',
                type2: 'include',
                value1: 'CCA-',
                value2: '',
                checked: false,
                remote: false,
                prohibitInput: false,
                hintShow: true,
                hintText: 'must start with CCA-',
              },
            },
          ],
        },
      },
    },
    {
      name: 'Policy Sections',
      key: 'policySections',
      dataType: DataTypeEnum.CodeLink,
      required: false,
      showInTable: false,
      permissions: AppPermissions.ReadPolicyBodySection,
      description: 'Policy Body Sections of the record',
      tableDefinition: {
        filter: {
          type: 'recordCode',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          recordCodeType: TargetTypeEnum.PolicyBodySection,
        },
      },
      importerDefinition: {
        type: 'text_content',
        field: {
          validators: null,
        },
        isArray: true,
        isCommaDelimiter: true,
      },
    },
    {
      name: 'Citations',
      key: 'citations',
      dataType: DataTypeEnum.CodeLink,
      required: false,
      showInTable: false,
      permissions: AppPermissions.ReadCitation,
      description: 'Citations of the record',
      tableDefinition: {
        filter: {
          type: 'recordCode',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          recordCodeType: TargetTypeEnum.Citation,
        },
      },
      importerDefinition: {
        type: 'text_content',
        field: {
          validators: null,
        },
        isArray: true,
        isCommaDelimiter: true,
      },
    },
    // {
    //     name: "Owner",
    //     key: "owner",
    //     dataType: DataTypeEnum.ResponsibilityListView,
    //     required: true,
    //     showInTable: true,
    //     // permissions: AppPermissions.ReadGroup,
    //     description: "Owner group of the asset",
    //     tableDefinition: {
    //         filter: {
    //             type: "text",
    //             display: "menu",
    //             matchMode: "startsWith",
    //             showMatchModes: true,
    //             showAddButton: true,
    //             showOperator: true,
    //         },
    //     }
    // },
    // {
    //     name: "Policy",
    //     key: "policy",
    //     dataType: DataTypeEnum.CodeLink,
    //     required: true,
    //     showInTable: true,
    //     permissions: AppPermissions.ReadPolicy,
    //     description: "Policy Linked to the control objective",
    //     tableDefinition: {
    //         filter: {
    //             type: 'recordCode',
    //             matchMode: "in",
    //             showMatchModes: false,
    //             showAddButton: false,
    //             showOperator: false,
    //             recordCodeType: TargetTypeEnum.Policy
    //         },
    //     }
    // },
    // {
    //     name: "Section",
    //     key: "policySection",
    //     dataType: DataTypeEnum.CodeLink,
    //     required: true,
    //     showInTable: true,
    //     permissions: AppPermissions.ReadPolicyBodySection,
    //     description: "Policy Section Linked to the control objective",
    //     tableDefinition: {
    //         filter: {
    //             type: 'recordCode',
    //             matchMode: "in",
    //             showMatchModes: false,
    //             showAddButton: false,
    //             showOperator: false,
    //             recordCodeType: TargetTypeEnum.Policy
    //         },
    //     }
    // },
    // {
    //     name: "Respondent",
    //     key: "attestationRespondent.name",
    //     dataType: DataTypeEnum.User,
    //     required: true,
    //     showInTable: true,
    //     // permissions: AppPermissions.ReadGroup,
    //     description: "Respondent of the control objective changes",
    //     tableDefinition: {
    //         filter: {
    //             type: "text",
    //             display: "menu",
    //             matchMode: "startsWith",
    //             showMatchModes: true,
    //             showAddButton: true,
    //             showOperator: true,
    //         },
    //     }
    // },
    // {
    //     name: "Compliance Status",
    //     key: "progress",
    //     dataType: DataTypeEnum.DynamicComponent,
    //     required: false,
    //     showInTable: true,
    //     description: "Compliance Status of the control objective respondents",
    //     tableDefinition: {
    //         options: {
    //             dynamicComponent: ControlComplianceProgressBarComponent
    //         },
    //         passRowAsData: true
    //     }
    // },
    // {
    //     name: "Control Frequency",
    //     key: "controlFrequency",
    //     dataType: DataTypeEnum.Number,
    //     required: true,
    //     showInTable: true,
    //     description: "Control Frequency of the control objective",
    //     tableDefinition: {
    //         filter: {
    //             type: "numeric",
    //             display: "menu",
    //             matchMode: "lte",
    //         },
    //     }
    // },
    // {
    //     name: "Control Classification",
    //     key: "controlClassification",
    //     dataType: DataTypeEnum.Badge,
    //     required: true,
    //     showInTable: true,
    //     description: "Control Classification of the control group",
    //     tableDefinition: {
    //         filter: {
    //             type: "enum",
    //             display: "menu",
    //             matchMode: "in",
    //             showMatchModes: false,
    //             showAddButton: false,
    //             showOperator: false,
    //             enumClass: ControlGroupDto.ControlClassificationEnum,
    //         },
    //     }
    // },
    // {
    //     name: "Entities",
    //     key: "controlEntities",
    //     dataType: DataTypeEnum.CodeLink,
    //     required: false,
    //     showInTable: true,
    //     permissions: AppPermissions.ReadAnyEntity,
    //     description: "Entities Linked to the control objective",
    //     tableDefinition: {
    //         filter: {
    //             type: "text",
    //             display: "menu",
    //             matchMode: "startsWith",
    //             showMatchModes: true,
    //             showAddButton: true,
    //             showOperator: true,
    //         },
    //     }
    // },
    // {
    //     name: "Questionnaire",
    //     key: "controlAttestationTemplate",
    //     dataType: DataTypeEnum.CodeLink,
    //     required: false,
    //     showInTable: true,
    //     permissions: AppPermissions.ReadQuestionnaire,
    //     description: "Questionnaire Linked to the control objective",
    //     tableDefinition: {
    //         filter: {
    //             type: 'recordCode',
    //             matchMode: "in",
    //             showMatchModes: false,
    //             showAddButton: false,
    //             showOperator: false,
    //             recordCodeType: TargetTypeEnum.Questionnaire
    //         },
    //     }
    // },
    {
      name: 'Description',
      key: 'description',
      dataType: DataTypeEnum.LongWithHoverText,
      required: false,
      showInTable: true,
      description: 'Record Description',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      importerDefinition: {
        type: 'text',
        field: {
          validators: null,
        },
      },
    },
  ];
  constructor() {
    super();
    this.setAllFields();
  }
}

<span
  class="p-inputgroup flex flex-row relative hide-selection flex flex-row md:flex-nowrap flex-wrap w-full algin-items-start"
  [class.p-float-label]="floatLabel"
>
  <ng-container *ngIf="!floatLabel; then labelTemplate"></ng-container>

  <div class="flex flex-column gap-3 min-w-0 max-w-calc-full" [class.md:max-w-calc-full-12rem]="label && !floatLabel">
    <p-tabMenu [(activeItem)]="currentItem" [model]="menuItems" [activeItem]="menuItems[0]" [scrollable]="true">
      <ng-template pTemplate="item" let-item>
        <a pRipple class="flex align-items-center gap-3 p-menuitem-link">
          <span class="font-bold white-space-nowrap"> {{ item.label }} </span>
          <p-badge [value]="this.tablesMap?.[item?.id]?.selectedItems?.length ?? 0" />
        </a>
      </ng-template>
    </p-tabMenu>

    @if (currentItem?.id) {
      <app-table
        style="display: block; height: 55vh; max-height: 55vh; min-height: 55vh"
        [useAutoUpdate]="false"
        [offlineConfig]="offlineConfig"
        [inputData]="tablesMap[currentItem.id].data"
        [pageInfo]="tablesMap[currentItem.id].tableEvent?.pageInfo"
        [isLoading]="tablesMap[currentItem.id].loading"
        [cols]="tablesMap[currentItem.id].cols"
        (filtersChanged)="fetchTableData(currentItem.id, $event)"
        (onRowSelection)="onRowSelected($event)"
        (onClearSelectionAndFilters)="onClearSelection($event)"
        [initSelectedItems]="tablesMap[currentItem.id].selectedItems"
        [dataKey]="optionValue == undefined ? dataKey : optionValue"
        [showSearchField]="showSearchField"
      ></app-table>
    } @else {
      <div style="display: block; height: 55vh; max-height: 55vh; min-height: 55vh">
        <div class="mb-4 w-full h-full text-center">
          @if (currentItem?.id) {
            <i class="pi pi-spin pi-spinner text-400"></i>
          } @else {
            <p>No Target Type Selected</p>
          }
        </div>
      </div>
    }
    <div
      class="w-full flex flex-row align-items-center justify-content-end gap-3"
      [class]="label || listBoxMode ? 'mb-3' : ''"
    >
      @if (currentItem?.id) {
        <span class="text-primary cursor-pointer hover:text-primary-700" (click)="clear()">
          Clear {{ this.allSelectedItems?.length ?? 0 }}
        </span>
      }
      @if (okButtonTemplate) {
        <ng-container *ngTemplateOutlet="okButtonTemplate"></ng-container>
      }
    </div>
  </div>

  <ng-container *ngIf="floatLabel; then labelTemplate"></ng-container>
</span>
<ng-template #labelTemplate>
  <label
    class="md:w-12rem md:max-w-12rem md:min-w-12rem w-full mr-1 input-field-label"
    *ngIf="label && !instanceInFormRepeater"
    >{{ label }}<span *ngIf="isControlRequired" class="text-red-500"> *</span>
    @if (resetFieldCheckVisable) {
      <br />
      <app-reset-switch-input [checked]="!resetable" (onChange)="setFieldResetable($event)"></app-reset-switch-input>
    }
  </label>
</ng-template>
<!-- [metaKeySelection] ="true" -->

import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AppMainComponent } from './app.main.component';
import { MenuService } from './app.menu.service';

@Component({
  /* tslint:disable:component-selector */
  selector: '[app-menuitem]',
  /* tslint:enable:component-selector */
  template: `
    <!-- <ng-container *ngxPermissionsOnly="item.permission"> -->
    <ng-container>
      @if (root && item.visible !== false) {
        <div class="layout-menuitem-root-text">{{ item.label }}</div>
      }
      @if ((!item.routerLink || item.items) && item.visible !== false) {
        <a
          [attr.href]="item.url"
          (mouseenter)="onMouseEnter()"
          [attr.target]="item.target"
          [attr.tabindex]="0"
          [ngClass]="item.class + (!root ? ' second-level-menu-item ' : '')"
          style="padding: 0;"
        >
          <i
            [routerLink]="item.routerLink"
            [ngClass]="item.icon"
            class="layout-menuitem-icon"
            style="padding: 8px 0 8px 8px;font-size: 1.5rem; width:auto;"
            pBadge
            severity="danger"
            value="Beta"
            [badgeDisabled]="item?.production != 'test'"
          ></i>
          <span
            [routerLink]="item.routerLink"
            class="layout-menuitem-text"
            style="width: 100%; height: 100%; padding: 8px 0 8px 0;"
            >{{ item.label }}</span
          >
          @if (item.items && item.items.length > 0) {
            <i
              (keydown.enter)="itemClick($event)"
              (click)="itemClick($event)"
              class="pi pi-fw pi-angle-down layout-submenu-toggler"
              style="font-size:1rem; padding:8px; width:auto;"
            ></i>
          }
          @if (item.badge) {
            <span class="menuitem-badge">{{ item.badge }}</span>
          }
        </a>
      }
      @if (item.routerLink && !item.items && item.visible !== false) {
        <a
          (click)="itemClick($event)"
          (mouseenter)="hover = true"
          (mouseleave)="hover = false"
          [routerLink]="item.routerLink"
          routerLinkActive="active-menuitem-routerlink"
          [routerLinkActiveOptions]="{ exact: !item.preventExact }"
          [attr.target]="item.target"
          [attr.tabindex]="0"
          [ngClass]="item.class"
          pRipple
        >
          <i
            [ngClass]="item.icon"
            class="layout-menuitem-icon"
            style="font-size: 1.5rem; width:auto;"
            pBadge
            severity="danger"
            value="Beta"
            [badgeDisabled]="item?.production != 'test'"
          ></i>
          <span class="layout-menuitem-text">{{ item.label }}</span>
          @if (item.items) {
            <i class="pi pi-fw pi-angle-down layout-submenu-toggler"></i>
          }
          @if (item.badge) {
            <span class="menuitem-badge">{{ item.badge }}</span>
          }
          @if (item.settings) {
            <span class="settings-button">
              <a class="text-white" [routerLink]="item.settings">
                <i class="pi pi-cog"></i>
              </a>
            </span>
          }
        </a>
      }
      @if (((item.items && active) || (item.items && app.isStatic() && (root || active))) && item.visible !== false) {
        <ul
          [@children]="
            (app.isSlim() || app.isHorizontal()) && !app.isMobile() && root
              ? active
                ? 'visible'
                : 'hidden'
              : app.isStatic() && root && !app.isMobile()
                ? 'visible'
                : active
                  ? 'visibleAnimated'
                  : 'hiddenAnimated'
          "
        >
          @for (child of item.items; track child; let i = $index) {
            <li app-menuitem [item]="child" [index]="i" [parentKey]="key" [class]="child.badgeClass"></li>
          }
        </ul>
      }
    </ng-container>
  `,
  host: {
    '[class.layout-root-menuitem]': 'root',
    '[class.active-menuitem]': 'active',
  },
  animations: [
    trigger('children', [
      state(
        'void',
        style({
          height: '0px',
        })
      ),
      state(
        'hiddenAnimated',
        style({
          height: '0px',
        })
      ),
      state(
        'visibleAnimated',
        style({
          height: '*',
        })
      ),
      state(
        'visible',
        style({
          height: '*',
          'z-index': 100,
        })
      ),
      state(
        'hidden',
        style({
          height: '0px',
          'z-index': '*',
        })
      ),
      transition('visibleAnimated => hiddenAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
      transition('hiddenAnimated => visibleAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
      transition('void => visibleAnimated, visibleAnimated => void', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
    ]),
  ],
})
export class AppMenuitemComponent implements OnInit, OnDestroy {
  @Input() item: any;

  @Input() index: number;

  @Input() root: boolean;

  @Input() parentKey: string;

  active = false;

  hover: boolean;

  menuSourceSubscription: Subscription;

  menuResetSubscription: Subscription;

  key: string;

  constructor(
    public app: AppMainComponent,
    public router: Router,
    private cd: ChangeDetectorRef,
    private menuService: MenuService
  ) {
    this.menuSourceSubscription = this.menuService.menuSource$.subscribe((key) => {
      // deactivate current active menu
      if (this.active && this.key !== key && key.indexOf(this.key) !== 0) {
        this.active = false;
      }
    });

    this.menuResetSubscription = this.menuService.resetSource$.subscribe(() => {
      this.active = false;
    });

    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((params) => {
      if ((this.app.isSlim() || this.app.isHorizontal()) && !this.app.isMobile()) {
        this.active = false;
      } else {
        if (this.item.routerLink) {
          this.updateActiveStateFromRoute();
        } else {
          this.active = false;
        }
      }
    });
  }

  ngOnInit() {
    if (!(this.app.isSlim() || this.app.isHorizontal()) && this.item.routerLink) {
      this.updateActiveStateFromRoute();
    }

    this.key = this.parentKey ? this.parentKey + '-' + this.index : String(this.index);
  }

  updateActiveStateFromRoute() {
    this.active = this.router.isActive(this.item.routerLink[0], !this.item.items && !this.item.preventExact);
  }

  itemClick(event: Event, expand = true) {
    // avoid processing disabled items
    event?.stopPropagation();
    if (this.item.disabled) {
      event.preventDefault();
      return;
    }

    // navigate with hover in horizontal mode
    if (this.root) {
      this.app.menuHoverActive = !this.app.menuHoverActive;
    }

    // notify other items
    this.menuService.onMenuStateChange(this.key);

    // execute command
    if (this.item.command) {
      this.item.command({ originalEvent: event, item: this.item });
    }

    // toggle active state
    if (this.item.items) {
      if (expand) {
        this.active = !this.active;
      }
    } else {
      // activate item
      this.active = true;

      if (this.app.isMobile()) {
        this.app.staticMenuMobileActive = false;
      }

      if (this.app.isOverlay()) {
        this.app.overlayMenuActive = false;
      }

      // reset horizontal menu
      if ((this.app.isSlim() || this.app.isHorizontal()) && !this.app.isMobile()) {
        this.menuService.reset();
        this.app.menuHoverActive = false;
      }
    }
  }

  onMouseEnter() {
    // activate item on hover
    if (
      this.root &&
      this.app.menuHoverActive &&
      (this.app.isSlim() || this.app.isHorizontal()) &&
      this.app.isDesktop()
    ) {
      this.menuService.onMenuStateChange(this.key);
      this.active = true;
    }
  }

  ngOnDestroy() {
    if (this.menuSourceSubscription) {
      this.menuSourceSubscription.unsubscribe();
    }

    if (this.menuResetSubscription) {
      this.menuResetSubscription.unsubscribe();
    }
  }
}

import { Injectable } from '@angular/core';
import { IFieldDefinition, DataTypeEnum, AuditExpense } from '@shared/classes';
import { BaseFieldDefinitionsService } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class AuditExpenseMappingService extends BaseFieldDefinitionsService {
  recordFields: IFieldDefinition[] = [
    {
      name: 'Name',
      key: 'name',
      dataType: DataTypeEnum.Text,
      required: false,
      showInTable: true,
      description: 'Name of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Status',
      key: 'status',
      dataType: DataTypeEnum.Badge,
      required: false,
      showInTable: true,
      description: 'Status of the record',
      tableDefinition: {
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: AuditExpense.StatusEnum,
        },
      },
    },
    {
      name: 'Auditor',
      key: 'auditor',
      dataType: DataTypeEnum.ResponsibilityListView,
      required: true,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Auditor of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Project',
      key: 'auditProject',
      dataType: DataTypeEnum.CodeLink,
      required: false,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Audit Project of this Record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Program',
      key: 'auditProgram',
      dataType: DataTypeEnum.CodeLink,
      required: false,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Audit Program of this Record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Case Result',
      key: 'caseResult',
      dataType: DataTypeEnum.CodeLink,
      required: false,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Audit Case Result of this Record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
  ];
  constructor() {
    super();
    this.setAllFields();
  }
}

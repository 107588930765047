import { FormControl, Validators } from '@angular/forms';
import { ListStringsComponent } from '@shared/components/components/list-strings/list-strings.component';
import { IconSelectorComponent } from '@shared/components/selectors/icon-selector/icon-selector.component';
import { TargetCodeSelectorComponent } from '@shared/components/selectors/target-code-selector/target-code-selector.component';
import { UserAndGroupSelectorComponent } from '@shared/components/selectors/user-and-group-selector/user-and-group-selector.component';
import { UserSelectorComponent } from '@shared/components/selectors/user-selector/user-selector.component';
import { AttachmentInputComponent } from '@shared/components/ui/input-fields/attachment-input/attachment-input.component';
import { BasicInputComponent } from '@shared/components/ui/input-fields/basic-input/basic-input.component';
import { ColorInputComponent } from '@shared/components/ui/input-fields/color-input/color-input.component';
import { DateInputComponent } from '@shared/components/ui/input-fields/date-input/date-input.component';
import { DropDownInputComponent } from '@shared/components/ui/input-fields/drop-down-input/drop-down-input.component';
import { PSliderInputComponent } from '@shared/components/ui/input-fields/p-slider-input/p-slider-input.component';
import { SwitchInputComponent } from '@shared/components/ui/input-fields/switch-input/switch-input.component';
import { TextEditorComponent } from '@shared/components/ui/input-fields/text-editor/text-editor.component';
import { TextInputComponent } from '@shared/components/ui/input-fields/text-input/text-input.component';
import { cloneDeep } from 'lodash-es';
import { getEnumOptions } from '../helpers';
import {
  EntityTypeFieldDto,
  Issue,
  PolicyBodySection,
  QuestionnaireQuestionsDto,
  RelationTypeDto,
  Task,
} from '../model';
import { ModuleKeywords } from './ModuleKeywords';
import { DataTypeEnum } from './view-enums';

export let BulkOperationFieldsMapper = {
  //Acknowledgment
  [ModuleKeywords.AcknowledgmentRequest]: [
    {
      componentType: BasicInputComponent,
      options: {
        label: 'Name',
        name: 'name',
        placeholder: 'Enter a name',
        control: new FormControl(''),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
      },
    },
  ],

  //Activity Feed
  [ModuleKeywords.EmailTemplate]: [
    {
      componentType: BasicInputComponent,
      options: {
        label: 'Name',
        name: 'name',
        placeholder: 'Enter a name',
        control: new FormControl('', Validators.required),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
      },
    },
    {
      componentType: TextInputComponent,
      options: {
        label: 'Subject Template',
        name: 'subjectTemplate',
        placeholder: 'Enter Text...',
        control: new FormControl('', Validators.required),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
        inputOptions: {
          textInput: {
            rows: 2,
          },
        },
      },
    },
    {
      componentType: TextEditorComponent,
      options: {
        label: 'Body Template',
        name: 'bodyTemplate',
        placeholder: 'Enter Body Template...',
        control: new FormControl(''),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
        inputOptions: {
          textEditorInput: {
            advanced: true,
          },
        },
      },
    },
  ],
  [ModuleKeywords.Task]: [
    {
      componentType: UserAndGroupSelectorComponent,
      options: {
        label: 'Assignee',
        name: 'principle',
        placeholder: 'Search Assignee...',
        control: new FormControl(null, Validators.required),
        inputOptions: {
          extra: { principleMode: true },
          dropDownInput: {
            optionValue: 'undefined',
            multi: false,
          },
        },
        showLabelInViewMode: false,
      },
    },
    {
      componentType: UserSelectorComponent,
      options: {
        label: 'Reporter',
        name: 'sender',
        placeholder: 'Reporter',
        control: new FormControl(null),
        inputOptions: {
          dropDownInput: {
            optionValue: 'userName',
            multi: false,
          },
        },
        showLabelInViewMode: false,
      },
    },
    {
      componentType: BasicInputComponent,
      options: {
        label: 'Subject',
        name: 'subject',
        placeholder: 'Subject',
        control: new FormControl('', Validators.required),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
      },
    },
    {
      componentType: DropDownInputComponent,
      options: {
        label: 'Priority',
        name: 'priority',
        placeholder: 'Select Item',
        control: new FormControl(''),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
        inputOptions: {
          dropDownInput: {
            optionLabel: 'label',
            optionValue: 'value',
            appendTo: 'body',
            items: getEnumOptions(Task.PriorityEnum),
          },
        },
      },
    },
    {
      componentType: DropDownInputComponent,
      options: {
        label: 'Status',
        name: 'status',
        placeholder: 'Select Item',
        control: new FormControl(''),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
        inputOptions: {
          dropDownInput: {
            optionLabel: 'label',
            optionValue: 'value',
            appendTo: 'body',
            items: getEnumOptions(Task.StatusEnum),
            badgeView: true,
          },
        },
      },
    },
    {
      componentType: PSliderInputComponent,
      options: {
        label: 'Progress',
        name: 'progress',
        placeholder: 'Enter Progress Number',
        control: new FormControl(0),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
      },
    },
    {
      componentType: DateInputComponent,
      options: {
        label: 'Due Date',
        name: 'dueDate',
        placeholder: '11/22/2022',
        control: new FormControl(''),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
        inputOptions: {
          dateInput: {
            showIcon: true,
          },
        },
      },
    },
    {
      componentType: TextEditorComponent,
      options: {
        label: 'Description',
        name: 'description',
        control: new FormControl(''),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
      },
    },
  ],
  [ModuleKeywords.TaskTemplate]: [
    {
      componentType: TextInputComponent,
      options: {
        label: 'Subject Template',
        name: 'titleTemplate',
        placeholder: 'Enter Text...',
        control: new FormControl('', Validators.required),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
        inputOptions: {
          textInput: {
            rows: 2,
          },
        },
      },
    },
    {
      componentType: TextEditorComponent,
      options: {
        label: 'Body Template',
        name: 'descriptionTemplate',
        placeholder: 'Enter Description Template...',
        control: new FormControl(''),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
        inputOptions: {
          textEditorInput: {
            advanced: true,
          },
        },
      },
    },
  ],

  //Audit
  [ModuleKeywords.AuditCase]: [
    {
      componentType: BasicInputComponent,
      options: {
        label: 'Name',
        name: 'name',
        placeholder: 'Enter a name',
        control: new FormControl('', Validators.required),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
      },
    },
    {
      componentType: UserAndGroupSelectorComponent,
      options: {
        label: 'Owner',
        name: 'owner',
        placeholder: 'Search...',
        control: new FormControl(null, Validators.required),
        inputOptions: {
          extra: { principleMode: true },
          dropDownInput: {
            optionValue: 'undefined',
            multi: false,
          },
        },
        showLabelInViewMode: false,
      },
    },
    {
      componentType: UserAndGroupSelectorComponent,
      options: {
        label: 'Respondent',
        name: 'respondent',
        placeholder: 'Search...',
        control: new FormControl(null, Validators.required),
        inputOptions: {
          extra: { principleMode: true },
          dropDownInput: {
            optionValue: 'undefined',
            multi: false,
          },
        },
        showLabelInViewMode: false,
      },
    },
    {
      componentType: UserAndGroupSelectorComponent,
      options: {
        label: 'Auditor',
        name: 'auditor',
        placeholder: 'Search...',
        control: new FormControl(null, Validators.required),
        inputOptions: {
          extra: { principleMode: true },
          dropDownInput: {
            optionValue: 'undefined',
            multi: false,
          },
        },
        showLabelInViewMode: false,
      },
    },
    {
      componentType: UserAndGroupSelectorComponent,
      options: {
        label: 'Auditee',
        name: 'auditee',
        placeholder: 'Search...',
        control: new FormControl(null),
        inputOptions: {
          extra: { principleMode: true },
          dropDownInput: {
            optionValue: 'undefined',
            multi: false,
          },
        },
        showLabelInViewMode: false,
      },
    },
    {
      componentType: TargetCodeSelectorComponent,
      options: {
        label: 'Audit Questionnaire',
        name: 'auditQuestionnaire',
        placeholder: 'Search Questionnaire',
        control: new FormControl(null, Validators.required),
        inputOptions: {
          codeSelectorInput: {
            targetTypes: ['QUESTIONNAIRE'],
          },
          dropDownInput: {
            optionLabel: 'label',
            optionValue: 'code',
            multi: false,
            customProjectionFields: ['code', 'recordStatus', 'label', 'name'],
          },
        },
        showLabelInViewMode: false,
      },
    },
    {
      componentType: TargetCodeSelectorComponent,
      options: {
        label: 'Evidence Questionnaire',
        name: 'evidenceQuestionnaire',
        placeholder: 'Search Questionnaire',
        control: new FormControl(null, Validators.required),
        inputOptions: {
          codeSelectorInput: {
            targetTypes: ['QUESTIONNAIRE'],
          },
          dropDownInput: {
            optionLabel: 'label',
            optionValue: 'code',
            multi: false,
            customProjectionFields: ['code', 'recordStatus', 'label', 'name'],
          },
        },
        showLabelInViewMode: false,
      },
    },
  ],
  [ModuleKeywords.AuditCaseResult]: [
    {
      componentType: BasicInputComponent,
      options: {
        label: 'Name',
        name: 'name',
        placeholder: 'Enter a name',
        control: new FormControl('', Validators.required),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
      },
    },
    {
      componentType: UserAndGroupSelectorComponent,
      options: {
        label: 'Owner',
        name: 'owner',
        placeholder: 'Search...',
        control: new FormControl(null, Validators.required),
        inputOptions: {
          extra: { principleMode: true },
          dropDownInput: {
            optionValue: 'undefined',
            multi: false,
          },
        },
        showLabelInViewMode: false,
      },
    },
    {
      componentType: UserAndGroupSelectorComponent,
      options: {
        label: 'Auditor',
        name: 'auditor',
        placeholder: 'Search...',
        control: new FormControl(null, Validators.required),
        inputOptions: {
          extra: { principleMode: true },
          dropDownInput: {
            optionValue: 'undefined',
            multi: false,
          },
        },
        showLabelInViewMode: false,
      },
    },
    {
      componentType: UserAndGroupSelectorComponent,
      options: {
        label: 'Auditee',
        name: 'auditee',
        placeholder: 'Search...',
        control: new FormControl(null),
        inputOptions: {
          extra: { principleMode: true },
          dropDownInput: {
            optionValue: 'undefined',
            multi: false,
          },
        },
        showLabelInViewMode: false,
      },
    },
    {
      componentType: TargetCodeSelectorComponent,
      options: {
        label: 'Audit Program',
        name: 'auditProgram',
        placeholder: 'Search Audit Programs',
        control: new FormControl(null),
        inputOptions: {
          codeSelectorInput: {
            targetTypes: ['AUDIT_PROGRAM'],
          },
          dropDownInput: {
            multi: false,
          },
        },
        showLabelInViewMode: false,
      },
    },
    {
      componentType: TargetCodeSelectorComponent,
      options: {
        label: 'Audit Project',
        name: 'auditProject',
        placeholder: 'Search Audit Projects',
        control: new FormControl(null),
        inputOptions: {
          codeSelectorInput: {
            targetTypes: ['AUDIT_PROJECT'],
          },
          dropDownInput: {
            multi: false,
          },
        },
        showLabelInViewMode: false,
      },
    },
    {
      componentType: TargetCodeSelectorComponent,
      options: {
        label: 'Audit Questionnaire',
        name: 'auditQuestionnaire',
        placeholder: 'Search Questionnaire',
        control: new FormControl(null),
        inputOptions: {
          codeSelectorInput: {
            targetTypes: ['QUESTIONNAIRE'],
          },
          dropDownInput: {
            optionLabel: 'label',
            optionValue: 'code',
            multi: false,
            customProjectionFields: ['code', 'recordStatus', 'label', 'name'],
          },
        },
        showLabelInViewMode: false,
      },
    },
    {
      componentType: TargetCodeSelectorComponent,
      options: {
        label: 'Evidence Questionnaire',
        name: 'evidenceQuestionnaire',
        placeholder: 'Search Questionnaire',
        control: new FormControl(null),
        inputOptions: {
          codeSelectorInput: {
            targetTypes: ['QUESTIONNAIRE'],
          },
          dropDownInput: {
            optionLabel: 'label',
            optionValue: 'code',
            multi: false,
            customProjectionFields: ['code', 'recordStatus', 'label', 'name'],
          },
        },
        showLabelInViewMode: false,
      },
    },
    {
      componentType: TargetCodeSelectorComponent,
      options: {
        label: 'Cases',
        name: 'cases',
        placeholder: 'Search Cases',
        control: new FormControl(null),
        inputOptions: {
          codeSelectorInput: {
            targetTypes: ['AUDIT_CASE'],
          },
          dropDownInput: {
            optionLabel: 'label',
            optionValue: 'code',
            multi: true,
            customProjectionFields: ['code', 'recordStatus', 'label', 'name'],
          },
        },
        showLabelInViewMode: false,
      },
    },
    {
      componentType: TargetCodeSelectorComponent,
      options: {
        label: 'Issues',
        name: 'issues',
        placeholder: 'Search Issues',
        control: new FormControl(null),
        inputOptions: {
          codeSelectorInput: {
            targetTypes: ['ISSUE'],
          },
          dropDownInput: {
            optionLabel: 'label',
            optionValue: 'code',
            multi: true,
            customProjectionFields: ['code', 'recordStatus', 'label', 'name'],
          },
        },
        showLabelInViewMode: false,
      },
    },
    {
      componentType: TargetCodeSelectorComponent,
      options: {
        label: 'Audit Requests',
        name: 'auditRequests',
        placeholder: 'Search Requests',
        control: new FormControl(null),
        inputOptions: {
          codeSelectorInput: {
            targetTypes: ['AUDIT_REQUEST'],
          },
          dropDownInput: {
            optionLabel: 'label',
            optionValue: 'code',
            multi: true,
            customProjectionFields: ['code', 'recordStatus', 'label', 'name'],
          },
        },
        showLabelInViewMode: false,
      },
    },
  ],
  [ModuleKeywords.AuditProgramReport]: [
    {
      componentType: BasicInputComponent,
      options: {
        label: 'Name',
        name: 'name',
        placeholder: 'Enter a name',
        control: new FormControl('', Validators.required),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
      },
    },
    {
      componentType: TextEditorComponent,
      options: {
        label: 'Description',
        name: 'description',
        control: new FormControl(''),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
      },
    },
    {
      componentType: TextEditorComponent,
      options: {
        label: 'Reporter Notes',
        placeholder: 'reporter Notes',
        name: 'reporterNotes',
        control: new FormControl('', Validators.required),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
      },
    },
    {
      componentType: TargetCodeSelectorComponent,
      options: {
        label: 'Issues',
        name: 'issues',
        placeholder: 'Search Issues',
        control: new FormControl(null),
        inputOptions: {
          codeSelectorInput: {
            targetTypes: ['ISSUE'],
          },
          dropDownInput: {
            optionLabel: 'label',
            optionValue: 'code',
            multi: true,
            customProjectionFields: ['code', 'recordStatus', 'label', 'name'],
          },
        },
        showLabelInViewMode: false,
      },
    },
  ],
  [ModuleKeywords.AuditProgramType]: [
    {
      componentType: BasicInputComponent,
      options: {
        label: 'Name',
        name: 'name',
        placeholder: 'Enter a name',
        control: new FormControl('', Validators.required),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
      },
    },
    {
      componentType: TextEditorComponent,
      options: {
        label: 'Description',
        name: 'description',
        control: new FormControl(''),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
      },
    },
  ],
  [ModuleKeywords.AuditProject]: [
    {
      componentType: BasicInputComponent,
      options: {
        label: 'Name',
        name: 'name',
        placeholder: 'Enter a name',
        control: new FormControl('', Validators.required),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
      },
    },
    {
      componentType: UserAndGroupSelectorComponent,
      options: {
        label: 'Owner',
        name: 'owner',
        placeholder: 'Search...',
        control: new FormControl(null, Validators.required),
        inputOptions: {
          extra: { principleMode: true },
          dropDownInput: {
            optionValue: 'undefined',
            multi: false,
          },
        },
        showLabelInViewMode: false,
      },
    },
    {
      componentType: UserAndGroupSelectorComponent,
      options: {
        label: 'Manager',
        name: 'manager',
        placeholder: 'Search...',
        control: new FormControl(null, Validators.required),
        inputOptions: {
          extra: { principleMode: true },
          dropDownInput: {
            optionValue: 'undefined',
            multi: false,
          },
        },
        showLabelInViewMode: false,
      },
    },
    {
      componentType: UserAndGroupSelectorComponent,
      options: {
        label: 'Approver',
        name: 'approver',
        placeholder: 'Search...',
        control: new FormControl(null, Validators.required),
        inputOptions: {
          extra: { principleMode: true },
          dropDownInput: {
            optionValue: 'undefined',
            multi: false,
          },
        },
        showLabelInViewMode: false,
      },
    },
    {
      componentType: TextEditorComponent,
      options: {
        label: 'Description',
        name: 'description',
        control: new FormControl(''),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
      },
    },
  ],
  [ModuleKeywords.AuditProjectReport]: [
    {
      componentType: BasicInputComponent,
      options: {
        label: 'Name',
        name: 'name',
        placeholder: 'Enter a name',
        control: new FormControl('', Validators.required),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
      },
    },
    {
      componentType: TextEditorComponent,
      options: {
        label: 'Description',
        name: 'description',
        control: new FormControl(''),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
      },
    },
    {
      componentType: TextEditorComponent,
      options: {
        label: 'Reporter Notes',
        placeholder: 'reporter Notes',
        name: 'reporterNotes',
        control: new FormControl('', Validators.required),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
      },
    },
    {
      componentType: TargetCodeSelectorComponent,
      options: {
        label: 'Issues',
        name: 'issues',
        placeholder: 'Search Issues',
        control: new FormControl(null),
        inputOptions: {
          codeSelectorInput: {
            targetTypes: ['ISSUE'],
          },
          dropDownInput: {
            optionLabel: 'label',
            optionValue: 'code',
            multi: true,
            customProjectionFields: ['code', 'recordStatus', 'label', 'name'],
          },
        },
        showLabelInViewMode: false,
      },
    },
  ],
  [ModuleKeywords.AuditRequest]: [
    {
      componentType: BasicInputComponent,
      options: {
        label: 'Subject',
        name: 'subject',
        placeholder: 'Enter a name',
        control: new FormControl('', Validators.required),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
      },
    },
    {
      componentType: UserAndGroupSelectorComponent,
      options: {
        label: 'Assigned To',
        name: 'assignedTo',
        placeholder: 'Search...',
        control: new FormControl(null),
        inputOptions: {
          extra: { principleMode: true },
          dropDownInput: {
            optionValue: 'undefined',
            multi: false,
          },
        },
        showLabelInViewMode: false,
      },
    },
    {
      componentType: AttachmentInputComponent,
      options: {
        label: 'Attachments',
        name: 'attachments',
        control: new FormControl(''),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
        inputOptions: {
          attachmentInput: {
            multiple: true,
            uploadLocation: 'CODE',
          },
        },
      },
    },
    {
      componentType: DateInputComponent,
      options: {
        label: 'Due Date',
        name: 'dueDate',
        placeholder: '11/22/2022',
        control: new FormControl(''),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
        inputOptions: {
          dateInput: {
            showIcon: true,
          },
        },
      },
    },
    {
      componentType: DateInputComponent,
      options: {
        label: 'Due Date To Response',
        name: 'dueDateToResponse',
        placeholder: '11/22/2022',
        control: new FormControl(''),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
        inputOptions: {
          dateInput: {
            showIcon: true,
          },
        },
      },
    },
    {
      componentType: TextEditorComponent,
      options: {
        label: 'Description',
        name: 'description',
        control: new FormControl(''),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
      },
    },
  ],
  [ModuleKeywords.AuditCaseTemplate]: [
    {
      componentType: BasicInputComponent,
      options: {
        label: 'Name',
        name: 'name',
        placeholder: 'Enter a name',
        control: new FormControl('', Validators.required),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
      },
    },
    {
      componentType: TextEditorComponent,
      options: {
        label: 'Description',
        name: 'description',
        control: new FormControl(''),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
      },
    },
    {
      componentType: UserAndGroupSelectorComponent,
      options: {
        label: 'Default Auditor',
        name: 'defaultAuditor',
        placeholder: 'Search...',
        control: new FormControl(null, Validators.required),
        inputOptions: {
          extra: { principleMode: true },
          dropDownInput: {
            optionValue: 'undefined',
            multi: false,
          },
        },
        showLabelInViewMode: false,
      },
    },
    {
      componentType: TargetCodeSelectorComponent,
      options: {
        label: 'Auditor Questionnaire',
        name: 'auditorQuestionnaire',
        placeholder: 'Search Questionnaire',
        control: new FormControl(null, Validators.required),
        inputOptions: {
          codeSelectorInput: {
            targetTypes: ['QUESTIONNAIRE'],
          },
          dropDownInput: {
            optionLabel: 'name',
            optionValue: 'code',
            multi: false,
            customProjectionFields: ['code', 'recordStatus', 'label', 'name', 'policy', 'policySection'],
          },
        },
        showLabelInViewMode: false,
      },
    },
  ],
  [ModuleKeywords.AuditUniverse]: [
    {
      componentType: BasicInputComponent,
      options: {
        label: 'Name',
        name: 'name',
        placeholder: 'Enter a name',
        control: new FormControl('', Validators.required),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
      },
    },
    {
      componentType: UserAndGroupSelectorComponent,
      options: {
        label: 'Approver',
        name: 'approver',
        placeholder: 'Search...',
        control: new FormControl(null, Validators.required),
        inputOptions: {
          extra: { principleMode: true },
          dropDownInput: {
            optionValue: 'undefined',
            multi: false,
          },
        },
        showLabelInViewMode: false,
      },
    },
    {
      componentType: TargetCodeSelectorComponent,
      options: {
        label: 'Entities',
        name: 'entities',
        placeholder: 'Search Entities',
        control: new FormControl(null, Validators.required),
        inputOptions: {
          codeSelectorInput: {
            targetTypes: ['RESPONSIBILITY', 'ASSET', 'OBJECTIVE', 'PROCESS', 'EVENT', 'GEOGRAPHY'],
          },
          dropDownInput: {
            optionLabel: 'name',
            optionValue: 'code',
            multi: true,
          },
        },
        showLabelInViewMode: false,
      },
    },
    {
      componentType: TextEditorComponent,
      options: {
        label: 'Description',
        name: 'description',
        control: new FormControl(''),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
      },
    },
  ],

  //Authority
  [ModuleKeywords.AuthorityDocument]: [
    {
      componentType: TextEditorComponent,
      options: {
        label: 'Description',
        name: "description",
        control: new FormControl(''),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false
      },

    },
    {
      componentType: ListStringsComponent,
      options: {
        label: 'External References',
        name: "externalReferences",
        control: new FormControl(''),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false
      },

    },
  ],
  [ModuleKeywords.Citation]: [
    // {
    //     componentType: TargetCodeSelectorComponent,
    //     options: {
    //         label: 'Authority Document',
    //         name: "authorityDocument",
    //         placeholder: 'Search Authority Document',
    //         control: new FormControl(null, Validators.required),
    //         inputOptions: {
    //             codeSelectorInput: {
    //                 targetTypes: ['AUTHORITY_DOCUMENT'],
    //             },
    //             dropDownInput: {
    //                 multi: false,
    //             },
    //         },
    //         showLabelInViewMode: false
    //     },
    // },
    {
      componentType: TargetCodeSelectorComponent,
      options: {
        label: 'Type',
        name: "citationType",
        placeholder: 'Search Citation Types',
        control: new FormControl(null, Validators.required),
        inputOptions: {
          codeSelectorInput: {
            targetTypes: ['CITATION_TYPE'],
          },
          dropDownInput: {
            optionLabel: 'name',
            optionValue: 'code',
            multi: false,
          },
        },
        showLabelInViewMode: false
      },
    },
    {
      componentType: SwitchInputComponent,
      options: {
        label: 'Mandatory',
        name: "mandatory",
        placeholder: 'Search Authority Document',
        control: new FormControl(null),
        showLabelInViewMode: false
      },
    },
    {
      componentType: TextEditorComponent,
      options: {
        label: 'Description',
        name: "description",
        control: new FormControl(''),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false
      },

    },
  ],
  [ModuleKeywords.CitationType]: [
    {
      componentType: BasicInputComponent,
      options: {
        label: 'Name',
        name: "name",
        placeholder: 'Enter a name',
        control: new FormControl('', Validators.required),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false
      },
    },
    {
      componentType: TextEditorComponent,
      options: {
        label: 'Description',
        name: "description",
        control: new FormControl(''),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false
      },

    },
  ],
  [ModuleKeywords.Exemption]: [
    {
      componentType: BasicInputComponent,
      options: {
        label: 'Name',
        name: "name",
        placeholder: 'Enter a name',
        control: new FormControl('', Validators.required),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false
      },
    },
    {
      componentType: UserAndGroupSelectorComponent,
      options: {
        label: 'Owner',
        name: "owner",
        placeholder: 'Search...',
        control: new FormControl(null, Validators.required),
        inputOptions: {
          extra: { principleMode: true },
          dropDownInput: {
            optionValue: 'undefined',
            multi: false,
          },
        },
        showLabelInViewMode: false
      },
    },
    {
      componentType: TargetCodeSelectorComponent,
      options: {
        label: 'Authority Document',
        name: "authorityDocument",
        placeholder: 'Search Authority Document',
        control: new FormControl(null, Validators.required),
        inputOptions: {
          codeSelectorInput: {
            targetTypes: ['AUTHORITY_DOCUMENT'],
          },
          dropDownInput: {
            multi: false,
          },
        },
        showLabelInViewMode: false
      },
    },
    {
      componentType: TextEditorComponent,
      options: {
        label: 'Description',
        name: "description",
        control: new FormControl(''),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false
      },

    },
  ],

  //Data Structure
  [ModuleKeywords.FieldTemplate]: [
    {
      componentType: BasicInputComponent,
      options: {
        label: 'Name',
        name: 'name',
        placeholder: 'Enter a name',
        control: new FormControl('', Validators.required),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
      },
    },
  ],
  [ModuleKeywords.GlobalValueList]: [
    {
      componentType: BasicInputComponent,
      options: {
        label: 'Name',
        name: 'name',
        placeholder: 'Enter a name',
        control: new FormControl('', Validators.required),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
      },
    },
  ],

  //Entity
  [ModuleKeywords.Asset]: [
    {
      componentType: BasicInputComponent,
      options: {
        label: 'Name',
        name: 'name',
        placeholder: 'Enter a name',
        control: new FormControl('', Validators.required),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
      },
    },
    {
      componentType: TargetCodeSelectorComponent,
      options: {
        label: 'Organization',
        name: 'organization',
        placeholder: 'Search Organizations',
        control: new FormControl(null, Validators.required),
        inputOptions: {
          codeSelectorInput: {
            targetTypes: ['ORGANIZATION'],
          },
          dropDownInput: {
            optionLabel: 'label',
            optionValue: 'code',
            multi: false,
          },
        },
        showLabelInViewMode: false,
      },
    },
    {
      componentType: UserAndGroupSelectorComponent,
      options: {
        label: 'Owner',
        name: 'owner',
        placeholder: 'Search...',
        control: new FormControl(null, Validators.required),
        inputOptions: {
          extra: { principleMode: true },
          dropDownInput: {
            optionValue: 'undefined',
            multi: false,
          },
        },
        showLabelInViewMode: false,
      },
    },
    {
      componentType: TargetCodeSelectorComponent,
      options: {
        label: 'Entity Type',
        name: 'type',
        placeholder: 'Search Entity Types',
        control: new FormControl(null, Validators.required),
        inputOptions: {
          codeSelectorInput: {
            targetTypes: ['ENTITY_TYPE'],
            extraFilters: [
              {
                property: 'category',
                operation: 'IN',
                value: [EntityTypeFieldDto.CategoryEnum.Asset],
              },
            ],
          },
          dropDownInput: {
            optionLabel: 'name',
            optionValue: 'code',
            multi: false,
          },
        },
        showLabelInViewMode: false,
      },
    },
    {
      componentType: TextEditorComponent,
      options: {
        label: 'Description',
        name: 'description',
        control: new FormControl(''),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
      },
    },
  ],
  [ModuleKeywords.EntityType]: [
    {
      componentType: BasicInputComponent,
      options: {
        label: 'Name',
        name: 'name',
        placeholder: 'Enter a name',
        control: new FormControl('', Validators.required),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
      },
    },
    {
      componentType: DropDownInputComponent,
      options: {
        label: 'Category',
        name: 'category',
        placeholder: 'Select Item',
        control: new FormControl('', Validators.required),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
        inputOptions: {
          dropDownInput: {
            optionLabel: 'label',
            optionValue: 'value',
            multi: false,
            items: getEnumOptions(EntityTypeFieldDto.CategoryEnum),
            badgeView: true,
          },
        },
      },
    },
    {
      componentType: IconSelectorComponent,
      options: {
        label: 'Icon',
        name: 'icon',
        placeholder: 'Select Item',
        control: new FormControl(''),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
      },
    },
    {
      componentType: TextEditorComponent,
      options: {
        label: 'Description',
        name: 'description',
        control: new FormControl(''),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
      },
    },
  ],
  [ModuleKeywords.Event]: [
    {
      componentType: BasicInputComponent,
      options: {
        label: 'Name',
        name: 'name',
        placeholder: 'Enter a name',
        control: new FormControl('', Validators.required),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
      },
    },
    {
      componentType: TargetCodeSelectorComponent,
      options: {
        label: 'Organization',
        name: 'organization',
        placeholder: 'Search Organizations',
        control: new FormControl(null, Validators.required),
        inputOptions: {
          codeSelectorInput: {
            targetTypes: ['ORGANIZATION'],
          },
          dropDownInput: {
            optionLabel: 'label',
            optionValue: 'code',
            multi: false,
          },
        },
        showLabelInViewMode: false,
      },
    },
    {
      componentType: UserAndGroupSelectorComponent,
      options: {
        label: 'Owner',
        name: 'owner',
        placeholder: 'Search...',
        control: new FormControl(null, Validators.required),
        inputOptions: {
          extra: { principleMode: true },
          dropDownInput: {
            optionValue: 'undefined',
            multi: false,
          },
        },
        showLabelInViewMode: false,
      },
    },
    {
      componentType: TargetCodeSelectorComponent,
      options: {
        label: 'Entity Type',
        name: 'type',
        placeholder: 'Search Entity Types',
        control: new FormControl(null, Validators.required),
        inputOptions: {
          codeSelectorInput: {
            targetTypes: ['ENTITY_TYPE'],
            extraFilters: [
              {
                property: 'category',
                operation: 'IN',
                value: [EntityTypeFieldDto.CategoryEnum.Event],
              },
            ],
          },
          dropDownInput: {
            optionLabel: 'name',
            optionValue: 'code',
            multi: false,
          },
        },
        showLabelInViewMode: false,
      },
    },
    {
      componentType: TextEditorComponent,
      options: {
        label: 'Description',
        name: 'description',
        control: new FormControl(''),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
      },
    },
  ],
  [ModuleKeywords.Geography]: [
    {
      componentType: BasicInputComponent,
      options: {
        label: 'Name',
        name: 'name',
        placeholder: 'Enter a name',
        control: new FormControl('', Validators.required),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
      },
    },
    {
      componentType: TargetCodeSelectorComponent,
      options: {
        label: 'Organization',
        name: 'organization',
        placeholder: 'Search Organizations',
        control: new FormControl(null, Validators.required),
        inputOptions: {
          codeSelectorInput: {
            targetTypes: ['ORGANIZATION'],
          },
          dropDownInput: {
            optionLabel: 'label',
            optionValue: 'code',
            multi: false,
          },
        },
        showLabelInViewMode: false,
      },
    },
    {
      componentType: UserAndGroupSelectorComponent,
      options: {
        label: 'Owner',
        name: 'owner',
        placeholder: 'Search...',
        control: new FormControl(null, Validators.required),
        inputOptions: {
          extra: { principleMode: true },
          dropDownInput: {
            optionValue: 'undefined',
            multi: false,
          },
        },
        showLabelInViewMode: false,
      },
    },
    {
      componentType: TargetCodeSelectorComponent,
      options: {
        label: 'Entity Type',
        name: 'type',
        placeholder: 'Search Entity Types',
        control: new FormControl(null, Validators.required),
        inputOptions: {
          codeSelectorInput: {
            targetTypes: ['ENTITY_TYPE'],
            extraFilters: [
              {
                property: 'category',
                operation: 'IN',
                value: [EntityTypeFieldDto.CategoryEnum.Geography],
              },
            ],
          },
          dropDownInput: {
            optionLabel: 'name',
            optionValue: 'code',
            multi: false,
          },
        },
        showLabelInViewMode: false,
      },
    },
    {
      componentType: TextEditorComponent,
      options: {
        label: 'Description',
        name: 'description',
        control: new FormControl(''),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
      },
    },
  ],
  [ModuleKeywords.Objective]: [
    {
      componentType: BasicInputComponent,
      options: {
        label: 'Name',
        name: 'name',
        placeholder: 'Enter a name',
        control: new FormControl('', Validators.required),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
      },
    },
    {
      componentType: TargetCodeSelectorComponent,
      options: {
        label: 'Organization',
        name: 'organization',
        placeholder: 'Search Organizations',
        control: new FormControl(null, Validators.required),
        inputOptions: {
          codeSelectorInput: {
            targetTypes: ['ORGANIZATION'],
          },
          dropDownInput: {
            optionLabel: 'label',
            optionValue: 'code',
            multi: false,
          },
        },
        showLabelInViewMode: false,
      },
    },
    {
      componentType: UserAndGroupSelectorComponent,
      options: {
        label: 'Owner',
        name: 'owner',
        placeholder: 'Search...',
        control: new FormControl(null, Validators.required),
        inputOptions: {
          extra: { principleMode: true },
          dropDownInput: {
            optionValue: 'undefined',
            multi: false,
          },
        },
        showLabelInViewMode: false,
      },
    },
    {
      componentType: TargetCodeSelectorComponent,
      options: {
        label: 'Entity Type',
        name: 'type',
        placeholder: 'Search Entity Types',
        control: new FormControl(null, Validators.required),
        inputOptions: {
          codeSelectorInput: {
            targetTypes: ['ENTITY_TYPE'],
            extraFilters: [
              {
                property: 'category',
                operation: 'IN',
                value: [EntityTypeFieldDto.CategoryEnum.Objective],
              },
            ],
          },
          dropDownInput: {
            optionLabel: 'name',
            optionValue: 'code',
            multi: false,
          },
        },
        showLabelInViewMode: false,
      },
    },
    {
      componentType: TextEditorComponent,
      options: {
        label: 'Description',
        name: 'description',
        control: new FormControl(''),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
      },
    },
  ],
  [ModuleKeywords.Organization]: [
    {
      componentType: BasicInputComponent,
      options: {
        label: 'Name',
        name: 'name',
        placeholder: 'Enter a name',
        control: new FormControl('', Validators.required),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
      },
    },
    {
      componentType: TargetCodeSelectorComponent,
      options: {
        label: 'Entity Type',
        name: 'type',
        placeholder: 'Search Entity Types',
        control: new FormControl(null, Validators.required),
        inputOptions: {
          codeSelectorInput: {
            targetTypes: ['ENTITY_TYPE'],
            extraFilters: [
              {
                property: 'category',
                operation: 'IN',
                value: [EntityTypeFieldDto.CategoryEnum.Organization],
              },
            ],
          },
          dropDownInput: {
            optionLabel: 'name',
            optionValue: 'code',
            multi: false,
          },
        },
        showLabelInViewMode: false,
      },
    },
    {
      componentType: TextEditorComponent,
      options: {
        label: 'Description',
        name: 'description',
        control: new FormControl(''),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
      },
    },
  ],
  [ModuleKeywords.Process]: [
    {
      componentType: BasicInputComponent,
      options: {
        label: 'Name',
        name: 'name',
        placeholder: 'Enter a name',
        control: new FormControl('', Validators.required),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
      },
    },
    {
      componentType: TargetCodeSelectorComponent,
      options: {
        label: 'Organization',
        name: 'organization',
        placeholder: 'Search Organizations',
        control: new FormControl(null, Validators.required),
        inputOptions: {
          codeSelectorInput: {
            targetTypes: ['ORGANIZATION'],
          },
          dropDownInput: {
            optionLabel: 'label',
            optionValue: 'code',
            multi: false,
          },
        },
        showLabelInViewMode: false,
      },
    },
    {
      componentType: UserAndGroupSelectorComponent,
      options: {
        label: 'Owner',
        name: 'owner',
        placeholder: 'Search...',
        control: new FormControl(null, Validators.required),
        inputOptions: {
          extra: { principleMode: true },
          dropDownInput: {
            optionValue: 'undefined',
            multi: false,
          },
        },
        showLabelInViewMode: false,
      },
    },
    {
      componentType: TargetCodeSelectorComponent,
      options: {
        label: 'Entity Type',
        name: 'type',
        placeholder: 'Search Entity Types',
        control: new FormControl(null, Validators.required),
        inputOptions: {
          codeSelectorInput: {
            targetTypes: ['ENTITY_TYPE'],
            extraFilters: [
              {
                property: 'category',
                operation: 'IN',
                value: [EntityTypeFieldDto.CategoryEnum.Process],
              },
            ],
          },
          dropDownInput: {
            optionLabel: 'name',
            optionValue: 'code',
            multi: false,
          },
        },
        showLabelInViewMode: false,
      },
    },
    {
      componentType: TextEditorComponent,
      options: {
        label: 'Description',
        name: 'description',
        control: new FormControl(''),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
      },
    },
  ],
  [ModuleKeywords.Responsibility]: [
    {
      componentType: BasicInputComponent,
      options: {
        label: 'Name',
        name: 'name',
        placeholder: 'Enter a name',
        control: new FormControl('', Validators.required),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
      },
    },
    {
      componentType: TargetCodeSelectorComponent,
      options: {
        label: 'Organization',
        name: 'organization',
        placeholder: 'Search Organizations',
        control: new FormControl(null, Validators.required),
        inputOptions: {
          codeSelectorInput: {
            targetTypes: ['ORGANIZATION'],
          },
          dropDownInput: {
            optionLabel: 'label',
            optionValue: 'code',
            multi: false,
          },
        },
        showLabelInViewMode: false,
      },
    },
    {
      componentType: TargetCodeSelectorComponent,
      options: {
        label: 'Entity Type',
        name: 'type',
        placeholder: 'Search Entity Types',
        control: new FormControl(null, Validators.required),
        inputOptions: {
          codeSelectorInput: {
            targetTypes: ['ENTITY_TYPE'],
            extraFilters: [
              {
                property: 'category',
                operation: 'IN',
                value: [EntityTypeFieldDto.CategoryEnum.Responsibility],
              },
            ],
          },
          dropDownInput: {
            optionLabel: 'name',
            optionValue: 'code',
            multi: false,
          },
        },
        showLabelInViewMode: false,
      },
    },
    {
      componentType: TextEditorComponent,
      options: {
        label: 'Description',
        name: 'description',
        control: new FormControl(''),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
      },
    },
  ],

  //Issues
  [ModuleKeywords.IssueType]: [
    {
      componentType: BasicInputComponent,
      options: {
        label: 'Name',
        name: 'name',
        placeholder: 'Enter a name',
        control: new FormControl('', Validators.required),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
      },
    },
    {
      componentType: TextEditorComponent,
      options: {
        label: 'Description',
        name: 'description',
        control: new FormControl(''),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
      },
    },
  ],
  [ModuleKeywords.Issue]: [
    {
      componentType: BasicInputComponent,
      options: {
        label: 'Name',
        name: 'name',
        placeholder: 'Enter a name',
        control: new FormControl('', Validators.required),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
      },
    },
    {
      componentType: UserAndGroupSelectorComponent,
      options: {
        label: 'Assignee',
        name: 'assignee',
        placeholder: 'Search Assignee...',
        control: new FormControl(null, Validators.required),
        inputOptions: {
          extra: { principleMode: true },
          dropDownInput: {
            optionValue: 'undefined',
            multi: false,
          },
        },
        showLabelInViewMode: false,
      },
    },
    {
      componentType: DropDownInputComponent,
      options: {
        label: 'Criticality',
        name: 'criticality',
        placeholder: 'Select Item',
        control: new FormControl('', Validators.required),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
        inputOptions: {
          dropDownInput: {
            optionLabel: 'label',
            optionValue: 'value',
            badgeView: true,
            items: getEnumOptions(Issue.CriticalityEnum),
          },
        },
      },
    },
    {
      componentType: UserAndGroupSelectorComponent,
      options: {
        label: 'Owner',
        name: 'issueOwner',
        placeholder: 'Search...',
        control: new FormControl(null, Validators.required),
        inputOptions: {
          extra: { principleMode: true },
          dropDownInput: {
            optionValue: 'undefined',
            multi: false,
          },
        },
        showLabelInViewMode: false,
      },
    },
    {
      componentType: TargetCodeSelectorComponent,
      options: {
        label: 'Type',
        name: 'issueTypeCode',
        placeholder: 'Search Issue Types',
        control: new FormControl(null, Validators.required),
        inputOptions: {
          codeSelectorInput: {
            targetTypes: ['ISSUE_TYPE'],
          },
          dropDownInput: {
            optionLabel: 'label',
            optionValue: 'code',
            multi: false,
            customProjectionFields: ['code', 'recordStatus', 'label', 'name'],
          },
        },
        showLabelInViewMode: false,
      },
    },
    {
      componentType: TextEditorComponent,
      options: {
        label: 'Description',
        name: 'description',
        control: new FormControl(''),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
      },
    },
  ],
  [ModuleKeywords.Remediation]: [
    {
      componentType: BasicInputComponent,
      options: {
        label: 'Name',
        name: 'name',
        placeholder: 'Enter a name',
        control: new FormControl('', Validators.required),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
      },
    },
    {
      componentType: UserAndGroupSelectorComponent,
      options: {
        label: 'Owner',
        name: 'issueOwner',
        placeholder: 'Search...',
        control: new FormControl(null, Validators.required),
        inputOptions: {
          extra: { principleMode: true },
          dropDownInput: {
            optionValue: 'undefined',
            multi: false,
          },
        },
        showLabelInViewMode: false,
      },
    },
    {
      componentType: TargetCodeSelectorComponent,
      options: {
        label: 'Issues',
        name: 'issues',
        placeholder: 'Search Issues',
        control: new FormControl(null, Validators.required),
        inputOptions: {
          codeSelectorInput: {
            targetTypes: ['ISSUE'],
          },
          dropDownInput: {
            optionLabel: 'label',
            optionValue: 'code',
            multi: true,
            customProjectionFields: ['code', 'recordStatus', 'label', 'name'],
          },
        },
        showLabelInViewMode: false,
      },
    },
    {
      componentType: PSliderInputComponent,
      options: {
        label: 'Progress',
        name: 'progress',
        placeholder: 'Enter Progress Number',
        control: new FormControl(0),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
      },
    },
    {
      componentType: TextEditorComponent,
      options: {
        label: 'Description',
        name: 'description',
        control: new FormControl(''),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
      },
    },
  ],

  //Framework
  [ModuleKeywords.ComplianceReport]: [
    {
      componentType: BasicInputComponent,
      options: {
        label: 'Name',
        name: 'name',
        placeholder: 'Enter a name',
        control: new FormControl('', Validators.required),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
      },
    },
    {
      componentType: TextEditorComponent,
      options: {
        label: 'Description',
        name: 'description',
        control: new FormControl(''),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
      },
    },
  ],
  [ModuleKeywords.ComplianceReport]: [
    {
      componentType: BasicInputComponent,
      options: {
        label: 'Name',
        name: 'name',
        placeholder: 'Enter a name',
        control: new FormControl('', Validators.required),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
      },
    },
    {
      componentType: TextEditorComponent,
      options: {
        label: 'Description',
        name: 'description',
        control: new FormControl(''),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
      },
    },
  ],
  [ModuleKeywords.Control]: [
    {
      componentType: UserAndGroupSelectorComponent,
      options: {
        label: 'Owner',
        name: 'owner',
        placeholder: 'Search...',
        control: new FormControl(null, Validators.required),
        inputOptions: {
          extra: { principleMode: true },
          dropDownInput: {
            optionValue: 'undefined',
            multi: false,
          },
        },
        showLabelInViewMode: false,
      },
    },
    {
      componentType: UserAndGroupSelectorComponent,
      options: {
        label: 'Respondent',
        name: 'attestationRespondent',
        placeholder: 'Search...',
        control: new FormControl(null, Validators.required),
        inputOptions: {
          extra: { principleMode: true },
          dropDownInput: {
            optionValue: 'undefined',
            multi: false,
          },
        },
        showLabelInViewMode: false,
      },
    },
    {
      componentType: TargetCodeSelectorComponent,
      options: {
        label: 'Control Group',
        name: 'controlGroup',
        placeholder: 'Search Control Groups',
        control: new FormControl(null, Validators.required),
        inputOptions: {
          codeSelectorInput: {
            targetTypes: ['CONTROL_GROUP'],
          },
          dropDownInput: {
            optionLabel: 'label',
            optionValue: 'code',
            multi: false,
            customProjectionFields: ['code', 'recordStatus', 'label', 'name'],
          },
        },
        showLabelInViewMode: false,
      },
    },
  ],
  [ModuleKeywords.ControlGroup]: [
    {
      componentType: UserAndGroupSelectorComponent,
      options: {
        label: 'Owner',
        name: 'owner',
        placeholder: 'Search...',
        control: new FormControl(null, Validators.required),
        inputOptions: {
          extra: { principleMode: true },
          dropDownInput: {
            optionValue: 'undefined',
            multi: false,
          },
        },
        showLabelInViewMode: false,
      },
    },
    {
      componentType: TargetCodeSelectorComponent,
      options: {
        label: 'Entities',
        name: 'controlEntities',
        placeholder: 'Search Entities',
        control: new FormControl(null),
        inputOptions: {
          codeSelectorInput: {
            targetTypes: ['RESPONSIBILITY', 'ASSET', 'OBJECTIVE', 'PROCESS', 'EVENT', 'GEOGRAPHY'],
          },
          dropDownInput: {
            optionLabel: 'label',
            optionValue: 'code',
            multi: true,
            customProjectionFields: ['code', 'recordStatus', 'label', 'name'],
          },
        },
        showLabelInViewMode: false,
      },
    },
  ],
  [ModuleKeywords.PolicyBody]: [
    {
      componentType: TargetCodeSelectorComponent,
      options: {
        label: 'Policy',
        name: 'policy',
        placeholder: 'Policy',
        control: new FormControl(null),
        inputOptions: {
          codeSelectorInput: {
            targetTypes: ['POLICY'],
          },
          dropDownInput: {
            optionLabel: 'label',
            optionValue: 'code',
            multi: false,
            customProjectionFields: ['code', 'recordStatus', 'label', 'name'],
          },
        },
        showLabelInViewMode: false,
      },
    },
  ],
  [ModuleKeywords.PolicyBodySection]: [
    {
      componentType: BasicInputComponent,
      options: {
        label: 'Title',
        name: 'subject',
        placeholder: 'Enter Title',
        control: new FormControl('', Validators.required),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
      },
    },
    {
      componentType: DropDownInputComponent,
      options: {
        label: 'Type',
        name: 'type',
        placeholder: 'Select Item',
        control: new FormControl(null, Validators.required),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
        inputOptions: {
          dropDownInput: {
            optionLabel: 'label',
            optionValue: 'value',
            badgeView: true,
            items: getEnumOptions(PolicyBodySection.TypeEnum),
          },
        },
      },
    },
    {
      componentType: TargetCodeSelectorComponent,
      options: {
        label: 'Policy Body',
        name: 'policyBody',
        placeholder: 'Policy Body',
        control: new FormControl(null),
        inputOptions: {
          codeSelectorInput: {
            targetTypes: ['POLICY_BODY'],
          },
          dropDownInput: {
            optionLabel: 'label',
            optionValue: 'code',
            multi: true,
            customProjectionFields: ['code', 'recordStatus', 'label', 'name'],
          },
        },
        showLabelInViewMode: false,
      },
    },
  ],

  //Question
  [ModuleKeywords.Questionnaire]: [
    {
      componentType: BasicInputComponent,
      options: {
        label: 'Name',
        name: 'name',
        placeholder: 'Enter Name',
        control: new FormControl('', Validators.required),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
      },
    },
    {
      componentType: DropDownInputComponent,
      options: {
        label: 'Type',
        name: 'type',
        placeholder: 'Select Item',
        control: new FormControl(null, Validators.required),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
        inputOptions: {
          dropDownInput: {
            optionLabel: 'label',
            optionValue: 'value',
            badgeView: true,
            items: getEnumOptions(QuestionnaireQuestionsDto.TypeEnum),
          },
        },
      },
    },
    {
      componentType: TextEditorComponent,
      options: {
        label: 'Description',
        name: 'description',
        control: new FormControl(''),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
      },
    },
  ],

  //Relation
  [ModuleKeywords.RelationType]: [
    {
      componentType: IconSelectorComponent,
      options: {
        label: 'Icon',
        name: 'icon',
        placeholder: 'Select Item',
        control: new FormControl(''),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
      },
    },
    {
      componentType: ColorInputComponent,
      options: {
        label: 'Color',
        name: 'color',
        control: new FormControl(null, Validators.required),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
      },
    },
    {
      componentType: TextEditorComponent,
      options: {
        label: 'Description',
        name: 'description',
        control: new FormControl(''),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
      },
    },
    {
      componentType: DropDownInputComponent,
      options: {
        label: 'Risk Cascade Direction',
        name: 'riskCascadeDirection',
        placeholder: 'Select Item',
        control: new FormControl(null, Validators.required),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
        inputOptions: {
          dropDownInput: {
            optionLabel: 'label',
            optionValue: 'value',
            badgeView: true,
            showClear: false,
            multi: false,
            items: getEnumOptions(RelationTypeDto.RiskCascadeDirectionEnum),
          },
        },
      },
    },
    {
      componentType: DropDownInputComponent,
      options: {
        label: 'Compliance Mode',
        name: 'complianceMode',
        placeholder: 'Select Item',
        control: new FormControl(null, Validators.required),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
        inputOptions: {
          dropDownInput: {
            optionLabel: 'label',
            optionValue: 'value',
            badgeView: true,
            showClear: false,
            multi: false,
            items: getEnumOptions(RelationTypeDto.ComplianceModeEnum),
          },
        },
      },
    },
  ],

  //Risk
  [ModuleKeywords.RiskStatement]: [
    {
      componentType: BasicInputComponent,
      options: {
        label: 'Name',
        name: 'name',
        placeholder: 'Enter Name',
        control: new FormControl('', Validators.required),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
      },
    },

    {
      componentType: TargetCodeSelectorComponent,
      options: {
        label: 'Statement Category',
        name: 'category',
        placeholder: 'Search Risk Statement Categories',
        control: new FormControl(null),
        inputOptions: {
          codeSelectorInput: {
            targetTypes: ['RISK_STATEMENT_CATEGORY'],
          },
          dropDownInput: {
            optionLabel: 'name',
            optionValue: 'code',
            multi: false,
            customProjectionFields: ['code', 'recordStatus', 'label', 'name'],
          },
        },
        showLabelInViewMode: false,
      },
    },
    {
      componentType: TextEditorComponent,
      options: {
        label: 'Description',
        name: 'description',
        control: new FormControl(''),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
      },
    },
  ],
  [ModuleKeywords.RiskStatementCategory]: [
    {
      componentType: BasicInputComponent,
      options: {
        label: 'Name',
        name: 'name',
        placeholder: 'Enter Name',
        control: new FormControl('', Validators.required),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
      },
    },
    {
      componentType: TextEditorComponent,
      options: {
        label: 'Description',
        name: 'description',
        control: new FormControl(''),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
      },
    },
  ],
};

export const BulkOperationFieldsMapperReset = cloneDeep(BulkOperationFieldsMapper);

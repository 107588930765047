import { Injectable } from '@angular/core';
import {
  AppPermissions,
  ControlGroupDto,
  DataTypeEnum,
  getEnumOptionsString,
  IFieldDefinition,
  TargetTypeEnum,
} from '@shared/classes';
import { BaseFieldDefinitionsService } from '@shared/services';
import { ControlComplianceProgressBarComponent } from 'app/modules/org-framework/policy/policy-progress-bar/policy-progress-bar.component';

@Injectable({
  providedIn: 'root',
})
export class ControlGroupMappingService extends BaseFieldDefinitionsService {
  recordFields: IFieldDefinition[] = [
    {
      name: 'Name',
      key: 'name',
      dataType: DataTypeEnum.Text,
      required: true,
      showInTable: true,
      description: 'Record Name',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      importerDefinition: {
        type: 'text',
        field: {
          validators: null,
        },
      },
    },
    {
      name: 'Owner',
      key: 'owner',
      dataType: DataTypeEnum.ResponsibilityListView,
      required: true,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Owner group of the asset',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
          extraKey: 'name',
        },
      },
      importerDefinition: {
        type: 'text_content',
        field: {
          validators: [
            {
              key: 'text_content',
              value: {
                type: 'text_content',
                type2: 'include',
                value1: 'RES-',
                value2: '',
                checked: false,
                remote: false,
                prohibitInput: false,
                hintShow: true,
                hintText: 'must start with RES-',
              },
            },
          ],
        },
      },
      calculationFunction: (params) => {
        return { name: params?.body?.[params?.fieldKey], mode: 'ONE', principleType: 'RESPONSIBILITY' };
      },
    },
    // {
    //   name: "Policy",
    //   key: "policy",
    //   dataType: DataTypeEnum.CodeLink,
    //   required: true,
    //   showInTable: true,
    //   permissions: AppPermissions.ReadPolicy,
    //   description: "Policy Linked to the control Group",
    //   tableDefinition: {
    //     filter: {
    //       type: 'recordCode',
    //       matchMode: "in",
    //       showMatchModes: false,
    //       showAddButton: false,
    //       showOperator: false,
    //       recordCodeType: TargetTypeEnum.Policy
    //     },
    //   }
    // },
    // {
    //   name: "Section",
    //   key: "controlObjective",
    //   dataType: DataTypeEnum.CodeLink,
    //   required: true,
    //   showInTable: true,
    //   permissions: AppPermissions.ReadPolicyBodySection,
    //   description: "Policy Section Linked to the control objective",
    //   tableDefinition: {
    //     filter: {
    //       type: 'recordCode',
    //       matchMode: "in",
    //       showMatchModes: false,
    //       showAddButton: false,
    //       showOperator: false,
    //       recordCodeType: TargetTypeEnum.ControlObjective
    //     },
    //   }
    // },
    {
      name: 'Respondent',
      key: 'attestationRespondent',
      dataType: DataTypeEnum.ResponsibilityListView,
      required: true,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Respondent of the control objective changes',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
          extraKey: 'name',
        },
      },
      importerDefinition: {
        type: 'text_content',
        field: {
          validators: [
            {
              key: 'text_content',
              value: {
                type: 'text_content',
                type2: 'include',
                value1: 'RES-',
                value2: '',
                checked: false,
                remote: false,
                prohibitInput: false,
                hintShow: true,
                hintText: 'must start with RES-',
              },
            },
          ],
        },
      },
      calculationFunction: (params) => {
        return { name: params?.body?.[params?.fieldKey], mode: 'ONE', principleType: 'RESPONSIBILITY' };
      },
    },
    {
      name: 'Compliance Status',
      key: 'progress',
      dataType: DataTypeEnum.DynamicComponent,
      required: false,
      showInTable: true,
      description: 'Compliance Status of the control objective respondents',
      tableDefinition: {
        options: {
          dynamicComponent: ControlComplianceProgressBarComponent,
        },
        passRowAsData: true,
      },
    },
    {
      name: 'Control Frequency',
      key: 'controlFrequency',
      dataType: DataTypeEnum.Number,
      required: true,
      showInTable: true,
      description: 'Control Frequency of the control group',
      tableDefinition: {
        filter: {
          type: 'numeric',
          display: 'menu',
          matchMode: 'lte',
        },
      },
      importerDefinition: {
        type: 'text',
        field: {
          validators: null,
        },
      },
    },
    {
      name: 'Control Classification',
      key: 'controlClassification',
      dataType: DataTypeEnum.Badge,
      required: true,
      showInTable: true,
      description: 'Control Classification of the control group',
      tableDefinition: {
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: ControlGroupDto.ControlClassificationEnum,
        },
      },
      importerDefinition: {
        type: 'dropdown',
        field: {
          validators: [
            {
              key: 'dropdown',
              value: {
                type: 'dropdown',
                type2: null,
                value1: getEnumOptionsString(ControlGroupDto.ControlClassificationEnum),
                value2: '',
                checked: false,
                remote: false,
                prohibitInput: false,
                hintShow: false,
                hintText: '',
              },
            },
          ],
        },
      },
    },
    {
      name: 'Entities',
      key: 'controlEntities',
      dataType: DataTypeEnum.CodeLink,
      required: false,
      showInTable: true,
      permissions: AppPermissions.ReadAnyEntity,
      description: 'Entities Linked to the control group',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      importerDefinition: {
        type: 'text_content',
        field: {
          validators: null,
        },
        isArray: true,
        isCommaDelimiter: true,
      },
    },
    {
      name: 'Control Objectives',
      key: 'controlObjectives',
      dataType: DataTypeEnum.CodeLink,
      required: false,
      showInTable: false,
      permissions: AppPermissions.ReadControlObjective,
      description: 'Control Objectives Linked to the control group',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      importerDefinition: {
        type: 'text_content',
        field: {
          validators: null,
        },
        isArray: true,
        isCommaDelimiter: true,
      },
    },
    {
      name: 'Questionnaire',
      key: 'controlAttestationTemplate',
      dataType: DataTypeEnum.CodeLink,
      required: false,
      showInTable: true,
      permissions: AppPermissions.ReadQuestionnaire,
      description: 'Questionnaire Linked to the control group',
      tableDefinition: {
        filter: {
          type: 'recordCode',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          recordCodeType: TargetTypeEnum.Questionnaire,
        },
      },
      importerDefinition: {
        type: 'text_content',
        field: {
          validators: [
            {
              key: 'text_content',
              value: {
                type: 'text_content',
                type2: 'include',
                value1: 'QSR-',
                value2: '',
                checked: false,
                remote: false,
                prohibitInput: false,
                hintShow: true,
                hintText: 'must start with QSR-',
              },
            },
          ],
        },
      },
    },
    {
      name: 'Standards',
      key: 'standards',
      dataType: DataTypeEnum.CodeLink,
      required: false,
      showInTable: false,
      permissions: AppPermissions.ReadStandard,
      description: 'Standard Linked to the control group',
      tableDefinition: {
        filter: {
          type: 'recordCode',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          recordCodeType: TargetTypeEnum.Standard,
        },
      },
      importerDefinition: {
        type: 'text_content',
        field: {
          validators: null,
        },
        isArray: true,
        isCommaDelimiter: true,
      },
    },
    {
      name: 'Guidelines',
      key: 'guidelines',
      dataType: DataTypeEnum.CodeLink,
      required: false,
      showInTable: false,
      permissions: AppPermissions.ReadGuideline,
      description: 'Guidelines to the control group',
      tableDefinition: {
        filter: {
          type: 'recordCode',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          recordCodeType: TargetTypeEnum.Guideline,
        },
      },
      importerDefinition: {
        type: 'text_content',
        field: {
          validators: null,
        },
        isArray: true,
        isCommaDelimiter: true,
      },
    },
    {
      name: 'Description',
      key: 'description',
      dataType: DataTypeEnum.LongWithHoverText,
      required: false,
      showInTable: true,
      description: 'Record Description',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      importerDefinition: {
        type: 'text',
        field: {
          validators: null,
        },
      },
    },
  ];
  constructor() {
    super();
    this.setAllFields();
  }
}

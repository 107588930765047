import { Pipe, PipeTransform } from '@angular/core';
import { humanizeCasedString, makePlural } from '@shared/classes';

@Pipe({
  name: 'pluralize',
})
export class PluralizePipe implements PipeTransform {
  transform(value: string) {
    return makePlural(humanizeCasedString(value));
  }
}

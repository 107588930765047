import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { BaseTablePage, DataTypeEnum, toKebabCase } from '@shared/classes';
import { EntitySelectorComponent } from '@shared/components/selectors/entity-selector/entity-selector.component';
import { UserSelectorComponent } from '@shared/components/selectors/user-selector/user-selector.component';
import { AppDialogService } from '@shared/services/app-dialog.service';
import { ExportDataService } from '@shared/services/export-data.service';
import { LoaderService } from '@shared/services/loader.service';
import { EntityDataService } from 'app/modules/entity-module/entity/entity-data.service';

@Component({
  selector: 'app-table-example',
  templateUrl: './table-example.component.html',
  styleUrls: ['./table-example.component.scss'],
})
export class TableExampleComponent extends BaseTablePage<any> implements OnInit {
  constructor(
    public requestService: EntityDataService,
    private loaderService: LoaderService,
    exportService: ExportDataService,
    appDialogService: AppDialogService,
    router: Router
  ) {
    super(requestService, exportService, appDialogService, router);
    this.SetPageValues({
      breadCrumb: {
        items: [
          { label: 'Entity', routerLink: [`${this.routePrefix ? this.routePrefix : toKebabCase(this.moduleKeyword)}`] },
          {
            label: 'Entity list',
            routerLink: ['/entity/list'],
          },
        ],
      },
      pageActions: [
        {
          label: 'test button',
          id: 1,
          type: 0,
          loading$: loaderService.loadingState,
          color: 'warning',
          command: this.testFunction1.bind(this),
        },
        {
          label: 'test button 2',
          id: 2,
          type: 0,
          loading$: loaderService.loadingState,
          color: 'success',
          command: this.testFunction2.bind(this),
        },
        {
          label: 'test button 3',
          id: 3,
          type: 0,
          loading$: loaderService.loadingState,
          color: 'primary',
          command: this.testFunction3.bind(this),
        },
      ],
    });
    this.pageActionsCommandData = { test: 'this is some test dynamic data to pass by through header actions' };
  }

  ngOnInit(): void {}

  setCols() {
    this.cols = [
      {
        name: 'Name',
        key: 'name',
        dataType: DataTypeEnum.Text,
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
        // frozen: true,
        alignFrozen: 'left',
      },
      {
        name: 'Owner',
        key: 'owner',
        dataType: DataTypeEnum.UserListView,
        filter: {
          type: 'multiDropdown',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          dynamicInput: {
            componentType: UserSelectorComponent,
            options: {
              label: '',
              name: '',
              control: new FormControl(null),
            },
          },
        },
      },
      {
        name: 'Parent',
        key: 'parent.name',
        dataType: DataTypeEnum.Text,
        filter: {
          type: 'multiDropdown',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          dynamicInput: {
            componentType: EntitySelectorComponent,
            options: {
              label: '',
              name: '',
              control: new FormControl(null),
            },
          },
        },
      },
      {
        name: 'level',
        key: 'level.name',
        dataType: DataTypeEnum.Text,
      },
      {
        name: 'Type',
        key: 'type.name',
        dataType: DataTypeEnum.Badge,
      },
    ];
  }
  setTableActions() {
    this.tableActions = [
      {
        id: 1,
        icon: 'pi pi-pencil',
        buttonStyle: 'text',
        color: 'success',
        command: this.editItem.bind(this),
        permission: `update${this.moduleKeyword}`,
      },
      {
        id: 2,
        icon: 'pi pi-trash',
        buttonStyle: 'text',
        color: 'danger',
        command: this.deleteItem.bind(this),
        permission: `delete${this.moduleKeyword}`,
      },
    ];
  }

  testFunction1(event) {
    this.loaderService.toggleLoader(true);
    setTimeout(() => {
      this.loaderService.toggleLoader(false);
    }, 2000);
  }
  testFunction2(event) {
    this.loaderService.toggleLoader(true);
    setTimeout(() => {
      this.loaderService.toggleLoader(false);
    }, 2000);
  }
  testFunction3(event) {
    this.loaderService.toggleLoader(true);
    setTimeout(() => {
      this.loaderService.toggleLoader(false);
    }, 2000);
  }
  deleteItem(row) {
    this.loaderService.toggleLoader(true);
    setTimeout(() => {
      this.loaderService.toggleLoader(false);
    }, 2000);
  }
  editItem(row) {}
}

import { Type } from '@angular/core';
import { Validators } from '@angular/forms';
import { IColumn, IFilter } from './AppTable';
import { IBaseInput } from './BaseInput';
import { ImporterColumnDefinition, ImporterSheetColumn, ImporterValidator } from './ImporterDefinitions';
import { DataTypeEnum, RecordStatusEnumFilterWithoutDeleted } from './view-enums';

export interface IFieldDefinition {
  name: string;
  key: string;
  dataType: DataTypeEnum;
  required: boolean;
  showInTable: boolean;
  permissions?: string | string[];
  angularValidators?: Validators[];
  description?: string;

  tableDefinition?: {
    // Table Column Specific Options
    filter?: IFilter;
    frozen?: boolean;
    alignFrozen?: 'left' | 'right';
    options?: any;
    sortDisabled?: boolean;
    passRowAsData?: boolean;
  };
  importerDefinition?: {
    // Importer Definitions
    // id: any;
    // field: ImporterField;
    type?: 'text' | 'text_content' | 'date' | 'dropdown';
    fieldGroupKey?: string;
    customValueObject?: { valueKey: string; customObject: any };
    boundedSheetColumns?: ImporterSheetColumn[];
    isArray?: boolean;
    isCommaDelimiter?: boolean;
    delimiterType?: string;
    field: {
      validators: ImporterValidator[];
      hideInImporter?: boolean;
    };
  };

  calculationFunction?: (params: { body: any; currentData?: any; fieldKey?: string }) => string | any;
  calculationFunctionParams?: string[];

  //Form fields definitions
  formField?: {
    componentType?: Type<any>;
    options: IBaseInput;
    viewModeOnly?: boolean;
    styleClass?: string;
  };
}

export function getTableColumnDefinitions(fields: IFieldDefinition[]): IColumn[] {
  return fields
    ?.filter((field) => field.showInTable)
    .map((field) => {
      return getTableColumnDefinitionFromField(field);
    });
}
export function getTableColumnDefinitionFromField(field: IFieldDefinition): IColumn {
  return {
    name: field?.name,
    key: field?.key,
    dataType: field?.dataType,
    permissions: field?.permissions,
    ...field.tableDefinition,
  };
}
export function getImporterColumnDefinitions(fields: IFieldDefinition[]): ImporterColumnDefinition[] {
  return fields
    ?.filter((field) => field.importerDefinition)
    .map((field, index) => {
      return {
        id: index,
        ...field?.importerDefinition,
        field: {
          ...field?.importerDefinition?.field,
          name: field?.name,
          key: field?.key,
          required: field?.required,
          description: field?.description,
          calculationFunction: field?.calculationFunction,
          calculationFunctionParams: field?.calculationFunctionParams,
        },
      };
    });
}

export const prefixBaseFields: IFieldDefinition[] = [
  {
    name: 'Id',
    key: 'code',
    dataType: DataTypeEnum.CodeWithStatus,
    required: false,
    showInTable: true,
    description: 'Unique identifier for the record',
    tableDefinition: {
      passRowAsData: true,
      filter: {
        type: 'text',
        matchMode: 'startsWith',
      },
      // frozen: true,
      alignFrozen: 'left',
    },
  },
  {
    name: 'Record Status',
    key: 'recordStatus',
    dataType: DataTypeEnum.Badge,
    required: false,
    showInTable: true,
    description: 'Current Status of the record',
    tableDefinition: {
      filter: {
        type: 'enum',
        display: 'menu',
        matchMode: 'in',
        showMatchModes: false,
        showAddButton: false,
        showOperator: false,
        enumClass: RecordStatusEnumFilterWithoutDeleted,
      },
    },
  },
];
export const suffixBaseFields: IFieldDefinition[] = [
  {
    name: 'Creator Name',
    key: 'creatorName',
    dataType: DataTypeEnum.UserListView,
    required: false,
    showInTable: true,
    description: 'The user who created this record.',
    tableDefinition: {
      filter: {
        type: 'userSelector',
        matchMode: 'in',
        showMatchModes: false,
        showAddButton: false,
        showOperator: false,
      },
    },
  },
  {
    name: 'Creation Date',
    key: 'creationDate',
    dataType: DataTypeEnum.DateLong,
    required: false,
    showInTable: true,
    description: 'Current Status of the record',
    tableDefinition: {
      filter: {
        type: 'date',
        matchMode: 'dateBefore',
      },
    },
  },
  {
    name: 'Last Modifier',
    key: 'lastModifierName',
    dataType: DataTypeEnum.UserListView,
    required: false,
    showInTable: true,
    description: 'Current Status of the record',
    tableDefinition: {
      filter: {
        type: 'userSelector',
        matchMode: 'in',
        showMatchModes: false,
        showAddButton: false,
        showOperator: false,
      },
    },
  },
  {
    name: 'Last Modification Date',
    key: 'lastModificationDate',
    dataType: DataTypeEnum.DateLong,
    required: false,
    showInTable: true,
    description: 'Current Status of the record',
    tableDefinition: {
      filter: {
        type: 'date',
        matchMode: 'dateBefore',
      },
    },
  },
];
export function getTableColumns(fields: IFieldDefinition[]) {
  [
    ...getTableColumnDefinitions(prefixBaseFields),
    ...getTableColumnDefinitions(fields),
    ...getTableColumnDefinitions(suffixBaseFields),
  ];
}

<app-input-view-switch
  [showLabelInViewMode]="showLabelInViewMode"
  [mode]="viewMode"
  [data]="data || control?.value"
  [dataType]="dataType"
  [label]="label"
  [labelSuffix]="': ' + (control?.value || data || 0) + valueSuffix"
  [keepValueCallback]="setFieldResetable.bind(this)"
  [resetFieldCheckVisable]="resetFieldCheckVisable"
  [resetableChecked]="resetable"
>
  <div class="field" [class.mb-1]="control?.dirty && control?.hasError('required')">
    <span class="w-full flex flex-column gap-2">
      <label [class]="'w-full ' + labelClassStyle" [for]="name"
        >{{ label }} : {{ control?.value || 0 }}{{ valueSuffix }}
        @if (isControlRequired) {
          <span class="text-red-500"> *</span>
        }
        @if (resetFieldCheckVisable) {
          <br />
          <app-reset-switch-input
            [checked]="!resetable"
            (onChange)="setFieldResetable($event)"
          ></app-reset-switch-input>
        }
      </label>
      <!-- tabindex for buttons is not published yet -->
      <p-slider
        class="w-full"
        [formControl]="fControl"
        [animate]="animate"
        [orientation]="orientation"
        [step]="step"
        [range]="range"
        [min]="min"
        [max]="max"
      ></p-slider>
    </span>
    @if (control?.dirty) {
      @if (control?.hasError('required')) {
        <small class="p-error p-invalid">{{ label }} {{ 'is Required' }}</small>
      }
      @if (control?.hasError('pattern') || control?.hasError('min') || control?.hasError('max')) {
        <small class="p-error p-invalid">{{ label }} {{ patternError }}</small>
      }
      @if (customError) {
        <small class="p-error p-invalid">{{ customErrorLabel }}</small>
      }
    }
  </div>
</app-input-view-switch>

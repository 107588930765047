/**
 * Sightflare Service
 * Rest Api for Sightflare Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Composites { 
    realm?: Set<string>;
    client?: { [key: string]: Array<string>; };
    /** @deprecated */
    application?: { [key: string]: Array<string>; };
}


import { Injectable } from '@angular/core';
import { DataTypeEnum, EmailTemplateDto, IFieldDefinition, TaskTemplateDto } from '@shared/classes';
import { BaseFieldDefinitionsService } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class TaskTemplatesMappingService extends BaseFieldDefinitionsService {
  recordFields: IFieldDefinition[] = [
    {
      name: 'Name',
      key: 'name',
      dataType: DataTypeEnum.Text,
      required: true,
      showInTable: true,
      description: 'Name of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Key',
      key: 'key',
      dataType: DataTypeEnum.Text,
      required: true,
      showInTable: true,
      description: 'Key of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Title',
      key: 'titleTemplate',
      dataType: DataTypeEnum.Text,
      required: true,
      showInTable: true,
      description: 'Title template of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Due Date Days',
      key: 'dueDateDays',
      dataType: DataTypeEnum.Number,
      required: true,
      showInTable: true,
      description: 'Due Date in Days of the record',
      tableDefinition: {
        filter: {
          type: 'numeric',
          display: 'menu',
          matchMode: 'lte',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Module',
      key: 'module',
      dataType: DataTypeEnum.Badge,
      required: true,
      showInTable: true,
      description: 'Module of the record',
      tableDefinition: {
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: TaskTemplateDto.ModuleEnum,
        },
      },
    },
    {
      name: 'System Generated',
      key: 'systemGenerated',
      dataType: DataTypeEnum.Badge,
      required: true,
      showInTable: true,
      description: 'Is the record system generated?',
      tableDefinition: {
        filter: {
          type: 'boolean',
          display: 'menu',
          matchMode: 'equals',
        },
      },
    },
  ];
  constructor() {
    super();
    this.setAllFields();
  }
}

import { Injectable } from '@angular/core';
import {
  IFieldDefinition,
  DataTypeEnum,
  AppPermissions,
  TargetTypeEnum,
  EntityTypeFieldDto,
  AcknowledgmentDto,
} from '@shared/classes';
import { BaseFieldDefinitionsService } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class AcknowledgmentMappingService extends BaseFieldDefinitionsService {
  recordFields: IFieldDefinition[] = [
    {
      name: 'Approver',
      key: 'approver',
      dataType: DataTypeEnum.ResponsibilityListView,
      required: false,
      showInTable: true,
      description: 'Users who should approve',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Approved by',
      key: 'approvalUser',
      dataType: DataTypeEnum.ResponsibilityListView,
      required: false,
      showInTable: true,
      description: 'Approved By Users',
      tableDefinition: {
        filter: {
          type: 'userSelector',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
        },
      },
    },
    {
      name: 'Approval Date',
      key: 'approvalDate',
      dataType: DataTypeEnum.DateLong,
      required: false,
      showInTable: true,
      description: 'Date of Approval',
      tableDefinition: {
        filter: {
          type: 'date',
          matchMode: 'dateBefore',
        },
      },
    },
    {
      name: 'Acknowledgment Request',
      key: 'acknowledgmentRequest',
      dataType: DataTypeEnum.CodeLink,
      required: true,
      showInTable: true,
      description: 'Request linked to the acknowledgement',
      tableDefinition: {
        filter: {
          type: 'recordCode',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          recordCodeType: TargetTypeEnum.AcknowledgmentRequest,
        },
      },
    },
    {
      name: 'Acknowledgment Status',
      key: 'acknowledgmentStatus',
      dataType: DataTypeEnum.Badge,
      required: false,
      showInTable: true,
      description: 'Status of the acknowledgement',
      tableDefinition: {
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: AcknowledgmentDto.AcknowledgmentStatusEnum,
        },
      },
    },
    {
      name: 'Signed Document',
      key: 'signedFileUrl',
      required: true,
      showInTable: true,
      description: 'Signed Document of this Record',
      dataType: DataTypeEnum.Link,
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Confirmed',
      key: 'confirmed',
      dataType: DataTypeEnum.Badge,
      required: false,
      showInTable: true,
      description: 'Status of the acknowledgement',
      tableDefinition: {
        filter: {
          type: 'boolean',
          display: 'menu',
          matchMode: 'equals',
        },
      },
    },
  ];
  constructor() {
    super();
    this.setAllFields();
  }
}

import { PageableObject, SortObject } from './index';

export interface BasePaginationResponse<T> {
  data?: PaginationModel<T>;
  status?: number;
  error?: boolean;
  errorMessage?: string;
}
export interface BaseListResponse<T> {
  status?: number;
  data?: Array<T>;
  error?: boolean;
  errorMessage?: string;
}

export interface BaseResponse<T> {
  data?: T;
  status?: number;
  error?: boolean;
  errorMessage?: string;
}

export interface PaginationModel<T> {
  totalPages?: number;
  totalElements?: number;
  size?: number;
  content?: Array<T>;
  number?: number;
  sort?: Array<SortObject>;
  pageable?: PageableObject;
  first?: boolean;
  numberOfElements?: number;
  last?: boolean;
  empty?: boolean;
}

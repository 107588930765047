/**
 * Sightflare Service
 * Rest Api for Sightflare Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface User { 
    id?: string;
    firstName?: string;
    lastName?: string;
    userName?: string;
    password?: string;
    emailVerified?: boolean;
    sfSysAdmin?: boolean;
    email?: string;
    enable?: boolean;
    roles?: Array<string>;
    contactInfo?: { [key: string]: string; };
}


/**
 * Sightflare Service
 * Rest Api for Sightflare Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AuditCaseDto } from './auditCaseDto';
import { AuditProgramReportDto } from './auditProgramReportDto';
import { AuditCaseResultDto } from './auditCaseResultDto';
import { AuditProgramDto } from './auditProgramDto';
import { AuditProjectDto } from './auditProjectDto';
import { AuditProjectReportDto } from './auditProjectReportDto';


export interface ProgramRelatedDetailsDto { 
    auditProgram?: AuditProgramDto;
    auditProgramReport?: AuditProgramReportDto;
    auditProjects?: Array<AuditProjectDto>;
    auditCases?: Array<AuditCaseDto>;
    auditCasesResults?: Array<AuditCaseResultDto>;
    auditProjectReports?: Array<AuditProjectReportDto>;
}


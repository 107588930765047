import { ModuleKeywords } from './ModuleKeywords';

export const CodeMapper = {
  //@TODONewModule: when you add new module make sure to define these
  // ENT : ModuleKeywords.Entity,
  RES: ModuleKeywords.Responsibility,
  AST: ModuleKeywords.Asset,
  OBJ: ModuleKeywords.Objective,
  EVE: ModuleKeywords.Event,
  GEO: ModuleKeywords.Geography,
  PRO: ModuleKeywords.Process,
  ETY: ModuleKeywords.EntityType,

  POL: ModuleKeywords.Policy,
  PBD: ModuleKeywords.PolicyBody,
  CON: ModuleKeywords.Control,
  COB: ModuleKeywords.ControlObjective,
  CGR: ModuleKeywords.ControlGroup,
  CCA: ModuleKeywords.ControlCategory,
  CPR: ModuleKeywords.ControlProcedure,
  EXP: ModuleKeywords.Exception,
  ATS: ModuleKeywords.Attestation,

  ACK: ModuleKeywords.Acknowledgment,
  AKR: ModuleKeywords.AcknowledgmentRequest,

  TSK: ModuleKeywords.Task,
  NTO: ModuleKeywords.Notification,

  // IFA : ModuleKeywords.ImpactFactor,
  RIT: ModuleKeywords.RiskItem,
  RAR: ModuleKeywords.RiskAssessmentRequest,
  RPR: ModuleKeywords.RiskProject,
  RST: ModuleKeywords.RiskStatement,
  RSC: ModuleKeywords.RiskStatementCategory,
  THR: ModuleKeywords.RiskThreat,
  VUL: ModuleKeywords.RiskVulnerability,

  AUD: ModuleKeywords.AuthorityDocument,
  CIT: ModuleKeywords.Citation,
  CTY: ModuleKeywords.CitationType,

  ISS: ModuleKeywords.Issue,
  IST: ModuleKeywords.IssueType,
  RMD: ModuleKeywords.Remediation, //@TODO CHECK

  QST: ModuleKeywords.Question,
  QSR: ModuleKeywords.Questionnaire,
  QSV: ModuleKeywords.QuestionnaireValue, //@TODO CHECK

  FDT: ModuleKeywords.FieldTemplate,
  ORG: ModuleKeywords.Organization,
  RTY: ModuleKeywords.RelationType,
  EXM: ModuleKeywords.Exemption,
  GVL: ModuleKeywords.GlobalValueList,
  PBS: ModuleKeywords.PolicyBodySection,
  STA: ModuleKeywords.Standard,
  // STS: ModuleKeywords.StandardSection,
  GUI: ModuleKeywords.Guideline,
  CRE: ModuleKeywords.ComplianceReport,
  CRR: ModuleKeywords.ComplianceReportResult,
  ETM: ModuleKeywords.EmailTemplate,
  TTM: ModuleKeywords.TaskTemplate,
  // CCE: ModuleKeywords.CitationComplianceReport,
  // CCR: ModuleKeywords.CitationComplianceReportResult,
  ADT: ModuleKeywords.AuditTrail,
  DOC: ModuleKeywords.Document,

  AUU: ModuleKeywords.AuditUniverse,
  APG: ModuleKeywords.AuditProgram,
  ARR: ModuleKeywords.AuditProgramReport,
  APR: ModuleKeywords.AuditProjectReport,
  APT: ModuleKeywords.AuditProgramType,
  ATT: ModuleKeywords.AuditCaseTemplate,
  ACT: ModuleKeywords.AuditCaseTemplate,
  APJ: ModuleKeywords.AuditProject,
  ACS: ModuleKeywords.AuditCase,
  ACR: ModuleKeywords.AuditCaseResult,
  AEX: ModuleKeywords.AuditExpense,
  ARE: ModuleKeywords.AuditRequest,
  FIL: ModuleKeywords.Filter,
  CVW: ModuleKeywords.ContentView,
  DSH: ModuleKeywords.Dashboard,
  EGP: ModuleKeywords.ExportGlobalPackage,
  IGP: ModuleKeywords.ImportGlobalPackage,
  REP: ModuleKeywords.Report,
  WCC: ModuleKeywords.TransparencyCaseCategory,
  WCA: ModuleKeywords.TransparencyCase,
  SCO: ModuleKeywords.StatisticCollector,
  GST: ModuleKeywords.GeneratedStatistic,
  RCT: ModuleKeywords.ReportConfigurationTemplate,
  TAG: ModuleKeywords.Tag,
  RPS: ModuleKeywords.ReportScheduler,
  MTR: ModuleKeywords.Metric,
  MTV: ModuleKeywords.MetricValue,
  MTC: ModuleKeywords.MetricCategory,
  MTH: ModuleKeywords.RiskMethodology,
  GRE: ModuleKeywords.GeneratedReport,
};
export const ModuleKeywordRootPath = {
  //@TODONewModule: when you add new module make sure to define these
  // [ModuleKeywords.EntityLevels] : 'entity',
  ['EntityFilters']: 'entity',
  [ModuleKeywords.EntityType]: 'entity',
  [ModuleKeywords.Asset]: 'entity',
  [ModuleKeywords.Event]: 'entity',
  [ModuleKeywords.Geography]: 'entity',
  [ModuleKeywords.Objective]: 'entity',
  [ModuleKeywords.Process]: 'entity',
  [ModuleKeywords.Responsibility]: 'entity',
  // [ModuleKeywords.Entity] : null,
  [ModuleKeywords.Question]: null,
  [ModuleKeywords.Questionnaire]: 'question',
  [ModuleKeywords.QuestionnaireValue]: 'question',
  [ModuleKeywords.MyQuestionnaireValue]: 'question',
  [ModuleKeywords.RiskItem]: 'risk',
  [ModuleKeywords.RiskRegister]: 'risk',
  [ModuleKeywords.RiskProject]: 'risk',
  [ModuleKeywords.RiskAssessmentRequest]: 'risk',
  [ModuleKeywords.MyRiskAssessmentRequest]: 'risk',
  [ModuleKeywords.RiskStatement]: 'risk',
  [ModuleKeywords.RiskStatementCategory]: 'risk',
  // [ModuleKeywords.ImpactFactor] : 'risk',
  [ModuleKeywords.RiskMethodology]: 'risk',
  [ModuleKeywords.RiskVulnerability]: 'risk',
  [ModuleKeywords.RiskThreat]: 'risk',
  [ModuleKeywords.Control]: 'framework',
  [ModuleKeywords.ControlGroup]: 'framework',
  [ModuleKeywords.Policy]: 'framework',
  [ModuleKeywords.PolicyBody]: 'framework/policy',
  [ModuleKeywords.PolicyBodySection]: 'framework/policy',
  [ModuleKeywords.Exception]: 'framework',
  [ModuleKeywords.Attestation]: 'framework',
  [ModuleKeywords.ComplianceReport]: 'framework',
  [ModuleKeywords.ComplianceReportResult]: 'framework',
  [ModuleKeywords.AuthorityDocument]: 'framework/authority',
  [ModuleKeywords.CitationType]: 'framework/authority',
  [ModuleKeywords.Citation]: 'framework/authority',
  [ModuleKeywords.Exemption]: 'framework/authority',
  // [ModuleKeywords.CitationComplianceReport]: 'authority',
  // [ModuleKeywords.CitationComplianceReportResult]: 'authority',
  [ModuleKeywords.Task]: 'activity-feed',
  [ModuleKeywords.MyTask]: 'activity-feed/task',
  [ModuleKeywords.Notification]: 'activity-feed',
  [ModuleKeywords.MyNotification]: 'activity-feed/notification',
  [ModuleKeywords.User]: 'user-management',
  [ModuleKeywords.Role]: 'user-management',
  [ModuleKeywords.Group]: 'user-management',
  [ModuleKeywords.Finding]: 'issue',
  [ModuleKeywords.Issue]: 'issue',
  [ModuleKeywords.IssueType]: 'issue',
  [ModuleKeywords.Remediation]: 'issue',
  [ModuleKeywords.Acknowledgment]: null,
  [ModuleKeywords.AcknowledgmentRequest]: 'acknowledgment',
  [ModuleKeywords.MyAcknowledgment]: 'acknowledgment',
  [ModuleKeywords.FieldTemplate]: 'data-templates',
  [ModuleKeywords.Organization]: 'entity',
  [ModuleKeywords.RelationType]: 'relation',
  // [ModuleKeywords.RelationTypes] : 'data-templates',
  [ModuleKeywords.GlobalValueList]: 'data-templates',
  [ModuleKeywords.Filter]: 'data-templates',
  [ModuleKeywords.ContentView]: 'data-templates',
  [ModuleKeywords.Dashboard]: 'data-templates',
  [ModuleKeywords.Report]: 'data-templates',
  [ModuleKeywords.ReportScheduler]: 'data-templates',
  [ModuleKeywords.ReportConfigurationTemplate]: 'data-templates',
  [ModuleKeywords.StatisticCollector]: 'data-templates',
  [ModuleKeywords.GeneratedStatistic]: 'data-templates',
  [ModuleKeywords.GeneratedReport]: 'data-templates',
  [ModuleKeywords.Tag]: 'data-templates',
  [ModuleKeywords.EmailTemplate]: 'activity-feed',
  [ModuleKeywords.TaskTemplate]: 'activity-feed',
  [ModuleKeywords.Document]: 'document', //@TODO:Document
  [ModuleKeywords.AuditTrail]: 'activity-feed', //@TODO:AuditTrail
  [ModuleKeywords.AuditUniverse]: 'audit',
  [ModuleKeywords.AuditProgram]: 'audit',
  [ModuleKeywords.AuditProgramReport]: 'audit',
  [ModuleKeywords.AuditProgramType]: 'audit',
  [ModuleKeywords.AuditCaseTemplate]: 'audit',
  [ModuleKeywords.AuditProject]: 'audit',
  [ModuleKeywords.AuditProjectReport]: 'audit',
  [ModuleKeywords.AuditCase]: 'audit',
  [ModuleKeywords.AuditCaseResult]: 'audit',
  [ModuleKeywords.AuditRequest]: 'audit',
  [ModuleKeywords.AuditExpense]: 'audit',
  [ModuleKeywords.ExportGlobalPackage]: 'global-package',
  [ModuleKeywords.ImportGlobalPackage]: 'global-package',
  [ModuleKeywords.TransparencyCaseCategory]: 'transparency-management',
  [ModuleKeywords.TransparencyCase]: 'transparency-management',
  [ModuleKeywords.Standard]: 'framework',
  // [ModuleKeywords.StandardSection]: 'framework/standard',
  [ModuleKeywords.Guideline]: 'framework',
  [ModuleKeywords.ControlGroup]: 'framework',
  [ModuleKeywords.ControlCategory]: 'framework',
  [ModuleKeywords.ControlProcedure]: 'framework',
  [ModuleKeywords.ControlObjective]: 'framework',
  [ModuleKeywords.Metric]: 'performance',
  [ModuleKeywords.MetricValue]: 'performance',
  [ModuleKeywords.MetricCategory]: 'performance',
};

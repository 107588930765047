import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { JWTTokenService } from '@core/services/JWT-token/jwttoken.service';
import { IAction, NotificationDto, PaginationModel, TargetTypeEnum } from '@shared/classes';
import { LoaderService } from '@shared/services/loader.service';
import { PathResolverService } from '@shared/services/path-resolver.service';
import { SocketDataService } from '@shared/services/socket-data.service';
import { ToastService } from '@shared/services/toast.service';
import { MyNotificationsDataService } from 'app/modules/activity-feed/services/data/my-notifications-data.service';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-notifications-tray',
  templateUrl: './notifications-tray.component.html',
  styleUrls: ['./notifications-tray.component.scss'],
})
export class NotificationsTrayComponent implements OnInit {
  notifications: NotificationDto[] = [];
  directNotifications: NotificationDto[] = [];
  watchingNotifications: NotificationDto[] = [];
  groupedNotifications: { [x: string]: NotificationDto[] } = {};
  directDateGroupedNotifications: any; //Map<string, NotificationExtraProps[]>; //{[x:string] :  NotificationDto[]} = {};
  watchingDateGroupedNotifications: any; //Map<string, NotificationExtraProps[]>; //{[x:string] :  NotificationDto[]} = {};
  loading: boolean = true;
  displayUnseen: boolean = false;
  seeAllAction: IAction = {
    id: 1,
    label: '',
    buttonType: 'button',
    command: this.markAllAsSeen.bind(this),
    icon: 'fas fa-check-double',
    color: 'secondary',
    buttonStyle: 'text',
    tooltipText: 'Mark all as seen',
    tooltipPosition: 'left',
  };
  goToPreferences: IAction = {
    id: 2,
    label: '',
    buttonType: 'button',
    command: this.navigateToPreferences.bind(this),
    icon: 'fa-solid fa-user',
    color: 'secondary',
    buttonStyle: 'text',
    tooltipText: 'My Preferences',
    tooltipPosition: 'left',
  };
  loadMoreAction: IAction = {
    id: 4,
    label: 'Load More',
    buttonType: 'button',
    command: this.loadMore.bind(this),
    icon: 'pi pi-angle-down',
    color: 'secondary',
    buttonStyle: 'text',
    buttonClass: 'w-full',
  };
  displayUnseenControl = new FormControl(false);
  today = new Date();
  todayClipped = null;
  notificationPage: PaginationModel<NotificationDto>;
  private loadingState = new Subject<boolean>();
  loading$: Observable<boolean> = this.loadingState.asObservable();

  constructor(
    private requestService: MyNotificationsDataService,
    private toastService: ToastService,
    private socketService: SocketDataService,
    private pathResolver: PathResolverService,
    private loaderService: LoaderService,
    private router: Router,
    private jWTTokenService: JWTTokenService
  ) {
    this.seeAllAction.loading$ = loaderService.loadingState;
    this.loadMoreAction.loading$ = this.loading$;
    this.todayClipped = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate()).toISOString();
  }
  ngOnInit(): void {
    this.getNotifications();
  }
  getNotifications(page = 0) {
    this.loading = true;
    this.loadingState.next(true);
    this.requestService.getNotifications(page, this.displayUnseen).subscribe({
      next: (res: any) => {
        this.notificationPage = res;
        this.notifications = this.notifications.concat(res.content);
        // this.directNotifications = this.directNotifications.concat((res.content as NotificationDto[]).filter(x => !!!x.seen && x.subscriptionType == 'DIRECT'));
        // this.watchingNotifications = this.watchingNotifications.concat((res.content as NotificationDto[]).filter(x => !!!x.seen && x.subscriptionType == 'WATCHING'));
        this.directNotifications = this.directNotifications.concat(
          (res.content as NotificationDto[]).filter((x) => x.subscriptionType == 'DIRECT')
        );
        this.watchingNotifications = this.watchingNotifications.concat(
          (res.content as NotificationDto[]).filter((x) => x.subscriptionType == 'WATCHING')
        );
        let group = {};
        const mappedDirectGroups: NotificationGroup[] = [];
        const mappedWatchingGroups: NotificationGroup[] = [];
        const groupedData = new Map<string, NotificationExtraProps[]>();
        // const codeGroupedData = groupBy(this.notifications, 'code');
        // this.directNotifications.filter(x => !!!x.seen).forEach(item => {
        this.directNotifications.forEach((item) => {
          const date = new Date(item.creationDate);
          // extract the day without the hours
          const day = new Date(date.getFullYear(), date.getMonth(), date.getDate()).toISOString();
          const existingData = mappedDirectGroups.find((x) => x.day == day)?.notifications || []; //|| groupedData.get(day) || [];
          if (existingData.length > 0) {
            let obj = existingData.find((x) => x.relatedToCode == item.relatedToCode);
            if (obj) {
              obj?.children?.length > 0 ? obj.children?.push(item) : (obj.children = [item]);
            } else {
              existingData.push(item);
            }
          } else {
            existingData.push(item);
            mappedDirectGroups.push({
              day: day,
              notifications: existingData,
            });
          }
        });
        // this.watchingNotifications.filter(x => !!!x.seen).forEach(item => {
        this.watchingNotifications.forEach((item) => {
          const date = new Date(item.creationDate);
          // extract the day without the hours
          const day = new Date(date.getFullYear(), date.getMonth(), date.getDate()).toISOString();
          const existingData = mappedWatchingGroups.find((x) => x.day == day)?.notifications || []; //|| groupedData.get(day) || [];
          if (existingData.length > 0) {
            let obj = existingData.find((x) => x.relatedToCode == item.relatedToCode);
            if (obj) {
              obj?.children?.length > 0 ? obj.children?.push(item) : (obj.children = [item]);
            } else {
              existingData.push(item);
            }
          } else {
            existingData.push(item);
            mappedWatchingGroups.push({
              day: day,
              notifications: existingData,
            });
          }
        });
        // this.notifications.filter(x => !!!x.seen).forEach(item => {
        this.notifications.forEach((item) => {
          // groupedData.set(day,existingData);

          let module = this.pathResolver.getModuleKeywordByCode(item.relatedToCode);
          if (!group[module]) {
            group[module] = [item];
          } else {
            (group[module] as NotificationDto[]).push(item);
          }
        });
        this.groupedNotifications = group; //groupedData;

        this.directDateGroupedNotifications = mappedDirectGroups; //groupedData;
        this.watchingDateGroupedNotifications = mappedWatchingGroups; //groupedData;
        this.loading = false;
        this.loadingState.next(false);
        if (!this.displayUnseen) {
          this.socketService.notifications = [...this.directNotifications, ...this.watchingNotifications];
          this.socketService.totalNotificationCount = res.totalElements;
        }
      },
      error: (err) => {
        this.loading = false;
        this.loadingState.next(false);
      },
    });
  }
  markAllAsSeen() {
    this.requestService.markAllAsSeen().subscribe((res) => {
      // this.toastService.success("Success","Marked all as seen",null);
      this.socketService.notifications = [];
      this.socketService.totalNotificationCount = 0;
    });
  }
  clearData() {
    this.notifications = [];
    this.directNotifications = [];
    this.watchingNotifications = [];
    this.groupedNotifications = {};
  }
  onChangeShowAll(event) {
    this.displayUnseen = event;
    this.clearData();
    this.getNotifications(0);
  }
  displayNotifications(event) {
    this.displayUnseen = event;
    if (this.displayUnseen) {
      this.directNotifications = this.notifications.filter((x) => x.subscriptionType == 'DIRECT');
      this.watchingNotifications = this.notifications.filter((x) => x.subscriptionType == 'WATCHING');
      let group = {};
      this.notifications.forEach((item) => {
        let module = this.pathResolver.getModuleKeywordByCode(item.relatedToCode);
        if (!group[module]) {
          group[module] = [item];
        } else {
          (group[module] as NotificationDto[]).push(item);
        }
      });
      this.groupedNotifications = group;
    } else {
      // this.directNotifications = this.notifications.filter(x => !!!x.seen && x.subscriptionType == 'DIRECT');
      // this.watchingNotifications = this.notifications.filter(x => !!!x.seen && x.subscriptionType == 'WATCHING');
      this.directNotifications = this.notifications.filter((x) => x.subscriptionType == 'DIRECT');
      this.watchingNotifications = this.notifications.filter((x) => x.subscriptionType == 'WATCHING');
      let group = {};
      // this.notifications.filter(x => !!!x.seen).forEach(item => {
      this.notifications.forEach((item) => {
        let module = this.pathResolver.getModuleKeywordByCode(item.relatedToCode);
        if (!group[module]) {
          group[module] = [item];
        } else {
          (group[module] as NotificationDto[]).push(item);
        }
      });
      this.groupedNotifications = group;
    }
  }
  loadMore() {
    if (this.notificationPage.number + 1 < this.notificationPage.totalPages) {
      this.getNotifications(this.notificationPage.number + 1);
    }
  }

  mapModuleToType(moduleName) {
    let type = TargetTypeEnum[moduleName];

    if (!type && moduleName.length) return TargetTypeEnum[moduleName.slice(0, -1)];

    return type;
  }

  getLastDate(isoDate) {
    var r = new Date(isoDate);

    const year = r.getFullYear();
    const month = r.getMonth(); // JavaScript months are 0-based, so 8 represents September
    const day = r.getDate(); // Use getDate() to get the day of the month

    // Create a Date object for the given day at 23:59:59
    const lastMinuteOfDay = new Date(year, month, day, 23, 59, 59);

    return lastMinuteOfDay.toISOString();
  }
  navigateToPreferences() {
    this.router.navigateByUrl(`/user-preferences/` + this.jWTTokenService.getPreferredUsername());
  }
}
interface NotificationExtraProps extends NotificationDto {
  children?: NotificationDto[];
}
interface NotificationGroup {
  day: string;
  notifications: NotificationExtraProps[];
}

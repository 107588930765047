import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { JWTTokenService } from '@core/services/JWT-token/jwttoken.service';
import { ControlObjectiveDto, DataTypeEnum, IAction, IColumn, ItemBasePage, ModuleKeywords } from '@shared/classes';
import { PageableOptions } from '@shared/classes/model/frontend/pageable-options';
import { AppDialogService, CodeLabelCacheService, ToastService, ViewModeService } from '@shared/services';
import { ControlObjectiveDataService } from 'app/modules/org-framework/service/data/ControlObjectiveDataService';
import { MenuItem } from 'primeng/api';
import { ControlObjectiveAddLinkPopupComponent } from '../control-objective-add-link-popup/control-objective-add-link-popup.component';
import { ControlObjectivePopupConfirmationComponent } from '../control-objective-popup-confirmation/control-objective-popup-confirmation.component';

@Component({
  selector: 'app-control-objective-matcher',
  templateUrl: './control-objective-matcher.component.html',
  styleUrl: './control-objective-matcher.component.scss',
})
export class ControlObjectiveMatcherComponent extends ItemBasePage<ControlObjectiveDto> implements OnInit {
  allControlObjectivesName: any[] = [];
  allControlObjectivesDesc: any[] = [];
  selectedControlObjectives: any[] = [];
  allControlObjectivesControl = new FormControl(null);
  paginatorName = new PageableOptions();
  paginatorDesc = new PageableOptions();
  totalNameElements = 0;
  totalDescElements = 0;

  exlcudedObjectives = [];
  cols: IColumn[] = [];
  linkAction: IAction = {
    id: 1,
    label: 'Process Linkin/Unlinking',
    icon: 'pi pi-save',
    iconPos: 'right',
    command: this.processLinking.bind(this),
  };

  addAndLinkAction: IAction = {
    id: 2,
    label: 'Add And Link',
    icon: 'pi pi-plus',
    iconPos: 'right',
    command: this.addAndLinkPopup.bind(this),
  };

  addToSelectedAction: IAction = {
    id: 3,
    label: 'Add Objectives',
    icon: 'pi pi-plus',
    iconPos: 'right',
    command: this.addToSelected.bind(this),
  };
  loading = true;

  activeMenuItem: MenuItem = {
    id: '1',
    label: 'Smart Matcher',
  };
  menuItems: MenuItem[] = [this.activeMenuItem, { id: '2', label: 'Manual Matcher' }];
  activeSmartMenuItem: MenuItem = {
    id: '1',
    label: 'Name',
  };
  smartMenuItems: MenuItem[] = [this.activeSmartMenuItem, { id: '2', label: 'Description' }];

  _searchData;
  @Input() set searchData(data) {
    this._searchData = data;
    if (data) {
      this.allControlObjectivesName = [];
      this.allControlObjectivesDesc = [];

      if (data?.name) {
        this.fetchControlObjectivesName(data.name);
      }
      if (data?.description) {
        this.fetchControlObjectivesDesc(data.description);
      }
      if (!this.allControlObjectivesName?.length && !this.allControlObjectivesDesc?.length) {
        this.activeMenuItem = { id: '2', label: 'Manual Matcher' };
        this.menuItems = [this.activeMenuItem];
      } else {
        this.menuItems = [this.activeMenuItem, { id: '2', label: 'Manual Matcher' }];
      }
    }
  }
  get searchData() {
    return this._searchData;
  }
  _linkedObjectives = [];
  @Input() set linkedObjectives(obj: ControlObjectiveDto[]) {
    this.readyToLink = [];
    this.readyToUnlink = [];
    this.readyToAddAndLink = [];

    this._linkedObjectives = obj;
    this.selectedControlObjectives = obj
      ?.map((x) => {
        return { ...x, matchingType: 'Linked', checked: true };
      })
      .sort((a, b) => {
        return a.searchScore - b.searchScore;
      });
  }

  get linkedObjectives() {
    return this._linkedObjectives;
  }

  @Output() onProcessLinking: EventEmitter<any> = new EventEmitter();

  readyToLink = [];
  readyToUnlink = [];
  readyToAddAndLink = [];

  constructor(
    private route: ActivatedRoute,
    public requestService: ControlObjectiveDataService,
    private toastService: ToastService,
    public viewModeService: ViewModeService,
    private router: Router,
    private jwtTokenService: JWTTokenService,
    private codeLabelService: CodeLabelCacheService,
    private appDialogService: AppDialogService
  ) {
    super(
      {
        moduleKeyword: ModuleKeywords.ControlObjective,
        routePrefix: 'framework',
      },
      router,
      requestService,
      toastService
    );
    // this.subs.sink = this.route.params.subscribe({
    //   next: (params) => {
    //     this.itemId = params['id'];
    //     this.SetPageValues({
    //       breadCrumb: {
    //         items: [
    //           {
    //             label: 'Framework',
    //             routerLink: [`${this.routePrefix ? this.routePrefix : toKebabCase(this.moduleKeyword)}`],
    //           },
    //           {
    //             label: 'Control Objectives',
    //             routerLink: [`/${this.routePrefix}/${toKebabCase(this.moduleKeyword)}/list`],
    //           },
    //           {
    //             label: 'Control Objective Matcher',
    //             routerLink: [],
    //           },
    //         ],
    //       },
    //       itemId: this.itemId,
    //       fromType: null, //TargetTypeEnum.EntityFilter
    //     });
    //   },
    // });
  }

  ngOnInit(): void {
    this.setCols();
  }

  setCols() {
    this.cols = [
      {
        name: 'Score',
        key: 'searchScore',
        dataType: DataTypeEnum.Number,
      },
      {
        name: 'Id',
        key: 'code',
        dataType: DataTypeEnum.CodeLink,
        options: {
          showLabel: false,
        },
      },
      {
        name: 'Name',
        key: 'name',
        dataType: DataTypeEnum.Text,
        options: {
          clamp: false,
        },
      },
      {
        name: 'Matching Type',
        key: 'matchingType',
        dataType: DataTypeEnum.Badge,
      },
    ];
    // const mappingService = AppInjector.get(ControlObjectivesMappingService);
    // this.cols = [...mappingService.tableFields];
  }

  processLinking() {
    this.appDialogService.showDialog(
      ControlObjectivePopupConfirmationComponent,
      'Control Objective Linking Summary',
      (e) => {
        if (e) {
          this.onProcessLinking.emit({
            readyToLink: this.readyToLink,
            readyToUnlink: this.readyToUnlink,
            addAndLink: this.readyToAddAndLink,
          });
        }
      },
      {
        data: { readyToLink: this.readyToLink, readyToUnlink: this.readyToUnlink, addAndLink: this.readyToAddAndLink },
      }
    );
  }

  onObjectiveSelectedName(event: any[]) {
    this.selectedControlObjectives = [...this.selectedControlObjectives, ...event.filter((x) => x.checked)];
    this.allControlObjectivesName = [
      ...event
        .filter((x) => !x.checked)
        .map((x) => {
          return {
            ...x,
            // _disableCheck:
            //   x.controlCategory != this.selectedControlObjectives?.[0]?.controlCategory ||
            //   x.code != this.selectedControlObjectives?.[0]?.code
          };
        }),
    ].sort((a, b) => b.searchScore - a.searchScore);
    this.checkLinkedUnlinked();
    const uniqueObjects = [...new Map(this.selectedControlObjectives.map((item) => [item.code, item])).values()];
    this.selectedControlObjectives = [...uniqueObjects];
    // this.allControlObjectives.sort((a, b) => b.searchScore - a.searchScore);
  }

  onObjectiveSelectedDesc(event: any[]) {
    this.selectedControlObjectives = [...this.selectedControlObjectives, ...event.filter((x) => x.checked)];
    this.allControlObjectivesDesc = [
      ...event
        .filter((x) => !x.checked)
        .map((x) => {
          return {
            ...x,
            // _disableCheck:
            //   x.controlCategory != this.selectedControlObjectives?.[0]?.controlCategory ||
            //   x.code != this.selectedControlObjectives?.[0]?.code
          };
        }),
    ].sort((a, b) => b.searchScore - a.searchScore);
    this.checkLinkedUnlinked();
    const uniqueObjects = [...new Map(this.selectedControlObjectives.map((item) => [item.code, item])).values()];
    this.selectedControlObjectives = [...uniqueObjects];
    // this.allControlObjectives.sort((a, b) => b.searchScore - a.searchScore);
  }

  onObjectiveRemoved(event: any[]) {
    this.selectedControlObjectives = [...event.filter((x) => x.checked)];

    if (this.activeMenuItem.id == '1') {
      if (this.selectedControlObjectives?.length == 0) {
        if (this.activeSmartMenuItem.id == '1') {
          this.allControlObjectivesName = [...this.allControlObjectivesName, ...event.filter((x) => !x.checked)]
            .map((x) => {
              return {
                ...x,
                // _disableCheck: false,
              };
            })
            .sort((a, b) => b.searchScore - a.searchScore);
        } else {
          this.allControlObjectivesDesc = [...this.allControlObjectivesDesc, ...event.filter((x) => !x.checked)]
            .map((x) => {
              return {
                ...x,
                // _disableCheck: false,
              };
            })
            .sort((a, b) => b.searchScore - a.searchScore);
        }
      } else {
        if (this.activeSmartMenuItem.id == '1') {
          this.allControlObjectivesName = [...this.allControlObjectivesName, ...event.filter((x) => !x.checked)].sort(
            (a, b) => b.searchScore - a.searchScore
          );
        } else {
          this.allControlObjectivesDesc = [...this.allControlObjectivesDesc, ...event.filter((x) => !x.checked)].sort(
            (a, b) => b.searchScore - a.searchScore
          );
        }
      }
    }
    if (this.activeSmartMenuItem.id == '1') {
      const uniqueObjects = [...new Map(this.allControlObjectivesName.map((item) => [item.code, item])).values()];
      this.allControlObjectivesName = [...uniqueObjects];
    } else {
      const uniqueObjects = [...new Map(this.allControlObjectivesDesc.map((item) => [item.code, item])).values()];
      this.allControlObjectivesDesc = [...uniqueObjects];
    }
    this.checkLinkedUnlinked();

    // this.allControlObjectives.sort((a, b) => b.searchScore - a.searchScore);
  }

  checkLinkedUnlinked() {
    this.readyToLink = [];
    this.readyToUnlink = [];
    this.readyToAddAndLink = [];
    const baseObjectives = this.linkedObjectives?.map((x) => x.code);
    const baseSelectedObjectives = this.selectedControlObjectives
      ?.filter((x) => x.matchingType != 'New')
      .map((x) => x.code);
    this.exlcudedObjectives = this.selectedControlObjectives.map((x) => x.code);
    this.selectedControlObjectives
      ?.filter((x) => x.matchingType != 'New')
      ?.forEach((x) => {
        if (!baseObjectives.includes(x.code)) {
          this.readyToLink.push(x.code);
        }
      });

    baseObjectives.forEach((x) => {
      if (!baseSelectedObjectives.includes(x)) {
        this.readyToUnlink.push(x);
      }
    });
  }

  onSubmitCreateObjectives(event, page = 'PAGE') {}

  onSetData(): void {}

  fetchControlObjectivesName(searchKeyword) {
    if (searchKeyword) {
      this.loading = true;
      this.requestService
        .search(
          { ...this.paginatorName, sort: ['searchScore desc'] },
          {
            projectionFields: ['name', 'code', 'searchScore'],
            filters: [],
            textSearch: {
              search: searchKeyword,
              language: 'en',
              caseSensitive: false,
              diacriticSensitive: false,
            },
          }
        )
        .subscribe((res) => {
          this.loading = false;
          if (res?.content) {
            this.paginatorName.size = res?.size;
            this.totalNameElements = res?.totalElements;
            const codesList = this.allControlObjectivesName?.map((x) => {
              return x.code;
            });
            const result = (res?.content as any).map((x) => {
              return { ...x, matchingType: 'Title' };
            });
            this.allControlObjectivesName = [...result?.filter((x) => !codesList.includes(x.code))];
            // this.allControlObjectivesName = [...this.allControlObjectivesName, ...result?.filter(x => !codesList.includes(x.code))];
            this.allControlObjectivesName.sort((a, b) => b.searchScore - a.searchScore);
            const selectedCodesList = this.linkedObjectives.map((x) => {
              return x.code;
            });
            this.allControlObjectivesName = this.allControlObjectivesName?.filter((x) => {
              return !selectedCodesList.includes(x.code);
            });
            if (!this.allControlObjectivesName?.length && !this.allControlObjectivesDesc?.length) {
              this.activeMenuItem = { id: '2', label: 'Manual Matcher' };
              this.menuItems = [this.activeMenuItem];
            } else {
              this.activeMenuItem = { id: '1', label: 'Smart Matcher' };
              this.menuItems = [this.activeMenuItem, { id: '2', label: 'Manual Matcher' }];
            }
          }
        });
    }
  }

  fetchControlObjectivesDesc(searchKeyword) {
    if (searchKeyword) {
      this.loading = true;
      this.requestService
        .search(
          { ...this.paginatorDesc, sort: ['searchScore desc'] },
          {
            projectionFields: ['name', 'code', 'searchScore'],
            filters: [],
            textSearch: {
              search: searchKeyword,
              language: 'en',
              caseSensitive: false,
              diacriticSensitive: false,
            },
          }
        )
        .subscribe((res) => {
          this.loading = false;
          if (res?.content) {
            this.paginatorDesc.size = res?.size;
            this.totalDescElements = res?.totalElements;
            const codesList = this.allControlObjectivesDesc?.map((x) => {
              return x.code;
            });
            const result = (res?.content as any).map((x) => {
              return { ...x, matchingType: 'Description' };
            });
            // this.allControlObjectivesDesc = [...this.allControlObjectivesDesc, ...result?.filter(x => !codesList.includes(x.code))];
            this.allControlObjectivesDesc = [...result?.filter((x) => !codesList.includes(x.code))];
            this.allControlObjectivesDesc.sort((a, b) => b.searchScore - a.searchScore);
            const selectedCodesList = this.linkedObjectives.map((x) => {
              return x.code;
            });
            this.allControlObjectivesDesc = this.allControlObjectivesDesc?.filter((x) => {
              return !selectedCodesList.includes(x.code);
            });
            if (!this.allControlObjectivesDesc?.length) {
              this.activeMenuItem = { id: '2', label: 'Manual Matcher' };
              this.menuItems = [this.activeMenuItem];
            } else {
              this.activeMenuItem = { id: '1', label: 'Smart Matcher' };
              this.menuItems = [this.activeMenuItem, { id: '2', label: 'Manual Matcher' }];
            }
          }
        });
    }
  }

  onPageChangeName(event) {
    this.paginatorName.page = event.page;
    if (event?.rows) {
      this.paginatorName.size = event.rows;
    }
    if (this._searchData?.name) this.fetchControlObjectivesName(this._searchData.name);
  }

  onPageChangeDesc(event) {
    this.paginatorDesc.page = event.page;
    if (event?.rows) {
      this.paginatorDesc.size = event.rows;
    }
    if (this._searchData?.description) this.fetchControlObjectivesDesc(this._searchData.description);
  }

  addAndLinkPopup() {
    this.appDialogService.showDialog(
      ControlObjectiveAddLinkPopupComponent,
      'Add And Link',
      (e) => {
        if (e) {
          this.readyToAddAndLink = e.map((x) => x.name?.name);
          this.selectedControlObjectives = [
            ...this.selectedControlObjectives,
            ...e.map((x) => {
              return { name: x.name?.name, matchingType: 'New', checked: true };
            }),
          ].sort((a, b) => b.searchScore - a.searchScore);
          // this.onProcessLinking.emit({ addAndLink: e.map(x => x.name?.name) });
        }
      },
      {
        data: {},
      }
    );
  }
  activeMenuItemChange(event) {
    this.activeMenuItem = event;
  }
  activeSmartMenuItemChange(event) {
    this.activeSmartMenuItem = event;
  }
  addToSelected() {
    const formObjs = this.allControlObjectivesControl.value;
    if (formObjs) {
      if (this.activeSmartMenuItem?.id == '1') {
        this.onObjectiveSelectedName([
          ...this.allControlObjectivesName,
          ...formObjs.map((x) => {
            return { ...x, checked: true, matchingType: 'Manual' };
          }),
        ]);
      } else {
        this.onObjectiveSelectedDesc([
          ...this.allControlObjectivesDesc,
          ...formObjs.map((x) => {
            return { ...x, checked: true, matchingType: 'Manual' };
          }),
        ]);
      }
    } else {
    }
    this.allControlObjectivesControl.patchValue(null);
  }
}

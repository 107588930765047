import { Injectable } from '@angular/core';
import { DataTypeEnum, EmailTemplateDto, IFieldDefinition } from '@shared/classes';
import { BaseFieldDefinitionsService } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class EmailTemplatesMappingService extends BaseFieldDefinitionsService {
  recordFields: IFieldDefinition[] = [
    {
      name: 'Name',
      key: 'name',
      dataType: DataTypeEnum.Text,
      required: true,
      showInTable: true,
      description: 'Name of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Subject',
      key: 'subjectTemplate',
      dataType: DataTypeEnum.Text,
      required: true,
      showInTable: true,
      description: 'Subject template of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Template Type',
      key: 'templateType',
      dataType: DataTypeEnum.Badge,
      required: true,
      showInTable: true,
      description: 'Template Type of the record',
      tableDefinition: {
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: EmailTemplateDto.TemplateTypeEnum,
        },
      },
    },
    {
      name: 'Apply To',
      key: 'applyTo',
      dataType: DataTypeEnum.Badge,
      required: true,
      showInTable: true,
      description: 'The record applies to what?',
      tableDefinition: {
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: EmailTemplateDto.ApplyToEnum,
        },
      },
    },
    {
      name: 'System Generated',
      key: 'systemGenerated',
      dataType: DataTypeEnum.Badge,
      required: false,
      showInTable: true,
      description: 'Is the record system generated?',
      tableDefinition: {
        filter: {
          type: 'boolean',
          display: 'menu',
          matchMode: 'equals',
        },
      },
    },
    {
      name: 'Default',
      key: 'isDefault',
      dataType: DataTypeEnum.Badge,
      required: false,
      showInTable: true,
      description: 'Is the record default?',
      tableDefinition: {
        filter: {
          type: 'boolean',
          display: 'menu',
          matchMode: 'equals',
        },
      },
    },
  ];
  constructor() {
    super();
    this.setAllFields();
  }
}

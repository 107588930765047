import { AbstractControl, ValidatorFn } from '@angular/forms';

export class CustomValidators {
  static urlValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value) {
        return null; // Return null if the control is empty, validation is not required for empty control
      }
      const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
      const valid = urlPattern.test(control.value);
      return valid ? null : { invalidUrl: { value: control.value } };
    };
  }
}

import { Injectable } from '@angular/core';
import {
  IFieldDefinition,
  DataTypeEnum,
  AppPermissions,
  TargetTypeEnum,
  QuestionnaireQuestionsDto,
  RemediationDto,
} from '@shared/classes';
import { BaseFieldDefinitionsService } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class RemediationPlanMappingService extends BaseFieldDefinitionsService {
  recordFields: IFieldDefinition[] = [
    {
      name: 'Name',
      key: 'name',
      dataType: DataTypeEnum.Text,
      required: true,
      showInTable: true,
      description: 'Record Name',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Owner',
      key: 'owner',
      dataType: DataTypeEnum.ResponsibilityListView,
      required: true,
      showInTable: true,
      description: 'Owner of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Status',
      key: 'status',
      dataType: DataTypeEnum.Badge,
      required: true,
      showInTable: true,
      description: 'Record Status',
      tableDefinition: {
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: RemediationDto.StatusEnum,
        },
      },
    },
    {
      name: 'Issues',
      key: 'issues',
      dataType: DataTypeEnum.Text,
      required: false,
      showInTable: true,
      description: 'Linked Issues',
      tableDefinition: {
        filter: {
          type: 'recordCode',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          recordCodeType: TargetTypeEnum.Issue,
        },
      },
    },
    {
      name: 'Approvers',
      key: 'approvers',
      dataType: DataTypeEnum.ResponsibilityListView,
      required: true,
      showInTable: true,
      description: 'Approvers of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Approved By',
      key: 'approvedBy',
      dataType: DataTypeEnum.UserListView,
      required: true,
      showInTable: true,
      description: 'Approved by of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Progress',
      key: 'progress',
      dataType: DataTypeEnum.ProgressBar,
      required: false,
      showInTable: true,
      description: 'Progress of the record',
      tableDefinition: {},
    },
    {
      name: 'Start Date',
      key: 'startDate',
      dataType: DataTypeEnum.DateLong,
      required: true,
      showInTable: true,
      description: 'Start date of the record',
      tableDefinition: {
        filter: {
          type: 'date',
          matchMode: 'dateBefore',
        },
      },
    },
    {
      name: 'Due Date',
      key: 'dueDate',
      dataType: DataTypeEnum.DateLong,
      required: true,
      showInTable: true,
      description: 'Due date of the record',
      tableDefinition: {
        filter: {
          type: 'date',
          matchMode: 'dateBefore',
        },
      },
    },
    {
      name: 'Actual Start Date',
      key: 'actualStartDate',
      dataType: DataTypeEnum.DateLong,
      required: true,
      showInTable: true,
      description: 'Actual start date of the record',
      tableDefinition: {
        filter: {
          type: 'date',
          matchMode: 'dateBefore',
        },
      },
    },
    {
      name: 'Actual Close Date',
      key: 'actualCloseDate',
      dataType: DataTypeEnum.DateLong,
      required: true,
      showInTable: true,
      description: 'Due date of the record',
      tableDefinition: {
        filter: {
          type: 'date',
          matchMode: 'dateBefore',
        },
      },
    },
    {
      name: 'Description',
      key: 'description',
      dataType: DataTypeEnum.LongWithHoverText,
      required: true,
      showInTable: true,
      description: 'Record Description',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
  ];
  constructor() {
    super();
    this.setAllFields();
  }
}

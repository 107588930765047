import { IColumn } from './AppTable';

export interface IExportData {
  page: number;
  size: number;
  type: 'xls' | 'pdf' | 'csv';
  cols: IColumn[];
  name?: string;
  description?: string;
  typesToInclude?: any[];
  selectedItems?: any[];
  exportManualRelations?: boolean;
  exportReferenceRelations?: boolean;
}

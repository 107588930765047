/**
 * Sightflare Service
 * Rest Api for Sightflare Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RoleInfo } from './roleInfo';
import { RealmAccessInfo } from './realmAccessInfo';


export interface UserInfoDTO { 
    sub?: string;
    resource_access?: { [key: string]: RoleInfo; };
    email_verified?: boolean;
    realm_access?: RealmAccessInfo;
    name?: string;
    preferred_username?: string;
    given_name?: string;
    family_name?: string;
    groups?: Array<string>;
    sf_sys_admin?: boolean;
    email?: string;
}


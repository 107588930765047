import { Injectable } from '@angular/core';
import { AuditProjectReport } from '@shared/classes/model/backend/audit';
import { IFieldDefinition } from '@shared/classes/view/AppFieldDefinition';
import { DataTypeEnum } from '@shared/classes/view/view-enums';
import { BaseFieldDefinitionsService } from '@shared/services/base-field-definitions.service';

@Injectable({
  providedIn: 'root',
})
export class AuditProjectReportMappingService extends BaseFieldDefinitionsService {
  recordFields: IFieldDefinition[] = [
    {
      name: 'Name',
      key: 'name',
      dataType: DataTypeEnum.Text,
      required: false,
      showInTable: true,
      description: 'Name of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Audit Project',
      key: 'auditProject',
      dataType: DataTypeEnum.CodeLink,
      required: false,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Audit Project to this Record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
  ];
  constructor() {
    super();
    this.setAllFields();
  }
}

/**
 * Sightflare Service
 * Rest Api for Sightflare Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface GroupRepresentation { 
    id?: string;
    name?: string;
    path?: string;
    attributes?: { [key: string]: Array<string>; };
    realmRoles?: Array<string>;
    clientRoles?: { [key: string]: Array<string>; };
    subGroups?: Array<GroupRepresentation>;
    access?: { [key: string]: boolean; };
}


import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  LicenseHandlerService,
  LicenseInfo,
  LicenseStatus,
} from '@core/services/license-handler/license-handler.service';
import { environment } from '@env/environment';
import { isNullObj } from '@shared/classes';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  constructor(private licenseHandler: LicenseHandlerService) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap((event) => {
        if (event instanceof HttpResponse) {
          if (!shouldExclude(event.url, LicenseCheckExcludedApiRoutes)) {
            if (isNullObj(event.headers.get('x-license-status'))) {
              console.warn('x-license-status does not exist in the headers', event);
            }

            this.licenseHandler.currentLicenseData = parseLicenseData(event.headers);
          }
        }
      })
    );
  }
}
export const LicenseCheckExcludedApiRoutes: string[] = [environment.user, environment.keyCloak.config.url];
export function shouldExclude(url: string, excludeUrls: string[]): boolean {
  return excludeUrls.some((excludeUrl) => url.startsWith(excludeUrl));
}
export function parseLicenseData(headers: HttpHeaders): LicenseInfo {
  return {
    licenseStatus: headers.get('x-license-status') as LicenseStatus,
    licenseName: headers.get('x-license-name'),
    licenseExpiryDate:
      headers.get('x-license-expiry-date') || headers.get('x-expiry-date')
        ? new Date(parseInt(headers.get('x-license-expiry-date') || headers.get('x-expiry-date')))
        : null,
  };
}

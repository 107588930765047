import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EventFieldDto, ItemBasePage, ModuleKeywords, TargetTypeEnum, toKebabCase } from '@shared/classes';
import { ToastService } from '@shared/services/toast.service';
import { ViewModeService } from '@shared/services/view-mode.service';
import { EventsDataService } from '../../services/data/events-data.service';

@Component({
  selector: 'app-events-item',
  templateUrl: './events-item.component.html',
  styleUrls: ['./events-item.component.scss'],
})
export class EventsItemComponent extends ItemBasePage<EventFieldDto> implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private requestService: EventsDataService,
    private toastService: ToastService,
    public viewModeService: ViewModeService,
    private router: Router
  ) {
    super({ moduleKeyword: ModuleKeywords.Event, routePrefix: 'entity' }, router, requestService, toastService);
    this.subs.sink = this.route.params.subscribe({
      next: (params) => {
        this.itemId = params['id'];
        if (params['parentId'] && params['orgId']) {
          this.data = { parent: params['parentId'], organization: params['orgId'] };
        }
        this.SetPageValues({
          breadCrumb: {
            items: [
              {
                label: 'Entity',
                routerLink: [`${this.routePrefix ? this.routePrefix : toKebabCase(this.moduleKeyword)}`],
              },
              {
                label: 'Events',
                routerLink: [`/${this.routePrefix}${toKebabCase(this.moduleKeyword)}/list`],
              },
              {
                label: this.itemId ? this.itemId : 'Add Event',
                routerLink: [],
              },
            ],
          },
          itemId: this.itemId,
          fromType: TargetTypeEnum.Event,
        });
      },
    });
  }

  ngOnInit(): void {}
}

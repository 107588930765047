//@TODO: resolve a way to remove redundancy in generated models (won't be needed once we move the whole backend to 1 subdomain place)
export * from './base-responses';
//ignore-auto-sort
export * from './data-structure/model/models';
//ignore-auto-sort
export * from './document/model/models';
//ignore-auto-sort
export * from './entity/model/models';
//ignore-auto-sort
export * from './framework/model/models';
//ignore-auto-sort
export * from './question/model/models';
//ignore-auto-sort
export * from './relations/model/models';
//ignore-auto-sort
export * from './risk/model/models';
//ignore-auto-sort
export * from './task/model/models';
//ignore-auto-sort
export * from './user/model/models';
// export * from "./authority/model/models"
//ignore-auto-sort
export * from './acknowledgement/model/models';
//ignore-auto-sort
export * from './audit/model/models';
//ignore-auto-sort
export * from './configuration/model/models';
//ignore-auto-sort
export * from './hub/model/models';
//ignore-auto-sort
export * from './issue/model/models';
//ignore-auto-sort
export * from './performance/model/models';
//ignore-auto-sort
export * from './transparency-management/model/models';
//ignore-auto-sort
export * from './bcm/model/models';

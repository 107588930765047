<app-base-page-item
  [data]="data"
  [recordStatusActions]="recordStatusActions"
  [extendLockTimeAction]="extendLockTimeAction"
  [showLocked]="showLocked"
  [showCommentsButton]="editMode"
  [showStateMachineAuditTrail]="showStateMachineAuditTrail"
  [showHistoryButton]="editMode"
  [showAuditButton]="editMode"
  [showAddCommentButton]="editMode"
  [showShareButton]="editMode"
  [breadCrumb]="breadCrumb"
  [sourceCode]="itemId"
  [hasEditChanges]="hasEditChanges$ | async"
  [fromType]="fromType"
  [showRelations]="editMode"
  [showViewSwitch]="editMode"
  (onViewModeChange)="onViewModeChange($event)"
  [moduleKeyword]="moduleKeyword"
>
  <ng-container page-header>
    <h4 class="mb-0">{{ editMode ? (viewModeService.viewMode | titlecase) : 'Add' }} Event</h4>
  </ng-container>

  <app-events-item-form
    (formSubmit)="onSubmit($event)"
    (formSubmitSave)="onSubmit($event, 'PAGE')"
    (formSubmitInPlace)="onSubmit($event, 'NONE')"
    (cancelEvent)="onCancel($event)"
    [resetForm]="resetFormTrigger"
    (valueEditChanged)="valueEditChanged($event)"
    [formData]="data"
    [itemId]="itemId"
  ></app-events-item-form>
</app-base-page-item>

import { Injectable } from '@angular/core';
import {
  AppPermissions,
  DataTypeEnum,
  IFieldDefinition,
  QuestionnaireQuestionsDto,
  TargetTypeEnum,
} from '@shared/classes';
import { BaseFieldDefinitionsService } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class QuestionnaireValueMappingService extends BaseFieldDefinitionsService {
  recordFields: IFieldDefinition[] = [
    {
      name: 'Questionnaire',
      key: 'questionnaire',
      dataType: DataTypeEnum.CodeLink,
      required: true,
      showInTable: true,
      description: 'Linked Questionnaire',
      permissions: AppPermissions.ReadQuestionnaire,
      tableDefinition: {
        filter: {
          type: 'recordCode',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          recordCodeType: TargetTypeEnum.Questionnaire,
        },
      },
    },
    {
      name: 'Questionnaire Type',
      key: 'questionnaireType',
      dataType: DataTypeEnum.Badge,
      required: true,
      showInTable: true,
      description: 'Record Type',
      tableDefinition: {
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: QuestionnaireQuestionsDto.TypeEnum,
        },
      },
    },
  ];
  constructor() {
    super();
    this.setAllFields();
  }
}

/**
 * Sightflare Service
 * Rest Api for Sightflare Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LinkedRelationItem } from './linkedRelationItem';
import { CreateItem } from './createItem';


export interface CreateBody { 
    createItems?: Array<CreateItem>;
    linkedDocuments?: Array<string>;
    linkedManualRelations?: Array<LinkedRelationItem>;
}


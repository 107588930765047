import { Injectable } from '@angular/core';
import { IFieldDefinition, DataTypeEnum, RelationTypeDto } from '@shared/classes';
import { BaseFieldDefinitionsService } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class RelationTypesMappingService extends BaseFieldDefinitionsService {
  recordFields: IFieldDefinition[] = [
    {
      name: 'Icon',
      key: 'icon',
      dataType: DataTypeEnum.Icon,
      required: false,
      showInTable: true,
      description: 'Record Icon',
      tableDefinition: {
        sortDisabled: true,
      },
    },
    {
      name: 'Name',
      key: 'name',
      dataType: DataTypeEnum.Text,
      required: true,
      showInTable: true,
      description: 'Record Name',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },

    {
      name: 'Color',
      key: 'color',
      dataType: DataTypeEnum.Color,
      required: true,
      showInTable: true,
      description: 'Record Color',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'System Generated',
      key: 'systemGenerated',
      dataType: DataTypeEnum.Badge,
      required: false,
      showInTable: true,
      description: 'Is the record system generated?',
      tableDefinition: {
        filter: {
          type: 'boolean',
          display: 'menu',
          matchMode: 'equals',
        },
      },
    },
    {
      name: 'Risk Cascade Direction',
      key: 'riskCascadeDirection',
      dataType: DataTypeEnum.Badge,
      required: false,
      showInTable: true,
      description: 'Risk Cascade Direction of the record',
      tableDefinition: {
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: RelationTypeDto.RiskCascadeDirectionEnum,
        },
      },
    },
    {
      name: 'Compliance Mode',
      key: 'complianceMode',
      dataType: DataTypeEnum.Badge,
      required: false,
      showInTable: true,
      description: 'Current Compliance Mode of the record',
      tableDefinition: {
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: RelationTypeDto.ComplianceModeEnum,
        },
      },
    },
    // {
    //     name: "Description",
    //     key: "description",
    //     dataType: DataTypeEnum.LongText,
    //     required: false,
    //     showInTable: true,
    //     description: "Record Description",
    //     tableDefinition: {
    //         filter: {
    //             type: "text",
    //             display: "menu",
    //             matchMode: "contains",
    //             showMatchModes: true,
    //             showAddButton: true,
    //             showOperator: true,
    //         },
    //     },
    // },
  ];
  constructor() {
    super();
    this.setAllFields();
  }
}

import { Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {
  DataTypeEnum,
  DynamicComponentBase,
  IDynamicComponent,
  QuestionAnswer,
  QuestionFieldsDto
} from '@shared/classes';
import { Subject, takeUntil } from 'rxjs';
import { DynamicFieldListInputComponent } from '../dynamic-field-list-input/dynamic-field-list-input.component';

@Component({
  selector: 'app-dynamic-field-form-list-input',
  templateUrl: './dynamic-field-form-list-input.component.html',
  styleUrls: ['./dynamic-field-form-list-input.component.scss'],
  providers: [{ provide: DynamicComponentBase, useExisting: DynamicFieldFormListInputComponent }],
})
export class DynamicFieldFormListInputComponent extends DynamicComponentBase implements OnInit {
  dataType: DataTypeEnum = DataTypeEnum.Text;
  private formValid = new Subject<boolean>();
  private _config;
  @ViewChildren(DynamicFieldListInputComponent) dynamicFieldLists!: QueryList<DynamicFieldListInputComponent>;

  @Input() set config(conf: { items: QuestionFieldsDto[]; values: QuestionAnswer[] }) {
    this._config = conf;
    this.initInputFields();
  }
  get config() {
    return this._config;
  }
  formList: { item: QuestionFieldsDto; value: QuestionAnswer }[] = [];
  constructor(private fb: FormBuilder) {
    super();
  }

  ngOnInit(): void {
    // this.initInputFields();
  }
  setInputOptions() { }
  initInputFields() {
    let fields: IDynamicComponent[] = [];

    this.fControl.statusChanges.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      this.formValid.next(res == 'VALID');
    });
    this.formArray?.reset();
    this.formArray?.clear();
    this.formList = [];

    this.config.items.forEach((item) => {
      const itemValue = this.config?.values?.find((x) => x.question === item.code) || null;
      this.formList.push({ item: item, value: itemValue });
      this.formArray.push(
        new FormGroup({
          question: new FormControl(item.code),
          dynamics: new FormGroup({}),
        })
      );
      this.formArray.controls;
    });
    setTimeout(() => {
      this.formValid.next((this.fControl as any as FormArray).valid);
      // this.fControl.markAsTouched  = () => updateFormControlTree(((this.fControl as any) as FormArray));
    }, 1);
  }
  get formArray() {
    return this.fControl as any as FormArray;
  }
}

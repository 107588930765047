export interface BreadCrumb {
  items: FEBreadCrumbItem[];
}
export interface FEBreadCrumbItem {
  label: string;
  routerLink?: string | string[];
  tooltipOptions?: any;
  visible?: boolean;
  styleClass?: string;
}

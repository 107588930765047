import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ApiResponseRiskMethodologyEvent,
  IAction,
  ItemBasePage,
  ModuleKeywords,
  RiskMethodologyDto,
  TargetTypeEnum,
  toKebabCase,
} from '@shared/classes';
import { ToastService } from '@shared/services/toast.service';
import { ViewModeService } from '@shared/services/view-mode.service';
import { RiskMethodologyDataService } from '../../../services/data/risk-methodology-data.service';

@Component({
  selector: 'app-risk-methodology-item',
  templateUrl: './risk-methodology-item.component.html',
  styleUrls: ['./risk-methodology-item.component.scss'],
})
export class RiskMethodologyItemComponent extends ItemBasePage<RiskMethodologyDto> implements OnInit {
  toDraftAction: IAction = {
    id: 1,
    label: 'Return to Draft',
    buttonType: 'button',
    buttonClass: 'ml-2',
    command: this.onChangeStatus.bind(this, ApiResponseRiskMethodologyEvent.DataEnum.Draft),
    icon: 'fas fa-clock-rotate-left',
    color: 'danger',
  };
  toPublishedAction: IAction = {
    id: 2,
    label: 'Publish',
    buttonType: 'button',
    command: this.onChangeStatus.bind(this, ApiResponseRiskMethodologyEvent.DataEnum.Published),
    icon: 'fas fa-check-double',
    color: 'success',
  };
  toApprovedAction: IAction = {
    id: 3,
    label: 'Approve',
    buttonType: 'button',
    buttonClass: 'ml-2',
    command: this.onChangeStatus.bind(this, ApiResponseRiskMethodologyEvent.DataEnum.Approved),
    icon: 'fas fa-check',
    color: 'success',
  };
  toReviewAction: IAction = {
    id: 4,
    label: 'Send For Review',
    buttonType: 'button',
    command: this.onChangeStatus.bind(this, ApiResponseRiskMethodologyEvent.DataEnum.Review),
    icon: 'far fa-eye',
    color: 'help',
  };
  constructor(
    private route: ActivatedRoute,
    public requestService: RiskMethodologyDataService,
    private toastService: ToastService,
    public viewModeService: ViewModeService,
    private router: Router
  ) {
    super({ moduleKeyword: ModuleKeywords.RiskMethodology, routePrefix: 'risk' }, router, requestService, toastService);
    this.subs.sink = this.route.params.subscribe({
      next: (params) => {
        this.itemId = params['id'];
        this.SetPageValues({
          breadCrumb: {
            items: [
              {
                label: 'Risk',
                routerLink: [`${this.routePrefix ? this.routePrefix : toKebabCase(this.moduleKeyword)}`],
              },
              {
                label: 'Risk Methodologies',
                routerLink: [`/${this.routePrefix}/${toKebabCase(this.moduleKeyword)}/list`],
              },
              {
                label: this.itemId ? this.itemId : 'Add Methodolgy',
                routerLink: [],
              },
            ],
          },
          itemId: this.itemId,
          fromType: TargetTypeEnum.Policy,
        });
      },
    });
  }

  ngOnInit(): void {}

  // onSubmit(data) {

  //   // this.riskMethodologyDataService.addAndActivate(riskMeth).subscribe((res) => {
  //   //   this.toastService.success('', 'Risk Methodology Updated');
  //   //   this.riskMethodologyDataService.navigateToListPage();
  //   // });
  // }

  formChanges(data) {}
  get actionsMap() {
    return {
      [ApiResponseRiskMethodologyEvent.DataEnum.Approved]: this.toApprovedAction,
      [ApiResponseRiskMethodologyEvent.DataEnum.Draft]: this.toDraftAction,
      [ApiResponseRiskMethodologyEvent.DataEnum.Published]: this.toPublishedAction,
      [ApiResponseRiskMethodologyEvent.DataEnum.Review]: this.toReviewAction,
    };
  }
  onChangeStatus(status: ApiResponseRiskMethodologyEvent.DataEnum) {
    this.requestService.changeStatus(this.itemId, status).subscribe((red) => {
      // this.requestService.navigateToListPage();
      this.getItemData(this.itemId);
      this.refreshRules();
      // this.data.acknowledgmentStatus = StatusUpdateMap[status];
    });
  }
  // onViewModeChange(viewMode: IViewMode) {
  //   this.router.navigateByUrl(this.getPageViewModeLink(viewMode, this.itemId));
  // }
  // getPageViewModeLink(viewMode: IViewMode, id = null) {
  //   return `/${this.routePrefix}${toKebabCase(this.moduleKeyword)}${this.routeMiddlefix}/${viewMode}`;
  // }
}

/**
 * Sightflare Service
 * Rest Api for Sightflare Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CredentialRepresentationConfig } from './credentialRepresentationConfig';


export interface CredentialRepresentation { 
    id?: string;
    type?: string;
    userLabel?: string;
    createdDate?: number;
    secretData?: string;
    credentialData?: string;
    priority?: number;
    value?: string;
    temporary?: boolean;
    /** @deprecated */
    device?: string;
    /** @deprecated */
    hashedSaltedValue?: string;
    /** @deprecated */
    salt?: string;
    /** @deprecated */
    hashIterations?: number;
    /** @deprecated */
    counter?: number;
    /** @deprecated */
    algorithm?: string;
    /** @deprecated */
    digits?: number;
    /** @deprecated */
    period?: number;
    /** @deprecated */
    config?: CredentialRepresentationConfig;
}


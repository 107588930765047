<ng-container>
  <div class="flex justify-content-between flex-wrap">
    <div class="flex md:flex-row flex-row-reverse flex-wrap gap-2 right-auto">
      <h4 class="mb-0">Relations</h4>
      <app-button [pTooltip]="'Refresh'" [action]="refreshButtonAction"></app-button>
      <app-button [pTooltip]="'Nice Mode'" [action]="niceModeButtonAction"></app-button>
    </div>
    <div class="flex md:flex-row flex-row-reverse flex-wrap gap-2 justify-content-between">
      @if (adminPermission) {
        <app-button *ngxPermissionsOnly="adminPermission" [action]="fixRelationsButtonAction"></app-button>
      }
      <app-button [action]="chartButtonAction"></app-button>
      @if (mode == 'edit') {
        <app-button [action]="submitButtonAction"></app-button>
      }
    </div>
  </div>
</ng-container>
@if (items?.length > 0) {
  <div class="w-full">
    <p-tabMenu [model]="items" [scrollable]="true" [activeItem]="items[0]"></p-tabMenu>
  </div>
}

<app-relationTab
  [typesMap]="typesMap"
  [subject]="subject"
  [refreshData]="refreshDataSubject"
  [sourceCode]="sourceCode"
  [sourceUUID]="sourceUUID"
  [relationType]="fromType"
  [fromType]="sourceType"
  [mode]="mode"
  [niceMode]="niceMode"
  (addedNew)="addedNew($event)"
  (onLoadData)="onLoadData($event)"
></app-relationTab>

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppPermissions } from '@shared/classes';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { RiskMethodologyItemComponent } from './component/risk-methodology-item/risk-methodology-item.component';
import { RiskMethodologyComponent } from './component/RiskMethodology/RiskMethodology.component';
import { RiskMethodologyListComponent } from './risk-methodology-list/risk-methodology-list.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'view' },
  {
    path: 'list',
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: [AppPermissions.ReadRiskMethodology],
        redirectTo: '/access',
      },
    },
    component: RiskMethodologyListComponent,
  },
  {
    path: 'active',
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: [AppPermissions.ReadRiskMethodology],
        redirectTo: '/access',
      },
    },
    component: RiskMethodologyComponent,
  },
  {
    path: 'create',
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: [AppPermissions.ReadRiskMethodology],
        redirectTo: '/access',
      },
    },
    component: RiskMethodologyItemComponent,
  },
  {
    path: 'view/:id',
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: [AppPermissions.ReadRiskMethodology],
        redirectTo: '/access',
      },
    },
    component: RiskMethodologyItemComponent,
  },
  {
    path: 'edit/:id',
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: [AppPermissions.UpdateRiskMethodology],
        redirectTo: '/access',
      },
    },
    component: RiskMethodologyItemComponent,
  },
  {
    path: 'settings',
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: [AppPermissions.DeleteRiskMethodology],
        redirectTo: '/access',
      },
    },
    loadChildren: () => import('./settings/settings.module').then((m) => m.SettingsModule),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RiskMethodologyRoutingModule {}

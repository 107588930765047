import { Injectable } from '@angular/core';
import { AppPermissions, ControlDto, DataTypeEnum, IFieldDefinition, TargetTypeEnum } from '@shared/classes';
import { BaseFieldDefinitionsService } from '@shared/services/base-field-definitions.service';

@Injectable({
  providedIn: 'root',
})
export class ControlsMappingService extends BaseFieldDefinitionsService {
  recordFields: IFieldDefinition[] = [
    {
      name: 'Name',
      key: 'name',
      dataType: DataTypeEnum.Text,
      required: true,
      showInTable: true,
      description: 'Record Name',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Owner',
      key: 'owner',
      dataType: DataTypeEnum.ResponsibilityListView,
      required: true,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Owner of the control',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Respondent',
      key: 'attestationRespondent',
      dataType: DataTypeEnum.ResponsibilityListView,
      required: true,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Respondent of the control',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Control Status',
      key: 'controlStatus',
      dataType: DataTypeEnum.Badge,
      required: false,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Status of the control',
      tableDefinition: {
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: ControlDto.ControlStatusEnum,
        },
      },
    },
    {
      name: 'Compliance Status',
      key: 'controlComplianceStatus',
      dataType: DataTypeEnum.Badge,
      required: false,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Compliance Status of the control',
      tableDefinition: {
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: ControlDto.ControlComplianceStatusEnum,
        },
      },
    },
    {
      name: 'Entity',
      key: 'controlEntity',
      dataType: DataTypeEnum.CodeLink,
      required: false,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Entity Linked to the control',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Control Frequency',
      key: 'controlFrequency',
      dataType: DataTypeEnum.Number,
      required: true,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Frequency of the control',
      tableDefinition: {
        filter: {
          type: 'numeric',
          display: 'menu',
          matchMode: 'lte',
        },
      },
    },
    {
      name: 'Control Classification',
      key: 'controlClassification',
      dataType: DataTypeEnum.Badge,
      required: true,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Classification of the control',
      tableDefinition: {
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: ControlDto.ControlClassificationEnum,
        },
      },
    },
    {
      name: 'Questionnaire',
      key: 'controlAttestationTemplate',
      dataType: DataTypeEnum.CodeLink,
      required: false,
      showInTable: true,
      permissions: AppPermissions.ReadQuestionnaire,
      description: 'Questionnaire Linked to the control',
      tableDefinition: {
        filter: {
          type: 'recordCode',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          recordCodeType: TargetTypeEnum.Questionnaire,
        },
      },
    },
    {
      name: 'Control Group',
      key: 'controlGroup',
      dataType: DataTypeEnum.CodeLink,
      required: false,
      showInTable: true,
      permissions: AppPermissions.ReadControlGroup,
      description: 'Control Group Linked to the control',
      tableDefinition: {
        filter: {
          type: 'recordCode',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          recordCodeType: TargetTypeEnum.ControlGroup,
        },
      },
    },
    // {
    //   name: 'Standard',
    //   key: 'standard',
    //   dataType: DataTypeEnum.CodeLink,
    //   required: true,
    //   showInTable: true,
    //   permissions: AppPermissions.ReadPolicy,
    //   description: 'Standard Linked to the control',
    //   tableDefinition: {
    //     filter: {
    //       type: 'recordCode',
    //       matchMode: 'in',
    //       showMatchModes: false,
    //       showAddButton: false,
    //       showOperator: false,
    //       recordCodeType: TargetTypeEnum.Standard,
    //     },
    //   },
    // },
    // {
    //   name: 'Standard Section',
    //   key: 'standardSection',
    //   dataType: DataTypeEnum.CodeLink,
    //   required: true,
    //   showInTable: true,
    //   permissions: AppPermissions.ReadPolicyBodySection,
    //   description: 'Standard Section Linked to the control',
    //   tableDefinition: {
    //     filter: {
    //       type: 'recordCode',
    //       matchMode: 'in',
    //       showMatchModes: false,
    //       showAddButton: false,
    //       showOperator: false,
    //       recordCodeType: TargetTypeEnum.Standard,
    //     },
    //   },
    // },
    {
      name: 'Description',
      key: 'description',
      dataType: DataTypeEnum.LongWithHoverText,
      required: true,
      showInTable: true,
      description: 'Record Description',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
  ];
  constructor() {
    super();
    this.setAllFields();
  }
}

import { Injectable } from '@angular/core';
import { DataTypeEnum, IFieldDefinition, TargetTypeEnum } from '@shared/classes';
import { BaseFieldDefinitionsService } from '@shared/services';

@Injectable({
  providedIn: 'root'
})
export class ReportSchedulerMappingService extends BaseFieldDefinitionsService {
  recordFields: IFieldDefinition[] = [
    {
      name: 'Name',
      key: 'name',
      dataType: DataTypeEnum.Text,
      required: true,
      showInTable: true,
      description: 'Name of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Report',
      key: 'report',
      dataType: DataTypeEnum.CodeLink,
      required: true,
      showInTable: true,
      description: 'Linked Report to the record',
      tableDefinition: {
        filter: {
          type: 'recordCode',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          recordCodeType: TargetTypeEnum.Report,
        },
      },
    },
    {
      name: 'Frequency',
      key: 'frequency',
      dataType: DataTypeEnum.Number,
      required: true,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Frequency of the report',
      tableDefinition: {
        filter: {
          type: 'numeric',
          display: 'menu',
          matchMode: 'lte',
        },
      },
    },
    {
      name: 'Report Template',
      key: 'reportTemplate',
      dataType: DataTypeEnum.CodeLink,
      required: true,
      showInTable: true,
      description: 'Linked Report Template to the record',
      tableDefinition: {
        filter: {
          type: 'recordCode',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          recordCodeType: TargetTypeEnum.ReportConfigurationTemplate,
        },
      },
    },
    {
      name: 'Last Run Date',
      key: 'lastRunDate',
      dataType: DataTypeEnum.DateLong,
      required: false,
      showInTable: true,
      description: 'Last Run Date of the report',
      tableDefinition: {
        filter: {
          type: 'date',
          matchMode: 'dateBefore',
        },
      },
    },
  ];
  constructor() {
    super();
    this.setAllFields();
  }
}

import { AppPermissions, ModuleIcons, ModuleKeywords, ProductionType } from '@shared/classes';
//@TODONewModule: when you add new module make sure to define these
export const MenuItemList: MenuItem[] = [
  {
    label: 'Dashboards',
    icon: 'sf sf-main',
    routerLink: ['/dashboard/tabs'],
    items: [
      // { label: 'Main', icon: 'pi pi-circle', routerLink: ['/dashboards/main'] },
      // { label: 'Entities', icon: ModuleIcons.Entity, routerLink: ['/dashboards/entities'], permission: [AppPermissions.ReadResponsibility] },
    ],
  },
  {
    label: 'Entities',
    icon: ModuleIcons.Entity,
    routerLink: ['/entity'],
    permission: [
      AppPermissions.ReadResponsibility,
      AppPermissions.ReadObjective,
      AppPermissions.ReadAsset,
      AppPermissions.ReadEvent,
      AppPermissions.ReadGeography,
      AppPermissions.ReadProcess,
      AppPermissions.ReadEntityType,
      AppPermissions.ReadOrganization,
    ],
    items: [
      // { label: 'Entities Dashboard', icon: 'pi pi-fw pi-table', routerLink: ['/entity/dashboard'], permission: [AppPermissions.ReadEntity] },
      // { label: 'Entity List', icon: 'pi pi-fw pi-ticket', routerLink: ['/entity/list'], permission: [AppPermissions.ReadEntity] },
      // { label: 'Import Data', icon: 'pi pi-fw pi-ticket', routerLink: ['/entity/import-data'], permission: [AppPermissions.ReadAnyEntity] },
      {
        label: 'Organizations',
        icon: ModuleIcons?.[ModuleKeywords.Organization],
        routerLink: ['/entity/organization/list'],
        permission: [AppPermissions.ReadOrganization],
        moduleKeyword: ModuleKeywords.Organization,
      },
      {
        label: 'Responsibilities',
        icon: ModuleIcons?.[ModuleKeywords.Responsibility],
        routerLink: ['/entity/responsibility/list'],
        permission: [AppPermissions.ReadResponsibility],
        moduleKeyword: ModuleKeywords.Responsibility,
      },
      {
        label: 'Objectives',
        icon: ModuleIcons?.[ModuleKeywords.Objective],
        routerLink: ['/entity/objective/list'],
        permission: [AppPermissions.ReadObjective],
        moduleKeyword: ModuleKeywords.Objective,
      },
      {
        label: 'Assets',
        icon: ModuleIcons?.[ModuleKeywords.Asset],
        routerLink: ['/entity/asset/list'],
        permission: [AppPermissions.ReadAsset],
        moduleKeyword: ModuleKeywords.Asset,
      },
      {
        label: 'Events',
        icon: ModuleIcons?.[ModuleKeywords.Event],
        routerLink: ['/entity/event/list'],
        permission: [AppPermissions.ReadEvent],
        moduleKeyword: ModuleKeywords.Event,
      },
      {
        label: 'Geographies',
        icon: ModuleIcons?.[ModuleKeywords.Geography],
        routerLink: ['/entity/geography/list'],
        permission: [AppPermissions.ReadGeography],
        moduleKeyword: ModuleKeywords.Geography,
      },
      {
        label: 'Processes',
        icon: ModuleIcons?.[ModuleKeywords.Process],
        routerLink: ['/entity/process/list'],
        permission: [AppPermissions.ReadProcess],
        moduleKeyword: ModuleKeywords.Process,
      },
      {
        label: 'Entity Types',
        icon: ModuleIcons?.[ModuleKeywords.EntityType],
        routerLink: ['/entity/entity-type/list'],
        permission: [AppPermissions.ReadEntityType],
        moduleKeyword: ModuleKeywords.EntityType,
      },
      // {
      //     label: 'Entity Configuration', icon: 'sf sf-entity-configuration', routerLink: ['/entity'], permission: [AppPermissions.ReadEntityType, AppPermissions.ReadEntityGroup],
      //     items: [
      //         { label: 'Entity Types', icon: ModuleIcons?.[ModuleKeywords.EntityType], routerLink: ['/entity/entity-type/list'], permission: [AppPermissions.ReadEntityType], moduleKeyword: ModuleKeywords.EntityType },
      //         // { label: 'Entity Levels', icon: 'pi pi-fw pi-sort-amount-up-alt', routerLink: ['/entity/entity-levels/list'],permission:[AppPermissions.ReadEntityLevels] },
      //         // { label: 'Entity Groups', icon: ModuleIcons?.[ModuleKeywords.EntityGroup], routerLink: ['/entity/entity-group/list'], permission: [AppPermissions.ReadEntityGroup], moduleKeyword: ModuleKeywords.EntityGroup },
      //     ]
      // },
    ],
  },
  {
    label: 'Framework',
    icon: ModuleIcons.FRAMEWORK,
    routerLink: ['/framework'],
    permission: [
      AppPermissions.ReadPolicy,
      AppPermissions.ReadControlGroup,
      AppPermissions.ReadControl,
      AppPermissions.ReadComplianceReport,
      AppPermissions.ReadException,
    ],
    items: [
      {
        label: 'Standards',
        icon: ModuleIcons?.[ModuleKeywords.Standard],
        routerLink: ['/framework/standard/list'],
        permission: [AppPermissions.ReadStandard],
        moduleKeyword: ModuleKeywords.Standard,
      },
      {
        label: 'Policy Management',
        icon: ModuleIcons?.[ModuleKeywords.Policy],
        routerLink: ['/framework'],
        permission: [AppPermissions.ReadPolicy, AppPermissions.ReadPolicyBody, AppPermissions.ReadPolicyBodySection],
        class: '',
        items: [
          {
            label: 'Policies',
            icon: ModuleIcons?.[ModuleKeywords.Policy],
            routerLink: ['/framework/policy/list'],
            permission: [AppPermissions.ReadPolicy],
            moduleKeyword: ModuleKeywords.Policy,
          },
          {
            label: 'Bodies',
            icon: ModuleIcons?.[ModuleKeywords.PolicyBody],
            routerLink: ['/framework/policy/policy-body/list'],
            permission: [AppPermissions.ReadPolicyBody],
            moduleKeyword: ModuleKeywords.PolicyBody,
          },
          {
            label: 'Sections',
            icon: ModuleIcons?.[ModuleKeywords.PolicyBodySection],
            routerLink: ['/framework/policy/policy-body-section/list'],
            permission: [AppPermissions.ReadPolicyBodySection],
            moduleKeyword: ModuleKeywords.PolicyBodySection,
          },
          {
            label: 'Unlinked Sections',
            icon: ModuleIcons?.[ModuleKeywords.PolicyBodySection],
            routerLink: ['/framework/policy/policy-body-section/unlinked'],
            permission: [AppPermissions.ReadPolicyBodySection],
            moduleKeyword: ModuleKeywords.PolicyBodySection,
          },
        ],
      },
      {
        label: 'Guidelines', //@TODO: check breadcrumbs for adding s
        icon: ModuleIcons?.[ModuleKeywords.Guideline],
        routerLink: ['/framework/guideline/list'],
        permission: [AppPermissions.ReadGuideline],
        moduleKeyword: ModuleKeywords.Guideline,
      },
      {
        label: 'Published',
        icon: ModuleIcons?.[ModuleKeywords.Policy],
        routerLink: ['/framework/published'],
        permission: [
          AppPermissions.ReadPublishedGuideline,
          AppPermissions.ReadPublishedPolicy,
          AppPermissions.ReadPublishedStandard,
        ],
        class: '',
        items: [
          {
            label: 'Published Guidelines',
            icon: ModuleIcons?.[ModuleKeywords.PublishedGuideline],
            routerLink: ['/framework/published/published-guideline/list'],
            permission: [AppPermissions.ReadPublishedGuideline],
            moduleKeyword: ModuleKeywords.PublishedGuideline,
          },
          {
            label: 'Published Policies',
            icon: ModuleIcons?.[ModuleKeywords.PublishedPolicy],
            routerLink: ['/framework/published/published-policy/list'],
            permission: [AppPermissions.ReadPublishedPolicy],
            moduleKeyword: ModuleKeywords.PublishedPolicy,
          },
          {
            label: 'Published Standards',
            icon: ModuleIcons?.[ModuleKeywords.Standard],
            routerLink: ['/framework/published/published-standard/list'],
            permission: [AppPermissions.ReadPublishedStandard],
            moduleKeyword: ModuleKeywords.PublishedStandard,
          },
        ],
      },
      // { label: 'Policies', icon: ModuleIcons?.[ModuleKeywords.Policy], routerLink: ['/framework/policy/list'], permission: [AppPermissions.ReadPolicy], moduleKeyword: ModuleKeywords.Policy },
      {
        label: 'Controls Management',
        icon: ModuleIcons?.[ModuleKeywords.Citation],
        routerLink: ['/framework'],
        permission: [AppPermissions.ReadCitationType, AppPermissions.ReadExemption, AppPermissions.ReadCitation],
        class: '',
        items: [
          {
            label: 'Controls',
            icon: ModuleIcons?.[ModuleKeywords.Control],
            routerLink: ['/framework/control/list'],
            permission: [AppPermissions.ReadControl],
            moduleKeyword: ModuleKeywords.Control,
          },
          {
            label: 'Control Categories',
            icon: ModuleIcons?.[ModuleKeywords.ControlCategory],
            routerLink: ['/framework/control-category/list'],
            permission: [AppPermissions.ReadControlCategory],
            moduleKeyword: ModuleKeywords.ControlCategory,
          },
          {
            label: 'Control Groups',
            icon: ModuleIcons?.[ModuleKeywords.ControlGroup],
            routerLink: ['/framework/control-group/list'],
            permission: [AppPermissions.ReadControlGroup],
            moduleKeyword: ModuleKeywords.ControlGroup,
          },
          {
            label: 'Control Objectives', //@TODO: check breadcrumbs for adding s
            icon: ModuleIcons?.[ModuleKeywords.ControlObjective],
            routerLink: ['/framework/control-objective/list'],
            permission: [AppPermissions.ReadControlObjective],
            moduleKeyword: ModuleKeywords.ControlObjective,
          },
          {
            label: 'Control Procedures', //@TODO: check breadcrumbs for adding s
            icon: ModuleIcons?.[ModuleKeywords.ControlProcedure],
            routerLink: ['/framework/control-procedure/list'],
            permission: [AppPermissions.ReadControlProcedure],
            moduleKeyword: ModuleKeywords.ControlProcedure,
          },
          {
            label: 'Control Attestations',
            icon: ModuleIcons?.[ModuleKeywords.Attestation],
            routerLink: ['/framework/attestation/list'],
            permission: [AppPermissions.ReadAttestation],
            moduleKeyword: ModuleKeywords.Attestation,
          },
        ],
      },
      {
        label: 'Exceptions',
        icon: ModuleIcons?.[ModuleKeywords.Exception],
        routerLink: ['/framework/exception/list'],
        permission: [AppPermissions.ReadException],
        moduleKeyword: ModuleKeywords.Exception,
      },
      // {
      //   label: 'Check Item',
      //   icon: 'pi pi-fw pi-check',
      //   routerLink: ['/framework/check-item'],
      //   permission: [AppPermissions.ReadAuthorityDocument],
      // },
      {
        label: 'Authority',
        icon: ModuleIcons?.[ModuleKeywords.Citation],
        routerLink: ['/framework/authority'],
        permission: [AppPermissions.ReadCitationType, AppPermissions.ReadExemption, AppPermissions.ReadCitation],
        class: '',
        items: [
          {
            label: 'Authority Documents',
            icon: ModuleIcons?.[ModuleKeywords.AuthorityDocument],
            routerLink: ['/framework/authority/authority-document/list'],
            permission: [AppPermissions.ReadAuthorityDocument],
            moduleKeyword: ModuleKeywords.AuthorityDocument,
          },
          {
            label: 'Citations',
            icon: ModuleIcons?.[ModuleKeywords.Citation],
            routerLink: ['/framework/authority/citation/list'],
            permission: [AppPermissions.ReadCitation],
            moduleKeyword: ModuleKeywords.Citation,
          },
          {
            label: 'Unlinked Citations',
            icon: ModuleIcons?.[ModuleKeywords.Citation],
            routerLink: ['/framework/authority/citation/unlinked'],
            permission: [AppPermissions.ReadCitation],
            moduleKeyword: ModuleKeywords.Citation,
          },
          {
            label: 'Citation Types',
            icon: ModuleIcons?.[ModuleKeywords.CitationType],
            routerLink: ['/framework/authority/citation-type/list'],
            permission: [AppPermissions.ReadCitationType],
            moduleKeyword: ModuleKeywords.CitationType,
          },
          {
            label: 'Exemptions',
            icon: ModuleIcons?.[ModuleKeywords.Exemption],
            routerLink: ['/framework/authority/exemption/list'],
            permission: [AppPermissions.ReadExemption],
            moduleKeyword: ModuleKeywords.Exemption,
          },
        ],
      },
      {
        label: 'Compliance Reports',
        icon: ModuleIcons?.[ModuleKeywords.ComplianceReport],
        routerLink: ['/framework/compliance-report/list'],
        permission: [AppPermissions.ReadComplianceReport],
        moduleKeyword: ModuleKeywords.ComplianceReport,
      },
      {
        label: 'Compliance Report Results',
        icon: ModuleIcons?.[ModuleKeywords.ComplianceReportResult],
        routerLink: ['/framework/compliance-report-result/list'],
        permission: [AppPermissions.ReadComplianceReportResult],
        moduleKeyword: ModuleKeywords.ComplianceReportResult,
      },
    ],
  },
  {
    label: 'Acknowledgment',
    icon: ModuleIcons?.[ModuleKeywords.Acknowledgment],
    routerLink: ['/acknowledgment'],
    permission: [AppPermissions.ReadAcknowledgment, AppPermissions.ReadAcknowledgmentRequest],
    items: [
      {
        label: 'Acknowledgments',
        icon: ModuleIcons?.[ModuleKeywords.Acknowledgment],
        routerLink: ['/acknowledgment/list'],
        permission: [AppPermissions.ReadAcknowledgment],
        moduleKeyword: ModuleKeywords.Acknowledgment,
      },
      // { label: 'My Acknowledgments', icon: 'pi pi-fw pi-book', routerLink: ['/acknowledgment/my-list'], permission: [AppPermissions.ReadAcknowledgment] },
      {
        label: 'Acknowledgment Requests',
        icon: ModuleIcons?.[ModuleKeywords.AcknowledgmentRequest],
        routerLink: ['/acknowledgment/acknowledgment-request/list'],
        permission: [AppPermissions.ReadAcknowledgmentRequest],
        moduleKeyword: ModuleKeywords.AcknowledgmentRequest,
      },
      {
        label: 'My Acknowledgments',
        icon: ModuleIcons?.[ModuleKeywords.MyAcknowledgment],
        routerLink: ['/acknowledgment/my-acknowledgment/list'],
        permission: [AppPermissions.ReadMyAcknowledgment],
        moduleKeyword: ModuleKeywords.MyAcknowledgment,
      },
    ],
  },
  {
    label: 'Activity Feed',
    icon: 'pi pi-fw pi-calendar',
    routerLink: ['/activity-feed'],
    permission: [
      AppPermissions.ReadNotification,
      AppPermissions.ReadMyNotification,
      AppPermissions.ReadEmailTemplate,
      AppPermissions.ReadTaskTemplate,
    ],
    items: [
      {
        label: 'My Notifications',
        icon: ModuleIcons?.[ModuleKeywords.MyNotification],
        routerLink: ['/activity-feed/notification/my-notification/list'],
        permission: [AppPermissions.ReadMyNotification],
        moduleKeyword: ModuleKeywords.MyNotification,
      },
      {
        label: 'All Notifications',
        icon: ModuleIcons.AllNotification,
        routerLink: ['/activity-feed/notification/list'],
        permission: [AppPermissions.ReadNotification],
        moduleKeyword: ModuleKeywords.Notification,
      },
      {
        label: 'My Tasks',
        icon: ModuleIcons.MyTasks,
        routerLink: ['/activity-feed/task/my-task/list'],
        permission: [AppPermissions.ReadMyTask],
      },
      {
        label: 'All Tasks',
        icon: ModuleIcons.AllTasks,
        routerLink: ['/activity-feed/task/list'],
        permission: [AppPermissions.ReadTask],
      },
      {
        label: 'Email Templates',
        icon: ModuleIcons?.[ModuleKeywords.EmailTemplate],
        routerLink: ['/activity-feed/email-template/list'],
        permission: [AppPermissions.ReadEmailTemplate],
        moduleKeyword: ModuleKeywords.EmailTemplate,
      },
      {
        label: 'Task Templates',
        icon: ModuleIcons?.[ModuleKeywords.TaskTemplate],
        routerLink: ['/activity-feed/task-template/list'],
        permission: [AppPermissions.ReadTaskTemplate],
        moduleKeyword: ModuleKeywords.TaskTemplate,
      },
    ],
  },
  {
    label: 'Audit',
    icon: 'sf sf-audit',
    routerLink: ['/audit'],
    permission: [
      AppPermissions.ReadAuditCase,
      AppPermissions.ReadAuditProgram,
      AppPermissions.ReadAuditProject,
      AppPermissions.ReadAuditUniverse,
      AppPermissions.ReadAuditProgramType,
      AppPermissions.ReadAuditTaskTemplate,
    ],
    items: [
      {
        label: 'Audit Universes', //@TODO: check breadcrumbs for adding s
        icon: ModuleIcons?.[ModuleKeywords.AuditUniverse],
        routerLink: ['/audit/audit-universe/list'],
        permission: [AppPermissions.ReadAuditUniverse],
        moduleKeyword: ModuleKeywords.AuditUniverse,
      },
      {
        label: 'Audit Programs', //@TODO: check breadcrumbs for adding s
        icon: ModuleIcons?.[ModuleKeywords.AuditProgram],
        routerLink: ['/audit/audit-program/list'],
        permission: [AppPermissions.ReadAuditProgram],
        moduleKeyword: ModuleKeywords.AuditProgram,
      },
      {
        label: 'Audit Program Types', //@TODO: check breadcrumbs for adding s
        icon: ModuleIcons?.[ModuleKeywords.AuditProgramType],
        routerLink: ['/audit/audit-program-type/list'],
        permission: [AppPermissions.ReadAuditProgramType],
        moduleKeyword: ModuleKeywords.AuditProgramType,
      },
      {
        label: 'Audit Program Reports', //@TODO: check breadcrumbs for adding s
        icon: ModuleIcons?.[ModuleKeywords.AuditProgramReport],
        routerLink: ['/audit/audit-program-report/list'],
        permission: [AppPermissions.ReadAuditProgramReport, AppPermissions.ReadIssue],
        moduleKeyword: ModuleKeywords.AuditProgramReport,
      },
      {
        label: 'Audit Case Templates', //@TODO: check breadcrumbs for adding s
        icon: ModuleIcons?.[ModuleKeywords.AuditCaseTemplate],
        routerLink: ['/audit/audit-case-template/list'],
        permission: [AppPermissions.ReadAuditTaskTemplate],
        moduleKeyword: ModuleKeywords.AuditCaseTemplate,
      },
      {
        label: 'Audit Projects', //@TODO: check breadcrumbs for adding s
        icon: ModuleIcons?.[ModuleKeywords.AuditProject],
        routerLink: ['/audit/audit-project/list'],
        permission: [AppPermissions.ReadAuditProject],
        moduleKeyword: ModuleKeywords.AuditProject,
      },
      {
        label: 'Audit Cases', //@TODO: check breadcrumbs for adding s
        icon: ModuleIcons?.[ModuleKeywords.AuditCase],
        routerLink: ['/audit/audit-case/list'],
        permission: [AppPermissions.ReadAuditCase],
        moduleKeyword: ModuleKeywords.AuditCase,
      },
      {
        label: 'Audit Case Results', //@TODO: check breadcrumbs for adding s
        icon: ModuleIcons?.[ModuleKeywords.AuditCaseResult],
        routerLink: ['/audit/audit-case-result/list'],
        permission: [AppPermissions.ReadAuditCaseResult],
        moduleKeyword: ModuleKeywords.AuditCaseResult,
      },
      {
        label: 'Audit Expenses', //@TODO: check breadcrumbs for adding s
        icon: ModuleIcons?.[ModuleKeywords.AuditExpense],
        routerLink: ['/audit/audit-expense/list'],
        permission: [AppPermissions.ReadAuditExpense],
        moduleKeyword: ModuleKeywords.AuditExpense,
      },
      // { label: 'My Auditee Cases', icon: 'pi pi-pen-to-square', routerLink: ['/audit/audit-case-result/my-audits-auditee'], permission: [AppPermissions.ReadAuditCaseResult], moduleKeyword: ModuleKeywords.AuditCaseResult },
      // { label: 'My Auditor Cases', icon: 'pi pi-user-edit', routerLink: ['/audit/audit-case-result/my-audits-auditor'], permission: [AppPermissions.ReadAuditCaseResult], moduleKeyword: ModuleKeywords.AuditCaseResult },
      {
        label: 'Audit Requests',
        icon: ModuleIcons?.[ModuleKeywords.AuditRequest],
        routerLink: ['/audit/audit-request/list'],
        permission: [AppPermissions.ReadAuditRequest],
        moduleKeyword: ModuleKeywords.AuditRequest,
      },
      {
        label: 'Audit Project Reports', //@TODO: check breadcrumbs for adding s
        icon: ModuleIcons?.[ModuleKeywords.AuditProjectReport],
        routerLink: ['/audit/audit-project-report/list'],
        permission: [AppPermissions.ReadAuditProjectReport, AppPermissions.ReadIssue],
        moduleKeyword: ModuleKeywords.AuditProjectReport,
      },
      {
        label: 'My Audit Workspace',
        icon: 'pi pi-pen-to-square',
        routerLink: ['/audit/my-audit-workspace'],
        permission: [AppPermissions.UpdateAuditRequest, AppPermissions.UpdateAuditCaseResult],
        moduleKeyword: ModuleKeywords.AuditRequest,
      },
    ],
  },
  // {
  //     label: 'Tasks', icon: 'sf sf-tasks', routerLink: ['/activity-feed/task'], permission: [AppPermissions.ReadTask, AppPermissions.ReadMyTask],
  //     items: [
  //         // { label: 'Notifications', icon: ModuleIcons.Notification, routerLink: ['/activity-feed/notifications'],permission:[AppPermissions.ReadNotification]},
  //         { label: 'My Tasks', icon: ModuleIcons.MyTasks, routerLink: ['/activity-feed/task/my-task/list'],permission:[AppPermissions.ReadTask], moduleKeyword: ModuleKeywords.MyTask},
  //         { label: 'All Tasks', icon: ModuleIcons.AllTasks, routerLink: ['/activity-feed/task/list'],permission:[AppPermissions.ReadTask], moduleKeyword: ModuleKeywords.Task},
  //     ]
  // },

  {
    label: 'Risk',
    icon: 'sf sf-risk-mangement',
    routerLink: ['/risk'],
    permission: [
      AppPermissions.ReadRiskItem,
      AppPermissions.ReadRiskMethodology,
      AppPermissions.ReadRiskProject,
      AppPermissions.ReadRiskStatement,
      AppPermissions.ReadRiskStatementCategory,
    ],
    items: [
      {
        label: 'Risk Methodologies',
        icon: ModuleIcons?.[ModuleKeywords.RiskMethodology],
        routerLink: ['/risk/risk-methodology/list'],
        permission: [AppPermissions.ReadRiskMethodology],
        moduleKeyword: ModuleKeywords.RiskMethodology,
      },
      {
        label: 'Active Risk Methodology',
        icon: ModuleIcons?.[ModuleKeywords.RiskMethodology],
        routerLink: ['/risk/risk-methodology/active'],
        permission: [AppPermissions.ReadRiskMethodology],
        moduleKeyword: ModuleKeywords.RiskMethodology,
      },
      {
        label: 'Risks', //@TODO: check breadcrumbs for adding s
        icon: ModuleIcons.RiskList,
        routerLink: ['/risk/risk-item/list'],
        permission: [AppPermissions.ReadRiskItem],
        moduleKeyword: ModuleKeywords.RiskItem,
      },
      {
        label: 'Risk Registers', //@TODO: check breadcrumbs for adding s
        icon: ModuleIcons?.[ModuleKeywords.RiskItem],
        routerLink: ['/risk/risk-item/register'],
        permission: [AppPermissions.ReadRiskRegister],
        moduleKeyword: ModuleKeywords.RiskRegister,
      },
      {
        label: 'Risk Projects',
        icon: ModuleIcons?.[ModuleKeywords.RiskProject],
        routerLink: ['/risk/risk-project/list'],
        permission: [AppPermissions.ReadRiskProject],
        moduleKeyword: ModuleKeywords.RiskProject,
      },
      {
        label: 'Risk Assessment Requests',
        icon: ModuleIcons?.[ModuleKeywords.RiskAssessmentRequest],
        routerLink: ['/risk/risk-assessment-request/list'],
        permission: [AppPermissions.ReadRiskAssessmentRequest],
        moduleKeyword: ModuleKeywords.RiskAssessmentRequest,
      },
      // { label: 'My Risk Assessment Request', icon: ModuleIcons?.[ModuleKeywords.MyRiskAssessmentRequest], routerLink: ['/risk/my-risk-assessment-request/list'], permission: [AppPermissions.ReadMyRiskAssessmentRequest], moduleKeyword: ModuleKeywords.MyRiskAssessmentRequest },
      {
        label: 'Risk Statements', //@TODO: check breadcrumbs for adding s
        icon: ModuleIcons?.[ModuleKeywords.RiskStatement],
        routerLink: ['/risk/risk-statement/list'],
        permission: [AppPermissions.ReadRiskStatement],
        moduleKeyword: ModuleKeywords.RiskStatement,
      },
      {
        label: 'Risk Statement Categories', //@TODO: check breadcrumbs for adding s
        icon: ModuleIcons?.[ModuleKeywords.RiskStatementCategory],
        routerLink: ['/risk/risk-statement-category/list'],
        permission: [AppPermissions.ReadRiskStatementCategory],
        moduleKeyword: ModuleKeywords.RiskStatementCategory,
      },
      {
        label: 'Risk Threats', //@TODO: check breadcrumbs for adding s
        icon: ModuleIcons?.[ModuleKeywords.RiskThreat],
        routerLink: ['/risk/risk-threat/list'],
        permission: [AppPermissions.ReadRiskThreat],
        moduleKeyword: ModuleKeywords.RiskThreat,
      },
      {
        label: 'Risk Vulnerabilities', //@TODO: check breadcrumbs for adding s
        icon: ModuleIcons?.[ModuleKeywords.RiskVulnerability],
        routerLink: ['/risk/risk-vulnerability/list'],
        permission: [AppPermissions.ReadRiskVulnerability],
        moduleKeyword: ModuleKeywords.RiskVulnerability,
      },
    ],
  },
  // {
  //     label: 'Pages', icon: 'pi pi-fw pi-briefcase', routerLink: ['/pages'],
  //     items: [
  //         { label: 'Landing', icon: 'pi pi-fw pi-globe', url: 'assets/pages/landing.html', target: '_blank' },
  //         { label: 'Login', icon: 'pi pi-fw pi-sign-in', routerLink: ['/login'] },
  //         { label: 'Error', icon: 'pi pi-fw pi-times-circle', routerLink: ['/error'] },
  //         { label: 'Not Found', icon: 'pi pi-fw pi-exclamation-circle', routerLink: ['/notfound'] },
  //         { label: 'Access Denied', icon: 'pi pi-fw pi-lock', routerLink: ['/access'] },
  //         { label: 'Empty', icon: 'pi pi-fw pi-circle-off', routerLink: ['/pages/empty'] },
  //         { label: 'Form Example', icon: 'pi pi-fw pi-id-card', routerLink: ['/pages/form'] },
  //         { label: 'Dynamic Form Example', icon: 'pi pi-fw pi-id-card', routerLink: ['/pages/dynamic-form'] },
  //         { label: 'Table Example', icon: 'pi pi-fw pi-list', routerLink: ['/pages/table'] }

  //     ]
  // },

  // {
  //     label: 'Authority', icon: ModuleIcons?.[ModuleKeywords.AuthorityDocument], routerLink: ['/authority'], permission: [AppPermissions.ReadAuthorityDocument, AppPermissions.ReadCitationType, AppPermissions.ReadExemption, AppPermissions.ReadCitation, AppPermissions.ReadCitationComplianceReport, AppPermissions.ReadCitationComplianceReportResult],
  //     items: [
  //         { label: 'Authority Documents', icon: ModuleIcons?.[ModuleKeywords.AuthorityDocument], routerLink: ['/authority/authority-document/list'], permission: [AppPermissions.ReadAuthorityDocument], moduleKeyword: ModuleKeywords.AuthorityDocument },
  //         { label: 'Citations', icon: ModuleIcons?.[ModuleKeywords.Citation], routerLink: ['/authority/citation/list'], permission: [AppPermissions.ReadCitation], moduleKeyword: ModuleKeywords.Citation },
  //         { label: 'Citation Types', icon: ModuleIcons?.[ModuleKeywords.CitationType], routerLink: ['/authority/citation-type/list'], permission: [AppPermissions.ReadCitationType], moduleKeyword: ModuleKeywords.CitationType },
  //         { label: 'Exemptions', icon: ModuleIcons?.[ModuleKeywords.Exemption], routerLink: ['/authority/exemption/list'], permission: [AppPermissions.ReadExemption], moduleKeyword: ModuleKeywords.Exemption },
  //         { label: 'Compliance Reports', icon: ModuleIcons?.[ModuleKeywords.ComplianceReport], routerLink: ['/authority/citation-compliance-report/list'], permission: [AppPermissions.ReadCitationComplianceReport], moduleKeyword: ModuleKeywords.CitationComplianceReport },
  //         { label: 'Compliance Report Results', icon: ModuleIcons?.[ModuleKeywords.ComplianceReportResult], routerLink: ['/authority/citation-compliance-report-result/list'], permission: [AppPermissions.ReadCitationComplianceReportResult], moduleKeyword: ModuleKeywords.CitationComplianceReportResult },
  //         // { label: 'Check Item', icon: 'pi pi-fw pi-check', routerLink: ['/authority/check-item'], permission: [AppPermissions.ReadAuthorityDocument] },
  //     ]
  // },
  {
    label: 'Issue Management',
    icon: ModuleIcons?.[ModuleKeywords.Issue],
    routerLink: ['/issue'],
    permission: [
      AppPermissions.ReadFinding,
      AppPermissions.ReadIssue,
      AppPermissions.ReadIssueType,
      AppPermissions.ReadRemediation,
    ],
    items: [
      {
        label: 'Issues',
        icon: ModuleIcons?.[ModuleKeywords.Issue],
        routerLink: ['/issue/issue/list'],
        permission: [AppPermissions.ReadIssue],
        moduleKeyword: ModuleKeywords.Issue,
      },
      {
        label: 'Issue Types',
        icon: ModuleIcons?.[ModuleKeywords.IssueType],
        routerLink: ['/issue/issue-type/list'],
        permission: [AppPermissions.ReadIssueType],
        moduleKeyword: ModuleKeywords.IssueType,
      },
      {
        label: 'Remediation Plans',
        icon: ModuleIcons?.[ModuleKeywords.Remediation],
        routerLink: ['/issue/remediation/list'],
        permission: [AppPermissions.ReadRemediation],
        moduleKeyword: ModuleKeywords.Remediation,
      },
    ],
  },
  {
    label: 'Data Management',
    icon: ModuleIcons.DataStructure,
    routerLink: ['/data-templates'],
    permission: [AppPermissions.ReadFieldTemplate, AppPermissions.ReadGlobalValueList, AppPermissions.ReadFilter],
    items: [
      {
        label: 'Field Templates',
        icon: ModuleIcons?.[ModuleKeywords.FieldTemplate],
        routerLink: ['/data-templates/field-template/list'],
        permission: [AppPermissions.ReadFieldTemplate],
        moduleKeyword: ModuleKeywords.FieldTemplate,
        production: 'test',
      },
      {
        label: 'Global Values',
        icon: ModuleIcons?.[ModuleKeywords.GlobalValueList],
        routerLink: ['/data-templates/global-value-list/list'],
        permission: [AppPermissions.ReadGlobalValueList],
        moduleKeyword: ModuleKeywords.GlobalValueList,
        production: 'test',
      },
      {
        label: 'Filters',
        icon: ModuleIcons?.[ModuleKeywords.Filter],
        routerLink: ['/data-templates/filter/list'],
        permission: [AppPermissions.ReadFilter],
        moduleKeyword: ModuleKeywords.Filter,
      },
      {
        label: 'Content Views',
        icon: ModuleIcons?.[ModuleKeywords.ContentView],
        routerLink: ['/data-templates/content-view/list'],
        permission: [AppPermissions.ReadContentView],
        moduleKeyword: ModuleKeywords.ContentView,
      },
      {
        label: 'Dashboards Management',
        icon: ModuleIcons?.[ModuleKeywords.Dashboard],
        routerLink: ['/data-templates/dashboard/list'],
        permission: [AppPermissions.ReadDashboard],
        moduleKeyword: ModuleKeywords.Dashboard,
      },
      // {
      //   label: 'Dashboards v2',
      //   icon: ModuleIcons?.[ModuleKeywords.Dashboard],
      //   routerLink: ['/data-templates/dashboard/dashboards/tabs'],
      //   permission: [AppPermissions.ReadDashboard],
      //   moduleKeyword: ModuleKeywords.Dashboard,
      // },
      // {
      //   label: 'Dashboard Cards',
      //   icon: ModuleIcons?.[ModuleKeywords.Dashboard],
      //   routerLink: ['/data-templates/dashboard/dashboards/cards'],
      //   permission: [AppPermissions.ReadDashboard],
      //   moduleKeyword: ModuleKeywords.Dashboard,
      // },
      {
        label: 'Reports',
        icon: ModuleIcons?.[ModuleKeywords.Report],
        routerLink: ['/data-templates/report/list'],
        permission: [AppPermissions.ReadReport],
        moduleKeyword: ModuleKeywords.Report,
        production: 'test',
      },
      {
        label: 'Report Configurations',
        icon: ModuleIcons?.[ModuleKeywords.ReportConfigurationTemplate],
        routerLink: ['/data-templates/report-configuration-template/list'],
        permission: [AppPermissions.ReadReportConfigurationTemplate],
        moduleKeyword: ModuleKeywords.ReportConfigurationTemplate,
        production: 'test',
      },
      {
        label: 'Report Shcedulers',
        icon: ModuleIcons?.[ModuleKeywords.ReportScheduler],
        routerLink: ['/data-templates/report-scheduler/list'],
        permission: [AppPermissions.ReadReportScheduler],
        moduleKeyword: ModuleKeywords.ReportScheduler,
        production: 'test',
      },
      {
        label: 'Generated Reports',
        icon: ModuleIcons?.[ModuleKeywords.GeneratedReport],
        routerLink: ['/data-templates/generated-report/list'],
        permission: [AppPermissions.ReadGeneratedReport],
        moduleKeyword: ModuleKeywords.GeneratedReport,
        production: 'test',
      },
      {
        label: 'Statistic Collectors',
        icon: ModuleIcons?.[ModuleKeywords.StatisticCollector],
        routerLink: ['/data-templates/statistic-collector/list'],
        permission: [AppPermissions.ReadStatisticCollector],
        moduleKeyword: ModuleKeywords.StatisticCollector,
        production: 'test',
      },
      {
        label: 'Generated Statistics',
        icon: ModuleIcons?.[ModuleKeywords.GeneratedStatistic],
        routerLink: ['/data-templates/generated-statistic/list'],
        permission: [AppPermissions.ReadGeneratedStatistic],
        moduleKeyword: ModuleKeywords.GeneratedStatistic,
        production: 'test',
      },
      {
        label: 'Tags',
        icon: ModuleIcons?.[ModuleKeywords.Tag],
        routerLink: ['/data-templates/tag/list'],
        permission: [AppPermissions.ReadTag],
        moduleKeyword: ModuleKeywords.Tag,
      },
    ],
  },
  {
    label: 'Relation',
    icon: 'pi pi-share-alt',
    routerLink: ['/relation'],
    permission: [AppPermissions.ReadRelationType],
    items: [
      {
        label: 'Relation Types',
        icon: ModuleIcons?.[ModuleKeywords.RelationType],
        routerLink: ['/relation/relation-type/list'],
        permission: [AppPermissions.ReadRelationType],
        moduleKeyword: ModuleKeywords.RelationType,
      },
    ],
  },

  {
    label: 'Questions',
    icon: ModuleIcons.Questions,
    routerLink: ['/question'],
    permission: [AppPermissions.ReadQuestion, AppPermissions.ReadQuestionnaire, AppPermissions.ReadQuestionnaireValue],
    items: [
      {
        label: 'Question Bank',
        icon: ModuleIcons?.[ModuleKeywords.Question],
        routerLink: ['/question/list'],
        permission: [AppPermissions.ReadQuestion],
        moduleKeyword: ModuleKeywords.Question,
      },
      {
        label: 'Questionnaires',
        icon: ModuleIcons?.[ModuleKeywords.Questionnaire],
        routerLink: ['/question/questionnaire/list'],
        permission: [AppPermissions.ReadQuestionnaire],
        moduleKeyword: ModuleKeywords.Questionnaire,
      },
      {
        label: 'Questionnaires Answers',
        icon: ModuleIcons?.[ModuleKeywords.QuestionnaireValue],
        routerLink: ['/question/questionnaire-value/list'],
        permission: [AppPermissions.ReadQuestionnaireValue],
        moduleKeyword: ModuleKeywords.QuestionnaireValue,
      },
      // { label: 'My Questionnaires Answers', icon: ModuleIcons?.[ModuleKeywords.MyQuestionnaireValue], routerLink: ['/question/my-questionnaire-value/list'], permission: [AppPermissions.ReadMyQuestionnaireValue], moduleKeyword: ModuleKeywords.MyQuestionnaireValue },
    ],
  },
  {
    label: 'Transparency',
    icon: 'pi pi-receipt',
    routerLink: ['/transparency-management'],
    permission: [AppPermissions.ReadTransparencyCase, AppPermissions.ReadTransparencyCaseCategory],
    production: 'test',
    items: [
      {
        label: 'Case Categories', //@TODO: check breadcrumbs for adding s
        icon: 'pi pi-clone',
        routerLink: ['/transparency-management/transparency-case-category/list'],
        permission: [AppPermissions.ReadTransparencyCaseCategory],
        moduleKeyword: ModuleKeywords.TransparencyCaseCategory,
        production: 'test',
      },
      {
        label: 'Cases', //@TODO: check breadcrumbs for adding s
        icon: 'pi pi-circle-on',
        routerLink: ['/transparency-management/transparency-case/list'],
        permission: [AppPermissions.ReadTransparencyCase],
        moduleKeyword: ModuleKeywords.TransparencyCase,
        production: 'test',
      },
    ],
  },
  {
    label: 'Global Package',
    icon: 'sf sf-global-packages',
    routerLink: ['/global-package'],
    permission: [AppPermissions.ReadImportGlobalPackage, AppPermissions.ReadExportGlobalPackage],
    production: 'test',
    items: [
      {
        label: 'Export Global Packages', //@TODO: check breadcrumbs for adding s
        icon: ModuleIcons?.[ModuleKeywords.ExportGlobalPackage],
        routerLink: ['/global-package/export-global-package/list'],
        permission: [AppPermissions.ReadExportGlobalPackage],
        moduleKeyword: ModuleKeywords.ExportGlobalPackage,
        production: 'test',
      },
      {
        label: 'Import Global Packages', //@TODO: check breadcrumbs for adding s
        icon: ModuleIcons?.[ModuleKeywords.ImportGlobalPackage],
        routerLink: ['/global-package/import-global-package/list'],
        permission: [AppPermissions.ReadImportGlobalPackage],
        moduleKeyword: ModuleKeywords.ImportGlobalPackage,
        production: 'test',
      },
    ],
  },
  {
    label: 'Performance',
    icon: ModuleIcons?.PERFORMANCE,
    routerLink: ['/performance'],
    permission: [AppPermissions.ReadMetric, AppPermissions.ReadMetricValue, AppPermissions.ReadMetricCategory],
    production: 'test',
    items: [
      {
        label: 'Metrics', //@TODO: check breadcrumbs for adding s
        icon: ModuleIcons?.[ModuleKeywords.Metric],
        routerLink: ['/performance/metric/list'],
        permission: [AppPermissions.ReadMetric],
        moduleKeyword: ModuleKeywords.Metric,
        production: 'test',
      },
      {
        label: 'Metric Values', //@TODO: check breadcrumbs for adding s
        icon: ModuleIcons?.[ModuleKeywords.MetricValue],
        routerLink: ['/performance/metric-value/list'],
        permission: [AppPermissions.ReadMetricValue],
        moduleKeyword: ModuleKeywords.MetricValue,
        production: 'test',
      },
      {
        label: 'Metric Categories', //@TODO: check breadcrumbs for adding s
        icon: ModuleIcons?.[ModuleKeywords.MetricCategory],
        routerLink: ['/performance/metric-category/list'],
        permission: [AppPermissions.ReadMetricCategory],
        moduleKeyword: ModuleKeywords.MetricCategory,
        production: 'test',
      },
    ],
  },
  {
    label: 'Management',
    icon: ModuleIcons.Management,
    routerLink: ['/user-management'],
    permission: [
      AppPermissions.ReadUser,
      AppPermissions.ReadRole,
      AppPermissions.ReadGroup,
      AppPermissions.ReadStaticValue,
    ],
    items: [
      {
        label: 'Users',
        icon: ModuleIcons?.[ModuleKeywords.User],
        routerLink: ['/user-management/user/list'],
        permission: [AppPermissions.ReadUser],
        moduleKeyword: ModuleKeywords.User,
      },
      {
        label: 'Groups',
        icon: ModuleIcons?.[ModuleKeywords.Group],
        routerLink: ['/user-management/group/list'],
        permission: [AppPermissions.ReadGroup],
        moduleKeyword: ModuleKeywords.Group,
      },
      {
        label: 'Roles',
        icon: ModuleIcons?.[ModuleKeywords.Role],
        routerLink: ['/user-management/role/list'],
        permission: [AppPermissions.ReadRole],
        moduleKeyword: ModuleKeywords.Role,
      },
      // { label: 'Services',      icon: 'pi pi-fw pi-server', routerLink: ['/user-management/service/list'], permission: [AppPermissions.ReadRole] },
      // { label: 'Properties',      icon: 'pi pi-fw pi-flag', routerLink: ['/user-management/property/list'], permission: [AppPermissions.ReadRole] },
      {
        label: 'Branding',
        icon: 'sf sf-branding',
        routerLink: ['/user-management/static-values'],
        permission: [AppPermissions.ReadStaticValue],
        moduleKeyword: ModuleKeywords.StaticValue,
      },
    ],
  },
];
export interface MenuItem {
  separator?: any;
  label?: string;
  icon?: string;
  routerLink?: string[];
  permission?: string[]; // Assuming permission is an array of strings
  moduleKeyword?: ModuleKeywords; // Optional moduleKeyword property
  items?: MenuItem[]; // Optional nested items
  class?: string;
  production?: ProductionType;
}

import { Injectable } from '@angular/core';
import { DataTypeEnum, GeneratedReportDto, IFieldDefinition, TargetTypeEnum } from '@shared/classes';
import { BaseFieldDefinitionsService } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class GeneratedReportMappingService extends BaseFieldDefinitionsService {
  recordFields: IFieldDefinition[] = [
    {
      name: 'Status',
      key: 'status',
      dataType: DataTypeEnum.Badge,
      required: false,
      showInTable: true,
      description: 'Status of the generation',
      tableDefinition: {
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: GeneratedReportDto.StatusEnum,
        },
      },
    },
    // {
    //   name: 'Principle',
    //   key: 'principle',
    //   dataType: DataTypeEnum.UserListView,
    //   required: false,
    //   showInTable: true,
    //   description: 'The user who created this record.',
    //   tableDefinition: {
    //     filter: {
    //       type: 'userSelector',
    //       matchMode: 'in',
    //       showMatchModes: false,
    //       showAddButton: false,
    //       showOperator: false,
    //     },
    //   },
    // },
    {
      name: 'Notes',
      key: 'notes',
      dataType: DataTypeEnum.LongWithHoverText,
      required: false,
      showInTable: true,
      description: 'Notes of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Report Generation',
      key: 'reportGeneration',
      dataType: DataTypeEnum.Badge,
      required: false,
      showInTable: true,
      description: 'The action that generated the report',
      tableDefinition: {
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: GeneratedReportDto.ReportGenerationEnum,
        },
      },
    },
    {
      name: 'Format',
      key: 'format',
      dataType: DataTypeEnum.Badge,
      required: false,
      showInTable: true,
      description: 'Format of the generated file',
      tableDefinition: {
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: GeneratedReportDto.FormatEnum,
        },
      },
    },
    {
      name: 'Date',
      key: 'date',
      dataType: DataTypeEnum.DateLong,
      required: false,
      showInTable: true,
      description: 'Date of the generated report',
      tableDefinition: {
        filter: {
          type: 'date',
          matchMode: 'dateBefore',
        },
      },
    },
    {
      name: 'Report Scheduler',
      key: 'reportScheduler',
      dataType: DataTypeEnum.CodeLink,
      required: true,
      showInTable: true,
      description: 'Report Scheduler linked to the generated report',
      tableDefinition: {
        filter: {
          type: 'recordCode',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          recordCodeType: TargetTypeEnum.ReportScheduler,
        },
      },
    },
  ];
  constructor() {
    super();
    this.setAllFields();
  }
}

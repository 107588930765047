import { Injectable } from '@angular/core';
import { IFieldDefinition, DataTypeEnum, AppPermissions, TargetTypeEnum } from '@shared/classes';
import { BaseFieldDefinitionsService } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class PolicyBodyMappingService extends BaseFieldDefinitionsService {
  recordFields: IFieldDefinition[] = [
    {
      name: 'Name',
      key: 'label',
      dataType: DataTypeEnum.Text,
      required: true,
      showInTable: true,
      description: 'Record Name',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Version',
      key: 'versionNumber',
      dataType: DataTypeEnum.Text,
      required: true,
      showInTable: true,
      description: 'Record Version Number',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    // {
    //     name: "Body",
    //     key: "body",
    //     dataType: DataTypeEnum.LongWithHoverText,
    //     required: true,
    //     showInTable: true,
    //     description: "Record Body",
    //     tableDefinition:{
    //         filter: {
    //             type: "text",
    //             display: "menu",
    //             matchMode: "startsWith",
    //             showMatchModes: true,
    //             showAddButton: true,
    //             showOperator: true,
    //         },
    //     }
    // },
    {
      name: 'Policy',
      key: 'policy',
      dataType: DataTypeEnum.CodeLink,
      required: false,
      showInTable: true,
      permissions: AppPermissions.ReadPolicy,
      description: 'Policy of the body',
      tableDefinition: {
        filter: {
          type: 'recordCode',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          recordCodeType: TargetTypeEnum.Policy,
        },
      },
    },
    {
      name: 'ReviewedBy',
      key: 'reviewedBy',
      dataType: DataTypeEnum.UserListView,
      required: false,
      showInTable: true,
      description: 'Users who should reviewedBy',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'approvedBy',
      key: 'approvedBy',
      dataType: DataTypeEnum.UserListView,
      required: false,
      showInTable: true,
      description: 'Users who approved',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Description',
      key: 'description',
      dataType: DataTypeEnum.Text,
      required: true,
      showInTable: true,
      description: 'Record Description',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
  ];
  constructor() {
    super();
    this.setAllFields();
  }
}

import { Injectable } from '@angular/core';
import { DataTypeEnum, FieldDto, IFieldDefinition } from '@shared/classes';
import { BaseFieldDefinitionsService } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class FieldsMappingService extends BaseFieldDefinitionsService {
  recordFields: IFieldDefinition[] = [
    {
      name: 'Name',
      key: 'name',
      dataType: DataTypeEnum.Text,
      required: true,
      showInTable: true,
      description: 'Name of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Type',
      key: 'type',
      dataType: DataTypeEnum.Badge,
      required: true,
      showInTable: true,
      description: 'Type of the record',
      tableDefinition: {
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: FieldDto.TypeEnum,
        },
      },
    },
    {
      name: 'Category',
      key: 'fieldCategory',
      dataType: DataTypeEnum.Badge,
      required: true,
      showInTable: true,
      description: 'Category of the record',
      tableDefinition: {
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: FieldDto.FieldCategoryEnum,
        },
      },
    },
  ];
  constructor() {
    super();
    this.setAllFields();
  }
}

@for (item of formList; track item; let i1 = $index) {
  <div class="flex flex-column gap-4 w-full">
    <p-divider align="left">
      <b>{{ item?.item?.question }}</b>
      @if (item?.item?.scoringType == 'SCORED') {
        Score 0/100
      }
    </p-divider>
    <div class="formgrid grid p-fluid">
      <div class="col-12">
        <app-dynamic-field-list-input
          [label]="''"
          [placeholder]="'Enter Question Value'"
          [control]="$any(formArray?.controls?.[i1])?.controls?.dynamics"
          [viewMode]="viewMode"
          [config]="{ dynamicFields: item?.item?.fields, dynamicFieldValues: item?.value?.dynamics }"
        >
        </app-dynamic-field-list-input>
      </div>
    </div>
  </div>
}

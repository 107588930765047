import { Injectable } from '@angular/core';
import { ComplianceReportResultDto, DataTypeEnum, IFieldDefinition } from '@shared/classes';
import { BaseFieldDefinitionsService } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class ComplianceReportMappingService extends BaseFieldDefinitionsService {
  recordFields: IFieldDefinition[] = [
    {
      name: 'Name',
      key: 'name',
      dataType: DataTypeEnum.Text,
      required: true,
      showInTable: true,
      description: 'Record Name',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Frequency',
      key: 'frequency',
      dataType: DataTypeEnum.Badge,
      required: true,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Frequency of the report',
      tableDefinition: {
        filter: {
          type: 'numeric',
          display: 'menu',
          matchMode: 'lte',
        },
      },
    },
    {
      name: 'Compliance Report Type',
      key: 'complianceReportType',
      dataType: DataTypeEnum.Badge,
      required: false,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Compliance Report Type (Scope) of the Record',
      tableDefinition: {
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: ComplianceReportResultDto.ComplianceReportTypeEnum,
        },
      },
    },
    // {
    //   name: 'Policy',
    //   key: 'policy',
    //   dataType: DataTypeEnum.CodeLink,
    //   required: true,
    //   showInTable: true,
    //   permissions: AppPermissions.ReadPolicy,
    //   description: 'Policy Linked to the control',
    //   tableDefinition: {
    //     filter: {
    //       type: 'text',
    //       display: 'menu',
    //       matchMode: 'startsWith',
    //       showMatchModes: true,
    //       showAddButton: true,
    //       showOperator: true,
    //     },
    //   },
    // },
    // {
    //   name: 'Description',
    //   key: 'description',
    //   dataType: DataTypeEnum.Text,
    //   required: true,
    //   showInTable: true,
    //   // permissions: AppPermissions.ReadGroup,
    //   description: 'Description of the report',
    //   tableDefinition: {
    //     filter: {
    //       type: 'text',
    //       display: 'menu',
    //       matchMode: 'startsWith',
    //       showMatchModes: true,
    //       showAddButton: true,
    //       showOperator: true,
    //     },
    //   },
    // },
  ];
  constructor() {
    super();
    this.setAllFields();
  }
}
